<template>
  <div class="col col-md-10 shadow-2 bg-white">
    <div class="q-pa-md">
      <div class="space-y-4">
        <fieldset :disabled="awaitSaving" class="no-border q-px-none">
          <q-input
            v-model="name"
            :error="v$.name.$error || !!backendValidationErrors.name"
            :error-message="getValidationErrorMessage('name')"
            autofocus
            bottom-slots
            hint="например, entity.action.some.value"
            label="Название"
          />

          <q-input
            v-model.trim="readable_name"
            :error="
              v$.readable_name.$error || !!backendValidationErrors.readable_name
            "
            :error-message="getValidationErrorMessage('readable_name')"
            bottom-slots
            label="Аннотация"
          />
        </fieldset>

        <q-btn
          :disable="awaitSaving"
          :loading="awaitSaving"
          class="full-width"
          color="primary"
          icon="mdi-content-save"
          label="Сохранить"
          @click="submitForm"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import api from "@/api";
  import useVuelidate from "@vuelidate/core";
  import { required, maxLength } from "@/utils/i18n-validators";
  import { createMetaMixin } from "quasar";

  export default {
    name: "PermissionAdd",

    setup() {
      return { v$: useVuelidate() };
    },

    mixins: [
      createMetaMixin(function () {
        return {
          title: "Добавить разрешение",
        };
      }),
    ],

    data() {
      return {
        awaitSaving: false,
        readable_name: null,
        name: null,
        backendValidationErrors: {},
      };
    },

    methods: {
      async submitForm() {
        this.awaitSaving = true;

        const valid = await this.v$.$validate();

        if (!valid) {
          this.awaitSaving = false;

          return;
        }

        api.permission
          .create({
            readable_name: this.readable_name,
            name: this.name,
          })
          .then(
            (res) => {
              if (res.status === 201) {
                this.$q.notify({
                  color: "positive",
                  message: res.data.message,
                });
              }
            },
            (error) => {
              if (error.response.status === 422) {
                this.backendValidationErrors = error.response.data.errors;
              }
            }
          )
          .then(() => {
            this.awaitSaving = false;
          });
      },

      getValidationErrorMessage(field) {
        if (this.v$[field].$error) {
          return this.v$[field].$errors[0].$message;
        } else if (this.backendValidationErrors[field]) {
          return this.backendValidationErrors[field][0];
        }

        return "";
      },
    },

    validations() {
      return {
        readable_name: { required, maxLength: maxLength(100) },
        name: { required, maxLength: maxLength(100) },
      };
    },
  };
</script>
