// endpoints
import auth from "./endpoints/auth";
import user from "./endpoints/user";
import role from "./endpoints/role";
import permission from "./endpoints/permission";
import box from "./endpoints/box";
import boxMark from "./endpoints/boxMark";
import boxBell from "./endpoints/boxBell";
import apBox from "./endpoints/apBox";
import apProject from "./endpoints/apProject";
import compound from "./endpoints/compound";
import log from "./endpoints/log";
import cadastral from "./endpoints/cadastral";
import region from "./endpoints/region";
import declaration from "./endpoints/declaration";
import declarationComposite from "./endpoints/declarationComposite";
import declarationCorrection from "./endpoints/declarationCorrection";
import pdFile from "./endpoints/pdFile";
import settings from "./endpoints/settings";
import utils from "./endpoints/utils";
import comment from "./endpoints/comment";

const api = {
  auth,
  user,
  role,
  permission,
  box,
  boxMark,
  boxBell,
  apBox,
  apProject,
  compound,
  log,
  cadastral,
  region,
  declaration,
  declarationComposite,
  declarationCorrection,
  pdFile,
  settings,
  utils,
  comment,
};

export default api;
