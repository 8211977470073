import { axiosInstance as axios } from "@/plugins/axios";
import { deleteKeysWithEmptyValues } from "@/utils/batch";

const pdFile = {
  find(params, include = "") {
    deleteKeysWithEmptyValues(params.q);

    return axios.get("/pd-files", {
      params: {
        q: params.q,
        sort_by: params.sort_by,
        descending: params.descending,
        page: params.page,
        limit: params.limit,
        include: include,
      },
    });
  },

  parse(id) {
    return axios.get(`/pd-files/${id}/parse`);
  },

  delete(id) {
    return axios.delete(`/pd-files/${id}`);
  },
};

export default pdFile;
