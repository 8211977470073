<template>
  <q-dialog
    ref="dialog"
    position="top"
    transition-hide="slide-up"
    transition-show="slide-down"
    @hide="onDialogHide"
  >
    <q-card style="width: 600px; max-width: 600px">
      <div class="flex items-center justify-between q-px-md">
        <div>Добавить ДомРФ корпус</div>
        <q-btn v-close-popup flat dense icon="mdi-close" size="lg" />
      </div>

      <q-card-section>
        <div class="flex wrap q-gutter-y-md">
          <div class="full-width">
            <q-toggle v-model="isZsk" :disable="awaitResponse" label="ЖСК" />
          </div>
          <div class="full-width">
            <q-input
              v-model.trim="serial"
              :disable="awaitResponse"
              :error="v$.serial.$error"
              :error-message="
                v$.serial.$error ? v$.serial.$errors[0].$message : ''
              "
              autofocus
              bottom-slots
              clearable
              label="Номер"
            />
          </div>
        </div>
      </q-card-section>

      <q-card-actions class="q-pa-md justify-between">
        <q-btn
          v-close-popup
          :disable="awaitResponse"
          label="Отмена"
          title="Закрыть окно"
        />
        <q-btn
          :disable="awaitResponse"
          :loading="awaitResponse"
          color="primary"
          label="Добавить"
          title="Добавить"
          @click="submit"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
  import api from "@/api";
  import { required } from "@/utils/i18n-validators";
  import useVuelidate from "@vuelidate/core";

  export default {
    name: "CreateBoxDialog",

    setup() {
      return { v$: useVuelidate() };
    },

    emits: ["hide"],

    data() {
      return {
        awaitResponse: false,
        serial: null,
        isZsk: false,
      };
    },

    methods: {
      async submit() {
        this.awaitResponse = true;

        const valid = await this.v$.$validate();

        if (!valid) {
          this.awaitResponse = false;

          return;
        }

        let payload = {
          serial: this.serial,
          is_zsk: this.isZsk,
        };

        api.box
          .create(payload)
          .then(
            (res) => {
              this.$q.notify({
                color: "positive",
                message: res.data.message,
              });

              this.hide();
            },
            (error) => {
              this.$q.notify({
                color: "negative",
                message: error.response.data.message,
              });
            }
          )
          .then(() => {
            this.awaitResponse = false;
          });
      },

      // following method is REQUIRED
      // (don't change its name --> "show")
      show() {
        this.$refs.dialog.show();
      },

      // following method is REQUIRED
      // (don't change its name --> "hide")
      hide() {
        this.$refs.dialog.hide();
      },

      onDialogHide() {
        this.$emit("hide");
      },
    },

    validations() {
      return {
        serial: { required },
      };
    },
  };
</script>
