import { removeAuthDataFromStorage, setAuthDataToStorage } from "@/utils/batch";
import {
  captureException as SentryException,
  configureScope as SentryConfigureScope,
} from "@sentry/vue";
import api from "@/api";
import { mutations } from "@/store/auth/mutations";
import { axiosInstance } from "@/plugins/axios";

export const actions = {
  login: "login",
  fetchUser: "fetchUser",
  logoutUser: "logoutUser",
  refreshToken: "refreshToken",
};

export default {
  async [actions.login]({ dispatch }, credentials) {
    try {
      const res = await api.auth.login(credentials);

      if (
        res.data.access_token &&
        res.data.refresh_token &&
        res.data.expires_in
      ) {
        await setAuthDataToStorage(
          res.data.access_token,
          res.data.refresh_token,
          res.data.expires_in
        );

        // sync auth between opened windows, see App.vue syncAuth() method.
        localStorage.setItem("login", "true");

        // localStorage event not executed on same window and will stay if only one window opened
        setTimeout(() => {
          localStorage.removeItem("login");
        }, 1000);

        return await dispatch(actions.fetchUser);
      }

      return res;
    } catch (error) {
      throw error;
    }
  },

  async [actions.fetchUser]({ commit }) {
    try {
      let res = await api.auth.me("roles,regions");
      let user = res.data.user;
      let permissions = res.data.permissions.map((item) => item.name);

      if (res.status === 200 && user && permissions) {
        commit(mutations.SET_USER, user);
        commit(mutations.SET_PERMISSIONS, permissions);

        SentryConfigureScope((scope) => {
          scope.setUser({
            id: user.id,
            username: user.name,
            email: user.email,
          });
        });
      }

      return res;
    } catch (error) {
      await removeAuthDataFromStorage();

      // cause if auth.me() request fails, it's usually problem like server error.
      // normally auth.me() request should go only if access token is valid
      SentryException(error);

      SentryConfigureScope((scope) => {
        scope.setUser({});
      });
    }
  },

  async [actions.logoutUser]({ commit }) {
    try {
      const res = await api.auth.logout();

      if (res.status === 204) {
        await removeAuthDataFromStorage();
        commit("UNSET_USER");
        commit("UNSET_PERMISSIONS");

        SentryConfigureScope((scope) => {
          scope.setUser({});
        });

        // sync auth between opened windows, see App.vue syncAuth() method.
        localStorage.setItem("logout", "true");

        // localStorage event not executed on same window and will stay if only one window opened
        setTimeout(() => {
          localStorage.removeItem("logout");
        }, 1000);
      }

      return res;
    } catch (error) {
      // note: move to /login with any error type
      if (error.response && error.response.status) {
        // clear storage only when http response come.
        // what reason for? 401 token expired?
        if (error.response.status === 401) {
          await removeAuthDataFromStorage();
          commit("UNSET_USER");
          commit("UNSET_PERMISSIONS");

          SentryConfigureScope((scope) => {
            scope.setUser({});
          });
        }

        return error.response;
      }

      SentryException(error);
    }
  },

  async [actions.refreshToken]({ state }, refreshToken) {
    try {
      const res = await api.auth.refresh(refreshToken);

      if (res.status && res.status === 200) {
        await setAuthDataToStorage(
          res.data.access_token,
          res.data.refresh_token,
          res.data.expires_in
        );

        axiosInstance.interceptors.request.use((config) => {
          config.headers["Authorization"] = `Bearer ${res.data.access_token}`;

          return config;
        });
      }

      return res;
    } catch (error) {
      // clear storage only when http response come.
      if (error.response) {
        await removeAuthDataFromStorage();

        axiosInstance.interceptors.request.use((config) => {
          config.headers["Authorization"] = "";

          return config;
        });

        return error.response;
      }

      SentryException(error);
    }
  },
};
