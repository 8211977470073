<template>
  <div v-if="!loading" class="col">
    <div class="row justify-center">
      <div class="col col-md-12">
        <div class="row q-col-gutter-x-md">
          <div class="col-24 col-md-12">
            <q-table
              :columns="columns"
              :loading="loading"
              :rows="sourceDbHasSerialPrefix"
              dense
              row-key="alias"
              :rows-per-page-options="[10]"
            >
              <template v-slot:top class="flex items-center">
                <div class="q-mr-md">Source DB has serial prefix</div>
                <q-btn
                  icon="mdi-plus"
                  dense
                  @click="
                    sourceDbHasSerialPrefix.unshift({
                      alias: 'alias',
                      prefix: 'prefix',
                    })
                  "
                />
              </template>

              <template v-slot:body="props">
                <q-tr :props="props">
                  <q-td key="alias" :props="props">
                    <span class="q-mr-sm">{{ props.row.alias }}</span>
                    <q-btn icon="mdi-pencil" flat dense size="sm">
                      <q-popup-edit
                        v-model="props.row.alias"
                        auto-save
                        v-slot="scope"
                        @save=""
                      >
                        <q-input v-model="scope.value" dense autofocus />
                      </q-popup-edit>
                    </q-btn>
                  </q-td>
                  <q-td key="prefix" :props="props">
                    <span class="q-mr-sm">{{ props.row.prefix }}</span>
                    <q-btn icon="mdi-pencil" flat dense size="sm">
                      <q-popup-edit
                        v-model="props.row.prefix"
                        auto-save
                        v-slot="scope"
                        @save=""
                      >
                        <q-input v-model="scope.value" dense autofocus />
                      </q-popup-edit>
                    </q-btn>
                  </q-td>
                  <q-td key="actions" :props="props">
                    <q-btn
                      dense
                      flat
                      icon="mdi-delete"
                      @click="sourceDbHasSerialPrefix.splice(props.rowIndex, 1)"
                    />
                  </q-td>
                </q-tr>
              </template>
            </q-table>
          </div>

          <div class="col-24 col-md-12">
            <q-table
              :columns="columns"
              :loading="loading"
              :rows="regionAliasHasSerialPrefix"
              dense
              row-key="alias"
              :rows-per-page-options="[10]"
            >
              <template v-slot:top class="flex items-center">
                <div class="q-mr-md">Region alias has serial prefix</div>
                <q-btn
                  icon="mdi-plus"
                  dense
                  @click="
                    regionAliasHasSerialPrefix.unshift({
                      alias: 'alias',
                      prefix: 'prefix',
                    })
                  "
                />
              </template>

              <template v-slot:body="props">
                <q-tr :props="props">
                  <q-td key="alias" :props="props">
                    <span class="q-mr-sm">{{ props.row.alias }}</span>
                    <q-btn icon="mdi-pencil" flat dense size="sm">
                      <q-popup-edit
                        v-model="props.row.alias"
                        auto-save
                        v-slot="scope"
                        @save=""
                      >
                        <q-input v-model="scope.value" dense autofocus />
                      </q-popup-edit>
                    </q-btn>
                  </q-td>
                  <q-td key="prefix" :props="props">
                    <span class="q-mr-sm">{{ props.row.prefix }}</span>
                    <q-btn icon="mdi-pencil" flat dense size="sm">
                      <q-popup-edit
                        v-model="props.row.prefix"
                        auto-save
                        v-slot="scope"
                        @save=""
                      >
                        <q-input v-model="scope.value" dense autofocus />
                      </q-popup-edit>
                    </q-btn>
                  </q-td>
                  <q-td key="actions" :props="props">
                    <q-btn
                      dense
                      flat
                      icon="mdi-delete"
                      @click="
                        regionAliasHasSerialPrefix.splice(props.rowIndex, 1)
                      "
                    />
                  </q-td>
                </q-tr>
              </template>
            </q-table>
          </div>

          <div class="col-24">
            <q-btn
              :loading="awaitUpdating"
              :disable="awaitUpdating"
              label="Сохранить"
              color="primary"
              class="q-mt-md"
              @click="save"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import api from "@/api";
  import { createMetaMixin } from "quasar";

  export default {
    name: "ApiApSettings",

    mixins: [
      createMetaMixin(function () {
        return {
          title: "Настройки API AP",
        };
      }),
    ],

    async mounted() {
      await this.setSettings();
      this.loading = false;
    },

    data() {
      return {
        loading: true,
        awaitUpdating: false,
        sourceDbHasSerialPrefix: [],
        regionAliasHasSerialPrefix: [],
      };
    },

    computed: {
      columns() {
        return [
          {
            name: "alias",
            required: true,
            label: "Alias",
            align: "left",
            field: (row) => row.alias,
            sortable: true,
          },
          {
            name: "prefix",
            required: true,
            label: "Prefix",
            align: "left",
            field: (row) => row.prefix,
            sortable: true,
          },
          {
            name: "actions",
            label: "",
            align: "right",
          },
        ];
      },
    },

    methods: {
      async setSettings() {
        const res = await api.settings.findApiAp();

        Object.keys(res.data.source_db_has_serial_prefix).forEach((alias) => {
          this.sourceDbHasSerialPrefix.push({
            alias: alias,
            prefix: res.data.source_db_has_serial_prefix[alias],
          });
        });

        Object.keys(res.data.region_alias_has_serial_prefix).forEach(
          (alias) => {
            this.regionAliasHasSerialPrefix.push({
              alias: alias,
              prefix: res.data.region_alias_has_serial_prefix[alias],
            });
          }
        );

        this.loading = false;
      },

      async save() {
        let source_db_has_serial_prefix = {};
        let region_alias_has_serial_prefix = {};

        this.sourceDbHasSerialPrefix.forEach((item) => {
          source_db_has_serial_prefix[item.alias] = item.prefix;
        });

        this.regionAliasHasSerialPrefix.forEach((item) => {
          region_alias_has_serial_prefix[item.alias] = item.prefix;
        });

        let payload = {
          source_db_has_serial_prefix,
          region_alias_has_serial_prefix,
        };

        this.awaitUpdating = true;

        return api.settings
          .updateApiAp(payload)
          .then(
            (res) => {
              this.$q.notify({
                color: "positive",
                message: res.data.message,
              });
            },
            (error) => {
              this.$q.notify({
                color: "negative",
                message: error.response.data.message,
              });
            }
          )
          .then(() => {
            this.awaitUpdating = false;
          });
      },
    },
  };
</script>
