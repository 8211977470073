export const columns = [
  {
    name: "actions",
    label: "",
    style: "width: 80px;",
  },
  {
    name: "id",
    label: "ID",
    style: "width: 80px;",
  },
  {
    name: "dated_at",
    label: "Дата слоя",
    style: "width: 100px;",
  },
  {
    name: "box_serial",
    label: "Номер ДомРФ",
    style: "width: 80px;",
  },
  {
    name: "box_mark",
    label: "Статус ДомРФ",
    style: "width: 140px;",
  },
  {
    name: "ap_box_serial",
    label: "АП корпуса",
    style: "width: 100px;",
  },
  {
    name: "box_name",
    label: "Название ДомРФ",
    style: "width: 240px;",
  },
  {
    name: "pd_number",
    style: "width: 100px;",
  },
  {
    name: "published_at",
    style: "width: 100px;",
  },
  {
    name: "company",
    style: "width: 200px;",
  },
  {
    name: "builder_inn",
    style: "width: 120px;",
  },
  {
    name: "reported_at",
    style: "width: 100px;",
  },
  {
    name: "buildings_qty",
    style: "width: 80px;",
  },
  {
    name: "floors_min",
    style: "width: 80px;",
  },
  {
    name: "floors_max",
    style: "width: 80px;",
  },
  {
    name: "object_square",
    style: "width: 100px;",
  },
  {
    name: "wall_frame_material",
    style: "width: 240px;",
  },
  {
    name: "floor_material",
    style: "width: 140px;",
  },
  {
    name: "living_square",
    style: "width: 100px;",
  },
  {
    name: "nonliving_square",
    style: "width: 100px;",
  },
  {
    name: "total_square",
    style: "width: 100px;",
  },
  {
    name: "general_contractors",
    style: "width: 240px;",
  },
  {
    name: "construction_permit_number",
    style: "width: 160px;",
  },
  {
    name: "construction_permit_issued_at",
    style: "width: 100px;",
  },
  {
    name: "construction_permit_valid_until",
    style: "width: 100px;",
  },
  {
    name: "construction_permit_valid_until_updated_at",
    style: "width: 100px;",
  },
  {
    name: "builder_rights_ground_valid_until",
    style: "width: 100px;",
  },
  {
    name: "builder_rights_contract_updated_at",
    style: "width: 100px;",
  },
  {
    name: "cadastrals",
    style: "width: 160px;",
  },
  {
    name: "living_total_qty",
    style: "width: 80px;",
  },
  {
    name: "nonliving_total_qty",
    style: "width: 80px;",
  },
  {
    name: "nonliving_total_parking_qty",
    style: "width: 80px;",
  },
  {
    name: "nonliving_total_other_qty",
    style: "width: 80px;",
  },
  {
    name: "construction_stages",
    style: "width: 160px;",
  },
  {
    name: "keys_initial_issue_at",
    style: "width: 100px;",
  },
  {
    name: "keys_planned_issue_at",
    style: "width: 100px;",
  },

  {
    name: "builder_obligations_securing",
    style: "width: 160px;",
  },
  {
    name: "escrow_bank",
    style: "width: 160px;",
  },
  {
    name: "payment_contributions_fund",
    style: "width: 100px;",
  },
  {
    name: "fund_bank",
    style: "width: 160px;",
  },
  {
    name: "builder_raising_money",
    style: "width: 160px;",
  },
  {
    name: "financing_bank",
    style: "width: 160px;",
  },
  {
    name: "living_escrow_qty",
    style: "width: 80px;",
  },
  {
    name: "living_fund_qty",
    style: "width: 80px;",
  },
  {
    name: "living_other_qty",
    style: "width: 80px;",
  },
  {
    name: "nonliving_escrow_qty",
    style: "width: 80px;",
  },
  {
    name: "nonliving_fund_qty",
    style: "width: 80px;",
  },
  {
    name: "nonliving_other_qty",
    style: "width: 80px;",
  },
  {
    name: "parking_escrow_qty",
    style: "width: 80px;",
  },
  {
    name: "parking_fund_qty",
    style: "width: 80px;",
  },
  {
    name: "parking_other_qty",
    style: "width: 80px;",
  },
  {
    name: "living_escrow_square",
    style: "width: 100px;",
  },
  {
    name: "living_fund_square",
    style: "width: 100px;",
  },
  {
    name: "living_other_square",
    style: "width: 100px;",
  },
  {
    name: "nonliving_escrow_square",
    style: "width: 100px;",
  },
  {
    name: "nonliving_fund_square",
    style: "width: 100px;",
  },
  {
    name: "nonliving_other_square",
    style: "width: 100px;",
  },
  {
    name: "parking_escrow_square",
    style: "width: 100px;",
  },
  {
    name: "parking_fund_square",
    style: "width: 100px;",
  },
  {
    name: "parking_other_square",
    style: "width: 100px;",
  },
  {
    name: "living_escrow_price",
    style: "width: 120px;",
  },
  {
    name: "living_fund_price",
    style: "width: 120px;",
  },
  {
    name: "living_other_price",
    style: "width: 120px;",
  },
  {
    name: "nonliving_escrow_price",
    style: "width: 120px;",
  },
  {
    name: "nonliving_fund_price",
    style: "width: 120px;",
  },
  {
    name: "nonliving_other_price",
    style: "width: 120px;",
  },
  {
    name: "parking_escrow_price",
    style: "width: 120px;",
  },
  {
    name: "parking_fund_price",
    style: "width: 120px;",
  },
  {
    name: "parking_other_price",
    style: "width: 120px;",
  },
  {
    name: "lift_qty",
    style: "width: 80px;",
  },
  {
    name: "lift_cargo_qty",
    style: "width: 80px;",
  },
  {
    name: "lift_wheelchair_qty",
    style: "width: 80px;",
  },
  {
    name: "created_at",
    label: "Создана",
    sortName: "created_at",
    style: "width: 140px;",
  },
  {
    name: "updated_at",
    label: "Обновлена",
    sortName: "updated_at",
    style: "width: 140px;",
  },
];
