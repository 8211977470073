import { createStore } from "vuex";
import auth from "./auth";
import { markRaw } from "vue";

const store = createStore({
  state: {
    boxMarks: [],
    boxBells: [],
    queuesWorkload: {},
    pdFields: [],
    windowInnerHeight: 0,
    declarationSearch: null,
    cadastralsSearch: null,
    declarationCompositeSearch: null,
    apProjectSearch: null,
    commentEditing: null
  },
  mutations: {
    SET_COMMENT_EDITING(state, payload) {
      state.commentEditing = {
        state: payload.state,
        id: payload.id,
      }
    },
    SET_BOX_MARKS(state, data) {
      state.boxMarks = markRaw(data);
    },

    SET_BOX_BELLS(state, data) {
      state.boxBells = markRaw(data);
    },

    SET_QUEUES_WORKLOAD(state, data) {
      let showcase = {};

      if (data.total >= 0 && data.total < 100) {
        showcase = {
          icon: "mdi-speedometer-slow",
          color: "green-7",
        };
      } else if (data.total >= 100 && data.total < 500) {
        showcase = {
          icon: "mdi-speedometer-medium",
          color: "orange-7",
        };
      } else {
        showcase = {
          icon: "mdi-speedometer",
          color: "red-7",
        };
      }

      data.showcase = showcase;
      state.queuesWorkload = markRaw(data);
    },

    SET_PD_FIELDS(state, data) {
      state.pdFields = markRaw(data);
    },

    SET_WINDOW_INNER_HEIGHT(state, height) {
      state.windowInnerHeight = height;
    },

    SET_DECLARATION_SEARCH(state, encodedStr) {
      state.declarationSearch = encodedStr;
    },
    SET_CADASTRALS_SEARCH(state, encodedStr) {
      state.cadastralsSearch = encodedStr;
    },

    SET_DECLARATION_COMPOSITE_SEARCH(state, encodedStr) {
      state.declarationCompositeSearch = encodedStr;
    },

    SET_AP_PROJECT_SEARCH(state, encodedStr) {
      state.apProjectSearch = encodedStr;
    },
  },
  actions: {},
  getters: {},
  modules: {
    auth,
  },
});

export default store;
