<template>
  <div class="col">
    <div :style="getStylesForSubmenu()" class="row">
      <div class="col-24 col-md-3 col-lg-2 col-xl-1 bg-white">
        <q-list class="l-menu q-py-md rounded-borders text-primary">
          <q-item
            v-for="item in menuItems"
            :key="item.component"
            :active="component === item.component"
            :disable="loading"
            active-class="l-menu__item--active"
            clickable
            @click="component = item.component"
          >
            <q-item-section>{{ item.label }}</q-item-section>
          </q-item>
        </q-list>
      </div>

      <div class="col-24 col-md-21 col-lg-22 col-xl-23">
        <div class="row justify-center">
          <div
            v-if="Object.keys(apProject).length && !loading"
            :class="!($q.screen.xs || $q.screen.sm) ? 'q-px-lg' : ''"
            class="col-24"
          >
            <component :is="component" :serial="apProject.serial" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { createMetaMixin, dom } from "quasar";
  import api from "@/api";
  import ApProjectDeclarationComposites from "@/components/apProjects/edit/ApProjectDeclarationComposites";

  const { height } = dom;

  export default {
    name: "ApProjectEdit",

    props: {
      serial: {
        type: String,
        required: true,
      },
    },

    components: {
      ApProjectDeclarationComposites,
    },

    mixins: [
      createMetaMixin(function () {
        return {
          title: this.title,
        };
      }),
    ],

    data() {
      return {
        loading: true,
        title: "Редактирование АП проекта",
        component: "ApProjectDeclarationComposites",
        apProject: {},
        submenuMinified: true,
      };
    },

    computed: {
      menuItems() {
        return [
          {
            component: "ApProjectDeclarationComposites",
            label: "ПД",
          },
        ];
      },
    },

    async mounted() {
      await this.setApProject();

      this.loading = false;
    },

    methods: {
      setApProject() {
        let params = {
          q: { serial: { c: "eq", v: this.serial } },
        };

        return api.apProject
          .find(params, "declaration_composites")
          .then((res) => {
            if (res.status === 200) {
              this.apProject = res.data[0];
              this.title = res.data[0].name || "Редактирование АП проекта";
            }

            if (res.status === 204) {
              this.$router.push({ name: "ApProjectList" });
            }
          });
      },

      getQPageHeight() {
        return height(document.getElementsByClassName("q-page")[0]);
      },

      getStylesForSubmenu() {
        let styles = "";

        if (this.$q.screen.md || this.$q.screen.lg || this.$q.screen.xl) {
          styles += "min-height:" + this.getQPageHeight() + "px";
        }

        return styles;
      },
    },
  };
</script>
