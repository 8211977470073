<template>
  <q-dialog
    ref="dialog"
    position="top"
    transition-hide="slide-up"
    transition-show="slide-down"
    @hide="onDialogHide"
  >
    <q-card style="width: 600px; max-width: 600px">
      <div class="flex items-center justify-between q-px-md">
        <div>Обновить строку сравнения</div>
        <q-btn v-close-popup flat dense icon="mdi-close" size="lg" />
      </div>

      <q-card-section>
        <q-input
          v-model.trim="compound.reviewed_comment"
          :disable="awaitResponse"
          autofocus
          autogrow
          bottom-slots
          clearable
          label="Комментарий"
          type="textarea"
        />
      </q-card-section>

      <q-card-actions class="q-pa-md justify-between">
        <q-btn
          v-close-popup
          :disable="awaitResponse"
          label="Отмена"
          title="Закрыть окно"
        />
        <q-btn
          :disable="awaitResponse"
          :loading="awaitResponse"
          color="primary"
          label="Сохранить"
          title="Сохранить"
          @click="submit"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
  import api from "@/api";
  import cloneDeep from "lodash.clonedeep";
  import emitter from "@/plugins/mitt";

  export default {
    name: "UpdateCompoundDialog",

    emits: ["hide"],

    props: {
      compoundInitial: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        awaitResponse: false,
        compound: null,
      };
    },

    mounted() {
      this.compound = cloneDeep(this.compoundInitial);
    },

    methods: {
      async submit() {
        this.awaitResponse = true;

        let payload = {
          reviewed_comment: this.compound.reviewed_comment,
        };

        api.compound
          .updateCompoundStatic(this.compound.id, payload)
          .then(
            (res) => {
              this.$q.notify({
                color: "positive",
                message: res.data.message,
              });

              emitter.emit("set-compounds");
              this.hide();
            },
            (error) => {
              this.$q.notify({
                color: "negative",
                message: error.response.data.message,
              });
            }
          )
          .then(() => {
            this.awaitResponse = false;
          });
      },

      // following method is REQUIRED
      // (don't change its name --> "show")
      show() {
        this.$refs.dialog.show();
      },

      // following method is REQUIRED
      // (don't change its name --> "hide")
      hide() {
        this.$refs.dialog.hide();
      },

      onDialogHide() {
        this.$emit("hide");
      },
    },
  };
</script>
