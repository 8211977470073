import { axiosInstance as axios } from "@/plugins/axios";
import { deleteKeysWithEmptyValues } from "@/utils/batch";

const box = {
  find(params, include = "") {
    deleteKeysWithEmptyValues(params.q);

    return axios.get("/boxes", {
      params: {
        q: params.q,
        sort_by: params.sort_by,
        descending: params.descending,
        page: params.page,
        limit: params.limit,
        transformer: params.transformer ? params.transformer : null,
        include: include,
      },
    });
  },

  findById(id, include = "") {
    return axios.get(`/boxes/${id}`, { params: { include } });
  },

  create(payload) {
    return axios.post("/boxes", payload);
  },

  updateCommon(id, payload) {
    return axios.post(`/boxes/${id}/common`, payload);
  },

  updateBell(boxId, boxBellId) {
    return axios.patch(`/boxes/${boxId}/bell`, { box_bell_id: boxBellId });
  },

  unlink(id, apBoxSerial) {
    return axios.patch(`/boxes/${id}/unlink`, { ap_box_serial: apBoxSerial });
  },

  link(id, apBoxSerials) {
    return axios.patch(`/boxes/${id}/link`, { ap_box_serials: apBoxSerials });
  },

  reCompare(id) {
    return axios.patch(`/boxes/${id}/re-compare`);
  },

  reParse(id) {
    return axios.patch(`/boxes/${id}/re-parse`);
  },

  parsePds(id, payload) {
    return axios.patch(`/boxes/${id}/parse-pds`, payload);
  },

  parsePdsMulti(payload) {
    return axios.patch("/boxes/parse-pds-multi", payload);
  },
};

export default box;
