<template>
  <div slim>
    <q-btn
        v-if="replyEntityId"
        :disable="awaitResponse"
        color="primary"
        dense
        flat
        label="отменить ответ"
        no-caps
        @click="resetSubmitForm"
    />

    <form ref="form">
      <div
          ref="textInputProvider"
          mode="passive"
          name="Комментарий"
          rules="required|min:1|max:3000"
      >
        <q-input
            ref="commentInput"
            v-model="text"
            autogrow
            bottom-slots
            clearable
            label="Добавить комментарий..."
            type="textarea"
        >
          <template v-slot:append>
            <q-btn dense flat label="🙂" @click="isEmojiPickerShowed = true">
              <q-menu>
                <EmojiPicker @select="onSelectEmoji"/>
              </q-menu>
            </q-btn>
          </template>

          <template v-slot:after>
            <q-btn
                :disable="
                awaitResponse ||
                !(typeof text === 'string' && text.length)
              "
                :loading="awaitResponse"
                color="primary"
                dense
                flat
                icon="mdi-send"
                round
                type="button"
                @click="submitCommentForm"
            />
          </template>
        </q-input>


      </div>
    </form>
  </div>
</template>

<script>

import EmojiPicker from "@/components/EmojiPicker";
import api from "@/api";
import emitter from "@/plugins/mitt";

export default {
  name: "WriteComment",

  props: {
    entity: String,
    entityId: Number,
    category: {
      type: Number,
      default: null,
    },
    showAttachFilesBtn: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    EmojiPicker,
  },

  data() {
    return {
      awaitResponse: false,
      awaitUploadFiles: false,
      isEmojiPickerShowed: false,
      text: null,
      replyEntity: null,
      replyEntityId: null,
    };
  },

  async created() {
    emitter.on("reply-to-comment", this.onReplyToComment);
    emitter.on("files-uploaded", this.onFilesUploaded);
    emitter.on("comment-file", this.onCommentFile);
  },

  destroyed() {
    emitter.off("reply-to-comment", this.onReplyToComment);
    emitter.off("files-uploaded", this.onFilesUploaded);
    emitter.off("comment-file", this.onCommentFile);
  },

  methods: {
    submitCommentForm() {

      this.awaitResponse = true;

      let data = {
        text: this.text,
        category: this.category,
      };

      let entity = this.replyEntity ? this.replyEntity : this.entity;
      let entityId = this.replyEntityId
          ? this.replyEntityId
          : this.entityId;


      api.comment
          .create(entity, entityId, data, "commented")
          .then(
              (res) => {
                emitter.emit("comment-added", {
                  comment: res.data.comment,
                });

                this.resetSubmitForm();
              },
              (error) => {
                if (error.response.status !== 500) {
                  this.$q.notify({
                    color: "negative",
                    message: error.response.data.message,
                  });
                }
              }
          )
          .then(() => {
            this.awaitResponse = false;
          });

    },

    resetSubmitForm() {
      this.replyEntity = null;
      this.replyEntityId = null;
      this.text = null;
      //this.$refs.textInputProvider.reset();
      this.$refs.form.reset();
    },

    onReplyToComment(payload) {
      this.text = "";
      this.replyEntity = payload.comment.entity;
      this.replyEntityId = payload.comment.id;
      this.$refs.commentInput.focus();
    },

    onCommentFile(payload) {
      this.checkTextInputAndAddSpace();
      this.text += `[${payload.file.name}](${payload.file.url}) `;
      this.$refs.commentInput.focus();
    },

    onFilesUploaded(payload) {
      if (!payload.isFileAttaching) {
        return;
      }

      this.checkTextInputAndAddSpace();

      payload.files.forEach((file) => {
        this.text += `[${file.name}](${file.url}) `;
      });

      this.$refs.form.reset();
    },


    checkTextInputAndAddSpace() {
      if (!this.text) {
        this.text = "";
      } else {
        this.text += " ";
      }
    },

    onSelectEmoji(emoji) {
      this.checkTextInputAndAddSpace();

      this.text += `${emoji} `;

      this.isEmojiPickerShowed = false;
    },
  },
};
</script>