<template>
  <div
    v-if="Object.keys(user).length"
    class="col col-md-10 q-pa-md bg-white rounded-borders shadow-2"
  >
    <fieldset :disabled="awaitResponse" class="no-border q-px-none">
      <div class="row flex items-center">
        <div class="col-9 col-md-6 text-grey-8">Имя</div>
        <div class="col-15 col-md-18">
          <q-input
            v-model="user.name"
            :error="v$.user.name.$error"
            :error-message="
              v$.user.name.$error ? v$.user.name.$errors[0].$message : ''
            "
            bottom-slots
            dense
          />
        </div>
      </div>

      <div class="row flex items-center">
        <div class="col-9 col-md-6 text-grey-8">Роль</div>
        <div class="col-15 col-md-18">
          <q-select
            v-model="user.roles[0]"
            :option-value="(opt) => opt"
            :options="roles"
            bottom-slots
            dense
            option-label="readable_name"
          />
        </div>
      </div>

      <div class="row flex items-center">
        <div class="col-9 col-md-6 text-grey-8">Статус</div>
        <div class="col-15 col-md-18">
          <q-select
            v-model="user.status"
            :options="userStatuses"
            bottom-slots
            dense
            emit-value
            map-options
          />
        </div>
      </div>

      <div class="row flex items-center">
        <div class="col-9 col-md-6 text-grey-8">Регионы</div>
        <div class="col-15 col-md-18">
          <q-select
            v-model="user.regions"
            :options="regions"
            bottom-slots
            dense
            map-options
            multiple
            option-label="name"
            option-value="id"
            use-chips
          />
        </div>
      </div>

      <div class="row flex items-center">
        <div class="col-9 col-md-6 text-grey-8">Разрешения</div>
        <div class="col-15 col-md-18">
          <q-btn
            :to="{ name: 'UserEditPermissions', params: { id: user.id } }"
            dense
            flat
            color="primary"
            label="Изменить разрешения"
          />
        </div>
      </div>

      <div class="row flex items-center">
          <div class="col-9 col-md-6 text-grey-8">Новый пароль</div>
          <div class="col-15 col-md-18">
              <q-input
                      v-model="userPassword"
                      bottom-slots
                      dense
              />
          </div>
      </div>
    </fieldset>

    <div class="row">
      <q-btn
        :disable="awaitSaving || authUserNotHavePermission"
        :loading="awaitSaving"
        color="primary"
        label="Сохранить"
        @click="save"
      />

      <q-space />

      <q-btn
        :disable="awaitDeleting || authUserNotHavePermission"
        :loading="awaitDeleting"
        color="negative"
        icon="mdi-delete-outline"
        outline
        round
        @click="remove"
      />
    </div>
  </div>
</template>

<script>
  import api from "@/api";
  import useVuelidate from "@vuelidate/core";
  import { maxLength, minLength, required } from "@/utils/i18n-validators";
  import { createMetaMixin } from "quasar";

  export default {
    name: "UserEdit",

    setup() {
      return { v$: useVuelidate() };
    },

    props: {
      id: {
        type: Number,
        required: true,
      },
    },

    mixins: [
      createMetaMixin(function () {
        return {
          title: this.title,
        };
      }),
    ],

    data() {
      return {
        awaitResponse: false,
        awaitSaving: false,
        awaitDeleting: false,
        title: "",
        user: {},
        userPassword: null,
        roles: [],
        regions: [],
      };
    },

    computed: {
      isOwner() {
        return this.$store.getters["auth/userId"] === this.user.id;
      },

      authUserNotHavePermission() {
        return !+this.$can(["user.update"]).andLevelGreater(
          this.user.roles[0].level
        );
      },

      userStatuses() {
        return [
          {
            label: "Активен",
            value: 3,
          },
          {
            label: "Заблокирован",
            value: 5,
          },
        ];
      },
    },

    async created() {
      this.awaitResponse = true;

      let [resUser, resRoles, resRegions] = await Promise.all([
        api.user.findUserById(this.id, "roles,regions"),
        api.role.find(),
        api.region.find(),
      ]);

      this.user = resUser.data;
      this.title = "Редактирование " + resUser.data.name;

      this.roles = resRoles.data.filter(
        (role) => this.$store.getters["auth/firstRole"].level > role.level
      );

      let regionCodes = resRegions.data.map((i) => i.codes).flat();
      let intersectedCodes = regionCodes.filter((code) =>
        this.$store.getters["auth/user"].region_codes.includes(code)
      );
      this.regions = resRegions.data.filter((r) =>
        r.codes.some((c) => intersectedCodes.includes(c))
      );

      this.awaitResponse = false;
    },

    methods: {
      async save() {
        this.awaitSaving = true;

        const valid = await this.v$.$validate();

        if (!valid) {
          this.awaitCreating = false;

          return;
        }

        let payload = {
          name: this.user.name,
          role_id: this.user.roles[0].id,
          status: this.user.status,
          region_ids: this.user.regions.map((r) => r.id),
          user_password: this.userPassword
        };

        api.user
          .update(this.id, payload)
          .then(
            (res) => {
              if (res.status === 200) {
                this.$q.notify({
                  color: "positive",
                  message: res.data.message,
                });
              }
            },
            (error) => {
              this.$q.notify({
                color: "negative",
                message: error.response.data.message,
              });
            }
          )
          .then(() => {
            this.awaitSaving = false;
          });
      },

      remove() {
        this.$q
          .dialog({
            title: `Удаление пользователя ${this.user.name}`,
            message: "Вы уверены?",
            ok: {
              label: "Удалить",
            },
          })
          .onOk(async () => {
            this.awaitDeleting = true;

            await api.user
              .delete(this.user.id)
              .then(
                (res) => {
                  if (res.status === 200) {
                    this.$q.notify({
                      color: "positive",
                      message: res.data.message,
                    });

                    this.$router.push({ name: "UserList" });
                  }
                },
                (error) => {
                  this.$q.notify({
                    color: "negative",
                    message: error.response.data.message,
                  });
                }
              )
              .then(() => {
                this.awaitDeleting = false;
              });
          });
      },
    },

    validations() {
      return {
        user: {
          name: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(50),
          },
        },
      };
    },
  };
</script>
