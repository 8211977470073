<template>
  <div class="col col-md-10 q-pa-md bg-white rounded-borders shadow-2">
    <fieldset :disabled="awaitResponse" class="no-border q-px-none">
      <div class="row flex items-center">
        <div class="col-9 col-md-6 text-grey-8">Имя</div>
        <div class="col-15 col-md-18">
          <q-input
            v-model="name"
            :error="v$.name.$error"
            :error-message="v$.name.$error ? v$.name.$errors[0].$message : ''"
            bottom-slots
            dense
          />
        </div>
      </div>

      <div class="row flex items-center">
        <div class="col-9 col-md-6 text-grey-8">Email</div>
        <div class="col-15 col-md-18">
          <q-input
            v-model="email"
            :error="v$.email.$error"
            :error-message="v$.email.$error ? v$.email.$errors[0].$message : ''"
            bottom-slots
            dense
          />
        </div>
      </div>

      <div class="row flex items-center">
        <div class="col-9 col-md-6 text-grey-8">Пароль</div>
        <div class="col-15 col-md-18">
          <q-input
            v-model="password"
            :error="v$.password.$error"
            :error-message="
              v$.password.$error ? v$.password.$errors[0].$message : ''
            "
            bottom-slots
            dense
          />
        </div>
      </div>

      <div class="row flex items-center">
        <div class="col-9 col-md-6 text-grey-8">Роль</div>
        <div class="col-15 col-md-18">
          <q-select
            v-model="roleSelected"
            :option-value="(opt) => opt"
            :options="roles"
            bottom-slots
            dense
            option-label="readable_name"
          />
        </div>
      </div>

      <div class="row flex items-center">
        <div class="col-9 col-md-6 text-grey-8">Статус</div>
        <div class="col-15 col-md-18">
          <q-select
            v-model="status"
            :options="userStatuses"
            bottom-slots
            dense
            emit-value
            map-options
          />
        </div>
      </div>

      <div class="row flex items-center">
        <div class="col-9 col-md-6 text-grey-8">Регионы</div>
        <div class="col-15 col-md-18">
          <q-select
            v-model="regionsSelected"
            :error="v$.regionsSelected.$error"
            :error-message="
              v$.regionsSelected.$error
                ? v$.regionsSelected.$errors[0].$message
                : ''
            "
            :options="regions"
            bottom-slots
            dense
            map-options
            multiple
            option-label="name"
            option-value="id"
            use-chips
          />
        </div>
      </div>
    </fieldset>

    <div class="row">
      <q-btn
        :disable="awaitCreating"
        :loading="awaitCreating"
        color="primary"
        label="Добавить"
        @click="save"
      />
    </div>
  </div>
</template>

<script>
  import api from "@/api";
  import useVuelidate from "@vuelidate/core";
  import {
    maxLength,
    minLength,
    required,
    email,
  } from "@/utils/i18n-validators";
  import { createMetaMixin } from "quasar";

  export default {
    name: "UserAdd",

    setup() {
      return { v$: useVuelidate() };
    },

    mixins: [
      createMetaMixin(function () {
        return {
          title: this.title,
        };
      }),
    ],

    data() {
      return {
        awaitResponse: false,
        awaitCreating: false,
        name: null,
        email: null,
        password: null,
        roleSelected: null,
        regionsSelected: null,
        status: 3,
        roles: [],
        regions: [],
      };
    },

    computed: {
      userStatuses() {
        return [
          {
            label: "Активен",
            value: 3,
          },
          {
            label: "Заблокирован",
            value: 5,
          },
        ];
      },
    },

    async created() {
      this.awaitResponse = true;

      let [resRoles, resRegions] = await Promise.all([
        api.role.find(),
        api.region.find(),
      ]);

      this.roles = resRoles.data.filter(
        (role) => this.$store.getters["auth/firstRole"].level > role.level
      );

      let regionCodes = resRegions.data.map((i) => i.codes).flat();
      let intersectedCodes = regionCodes.filter((code) =>
        this.$store.getters["auth/user"].region_codes.includes(code)
      );
      this.regions = resRegions.data.filter((r) =>
        r.codes.some((c) => intersectedCodes.includes(c))
      );

      this.roleSelected = this.roles[0];
      this.awaitResponse = false;
    },

    methods: {
      async save() {
        this.awaitCreating = true;

        const valid = await this.v$.$validate();

        if (!valid) {
          this.awaitCreating = false;

          return;
        }

        let payload = {
          name: this.name,
          email: this.email,
          password: this.password,
          role_id: this.roleSelected.id,
          status: this.status,
          region_ids: this.regionsSelected.map((r) => r.id),
        };

        api.user
          .create(payload)
          .then(
            (res) => {
              this.$q.notify({
                color: "positive",
                message: res.data.message,
              });

              this.$router.push({ name: "UserList" });
            },
            (error) => {
              this.$q.notify({
                color: "negative",
                message: error.response.data.message,
              });

              if (error.response.status === 422) {
                Object.keys(error.response.data.errors).forEach((name, i) => {
                  this.$q.notify({
                    color: "negative",
                    message: error.response.data.errors[name][i],
                    timeout: 10000,
                  });
                });
              }
            }
          )
          .then(() => {
            this.awaitCreating = false;
          });
      },
    },

    validations() {
      return {
        name: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(50),
        },
        email: { required, email },
        password: {
          required,
          minLength: minLength(5),
          maxLength: maxLength(256),
        },
        regionsSelected: { required },
      };
    },
  };
</script>
