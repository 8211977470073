import { axiosInstance as axios } from "@/plugins/axios";
import { deleteKeysWithEmptyValues } from "@/utils/batch";

const declarationCorrection = {
  find(params, include = "") {
    deleteKeysWithEmptyValues(params.q);

    return axios.get("/declaration-corrections", {
      params: {
        q: params.q,
        sort_by: params.sort_by,
        descending: params.descending,
        page: params.page,
        limit: params.limit,
        include: include,
      },
    });
  },

  create(payload, include = "") {
    return include
      ? axios.post("/declaration-corrections", payload, { params: { include } })
      : axios.post("/declaration-corrections", payload);
  },

  update(id, payload, include = "") {
    return include
      ? axios.patch(`/declaration-corrections/${id}`, payload, {
          params: { include },
        })
      : axios.patch(`/declaration-corrections/${id}`, payload);
  },

  delete(id) {
    return axios.delete(`/declaration-corrections/${id}`);
  },

  apply(id) {
    return axios.get(`/declaration-corrections/${id}/apply`);
  },

  rollback(ids) {
    return axios.delete(
      `/declaration-corrections/rollback/?ids=${ids.join(",")}`
    );
  },
};

export default declarationCorrection;
