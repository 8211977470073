import { axiosInstance as axios } from "@/plugins/axios";
import { deleteKeysWithEmptyValues } from "@/utils/batch";

const compound = {
  find(params, include = "") {
    deleteKeysWithEmptyValues(params.q);

    return axios.get("/compounds", {
      params: {
        q: params.q,
        sort_by: params.sort_by,
        descending: params.descending,
        page: params.page,
        limit: params.limit,
        include: include,
      },
    });
  },

  findFilters() {
    return axios.get("/compounds/filters");
  },

  updateCompoundStatic(id, payload) {
    return axios.patch(`/compounds/${id}/compound-static`, payload);
  },

  review(id) {
    return axios.patch(`/compounds/${id}/review`);
  },

  exportExcel(params) {
    deleteKeysWithEmptyValues(params.q);

    return axios.post(
        "/compound/export/excel",
        {
          q: params.q,
          sort_by: params.sort_by,
          descending: params.descending,
          page: params.page,
          include: params.include,
          columns: params.columns,
          title: params.title,
          rows_qty: params.rows_qty,
        },
        {
          responseType: "arraybuffer",
        }
    );
  },
};

export default compound;
