<template>
  <div v-if="!loading" class="q-pa-md">
    <div aria-label="Декларации" class="l-table" role="table">
      <div
        v-for="declaration in declarations"
        :key="declaration.id"
        class="l-table__row"
        role="rowgroup"
      >
        <div
          :style="getCellStyle('actions')"
          class="l-table__cell l-table__cell--bordered"
        />

        <div
          :style="getCellStyle('id')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.id }}
        </div>

        <div
          :style="getCellStyle('published_at')"
          class="l-table__cell l-table__cell--bordered"
        />

        <div
          :style="getCellStyle('box_serial')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.box && declaration.box.serial }}
        </div>

        <div
          :style="getCellStyle('box_mark')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{
            declaration.box &&
            declaration.box.box_mark &&
            declaration.box.box_mark.name
          }}
        </div>

        <div
          :style="getCellStyle('ap_box_serial')"
          class="l-table__cell l-table__cell--bordered pre-line"
        >
          {{
            declaration.box.ap_boxes &&
            declaration.box.ap_boxes.map((i) => i.serial).join("\n")
          }}
        </div>

        <div
          :style="getCellStyle('box_name')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ shortness((declaration.box && declaration.box.name) || "", 120) }}
        </div>

        <div
          :style="getCellStyle('pd_number')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.pd_number }}
        </div>

        <div
          :style="getCellStyle('published_at')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.published_at }}
        </div>

        <div
          :style="getCellStyle('company')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.company }}
        </div>

        <div
          :style="getCellStyle('builder_inn')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.builder_inn }}
        </div>

        <div
          :style="getCellStyle('reported_at')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.reported_at }}
        </div>

        <div
          :style="getCellStyle('buildings_qty')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.buildings_qty }}
        </div>

        <div
          :style="getCellStyle('floors_min')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.floors_min }}
        </div>

        <div
          :style="getCellStyle('floors_max')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.floors_max }}
        </div>

        <div
          :style="getCellStyle('object_square')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.object_square }}
        </div>

        <div
          :style="getCellStyle('wall_frame_material')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.wall_frame_material }}
        </div>

        <div
          :style="getCellStyle('floor_material')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.floor_material }}
        </div>

        <div
          :style="getCellStyle('living_square')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.living_square }}
        </div>

        <div
          :style="getCellStyle('nonliving_square')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.nonliving_square }}
        </div>

        <div
          :style="getCellStyle('total_square')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.total_square }}
        </div>

        <div
          :style="getCellStyle('general_contractors')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ getGeneralContractors(declaration.general_contractors) }}
        </div>

        <div
          :style="getCellStyle('construction_permit_number')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.construction_permit_number }}
        </div>

        <div
          :style="getCellStyle('construction_permit_issued_at')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.construction_permit_issued_at }}
        </div>

        <div
          :style="getCellStyle('construction_permit_valid_until')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.construction_permit_valid_until }}
        </div>

        <div
          :style="getCellStyle('construction_permit_valid_until_updated_at')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.construction_permit_valid_until_updated_at }}
        </div>

        <div
          :style="getCellStyle('builder_rights_ground_valid_until')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.builder_rights_ground_valid_until }}
        </div>

        <div
          :style="getCellStyle('builder_rights_contract_updated_at')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.builder_rights_contract_updated_at }}
        </div>

        <div
          :style="getCellStyle('cadastrals')"
          class="l-table__cell l-table__cell--bordered pre-line"
        >
          {{ getCadastrals(declaration.cadastrals) }}
        </div>

        <div
          :style="getCellStyle('living_total_qty')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.living_total_qty }}
        </div>

        <div
          :style="getCellStyle('nonliving_total_qty')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.nonliving_total_qty }}
        </div>

        <div
          :style="getCellStyle('nonliving_total_parking_qty')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.nonliving_total_parking_qty }}
        </div>

        <div
          :style="getCellStyle('nonliving_total_other_qty')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.nonliving_total_other_qty }}
        </div>

        <div
          :style="getCellStyle('construction_stages')"
          class="l-table__cell l-table__cell--bordered pre-line"
        >
          {{ getConstructionStages(declaration.construction_stages) }}
        </div>

        <div
          :style="getCellStyle('keys_initial_issue_at')"
          class="l-table__cell l-table__cell--bordered pre-line"
        >
          {{ declaration.keys_initial_issue_at }}
        </div>

        <div
          :style="getCellStyle('keys_planned_issue_at')"
          class="l-table__cell l-table__cell--bordered pre-line"
        >
          {{ declaration.keys_planned_issue_at }}
        </div>

        <div
          :style="getCellStyle('builder_obligations_securing')"
          class="l-table__cell l-table__cell--bordered pre-line"
        >
          {{ declaration.builder_obligations_securing }}
        </div>

        <div
          :style="getCellStyle('escrow_bank')"
          class="l-table__cell l-table__cell--bordered pre-line"
        >
          {{ declaration.escrow_bank }}
        </div>

        <div
          :style="getCellStyle('payment_contributions_fund')"
          class="l-table__cell l-table__cell--bordered pre-line"
        >
          {{ declaration.payment_contributions_fund }}
        </div>

        <div
          :style="getCellStyle('fund_bank')"
          class="l-table__cell l-table__cell--bordered pre-line"
        >
          {{ declaration.fund_bank }}
        </div>

        <div
          :style="getCellStyle('builder_raising_money')"
          class="l-table__cell l-table__cell--bordered pre-line"
        >
          {{ declaration.builder_raising_money }}
        </div>

        <div
          :style="getCellStyle('financing_bank')"
          class="l-table__cell l-table__cell--bordered pre-line"
        >
          {{ declaration.financing_bank }}
        </div>

        <div
          :style="getCellStyle('living_escrow_qty')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.living_escrow_qty }}
        </div>

        <div
          :style="getCellStyle('living_fund_qty')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.living_fund_qty }}
        </div>

        <div
          :style="getCellStyle('living_other_qty')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.living_other_qty }}
        </div>

        <div
          :style="getCellStyle('nonliving_escrow_qty')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.nonliving_escrow_qty }}
        </div>

        <div
          :style="getCellStyle('nonliving_fund_qty')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.nonliving_fund_qty }}
        </div>

        <div
          :style="getCellStyle('nonliving_other_qty')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.nonliving_other_qty }}
        </div>

        <div
          :style="getCellStyle('parking_escrow_qty')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.parking_escrow_qty }}
        </div>

        <div
          :style="getCellStyle('parking_fund_qty')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.parking_fund_qty }}
        </div>

        <div
          :style="getCellStyle('parking_other_qty')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.parking_other_qty }}
        </div>

        <div
          :style="getCellStyle('living_escrow_square')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.living_escrow_square }}
        </div>

        <div
          :style="getCellStyle('living_fund_square')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.living_fund_square }}
        </div>

        <div
          :style="getCellStyle('living_other_square')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.living_other_square }}
        </div>

        <div
          :style="getCellStyle('nonliving_escrow_square')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.nonliving_escrow_square }}
        </div>

        <div
          :style="getCellStyle('nonliving_fund_square')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.nonliving_fund_square }}
        </div>

        <div
          :style="getCellStyle('nonliving_other_square')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.nonliving_other_square }}
        </div>

        <div
          :style="getCellStyle('parking_escrow_square')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.parking_escrow_square }}
        </div>

        <div
          :style="getCellStyle('parking_fund_square')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.parking_fund_square }}
        </div>

        <div
          :style="getCellStyle('parking_other_square')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.parking_other_square }}
        </div>

        <div
          :style="getCellStyle('living_escrow_price')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ rubF(declaration.living_escrow_price) }}
        </div>

        <div
          :style="getCellStyle('living_fund_price')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ rubF(declaration.living_fund_price) }}
        </div>

        <div
          :style="getCellStyle('living_other_price')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ rubF(declaration.living_other_price) }}
        </div>

        <div
          :style="getCellStyle('nonliving_escrow_price')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ rubF(declaration.nonliving_escrow_price) }}
        </div>

        <div
          :style="getCellStyle('nonliving_fund_price')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ rubF(declaration.nonliving_fund_price) }}
        </div>

        <div
          :style="getCellStyle('nonliving_other_price')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ rubF(declaration.nonliving_other_price) }}
        </div>

        <div
          :style="getCellStyle('parking_escrow_price')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ rubF(declaration.parking_escrow_price) }}
        </div>

        <div
          :style="getCellStyle('parking_fund_price')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ rubF(declaration.parking_fund_price) }}
        </div>

        <div
          :style="getCellStyle('parking_other_price')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ rubF(declaration.parking_other_price) }}
        </div>

        <div
          :style="getCellStyle('lift_qty')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.lift_qty }}
        </div>

        <div
          :style="getCellStyle('lift_cargo_qty')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.lift_cargo_qty }}
        </div>

        <div
          :style="getCellStyle('lift_wheelchair_qty')"
          class="l-table__cell l-table__cell--bordered"
        >
          {{ declaration.lift_wheelchair_qty }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { shortness } from "@/plugins/filters";
  import { markRaw } from "vue";
  import { numberFormat, rubFormat } from "@/utils/numberFormatter";
  import { columns as declarationCompositeColumns } from "@/utils/declarationCompositeForApProject";
  import cloneDeep from "lodash.clonedeep";

  export default {
    name: "ApProjectDeclarationCompositesExtendedRow",

    props: {
      declarations: {
        type: Array,
        required: true,
      },
    },

    mounted() {
      this.columns = markRaw(cloneDeep(declarationCompositeColumns));
      const index = this.columns.findIndex((c) => c.name === "actions");
      this.columns[index].style = "width: 65px;";
      this.updateColumnStyles(this.columns);
      this.loading = false;
    },

    data() {
      return {
        loading: true,
        columns: [],
        cellStyles: {},
      };
    },

    methods: {
      shortness(value, length) {
        return shortness(value, length);
      },

      getCellStyle(columnName) {
        return this.cellStyles[columnName];
      },

      numF(val) {
        return numberFormat(val);
      },

      rubF(val) {
        return rubFormat(val);
      },

      updateColumnStyles(columns) {
        let obj = {};

        columns.forEach((item) => {
          obj[item.name] = item.style;
        });

        this.cellStyles = obj;
      },

      getGeneralContractors(data) {
        if (!data) {
          return "";
        }

        let text = "";

        data.forEach((i, index) => {
          text += `${index + 1}) ${i.contractor} (ИНН: ${i.inn})\n`;
        });

        return text;
      },

      getCadastrals(cadastrals) {
        if (!cadastrals) {
          return "";
        }

        let text = "";

        cadastrals.forEach((i, index) => {
          text += `${index + 1}) ${i.number} (S: ${i.square})\n`;
        });

        return text;
      },

      getConstructionStages(stages) {
        if (!stages) {
          return "";
        }

        let text = "";

        stages.forEach((i) => {
          text += `${i.ending_at} ${i.percent}%\n`;
        });

        return text;
      },
    },
  };
</script>
