export const columns = [
  {
    name: "actions",
    label: "Действия",
    style: "width: 80px;",
  },
  {
    name: "id",
    label: "ID",
    sortName: "id",
    style: "width: 80px;",
  },
  {
    name: "dated_at",
    label: "Дата слоя",
    sortName: "dated_at",
    style: "width: 130px;",
  },
  {
    name: "ap_project_serial",
    label: "Номера АП проектов",
    style: "width: 100px;",
  },
  {
    name: "ap_project_name",
    label: "АП проекты",
    style: "width: 160px;",
  },
  {
    name: "ap_project_address",
    label: "Адреса АП проектов",
    style: "width: 200px;",
  },
  {
    name: "ap_project_region_name",
    label: "Регионы АП проектов",
    style: "width: 160px;",
  },
  {
    name: "box_serial",
    label: "Номер ДомРФ",
    sortName: "box_serial",
    style: "width: 80px;",
  },
  {
    name: "box_mark",
    label: "Статус ДомРФ",
    sortName: "box_mark",
    style: "width: 140px;",
  },
  {
    name: "ap_box_serial",
    label: "АП корпуса",
    sortName: "ap_box_serial",
    style: "width: 100px;",
  },
  {
    name: "box_name",
    label: "Название ДомРФ",
    sortName: "box_name",
    style: "width: 240px;",
  },
  {
    name: "pd_number",
    sortName: "pd_number",
    style: "width: 100px;",
  },
  {
    name: "published_at",
    sortName: "published_at",
    style: "width: 130px;",
  },
  {
    name: "company",
    sortName: "company",
    style: "width: 200px;",
  },
  {
    name: "builder_inn",
    sortName: "builder_inn",
    style: "width: 120px;",
  },
  {
    name: "reported_at",
    sortName: "reported_at",
    style: "width: 130px;",
  },
  {
    name: "buildings_qty",
    sortName: "buildings_qty",
    style: "width: 80px;",
  },
  {
    name: "floors_min",
    sortName: "floors_min",
    style: "width: 80px;",
  },
  {
    name: "floors_max",
    sortName: "floors_max",
    style: "width: 80px;",
  },
  {
    name: "object_square",
    sortName: "object_square",
    style: "width: 100px;",
  },
  {
    name: "wall_frame_material",
    sortName: "wall_frame_material",
    style: "width: 240px;",
  },
  {
    name: "floor_material",
    sortName: "floor_material",
    style: "width: 140px;",
  },
  {
    name: "living_square",
    sortName: "living_square",
    style: "width: 100px;",
  },
  {
    name: "nonliving_square",
    sortName: "nonliving_square",
    style: "width: 100px;",
  },
  {
    name: "total_square",
    sortName: "total_square",
    style: "width: 100px;",
  },
  {
    name: "general_contractor",
    sortName: "general_contractor",
    style: "width: 200px;",
  },
  {
    name: "general_contractor_inn",
    sortName: "general_contractor_inn",
    style: "width: 100px;",
  },
  {
    name: "construction_permit_number",
    sortName: "construction_permit_number",
    style: "width: 160px;",
  },
  {
    name: "construction_permit_issued_at",
    sortName: "construction_permit_issued_at",
    style: "width: 100px;",
  },
  {
    name: "construction_permit_valid_until",
    sortName: "construction_permit_valid_until",
    style: "width: 100px;",
  },
  {
    name: "construction_permit_valid_until_updated_at",
    sortName: "construction_permit_valid_until_updated_at",
    style: "width: 100px;",
  },
  {
    name: "builder_rights_ground_valid_until",
    sortName: "builder_rights_ground_valid_until",
    style: "width: 100px;",
  },
  {
    name: "builder_rights_contract_updated_at",
    sortName: "builder_rights_contract_updated_at",
    style: "width: 100px;",
  },
  {
    name: "cadastrals",
    style: "width: 160px;",
  },
  {
    name: "living_total_qty",
    sortName: "living_total_qty",
    style: "width: 80px;",
  },
  {
    name: "nonliving_total_qty",
    sortName: "nonliving_total_qty",
    style: "width: 80px;",
  },
  {
    name: "nonliving_total_parking_qty",
    sortName: "nonliving_total_parking_qty",
    style: "width: 80px;",
  },
  {
    name: "nonliving_total_other_qty",
    sortName: "nonliving_total_other_qty",
    style: "width: 80px;",
  },
  {
    name: "construction_stages",
    style: "width: 160px;",
  },
  {
    name: "keys_initial_issue_at",
    sortName: "keys_initial_issue_at",
    style: "width: 100px;",
  },
  {
    name: "keys_planned_issue_at",
    sortName: "keys_planned_issue_at",
    style: "width: 100px;",
  },
  {
    name: "builder_obligations_securing",
    sortName: "builder_obligations_securing",
    style: "width: 160px;",
  },
  {
    name: "escrow_bank",
    sortName: "escrow_bank",
    style: "width: 160px;",
  },
  {
    name: "payment_contributions_fund",
    sortName: "payment_contributions_fund",
    style: "width: 100px;",
  },
  {
    name: "fund_bank",
    sortName: "fund_bank",
    style: "width: 160px;",
  },
  {
    name: "builder_raising_money",
    sortName: "builder_raising_money",
    style: "width: 160px;",
  },
  {
    name: "financing_bank",
    sortName: "financing_bank",
    style: "width: 160px;",
  },
  {
    name: "living_escrow_qty",
    sortName: "living_escrow_qty",
    style: "width: 80px;",
  },
  {
    name: "living_fund_qty",
    sortName: "living_fund_qty",
    style: "width: 80px;",
  },
  {
    name: "living_other_qty",
    sortName: "living_other_qty",
    style: "width: 80px;",
  },
  {
    name: "nonliving_escrow_qty",
    sortName: "nonliving_escrow_qty",
    style: "width: 80px;",
  },
  {
    name: "nonliving_fund_qty",
    sortName: "nonliving_fund_qty",
    style: "width: 80px;",
  },
  {
    name: "nonliving_other_qty",
    sortName: "nonliving_other_qty",
    style: "width: 80px;",
  },
  {
    name: "parking_escrow_qty",
    sortName: "parking_escrow_qty",
    style: "width: 80px;",
  },
  {
    name: "parking_fund_qty",
    sortName: "parking_fund_qty",
    style: "width: 80px;",
  },
  {
    name: "parking_other_qty",
    sortName: "parking_other_qty",
    style: "width: 80px;",
  },
  {
    name: "living_escrow_square",
    sortName: "living_escrow_square",
    style: "width: 100px;",
  },
  {
    name: "living_fund_square",
    sortName: "living_fund_square",
    style: "width: 100px;",
  },
  {
    name: "living_other_square",
    sortName: "living_other_square",
    style: "width: 100px;",
  },
  {
    name: "nonliving_escrow_square",
    sortName: "nonliving_escrow_square",
    style: "width: 100px;",
  },
  {
    name: "nonliving_fund_square",
    sortName: "nonliving_fund_square",
    style: "width: 100px;",
  },
  {
    name: "nonliving_other_square",
    sortName: "nonliving_other_square",
    style: "width: 100px;",
  },
  {
    name: "parking_escrow_square",
    sortName: "parking_escrow_square",
    style: "width: 100px;",
  },
  {
    name: "parking_fund_square",
    sortName: "parking_fund_square",
    style: "width: 100px;",
  },
  {
    name: "parking_other_square",
    sortName: "parking_other_square",
    style: "width: 100px;",
  },
  {
    name: "living_escrow_price",
    sortName: "living_escrow_price",
    style: "width: 120px;",
  },
  {
    name: "living_fund_price",
    sortName: "living_fund_price",
    style: "width: 120px;",
  },
  {
    name: "living_other_price",
    sortName: "living_other_price",
    style: "width: 120px;",
  },
  {
    name: "nonliving_escrow_price",
    sortName: "nonliving_escrow_price",
    style: "width: 120px;",
  },
  {
    name: "nonliving_fund_price",
    sortName: "nonliving_fund_price",
    style: "width: 120px;",
  },
  {
    name: "nonliving_other_price",
    sortName: "nonliving_other_price",
    style: "width: 120px;",
  },
  {
    name: "parking_escrow_price",
    sortName: "parking_escrow_price",
    style: "width: 120px;",
  },
  {
    name: "parking_fund_price",
    sortName: "parking_fund_price",
    style: "width: 120px;",
  },
  {
    name: "parking_other_price",
    sortName: "parking_other_price",
    style: "width: 120px;",
  },
  {
    name: "lift_qty",
    sortName: "lift_qty",
    style: "width: 80px;",
  },
  {
    name: "lift_cargo_qty",
    sortName: "lift_cargo_qty",
    style: "width: 80px;",
  },
  {
    name: "lift_wheelchair_qty",
    sortName: "lift_wheelchair_qty",
    style: "width: 80px;",
  },
  {
    name: "created_at",
    label: "Создана",
    sortName: "created_at",
    style: "width: 140px;",
  },
  {
    name: "updated_at",
    label: "Обновлена",
    sortName: "updated_at",
    style: "width: 140px;",
  },
];

export const defaultVisibleColumns = [
  "actions",
  "id",
  "dated_at",
  "ap_project_serial",
  "ap_project_name",
  "ap_project_region_name",
  "box_serial",
  "box_mark",
  "ap_box_serial",
  "box_name",
  "pd_number",
  "published_at",
  "living_square",
  "nonliving_square",
  "total_square",
  "cadastrals",
  "living_total_qty",
  "construction_stages",
  "keys_planned_issue_at",
  "builder_obligations_securing",
  "living_escrow_qty",
  "living_fund_qty",
  "living_other_qty",
  "created_at",
  "updated_at",
];
