<template>
  <div class="space-y-4">
    <fieldset :disabled="awaitSaving" class="no-border q-px-none">
      <q-select
        v-model="declarationCorrection.field"
        :options="pdFields"
        :error="v$.declarationCorrection.field.$error"
        :error-message="
          v$.declarationCorrection.field.$error
            ? v$.declarationCorrection.field.$errors[0].$message
            : ''
        "
        autofocus
        bottom-slots
        label="Поле"
      >
        <template v-slot:selected-item="scope">
          {{ scope.opt.name_readable }}
        </template>

        <template v-slot:option="scope">
          <q-item dense v-bind="scope.itemProps">
            <q-item-section>
              <q-item-label>{{ scope.opt.name_readable }}</q-item-label>
              <q-item-label caption>{{ scope.opt.name }}</q-item-label>
              <q-item-label caption
                >Артикул в ПД: {{ scope.opt.pd_article }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </template>
      </q-select>

      <q-input
        v-model="declarationCorrection.search"
        :error="v$.declarationCorrection.search.$error"
        :error-message="
          v$.declarationCorrection.search.$error
            ? v$.declarationCorrection.search.$errors[0].$message
            : ''
        "
        autogrow
        bottom-slots
        clearable
        label="Поиск"
        type="textarea"
        hint="для использования regex начните с символа #, пример: #[А-Я]"
        @update:model-value="onInputDeclarationCorrectionSearch"
      />

      <q-input
        v-model="declarationCorrection.replace"
        :error="v$.declarationCorrection.replace.$error"
        :error-message="
          v$.declarationCorrection.replace.$error
            ? v$.declarationCorrection.replace.$errors[0].$message
            : ''
        "
        autogrow
        bottom-slots
        clearable
        label="Замена"
        type="textarea"
        @update:model-value="onInputDeclarationCorrectionReplace"
      />

      <q-select
        v-model="declarationCorrection.box"
        :options="declarationCorrection.boxes"
        bottom-slots
        hint="Поиск по номеру. Минимум 2 символа."
        input-debounce="300"
        label="ДомРФ корпус"
        options-dense
        use-input
        clearable
        @filter="asyncFindBox"
      >
        <template v-slot:option="scope">
          <q-item v-bind="scope.itemProps">
            <q-item-section>
              <q-item-label
                >{{ scope.opt.name || scope.opt.address }}
              </q-item-label>
              <q-item-label class="text-subtitle2"
                >{{ scope.opt.serial }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </template>

        <template v-slot:selected-item="scope">
          {{ scope.opt.name || scope.opt.address }} ({{ scope.opt.serial }})
        </template>

        <template v-slot:no-option>
          <q-item>
            <q-item-section class="text-grey">Нет данных</q-item-section>
          </q-item>
        </template>
      </q-select>

      <q-select
        v-model="declarationCorrection.pd_file"
        :options="pdFiles"
        bottom-slots
        hint="Поиск по номеру. Минимум 4 символа."
        input-debounce="300"
        label="ПД файл"
        options-dense
        use-input
        clearable
        @filter="asyncFindPdFile"
      >
        <template v-slot:option="scope">
          <q-item v-bind="scope.itemProps">
            <q-item-section>
              <q-item-label>{{ scope.opt.pd_number }}</q-item-label>
              <q-item-label caption>id: {{ scope.opt.id }}</q-item-label>
              <q-item-label caption
                >дата размещения: {{ scope.opt.published_at }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </template>

        <template v-slot:selected-item="scope">
          {{ scope.opt.pd_number }} ({{ scope.opt.published_at }})
        </template>

        <template v-slot:no-option>
          <q-item>
            <q-item-section class="text-grey">Нет данных</q-item-section>
          </q-item>
        </template>
      </q-select>

      <q-checkbox
        v-model="declarationCorrection.manual"
        dense
        label="Только ручной запуск"
        class="q-my-md"
      />
    </fieldset>

    <div class="flex justify-between items-center">
      <q-btn
        :disable="awaitSaving"
        :loading="awaitSaving"
        color="secondary"
        outline
        icon="mdi-content-save"
        label="Сохранить и применить"
        @click="save(true)"
      />

      <q-btn
        :disable="awaitSaving"
        :loading="awaitSaving"
        color="primary"
        icon="mdi-content-save"
        label="Сохранить"
        @click="save(false)"
      />
    </div>
  </div>
</template>

<script>
  import api from "@/api";
  import useVuelidate from "@vuelidate/core";
  import { helpers } from "@vuelidate/validators";
  import { required } from "@/utils/i18n-validators";

  export default {
    name: "DeclarationCorrectionUpdateForm",

    emits: ["correction-saved"],

    setup() {
      return { v$: useVuelidate() };
    },

    props: {
      declarationCorrectionInitial: {
        type: Object,
        required: false,
      },
      boxSelectedInitial: {
        type: Object,
      },
      pdFileSelectedInitial: {
        type: Object,
      },
      fieldName: {
        type: String,
        required: false,
      },
      fieldValue: {
        required: false,
      },
    },

    async mounted() {
      if (this.declarationCorrectionInitial) {
        this.declarationCorrection = this.declarationCorrectionInitial;
      }

      if (this.boxSelectedInitial) {
        this.declarationCorrection.box = this.boxSelectedInitial;
      }

      if (this.pdFileSelectedInitial) {
        this.declarationCorrection.pd_file = this.pdFileSelectedInitial;
      }

      const fieldName = this.declarationCorrection.field
        ? this.declarationCorrection.field
        : this.fieldName;
      const search = this.declarationCorrection.search
        ? this.declarationCorrection.search
        : this.fieldValue;

      if (fieldName && this.pdFields.map((f) => f.name).includes(fieldName)) {
        this.declarationCorrection.field = this.pdFields.filter(
          (f) => f.name === fieldName
        )[0];
        this.declarationCorrection.search = search === null ? "" : search;
      }

      this.loading = false;
    },

    computed: {
      pdFields() {
        return Object.freeze(
          this.$store.state.pdFields.filter((f) => f.correctable)
        );
      },
    },

    data() {
      return {
        awaitSaving: false,
        declarationCorrection: {
          id: null,
          field: null,
          search: "",
          replace: "",
          manual: false,
          box: null,
          pd_file: null,
        },
        boxes: [],
        pdFiles: [],
      };
    },

    methods: {
      applyCorrection(id) {
        return api.declarationCorrection.apply(id).then(
          (res) => {
            this.$q.notify({
              color: "positive",
              message: res.data.message,
            });
          },
          (error) => {
            this.$q.notify({
              color: "negative",
              message: error.response.data.message,
            });
          }
        );
      },

      async save(thenApply) {
        this.awaitSaving = true;

        const valid = await this.v$.$validate();

        if (!valid) {
          this.awaitSaving = false;

          return;
        }

        let payload = {
          field: this.declarationCorrection.field.name,
          search: this.declarationCorrection.search
            .toString()
            .replace(/␣/g, " "),
          replace: this.declarationCorrection.replace
            .toString()
            .replace(/␣/g, " "),
          manual: this.declarationCorrection.manual,
          box_id: this.declarationCorrection.box
            ? this.declarationCorrection.box.id
            : null,
          pd_file_id: this.declarationCorrection.pd_file
            ? this.declarationCorrection.pd_file.id
            : null,
        };

        const promise = this.declarationCorrection.id
          ? api.declarationCorrection.update(
              this.declarationCorrection.id,
              payload
            )
          : api.declarationCorrection.create(payload);

        promise
          .then(
            async (res) => {
              this.$q.notify({
                color: "positive",
                message: res.data.message,
              });

              this.declarationCorrection.id = res.data.declarationCorrection.id;

              if (thenApply) {
                await this.applyCorrection(this.declarationCorrection.id);
              }

              this.$emit("correction-saved", thenApply);
            },
            (error) => {
              if (error.response.status === 422) {
                this.$q.notify({
                  color: "negative",
                  message: error.response.data.message,
                });
              }
            }
          )
          .then(() => {
            this.awaitSaving = false;
          });
      },

      asyncFindBox(val, update, abort) {
        let params = {
          q: { serial: { c: "ctn", v: val.trim() } },
          sort_by: "serial",
          descending: false,
          page: 1,
          limit: 10,
        };

        if (val.length > 1) {
          api.box.find(params).then((res) => {
            update(() => {
              this.boxes = res.data.boxes;
            });
          });
        } else {
          abort();
        }
      },

      asyncFindPdFile(val, update, abort) {
        let params = {
          q: { pd_number: { c: "ctn", v: val.trim() } },
          sort_by: "published_at",
          descending: true,
          page: 1,
          limit: 100,
        };

        if (val.length > 3) {
          api.pdFile.find(params).then((res) => {
            update(() => {
              this.pdFiles = res.data.pdfiles;
            });
          });
        } else {
          abort();
        }
      },

      onInputDeclarationCorrectionSearch(val) {
        this.declarationCorrection.search = val.replace(/\s/g, "␣");
      },

      onInputDeclarationCorrectionReplace(val) {
        this.declarationCorrection.replace = val.replace(/\s/g, "␣");
      },
    },

    validations() {
      return {
        declarationCorrection: {
          field: { required },
          search: {
            isDifferent: helpers.withMessage(
              "Значение должно отличаться",
              (val) => val !== this.declarationCorrection.replace
            ),
          },
          replace: {
            isDifferent: helpers.withMessage(
              "Значение должно отличаться",
              (val) => val !== this.declarationCorrection.search
            ),
          },
        },
      };
    },
  };
</script>
