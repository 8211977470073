import app from "@/main";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import router from "@/router";

if (process.env.VUE_APP_SENTRY_ENABLED === "true") {
  Sentry.init({
    Vue: app,
    environment: process.env.NODE_ENV,
    autoSessionTracking: false,
    dsn:
      process.env.NODE_ENV === "production" && process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", process.env.VUE_APP_API_URL],
      }),
    ],
    tracesSampleRate: 1.0,
  });
}
