import auth from "@/router/middlewares/auth";
import checkPermissions from "@/router/middlewares/checkPermissions";

import DeclarationList from "@/pages/declarations/DeclarationList";
import PdFileList from "@/pages/declarations/PdFileList";
import DeclarationCorrectionList from "@/pages/declarations/corrections/DeclarationCorrectionList";
import DeclarationCorrectionAdd from "@/pages/declarations/corrections/DeclarationCorrectionAdd";
import DeclarationCorrectionEdit from "@/pages/declarations/corrections/DeclarationCorrectionEdit";
import PdSettings from "@/pages/declarations/PdSettings";
import DeclarationCompositeList from "@/pages/declarations/DeclarationCompositeList";

const declarationRoutes = [
  {
    path: "/declarations",
    component: DeclarationList,
    name: "DeclarationList",
    beforeEnter: [auth, checkPermissions],
    meta: {
      layout: "main",
      permissions: ["declaration.view"],
      crumbs: "/Декларации",
    },
  },
  {
    path: "/declarations/files",
    component: PdFileList,
    name: "PdFileList",
    beforeEnter: [auth, checkPermissions],
    meta: {
      layout: "main",
      permissions: ["pd-file.view"],
      crumbs: "/Декларации/Файлы",
    },
  },
  {
    path: "/declarations/corrections",
    component: DeclarationCorrectionList,
    name: "DeclarationCorrectionList",
    beforeEnter: [auth, checkPermissions],
    meta: {
      layout: "main",
      permissions: ["declaration-correction.view"],
      crumbs: "/Декларации/Корректировки",
    },
  },
  {
    path: "/declarations/corrections/add",
    component: DeclarationCorrectionAdd,
    name: "DeclarationCorrectionAdd",
    beforeEnter: [auth, checkPermissions],
    meta: {
      layout: "main",
      permissions: ["declaration-correction.add"],
      crumbs: "/Декларации/Корректировки/Добавить",
    },
  },
  {
    path: "/declarations/corrections/:id",
    redirect: { name: "DeclarationCorrectionEdit" },
  },
  {
    path: "/declarations/corrections/:id/edit",
    component: DeclarationCorrectionEdit,
    name: "DeclarationCorrectionEdit",
    beforeEnter: [auth, checkPermissions],
    meta: {
      layout: "main",
      permissions: ["declaration-correction.update"],
      crumbs: "/Декларации/Корректировки/{:}/Изменить",
    },
    props: (route) => ({ id: Number(route.params.id) }),
  },
  {
    path: "/declarations/settings",
    component: PdSettings,
    name: "PdSettings",
    beforeEnter: [auth, checkPermissions],
    meta: {
      layout: "main",
      permissions: ["settings.pd.view"],
      crumbs: "/Декларации/Настройки",
    },
  },
  {
    path: "/declarations/composites",
    component: DeclarationCompositeList,
    name: "DeclarationCompositeList",
    beforeEnter: [auth, checkPermissions],
    meta: {
      layout: "main",
      permissions: ["declaration-composite.view"],
      crumbs: "/Декларации/Агрегированные",
    },
  },
];

export default declarationRoutes;
