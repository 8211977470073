<template>
  <div v-if="!loading" class="col bg-white rounded-borders shadow-2">
    <div style="height: 2px">
      <q-linear-progress v-show="awaitPdFiles" indeterminate size="2px" />
    </div>

    <div class="row q-px-md q-py-sm">
      <div class="col-24">
        <div aria-label="ПД файлы" class="l-table q-pb-sm" role="table">
          <div class="l-table__row min-w-full" role="rowgroup">
            <div
              v-for="item in columns"
              :key="item.name"
              :style="getCellStyle(item.name)"
              class="l-table__cell"
              role="columnheader"
            >
              <div
                :class="item.sortName && 'cursor-pointer'"
                class="text-body3"
                @click="item.sortName && sortByColumn(item.sortName)"
              >
                {{ item.label }}
                <q-icon
                  v-if="pagination.sortBy === item.sortName"
                  :name="
                    pagination.descending ? 'mdi-arrow-down' : 'mdi-arrow-up'
                  "
                />
              </div>
            </div>
          </div>

          <div class="l-table__row min-w-full" role="rowgroup">
            <div :style="getCellStyle('id')" class="l-table__cell">
              <q-input
                v-model.number="search.id.v"
                class="appearance-none"
                type="number"
                v-bind="searchInputProps"
                @update:model-value="onSearchInput"
              >
                <template v-slot:prepend>
                  <ComparisonSelect
                    v-model="search.id.c"
                    :options="compareNumberOptions"
                    :value="search.id.c"
                    @update:model-value="onSearchCompareInput"
                  />
                </template>
              </q-input>
            </div>

            <div :style="getCellStyle('box_serial')" class="l-table__cell">
              <q-input
                v-model.trim="search.box_serial.v"
                v-bind="searchInputProps"
                @update:model-value="onSearchInput"
              >
                <template v-slot:prepend>
                  <ComparisonSelect
                    v-model="search.box_serial.c"
                    :options="compareStringOptions"
                    :value="search.box_serial.c"
                    @update:model-value="onSearchCompareInput"
                  />
                </template>
              </q-input>
            </div>

            <div :style="getCellStyle('box_name')" class="l-table__cell">
              <q-input
                v-model.trim="search.box_name.v"
                v-bind="searchInputProps"
                @update:model-value="onSearchInput"
              >
                <template v-slot:prepend>
                  <ComparisonSelect
                    v-model="search.box_name.c"
                    :options="compareStringOptions"
                    :value="search.box_name.c"
                    @update:model-value="onSearchCompareInput"
                  />
                </template>
              </q-input>
            </div>

            <div :style="getCellStyle('region_name')" class="l-table__cell">
              <q-input
                v-model.trim="search.region_name.v"
                v-bind="searchInputProps"
                @update:model-value="onSearchInput"
              >
                <template v-slot:prepend>
                  <ComparisonSelect
                    v-model="search.region_name.c"
                    :options="compareStringOptions"
                    :value="search.region_name.c"
                    @update:model-value="onSearchCompareInput"
                  />
                </template>
              </q-input>
            </div>

            <div :style="getCellStyle('pd_number')" class="l-table__cell">
              <q-input
                v-model.trim="search.pd_number.v"
                v-bind="searchInputProps"
                @update:model-value="onSearchInput"
              >
                <template v-slot:prepend>
                  <ComparisonSelect
                    v-model="search.pd_number.c"
                    :options="compareStringOptions"
                    :value="search.pd_number.c"
                    @update:model-value="onSearchCompareInput"
                  />
                </template>
              </q-input>
            </div>

            <div :style="getCellStyle('published_at')" class="l-table__cell">
              <div class="full-width">
                <div class="flex justify-between items-center">
                  <div>
                    {{ search.published_at.from || "от" }}
                  </div>
                  <div>
                    <q-icon
                      v-if="search.published_at.from"
                      class="cursor-pointer"
                      color="grey-7"
                      name="mdi-close-circle"
                      size="sm"
                      @click="search.published_at.from = null"
                    />

                    <q-icon
                      class="cursor-pointer"
                      color="grey-7"
                      name="mdi-calendar"
                      size="sm"
                    >
                      <q-popup-proxy ref="datePickerPublishedFrom">
                        <q-date
                          v-model="search.published_at.from"
                          mask="YYYY-MM-DD"
                          @mouseleave="$refs.datePickerPublishedFrom.hide()"
                        />
                      </q-popup-proxy>
                    </q-icon>
                  </div>
                </div>

                <div class="flex justify-between items-center">
                  <div>
                    {{ search.published_at.to || "до" }}
                  </div>
                  <div>
                    <q-icon
                      v-if="search.published_at.to"
                      class="cursor-pointer"
                      color="grey-7"
                      name="mdi-close-circle"
                      size="sm"
                      @click="search.published_at.to = null"
                    />

                    <q-icon
                      class="cursor-pointer"
                      color="grey-7"
                      name="mdi-calendar"
                      size="sm"
                    >
                      <q-popup-proxy ref="datePickerPublishedTo">
                        <q-date
                          v-model="search.published_at.to"
                          mask="YYYY-MM-DD"
                          @mouseleave="$refs.datePickerPublishedTo.hide()"
                        />
                      </q-popup-proxy>
                    </q-icon>
                  </div>
                </div>
              </div>
            </div>

            <div :style="getCellStyle('cdn_url')" class="l-table__cell" />
            <div
              :style="getCellStyle('external_url_inline')"
              class="l-table__cell"
            />

            <div :style="getCellStyle('buildings_qty')" class="l-table__cell">
              <q-input
                v-model.number="search.buildings_qty.v"
                class="appearance-none"
                type="number"
                v-bind="searchInputProps"
                @update:model-value="onSearchInput"
              >
                <template v-slot:prepend>
                  <ComparisonSelect
                    v-model="search.buildings_qty.c"
                    :options="compareNumberOptions"
                    :value="search.buildings_qty.c"
                    @update:model-value="onSearchCompareInput"
                  />
                </template>
              </q-input>
            </div>

            <div :style="getCellStyle('updated_at')" class="l-table__cell">
              <div class="full-width">
                <div class="flex justify-between items-center">
                  <div>
                    {{ search.updated_at.from || "от" }}
                  </div>
                  <div>
                    <q-icon
                      v-if="search.updated_at.from"
                      class="cursor-pointer"
                      color="grey-7"
                      name="mdi-close-circle"
                      size="sm"
                      @click="search.updated_at.from = null"
                    />

                    <q-icon
                      class="cursor-pointer"
                      color="grey-7"
                      name="mdi-calendar"
                      size="sm"
                    >
                      <q-popup-proxy ref="datePickerUpdatedFrom">
                        <q-date
                          v-model="search.updated_at.from"
                          mask="YYYY-MM-DD"
                          @mouseleave="$refs.datePickerUpdatedFrom.hide()"
                        />
                      </q-popup-proxy>
                    </q-icon>
                  </div>
                </div>

                <div class="flex justify-between items-center">
                  <div>
                    {{ search.updated_at.to || "до" }}
                  </div>
                  <div>
                    <q-icon
                      v-if="search.updated_at.to"
                      class="cursor-pointer"
                      color="grey-7"
                      name="mdi-close-circle"
                      size="sm"
                      @click="search.updated_at.to = null"
                    />

                    <q-icon
                      class="cursor-pointer"
                      color="grey-7"
                      name="mdi-calendar"
                      size="sm"
                    >
                      <q-popup-proxy ref="datePickerUpdatedTo">
                        <q-date
                          v-model="search.updated_at.to"
                          mask="YYYY-MM-DD"
                          @mouseleave="$refs.datePickerUpdatedTo.hide()"
                        />
                      </q-popup-proxy>
                    </q-icon>
                  </div>
                </div>
              </div>
            </div>

            <div :style="getCellStyle('actions')" class="l-table__cell" />
          </div>

          <div
            v-for="pdFile in pdFiles"
            :key="pdFile.id"
            class="l-table__row min-w-full wrap"
            role="rowgroup"
          >
            <div class="l-table__row min-w-full" role="rowgroup">
              <div :style="getCellStyle('id')" class="l-table__cell">
                {{ pdFile.id }}
              </div>

              <div :style="getCellStyle('box_serial')" class="l-table__cell">
                {{ pdFile.boxes.map((i) => i.serial).join(", ") }}
              </div>

              <div :style="getCellStyle('box_name')" class="l-table__cell">
                {{ shortness(pdFile.boxes.map((i) => i.name).join(", "), 80) }}
              </div>

              <div
                :style="getCellStyle('region_name')"
                class="l-table__cell pre-line"
              >
                {{ getUniqueRegionNames(pdFile.boxes) }}
              </div>

              <div :style="getCellStyle('pd_number')" class="l-table__cell">
                {{ pdFile.pd_number }}
              </div>

              <div :style="getCellStyle('published_at')" class="l-table__cell">
                {{ pdFile.published_at }}
              </div>

              <div :style="getCellStyle('cdn_url')" class="l-table__cell">
                <a :href="pdFile.cdn_url" class="l-link" target="_blank"
                  >ссылка</a
                >
              </div>

              <div
                :style="getCellStyle('external_url_inline')"
                class="l-table__cell"
              >
                <a
                  :href="pdFile.external_url_inline"
                  class="l-link"
                  target="_blank"
                  >ссылка</a
                >
              </div>

              <div :style="getCellStyle('buildings_qty')" class="l-table__cell">
                {{ pdFile.buildings_qty }}
              </div>

              <div :style="getCellStyle('updated_at')" class="l-table__cell">
                {{ pdFile.updated_at }}
              </div>

              <div :style="getCellStyle('actions')" class="l-table__cell">
                <div class="flex no-wrap">
                  <q-btn
                    v-if="+$can(['pd-file.delete'])"
                    :disabled="
                      (pdFile.declarations && pdFile.declarations.length) ||
                      (pdFile.boxes && pdFile.boxes.length)
                    "
                    alt="Удалить"
                    flat
                    :title="
                      (pdFile.declarations && pdFile.declarations.length) ||
                      (pdFile.boxes && pdFile.boxes.length)
                        ? 'к файлу привязаны декларации или корпуса'
                        : 'удалить'
                    "
                    icon="mdi-delete-outline"
                    @click="removePdFile(pdFile.id)"
                  />

                  <q-btn
                    v-if="+$can(['pd-file.update'])"
                    :disabled="awaitStartParsing"
                    icon="mdi-data-matrix-scan"
                    flat
                    title="Запустить парс"
                    @click="parsePdFile(pdFile.id)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="!pdFiles || (Array.isArray(pdFiles) && pdFiles.length === 0)"
        class="q-pa-md"
      >
        Нет данных
      </div>
    </div>

    <div
      v-if="pdFiles && pdFiles.length"
      class="col-24 flex justify-end items-center q-px-md q-py-sm bg-white sticky-bottom shadow-up-3"
    >
      <div class="text-body3 q-mr-md">
        Всего: <span class="text-body1">{{ pagination.rowsNumber }}</span>
      </div>

      <q-pagination
        v-model="pagination.page"
        :disable="loading"
        :input="true"
        :max="Math.ceil(pagination.rowsNumber / pagination.rowsPerPage)"
        @update:model-value="onPaginationInput"
      />
    </div>
  </div>
</template>

<script>
  import api from "@/api";
  import {
    normalizeQueryForRequest,
    COMPARE_NUMBER_OPTIONS,
    COMPARE_STRING_OPTIONS,
    SEARCH_INPUT_PROPS,
    SEARCH_SELECT_PROPS,
  } from "@/utils/batch";
  import ComparisonSelect from "@/components/ComparisonSelect";
  import { createMetaMixin } from "quasar";
  import { shortness } from "@/plugins/filters";

  export default {
    name: "PdFileList",

    mixins: [
      createMetaMixin(function () {
        return {
          title: "Файлы ПД",
        };
      }),
    ],

    components: {
      ComparisonSelect,
    },

    async mounted() {
      await this.setPdFiles();
      this.updateColumnStyles(this.columns);
      this.loading = false;
    },

    data() {
      return {
        loading: true,
        awaitPdFiles: false,
        awaitStartParsing: false,
        pdFiles: [],
        pagination: {
          rowsNumber: null,
          rowsPerPage: 15,
          sortBy: "updated_at",
          descending: true,
          page: 1,
        },
        columns: [
          {
            name: "id",
            label: "ID",
            sortName: "id",
            style: "width: 80px;",
          },
          {
            name: "box_serial",
            label: "Корпуса ДомРФ",
            style: "width: 120px;",
          },
          {
            name: "box_name",
            label: "Названия корпусов",
            style: "width: 200px;",
          },
          {
            name: "region_name",
            label: "Регион",
            style: "width: 160px;",
          },
          {
            name: "pd_number",
            label: "Номер ПД",
            sortName: "pd_number",
            style: "width: 120px;",
          },
          {
            name: "published_at",
            label: "Дата размещения",
            sortName: "published_at",
            style: "width: 160px;",
          },
          {
            name: "cdn_url",
            label: "Файл CDN",
            style: "width: 100px;",
          },
          {
            name: "external_url_inline",
            label: "Файл домрф",
            style: "width: 100px;",
          },
          {
            name: "buildings_qty",
            label: "Корпусов в ПД, шт.",
            sortName: "buildings_qty",
            style: "width: 120px;",
          },
          {
            name: "updated_at",
            label: "Обновлен от",
            sortName: "updated_at",
            style: "width: 160px;",
          },
          {
            name: "actions",
            label: "",
            style: "width: 80px;",
          },
        ],
        search: {
          id: { c: "eq", v: null },
          box_serial: { c: "eq", v: null },
          box_name: { c: "ctn", v: null },
          region_name: { c: "ctn", v: null },
          pd_number: { c: "ctn", v: null },
          published_at: {
            from: null,
            to: null,
          },
          buildings_qty: { c: "eq", v: null },
          updated_at: {
            from: null,
            to: null,
          },
        },
        cellStyles: {},
        searchSelectProps: SEARCH_SELECT_PROPS,
        searchInputProps: SEARCH_INPUT_PROPS,
        compareNumberOptions: COMPARE_NUMBER_OPTIONS,
        compareStringOptions: COMPARE_STRING_OPTIONS,
      };
    },

    watch: {
      "search.published_at.from"() {
        this.setPdFiles(true);
      },
      "search.published_at.to"() {
        this.setPdFiles(true);
      },
      "search.updated_at.from"() {
        this.setPdFiles(true);
      },
      "search.updated_at.to"() {
        this.setPdFiles(true);
      },
    },

    methods: {
      async filterPdFiles() {
        await this.setPdFiles(true);
      },

      async setPdFiles(isFiltering = false) {
        this.awaitPdFiles = true;

        // we can't send filtering request from page greater then 1
        if (isFiltering) {
          this.pagination.page = 1;
        }

        let paginateOptions = {
          q: normalizeQueryForRequest(this.search),
          sort_by: this.pagination.sortBy,
          descending: this.pagination.descending,
          limit: this.pagination.rowsPerPage,
          page: this.pagination.page,
        };

        const res = await api.pdFile.find(
          paginateOptions,
          "boxes,declarations"
        );

        if (res.status === 200 && res.data.pdfiles) {
          this.pdFiles = res.data.pdfiles;
          this.pagination.rowsNumber = res.data.meta.pagination.total;
        }

        if (res.status === 204) {
          this.pdFiles = [];
        }

        this.awaitPdFiles = false;
      },

      onPaginationInput(page) {
        this.setPdFiles();

        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      },

      getCellStyle(columnName) {
        return this.cellStyles[columnName];
      },

      updateColumnStyles(columns) {
        let obj = {};

        columns.forEach((item) => {
          obj[item.name] = item.style;
        });

        this.cellStyles = obj;
      },

      async sortByColumn(field) {
        this.pagination.sortBy = field;
        this.pagination.descending = !this.pagination.descending;
        await this.setPdFiles();
      },

      async onSearchInput(val) {
        await this.filterPdFiles();
      },

      async onSearchCompareInput(val) {
        if (!val) {
          return;
        }

        await this.filterPdFiles();
      },

      async removePdFile(id) {
        this.$q
          .dialog({
            title: "Удаление ПД файла",
            message: "Вы уверены?",
            ok: {
              label: "Удалить",
            },
          })
          .onOk(async () => {
            await api.pdFile.delete(id);
            await this.setPdFiles();
          });
      },

      getUniqueRegionNames(boxes) {
        let regionNames = boxes.map((i) => i.region_name);

        return regionNames
          .filter((e, i) => regionNames.indexOf(e) >= i)
          .join("\n");
      },

      async parsePdFile(id) {
        this.awaitStartParsing = true;

        api.pdFile
          .parse(id)
          .then(
            (res) => {
              if (res.status === 201) {
                this.$q.notify({
                  color: "positive",
                  message: res.data.message,
                });
              }
            },
            (error) => {
              this.$q.notify({
                color: "negative",
                message: error.response.data.message,
              });
            }
          )
          .then(() => {
            this.awaitStartParsing = false;
          });
      },

      shortness(value, length) {
        return shortness(value, length);
      },
    },
  };
</script>
