<template>
  <div class="l-table__row min-w-full" role="rowgroup">
    <div
      v-if="visibleColumns.includes('actions')"
      :style="getCellStyle('actions')"
      class="l-table__cell"
    />

    <div
      v-if="visibleColumns.includes('id')"
      :style="getCellStyle('id')"
      class="l-table__cell"
    >
      <q-input
        v-model.number="search.id.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.id.c"
            :options="compareNumberOptions"
            :value="search.id.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('dated_at')"
      :style="getCellStyle('dated_at')"
      class="l-table__cell"
    >
      <div class="full-width">
        <div class="flex justify-between items-center">
          <div>
            {{ search.dated_at.from || "от" }}
          </div>
          <div>
            <q-icon
              v-if="search.dated_at.from"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.dated_at.from = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerDatedAtFrom">
                <q-date
                  v-model="search.dated_at.from"
                  mask="YYYY-MM-DD"
                  @mouseleave="$refs.datePickerDatedAtFrom.hide()"
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>

        <div class="flex justify-between items-center">
          <div>
            {{ search.dated_at.to || "до" }}
          </div>
          <div>
            <q-icon
              v-if="search.dated_at.to"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.dated_at.to = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerDatedAtTo">
                <q-date
                  v-model="search.dated_at.to"
                  mask="YYYY-MM-DD"
                  @mouseleave="$refs.datePickerDatedAtTo.hide()"
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="visibleColumns.includes('ap_project_serial')"
      :style="getCellStyle('ap_project_serial')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.ap_project_serial.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.ap_project_serial.c"
            :options="compareStringOptions"
            :value="search.ap_project_serial.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('ap_project_name')"
      :style="getCellStyle('ap_project_name')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.ap_project_serials"
        :options="apProjectsOptions"
        options-dense
        use-chips
        multiple
        dense
        map-options
        emit-value
        option-label="name"
        option-value="serial"
        use-input
        @filter="filterApProjects"
        @update:model-value="onSearchInput"
      >
        <template v-slot:option="scope">
          <q-item v-bind="scope.itemProps">
            <q-item-section>
              <q-item-label>{{ scope.opt.name }}</q-item-label>
              <q-item-label caption>{{ scope.opt.serial }}</q-item-label>
              <q-item-label caption>{{ scope.opt.region_name }}</q-item-label>
            </q-item-section>
          </q-item>
        </template>
      </q-select>
    </div>

    <div
      v-if="visibleColumns.includes('ap_project_address')"
      :style="getCellStyle('ap_project_address')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.ap_project_address.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.ap_project_address.c"
            :options="compareStringOptions"
            :value="search.ap_project_address.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('ap_project_region_name')"
      :style="getCellStyle('ap_project_region_name')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.ap_project_region_name"
        :options="regionNameOptions"
        options-dense
        use-chips
        multiple
        dense
        @update:model-value="onSearchApProjectRegionName"
      />
    </div>

    <div
      v-if="visibleColumns.includes('box_serial')"
      :style="getCellStyle('box_serial')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.box_serial.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.box_serial.c"
            :options="compareStringOptions"
            :value="search.box_serial.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('box_mark')"
      :style="getCellStyle('box_mark')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.box_mark.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.box_mark.c"
            :options="compareStringOptions"
            :value="search.box_mark.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('ap_box_serial')"
      :style="getCellStyle('ap_box_serial')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.ap_box_serial.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.ap_box_serial.c"
            :options="compareStringOptions"
            :value="search.ap_box_serial.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('box_name')"
      :style="getCellStyle('box_name')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.box_name"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('pd_number')"
      :style="getCellStyle('pd_number')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.pd_number.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.pd_number.c"
            :options="compareStringOptions"
            :value="search.pd_number.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('published_at')"
      :style="getCellStyle('published_at')"
      class="l-table__cell"
    />

    <div
      v-if="visibleColumns.includes('company')"
      :style="getCellStyle('company')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.company"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('builder_inn')"
      :style="getCellStyle('builder_inn')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.builder_inn.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.builder_inn.c"
            :options="compareStringOptions"
            :value="search.builder_inn.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('reported_at')"
      :style="getCellStyle('reported_at')"
      class="l-table__cell"
    >
      <div class="full-width">
        <div class="flex justify-between items-center">
          <div>
            {{ search.reported_at.from || "от" }}
          </div>
          <div>
            <q-icon
              v-if="search.reported_at.from"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.reported_at.from = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerReportedFrom">
                <q-date
                  v-model="search.reported_at.from"
                  mask="YYYY-MM-DD"
                  @mouseleave="$refs.datePickerReportedFrom.hide()"
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>

        <div class="flex justify-between items-center">
          <div>
            {{ search.reported_at.to || "до" }}
          </div>
          <div>
            <q-icon
              v-if="search.reported_at.to"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.reported_at.to = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerReportedTo">
                <q-date
                  v-model="search.reported_at.to"
                  mask="YYYY-MM-DD"
                  @mouseleave="$refs.datePickerReportedTo.hide()"
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="visibleColumns.includes('buildings_qty')"
      :style="getCellStyle('buildings_qty')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.buildings_qty.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.buildings_qty.c"
            :options="compareNumberOptions"
            :value="search.buildings_qty.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('floors_min')"
      :style="getCellStyle('floors_min')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.floors_min.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.floors_min.c"
            :options="compareNumberOptions"
            :value="search.floors_min.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('floors_max')"
      :style="getCellStyle('floors_max')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.floors_max.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.floors_max.c"
            :options="compareNumberOptions"
            :value="search.floors_max.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('object_square')"
      :style="getCellStyle('object_square')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.object_square.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.object_square.c"
            :options="compareNumberOptions"
            :value="search.object_square.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('wall_frame_material')"
      :style="getCellStyle('wall_frame_material')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.wall_frame_material"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('floor_material')"
      :style="getCellStyle('floor_material')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.floor_material"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('living_square')"
      :style="getCellStyle('living_square')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.living_square.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.living_square.c"
            :options="compareNumberOptions"
            :value="search.living_square.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('nonliving_square')"
      :style="getCellStyle('nonliving_square')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.nonliving_square.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.nonliving_square.c"
            :options="compareNumberOptions"
            :value="search.nonliving_square.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('total_square')"
      :style="getCellStyle('total_square')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.total_square.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.total_square.c"
            :options="compareNumberOptions"
            :value="search.total_square.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('general_contractor')"
      :style="getCellStyle('general_contractor')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.general_contractor"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('general_contractor_inn')"
      :style="getCellStyle('general_contractor_inn')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.general_contractor_inn"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('construction_permit_number')"
      :style="getCellStyle('construction_permit_number')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.construction_permit_number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('construction_permit_issued_at')"
      :style="getCellStyle('construction_permit_issued_at')"
      class="l-table__cell"
    />

    <div
      v-if="visibleColumns.includes('construction_permit_valid_until')"
      :style="getCellStyle('construction_permit_valid_until')"
      class="l-table__cell"
    />

    <div
      v-if="
        visibleColumns.includes('construction_permit_valid_until_updated_at')
      "
      :style="getCellStyle('construction_permit_valid_until_updated_at')"
      class="l-table__cell"
    />

    <div
      v-if="visibleColumns.includes('builder_rights_ground_valid_until')"
      :style="getCellStyle('builder_rights_ground_valid_until')"
      class="l-table__cell"
    />

    <div
      v-if="visibleColumns.includes('builder_rights_contract_updated_at')"
      :style="getCellStyle('builder_rights_contract_updated_at')"
      class="l-table__cell"
    />

    <div
      v-if="visibleColumns.includes('cadastrals')"
      :style="getCellStyle('cadastrals')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.cadastral"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('living_total_qty')"
      :style="getCellStyle('living_total_qty')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.living_total_qty.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.living_total_qty.c"
            :options="compareNumberOptions"
            :value="search.living_total_qty.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('nonliving_total_qty')"
      :style="getCellStyle('nonliving_total_qty')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.nonliving_total_qty.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.nonliving_total_qty.c"
            :options="compareNumberOptions"
            :value="search.nonliving_total_qty.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('nonliving_total_parking_qty')"
      :style="getCellStyle('nonliving_total_parking_qty')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.nonliving_total_parking_qty.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.nonliving_total_parking_qty.c"
            :options="compareNumberOptions"
            :value="search.nonliving_total_parking_qty.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('nonliving_total_other_qty')"
      :style="getCellStyle('nonliving_total_other_qty')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.nonliving_total_other_qty.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.nonliving_total_other_qty.c"
            :options="compareNumberOptions"
            :value="search.nonliving_total_other_qty.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('construction_stages')"
      :style="getCellStyle('construction_stages')"
      class="l-table__cell"
    />

    <div
      v-if="visibleColumns.includes('keys_initial_issue_at')"
      :style="getCellStyle('keys_initial_issue_at')"
      class="l-table__cell"
    />

    <div
      v-if="visibleColumns.includes('keys_planned_issue_at')"
      :style="getCellStyle('keys_planned_issue_at')"
      class="l-table__cell"
    />

    <div
      v-if="visibleColumns.includes('builder_obligations_securing')"
      :style="getCellStyle('builder_obligations_securing')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.builder_obligations_securing"
        :options="builderObligationsSecuringOptions"
        options-dense
        dense
        use-chips
        multiple
        @update:model-value="
          () => {
            onSearchInput();
            setDynamicFilters();
          }
        "
      />
    </div>

    <div
      v-if="visibleColumns.includes('escrow_bank')"
      :style="getCellStyle('escrow_bank')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.escrow_bank"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('payment_contributions_fund')"
      :style="getCellStyle('payment_contributions_fund')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.payment_contributions_fund"
        :options="paymentContributionsFundOptions"
        options-dense
        dense
        use-chips
        multiple
        @update:model-value="
          () => {
            onSearchInput();
            setDynamicFilters();
          }
        "
      />
    </div>

    <div
      v-if="visibleColumns.includes('fund_bank')"
      :style="getCellStyle('fund_bank')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.fund_bank"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('builder_raising_money')"
      :style="getCellStyle('builder_raising_money')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.builder_raising_money"
        :options="builderRaisingMoneyOptions"
        options-dense
        dense
        use-chips
        multiple
        @update:model-value="
          () => {
            onSearchInput();
            setDynamicFilters();
          }
        "
      />
    </div>

    <div
      v-if="visibleColumns.includes('financing_bank')"
      :style="getCellStyle('financing_bank')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.financing_bank"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('living_escrow_qty')"
      :style="getCellStyle('living_escrow_qty')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.living_escrow_qty.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.living_escrow_qty.c"
            :options="compareNumberOptions"
            :value="search.living_escrow_qty.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('living_fund_qty')"
      :style="getCellStyle('living_fund_qty')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.living_fund_qty.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.living_fund_qty.c"
            :options="compareNumberOptions"
            :value="search.living_fund_qty.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('living_other_qty')"
      :style="getCellStyle('living_other_qty')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.living_other_qty.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.living_other_qty.c"
            :options="compareNumberOptions"
            :value="search.living_other_qty.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('nonliving_escrow_qty')"
      :style="getCellStyle('nonliving_escrow_qty')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.nonliving_escrow_qty.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.nonliving_escrow_qty.c"
            :options="compareNumberOptions"
            :value="search.nonliving_escrow_qty.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('nonliving_fund_qty')"
      :style="getCellStyle('nonliving_fund_qty')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.nonliving_fund_qty.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.nonliving_fund_qty.c"
            :options="compareNumberOptions"
            :value="search.nonliving_fund_qty.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('nonliving_other_qty')"
      :style="getCellStyle('nonliving_other_qty')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.nonliving_other_qty.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.nonliving_other_qty.c"
            :options="compareNumberOptions"
            :value="search.nonliving_other_qty.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('parking_escrow_qty')"
      :style="getCellStyle('parking_escrow_qty')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.parking_escrow_qty.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.parking_escrow_qty.c"
            :options="compareNumberOptions"
            :value="search.parking_escrow_qty.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('parking_fund_qty')"
      :style="getCellStyle('parking_fund_qty')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.parking_fund_qty.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.parking_fund_qty.c"
            :options="compareNumberOptions"
            :value="search.parking_fund_qty.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('parking_other_qty')"
      :style="getCellStyle('parking_other_qty')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.parking_other_qty.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.parking_other_qty.c"
            :options="compareNumberOptions"
            :value="search.parking_other_qty.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('living_escrow_square')"
      :style="getCellStyle('living_escrow_square')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.living_escrow_square.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.living_escrow_square.c"
            :options="compareNumberOptions"
            :value="search.living_escrow_square.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('living_fund_square')"
      :style="getCellStyle('living_fund_square')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.living_fund_square.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.living_fund_square.c"
            :options="compareNumberOptions"
            :value="search.living_fund_square.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('living_other_square')"
      :style="getCellStyle('living_other_square')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.living_other_square.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.living_other_square.c"
            :options="compareNumberOptions"
            :value="search.living_other_square.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('nonliving_escrow_square')"
      :style="getCellStyle('nonliving_escrow_square')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.nonliving_escrow_square.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.nonliving_escrow_square.c"
            :options="compareNumberOptions"
            :value="search.nonliving_escrow_square.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('nonliving_fund_square')"
      :style="getCellStyle('nonliving_fund_square')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.nonliving_fund_square.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.nonliving_fund_square.c"
            :options="compareNumberOptions"
            :value="search.nonliving_fund_square.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('nonliving_other_square')"
      :style="getCellStyle('nonliving_other_square')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.nonliving_other_square.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.nonliving_other_square.c"
            :options="compareNumberOptions"
            :value="search.nonliving_other_square.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('parking_escrow_square')"
      :style="getCellStyle('parking_escrow_square')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.parking_escrow_square.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.parking_escrow_square.c"
            :options="compareNumberOptions"
            :value="search.parking_escrow_square.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('parking_fund_square')"
      :style="getCellStyle('parking_fund_square')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.parking_fund_square.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.parking_fund_square.c"
            :options="compareNumberOptions"
            :value="search.parking_fund_square.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('parking_other_square')"
      :style="getCellStyle('parking_other_square')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.parking_other_square.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.parking_other_square.c"
            :options="compareNumberOptions"
            :value="search.parking_other_square.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('living_escrow_price')"
      :style="getCellStyle('living_escrow_price')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.living_escrow_price.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.living_escrow_price.c"
            :options="compareNumberOptions"
            :value="search.living_escrow_price.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('living_fund_price')"
      :style="getCellStyle('living_fund_price')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.living_fund_price.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.living_fund_price.c"
            :options="compareNumberOptions"
            :value="search.living_fund_price.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('living_other_price')"
      :style="getCellStyle('living_other_price')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.living_other_price.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.living_other_price.c"
            :options="compareNumberOptions"
            :value="search.living_other_price.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('nonliving_escrow_price')"
      :style="getCellStyle('nonliving_escrow_price')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.nonliving_escrow_price.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.nonliving_escrow_price.c"
            :options="compareNumberOptions"
            :value="search.nonliving_escrow_price.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('nonliving_fund_price')"
      :style="getCellStyle('nonliving_fund_price')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.nonliving_fund_price.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.nonliving_fund_price.c"
            :options="compareNumberOptions"
            :value="search.nonliving_fund_price.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('nonliving_other_price')"
      :style="getCellStyle('nonliving_other_price')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.nonliving_other_price.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.nonliving_other_price.c"
            :options="compareNumberOptions"
            :value="search.nonliving_other_price.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('parking_escrow_price')"
      :style="getCellStyle('parking_escrow_price')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.parking_escrow_price.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.parking_escrow_price.c"
            :options="compareNumberOptions"
            :value="search.parking_escrow_price.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('parking_fund_price')"
      :style="getCellStyle('parking_fund_price')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.parking_fund_price.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.parking_fund_price.c"
            :options="compareNumberOptions"
            :value="search.parking_fund_price.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('parking_other_price')"
      :style="getCellStyle('parking_other_price')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.parking_other_price.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.parking_other_price.c"
            :options="compareNumberOptions"
            :value="search.parking_other_price.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('lift_qty')"
      :style="getCellStyle('lift_qty')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.lift_qty.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.lift_qty.c"
            :options="compareNumberOptions"
            :value="search.lift_qty.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('lift_cargo_qty')"
      :style="getCellStyle('lift_cargo_qty')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.lift_cargo_qty.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.lift_cargo_qty.c"
            :options="compareNumberOptions"
            :value="search.lift_cargo_qty.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('lift_wheelchair_qty')"
      :style="getCellStyle('lift_wheelchair_qty')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.lift_wheelchair_qty.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.lift_wheelchair_qty.c"
            :options="compareNumberOptions"
            :value="search.lift_wheelchair_qty.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('created_at')"
      :style="getCellStyle('created_at')"
      class="l-table__cell"
    >
      <div class="full-width">
        <div class="flex justify-between items-center">
          <div>
            {{ search.created_at.from || "от" }}
          </div>
          <div>
            <q-icon
              v-if="search.created_at.from"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.created_at.from = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerCreatedFrom">
                <q-date
                  v-model="search.created_at.from"
                  mask="YYYY-MM-DD"
                  @mouseleave="$refs.datePickerCreatedFrom.hide()"
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>

        <div class="flex justify-between items-center">
          <div>
            {{ search.created_at.to || "до" }}
          </div>
          <div>
            <q-icon
              v-if="search.created_at.to"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.created_at.to = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerCreatedTo">
                <q-date
                  v-model="search.created_at.to"
                  mask="YYYY-MM-DD"
                  @mouseleave="$refs.datePickerCreatedTo.hide()"
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="visibleColumns.includes('updated_at')"
      :style="getCellStyle('updated_at')"
      class="l-table__cell"
    >
      <div class="full-width">
        <div class="flex justify-between items-center">
          <div>
            {{ search.updated_at.from || "от" }}
          </div>
          <div>
            <q-icon
              v-if="search.updated_at.from"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.updated_at.from = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerUpdatedFrom">
                <q-date
                  v-model="search.updated_at.from"
                  mask="YYYY-MM-DD"
                  @mouseleave="$refs.datePickerUpdatedFrom.hide()"
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>

        <div class="flex justify-between items-center">
          <div>
            {{ search.updated_at.to || "до" }}
          </div>
          <div>
            <q-icon
              v-if="search.updated_at.to"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.updated_at.to = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerUpdatedTo">
                <q-date
                  v-model="search.updated_at.to"
                  mask="YYYY-MM-DD"
                  @mouseleave="$refs.datePickerUpdatedTo.hide()"
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ComparisonSelect from "@/components/ComparisonSelect";
  import {
    COMPARE_NUMBER_OPTIONS,
    COMPARE_STRING_OPTIONS,
    SEARCH_SELECT_PROPS,
    SEARCH_INPUT_PROPS,
    normalizeQueryForRequest,
  } from "@/utils/batch";
  import api from "@/api";
  import emitter from "@/plugins/mitt";

  export default {
    name: "DeclarationCompositeTableFiltersRow",

    emits: ["filter-declaration-composites", "set-declaration-composites"],

    props: {
      cellStyles: {
        type: Object,
        required: true,
      },
      visibleColumns: {
        type: Array,
        required: true,
      },
      search: {
        type: Object,
        required: true,
      },
    },

    components: {
      ComparisonSelect,
    },

    created() {
      emitter.on(
        "declaration-composites-update-dynamic-filters",
        this.setDynamicFilters
      );
    },

    unmounted() {
      emitter.off(
        "declaration-composites-update-dynamic-filters",
        this.setDynamicFilters
      );
    },

    data() {
      return {
        searchSelectProps: SEARCH_SELECT_PROPS,
        searchInputProps: SEARCH_INPUT_PROPS,
        compareNumberOptions: COMPARE_NUMBER_OPTIONS,
        compareStringOptions: COMPARE_STRING_OPTIONS,
        regionNameOptions: [],
        apProjectsOptions: [],
        builderObligationsSecuringOptions: [],
        builderRaisingMoneyOptions: [],
        paymentContributionsFundOptions: [],
      };
    },

    watch: {
      "search.dated_at.from"() {
        this.emitFilter();
      },
      "search.dated_at.to"() {
        this.emitFilter();
      },
      "search.published_at.from"() {
        this.emitFilter();
      },
      "search.published_at.to"() {
        this.emitFilter();
      },
      "search.reported_at.from"() {
        this.emitFilter();
      },
      "search.reported_at.to"() {
        this.emitFilter();
      },
      "search.construction_permit_issued_at.from"() {
        this.emitFilter();
      },
      "search.construction_permit_issued_at.to"() {
        this.emitFilter();
      },
      "search.construction_permit_valid_until.from"() {
        this.emitFilter();
      },
      "search.construction_permit_valid_until.to"() {
        this.emitFilter();
      },
      "search.construction_permit_valid_until_updated_at.from"() {
        this.emitFilter();
      },
      "search.construction_permit_valid_until_updated_at.to"() {
        this.emitFilter();
      },
      "search.builder_rights_ground_valid_until.from"() {
        this.emitFilter();
      },
      "search.builder_rights_ground_valid_until.to"() {
        this.emitFilter();
      },
      "search.builder_rights_contract_updated_at.from"() {
        this.emitFilter();
      },
      "search.builder_rights_contract_updated_at.to"() {
        this.emitFilter();
      },
      "search.keys_initial_issue_at.from"() {
        this.emitFilter();
      },
      "search.keys_initial_issue_at.to"() {
        this.emitFilter();
      },
      "search.keys_planned_issue_at.from"() {
        this.emitFilter();
      },
      "search.keys_planned_issue_at.to"() {
        this.emitFilter();
      },
      "search.created_at.from"() {
        this.emitFilter();
      },
      "search.created_at.to"() {
        this.emitFilter();
      },
      "search.updated_at.from"() {
        this.emitFilter();
      },
      "search.updated_at.to"() {
        this.emitFilter();
      },
    },

    mounted() {
      this.setFilters();
      this.setDynamicFilters();
      this.emitFilter();
    },

    methods: {
      async setFilters() {
        const res = await api.declarationComposite.filters(
          normalizeQueryForRequest(this.search)
        );

        this.regionNameOptions = res.data.region_names.sort();
        this.apProjectsOptions = res.data.ap_projects.sort();

        // not reactive for filtering
        this.apProjectsOptionsData = res.data.ap_projects.sort();
      },

      async setDynamicFilters() {
        const res = await api.declarationComposite.dynamicFilters(
          normalizeQueryForRequest(this.search)
        );

        this.builderObligationsSecuringOptions =
          res.data.builder_obligations_securing.sort();
        this.builderRaisingMoneyOptions = res.data.builder_raising_money.sort();
        this.paymentContributionsFundOptions =
          res.data.payment_contributions_fund.sort();
      },

      emitFilter() {
        this.$emit("filter-declaration-composites");
      },

      onSearchInput(val) {
        this.emitFilter();
      },

      onSearchCompareInput(val) {
        this.emitFilter();
      },

      filterApProjects(val, update) {
        update(() => {
          const needle = val.toLowerCase().trim();
          this.apProjectsOptions = this.apProjectsOptionsData.filter(
            (i) => i.name.toLowerCase().indexOf(needle) > -1
          );
        });
      },

      onSearchApProjectRegionName(val) {
        this.setFilters();
        this.onSearchInput(val);
      },

      getCellStyle(columnName) {
        return this.cellStyles[columnName];
      },
    },
  };
</script>
