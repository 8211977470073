<template>
  <div class="l-table__row min-w-full bg-white sticky-filters-row" role="rowgroup">
    <div
      v-if="visibleColumns.includes('id')"
      :style="getCellStyle('id')"
      class="l-table__cell"
    >
      <q-input
        v-model.number="search.id.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.id.c"
            :options="compareNumberOptions"
            :value="search.id.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('serial')"
      :style="getCellStyle('serial')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.serial.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.serial.c"
            :options="compareStringOptions"
            :value="search.serial.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('region_name')"
      :style="getCellStyle('region_name')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.region_name.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.region_name.c"
            :options="compareStringOptions"
            :value="search.region_name.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('name')"
      :style="getCellStyle('name')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.name.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.name.c"
            :options="compareStringOptions"
            :value="search.name.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('address')"
      :style="getCellStyle('address')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.address.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.address.c"
            :options="compareStringOptions"
            :value="search.address.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('actions')"
      :style="getCellStyle('actions')"
      class="l-table__cell"
    />
  </div>
</template>

<script>
  import ComparisonSelect from "@/components/ComparisonSelect";
  import {
    COMPARE_NUMBER_OPTIONS,
    COMPARE_STRING_OPTIONS,
    SEARCH_SELECT_PROPS,
    SEARCH_INPUT_PROPS,
  } from "@/utils/batch";

  export default {
    name: "ApProjectTableFiltersRow",

    emits: ["filter-projects", "set-projects"],

    props: {
      cellStyles: {
        type: Object,
        required: true,
      },
      visibleColumns: {
        type: Array,
        required: true,
      },
      search: {
        type: Object,
        required: true,
      },
    },

    components: {
      ComparisonSelect,
    },

    data() {
      return {
        searchSelectProps: SEARCH_SELECT_PROPS,
        searchInputProps: SEARCH_INPUT_PROPS,
        compareNumberOptions: COMPARE_NUMBER_OPTIONS,
        compareStringOptions: COMPARE_STRING_OPTIONS,
      };
    },

    async mounted() {
      this.$emit("set-projects");
    },

    methods: {
      onSearchInput(val) {
        this.$emit("filter-projects");
      },

      onSearchCompareInput(val) {
        this.$emit("filter-projects");
      },

      getCellStyle(columnName) {
        return this.cellStyles[columnName];
      },
    },
  };
</script>
