<template>
  <div class="q-pa-md">
    <div
      v-if="apBoxes && apBoxes.length"
      aria-label="Корпуса АП"
      class="l-table q-table--bordered bg-white"
      role="table"
    >
      <div class="l-table__row min-w-full" role="rowgroup">
        <div
          v-for="column in columns"
          :key="column.name"
          :style="column.style"
          class="l-table__cell"
          role="columnheader"
        >
          <div class="text-body3">{{ column.label }}</div>
        </div>
      </div>

      <div
        v-for="apBox in apBoxes"
        :key="apBox.id"
        class="l-table__row min-w-full"
        role="rowgroup"
      >
        <div :style="getColumnStyle('serial')" class="l-table__cell">
          {{ apBox.serial }}
        </div>

        <div :style="getColumnStyle('name')" class="l-table__cell">
          {{ apBox.name }}
        </div>

        <div :style="getColumnStyle('builder')" class="l-table__cell">
          {{ apBox.builder }}
        </div>

        <div :style="getColumnStyle('floor_max')" class="l-table__cell">
          {{ apBox.floor_max }}
        </div>

        <div :style="getColumnStyle('flat_qty')" class="l-table__cell">
          {{ apBox.flat_qty }}
        </div>

        <div :style="getColumnStyle('parking_qty')" class="l-table__cell">
          {{ apBox.parking_qty }}
        </div>

        <div :style="getColumnStyle('date_rve')" class="l-table__cell">
          {{ apBox.date_rve }}
        </div>

        <div :style="getColumnStyle('finishing_type')" class="l-table__cell">
          {{ apBox.finishing_type }}
        </div>

        <div :style="getColumnStyle('living_square')" class="l-table__cell">
          {{ apBox.living_square }}
        </div>

        <div :style="getColumnStyle('ceiling_height')" class="l-table__cell">
          {{ getCeilingHeight(apBox.ceiling_height) }}
        </div>

        <div :style="getColumnStyle('cadastrals')" class="l-table__cell">
          {{ apBox.cadastrals && apBox.cadastrals.join(", ") }}
        </div>

        <div :style="getColumnStyle('actions')" class="l-table__cell">
          <div class="flex no-wrap">
            <q-btn
              :disable="loading"
              flat
              icon="mdi-link-variant-off"
              title="Отвязать"
              @click="unlink(apBox.serial)"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="q-py-md">
      <q-btn
        class="bg-white"
        icon="mdi-link-variant-plus"
        label="Привязать"
        @click="link"
      />
    </div>
  </div>
</template>

<script>
  import api from "@/api";
  import LinkApBoxesDialog from "@/components/dialogs/LinkApBoxesDialog";
  import emitter from "@/plugins/mitt";
  import { formatCeilingHeight } from "@/utils/batch";

  export default {
    name: "ExpandedApBoxRow",

    components: {
      LinkApBoxesDialog,
    },

    props: {
      boxId: {
        type: Number,
        required: true,
      },
      apBoxes: {
        type: Array,
        required: false,
      },
    },

    data() {
      return {
        loading: false,
        columns: [
          {
            name: "serial",
            label: "Номер",
            style: "width: 80px;",
          },
          {
            name: "name",
            label: "Название",
            style: "width: 160px;",
          },
          {
            name: "builder",
            label: "Застройщик",
            style: "width: 160px;",
          },
          {
            name: "floor_max",
            label: "Этажность",
            style: "width: 100px;",
          },
          {
            name: "flat_qty",
            label: "Квартир",
            style: "width: 100px;",
          },
          {
            name: "parking_qty",
            label: "Машиномест",
            style: "width: 100px;",
          },
          {
            name: "date_rve",
            label: "РВЭ",
            style: "width: 160px;",
          },
          {
            name: "finishing_type",
            label: "Отделка",
            style: "width: 160px;",
          },
          {
            name: "living_square",
            label: "Жилая площадь",
            style: "width: 160px;",
          },
          {
            name: "ceiling_height",
            label: "Высота потолков",
            style: "width: 120px;",
          },
          {
            name: "cadastrals",
            label: "Кадастр",
            style: "width: 160px;",
          },
          {
            name: "actions",
            label: "",
            style: "width: 100px;",
          },
        ],
      };
    },

    methods: {
      getColumnStyle(col) {
        return this.columns.filter((obj) => obj.name === col)[0].style;
      },

      async unlink(apBoxSerial) {
        this.loading = true;

        await api.box
          .unlink(this.boxId, apBoxSerial)
          .then((res) => {
            this.$q.notify({
              color: "positive",
              message: res.data.message,
            });

            emitter.emit("ap-box-unlinked");
          })
          .finally(() => {
            this.loading = false;
          });
      },

      link() {
        this.$q.dialog({
          component: LinkApBoxesDialog,
          componentProps: {
            boxId: this.boxId,
          },
        });
      },

      getCeilingHeight(value) {
        return formatCeilingHeight(value);
      },
    },
  };
</script>
