<template>
  <div class="flex justify-center items-center" style="height: 100vh">
    <div class="text-h4">404 Не найдено</div>
  </div>
</template>

<script>
  import { createMetaMixin } from "quasar";

  export default {
    name: "NotFound",

    mixins: [
      createMetaMixin(function () {
        return {
          title: "404 Не найдено",
        };
      }),
    ],
  };
</script>
