<template>
  <div class="row justify-center">
    <div class="col col-md-10 q-pa-md bg-white rounded-borders shadow-2">
      <div class="w-full flex column">
        <div class="text-h4">{{ authUser.name }}</div>
        <div class="text-subtitle1">{{ authUser.email }}</div>
      </div>

      <div class="w-full q-mt-md">
        <q-btn :to="{ name: 'AccountFiles' }" label="Файлы" />
      </div>
    </div>
  </div>
</template>

<script>
  import { createMetaMixin } from "quasar";

  export default {
    name: "Account",

    mixins: [
      createMetaMixin(function () {
        return {
          title: "Личный кабинет",
        };
      }),
    ],

    computed: {
      authUser() {
        return this.$store.getters["auth/user"];
      },
    },
  };
</script>
