<template>
  <div
    :class="$q.screen.xs || $q.screen.sm ? 'q-px-md' : ''"
    class="row q-py-lg"
  >
    <div class="col-24 col-md-16">
      <BoxTitle :box="box" />
    </div>
    <div class="col-24 col-md-8 flex items-center justify-end">
      <slot name="buttons"></slot>
    </div>
  </div>
</template>

<script>
  import BoxTitle from "@/components/boxes/BoxTitle";

  export default {
    name: "BoxHeader",

    components: { BoxTitle },

    props: {
      box: {
        type: Object,
        required: true,
      },
    },
  };
</script>