const NumberFormatRub = new Intl.NumberFormat("ru", {
  style: "currency",
  currency: "RUB",
  notation: "compact",
  compactDisplay: "short",
});

const NumberFormat = new Intl.NumberFormat("ru", {
  maximumFractionDigits: 2,
});

const rubFormat = (value) => {
  if (value === 0) {
    return `0 ₽`;
  }

  return value ? NumberFormatRub.format(value) : null;
};

const numberFormat = (value) => {
  return value ? NumberFormat.format(value) : null;
};

const numberFormatReverse = (string) => {
  return string
    ? parseFloat(string.replace(/\xa0/g, "").replace(/\,/g, "."))
    : null;
};

export { rubFormat, numberFormat, numberFormatReverse };
