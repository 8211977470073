<template>
  <div class="col col-md-10 q-pa-md bg-white rounded-borders shadow-2">
    <fieldset :disabled="awaitResponse" class="no-border q-px-none">
      <div class="row flex items-center">
        <div class="col-9 col-md-6 text-grey-8">Название</div>
        <div class="col-15 col-md-18">
          <q-input
            v-model="name"
            ref="name"
            bottom-slots
            dense
            :rules="nameRules"
          />
        </div>

        <div class="col-9 col-md-6 text-grey-8">Коды</div>
        <div class="col-15 col-md-18">
          <q-select
            v-model="codes"
            :rules="codesRules"
            ref="codes"
            bottom-slots
            label="Коды"
            multiple
            new-value-mode="add-unique"
            use-chips
            use-input
          />
        </div>
      </div>
    </fieldset>

    <div class="row">
      <q-btn
        :disable="awaitCreating"
        :loading="awaitCreating"
        color="primary"
        label="Добавить"
        @click="save"
      />
    </div>
  </div>
</template>

<script>
  import api from "@/api";
  import { createMetaMixin } from "quasar";

  export default {
    name: "RegionAdd",

    mixins: [
      createMetaMixin(function () {
        return {
          title: this.title,
        };
      }),
    ],

    computed: {
      nameRules() {
        return [
          (val) => !!val || "Заполните это поле",
          (val) => !(val && val.length < 3) || "Минимум 3 символа",
          (val) => !(val && val.length > 191) || "Максимум 191 символ",
        ];
      },

      codesRules() {
        return [
          (val) => (!!val && val.length) || "Заполните это поле",
          (val) => !(Array.isArray(val) && val.length < 1) || "Минимум 1 код",
        ];
      },
    },

    data() {
      return {
        awaitResponse: false,
        awaitCreating: false,
        name: null,
        codes: null,
      };
    },

    methods: {
      async save() {
        this.awaitCreating = true;

        const fieldsToValidate = ["name", "codes"];

        fieldsToValidate.forEach((field) => {
          this.$refs[field].validate();
        });

        let isValid = true;

        fieldsToValidate.forEach((field) => {
          if (this.$refs[field].hasError) {
            isValid = false;
          }
        });

        if (!isValid) {
          this.awaitCreating = false;

          return;
        }

        let payload = {
          name: this.name,
          codes: this.codes,
        };

        api.region
          .create(payload)
          .then(
            (res) => {
              this.$q.notify({
                color: "positive",
                message: res.data.message,
              });

              this.$router.push({ name: "RegionList" });
            },
            (error) => {
              this.$q.notify({
                color: "negative",
                message: error.response.data.message,
              });

              if (error.response.status === 422) {
                Object.keys(error.response.data.errors).forEach((name, i) => {
                  this.$q.notify({
                    color: "negative",
                    message: error.response.data.errors[name][i],
                    timeout: 10000,
                  });
                });
              }
            }
          )
          .then(() => {
            this.awaitCreating = false;
          });
      },
    },
  };
</script>
