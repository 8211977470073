<template>
  <q-dialog
    ref="dialog"
    position="top"
    transition-hide="slide-up"
    transition-show="slide-down"
    @hide="onDialogHide"
  >
    <q-card style="width: 600px; max-width: 600px">
      <div class="flex items-center justify-between q-px-md">
        <div v-if="box">Запустить парс ПД для корпуса {{ box.serial }}</div>
        <div v-if="multiMode">
          Запустить парс ПД для
          {{ selectionMode === "selected" ? ids.length : queryRowsQty }}
          корпусов
        </div>
        <q-btn v-close-popup flat dense icon="mdi-close" size="lg" />
      </div>

      <q-card-section>
        <div class="flex wrap q-gutter-y-md">
          <div v-if="multiMode" class="full-width">
            <q-radio v-model="selectionMode" val="selected" label="Выбранные" />
            <q-radio v-model="selectionMode" val="query" label="По фильтрам" />
          </div>
          <div class="full-width">
            <q-toggle
              v-model="actualOne"
              :disable="awaitResponse"
              label="Только актуальную ПД"
            />
          </div>
          <div class="full-width">
            <q-toggle
              v-model="forceParse"
              :disable="awaitResponse"
              label="Принудительный парс"
            />
          </div>
          <div class="full-width">
            <q-toggle
              v-model="updateExisting"
              :disable="awaitResponse"
              label="Обновить существующие"
            />
          </div>
          <q-input
            v-model="fromDate"
            :disable="awaitResponse"
            clearable
            filled
            label="Начиная с даты размещения"
            style="min-width: 20rem"
          >
            <template v-slot:append>
              <q-icon class="cursor-pointer" name="mdi-calendar">
                <q-popup-proxy>
                  <q-date v-model="fromDate" mask="YYYY-MM-DD" />
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
          <q-select
            v-model="field_names"
            :options="$store.state.pdFields"
            options-dense
            use-chips
            multiple
            dense
            map-options
            emit-value
            option-label="name_readable"
            option-value="name"
            label="Только эти поля ПД"
            class="full-width"
          />
        </div>
      </q-card-section>
      <q-card-section>
        <q-expansion-item expand-separator label="Описание параметров запуска">
          <ul>
            <li>
              <b>Только актуальную ПД</b> - ограничивает парс одной актуальной
              ПД. Полезно, когда нужно распарсить только самую свежую ПД.
            </li>
            <li>
              <b>Принудительный парс</b> - если выключено, значит будут
              парситься только файлы, которых еще нет в скачанных, на cdn. Если
              необходимо перепарсить или обновить имеющиеся записи деклараций,
              то необходимо активировать этот параметр.
            </li>
            <li>
              <b>Обновить существующие</b> - если выключено, то будут
              создаваться записи в таблице деклараций несмотря на то, что записи
              с такими датами размещения уже могут присутствовать. Другими
              словами, включайтся этот параметр, если вам необходимо обновить
              существующие декларации, а не создавать новые, которые могут быть
              дублями.
            </li>
            <li>
              <b>Начиная с даты размещения</b> - ограничивает доступные для
              парса файлы ПД на основе их дат размещения. Полезно для
              оптимизации ресурсов сервера, когда нужно распарсить только
              последние несколько штук. А также чтобы распарсить ПД, которые
              старше заданной в системе по-умолчанию даты.
            </li>
            <li>
              <b>Только эти поля ПД</b> - ограничивает набор полей для парса.
              Полезно, когда нужно обновить только выборочные поля, не
              затрагивая остальные.
            </li>
          </ul>
        </q-expansion-item>
      </q-card-section>

      <q-card-actions class="q-pa-md">
        <q-btn
          :disable="
            awaitResponse ||
            (multiMode && selectionMode !== 'query' && !ids.length)
          "
          :loading="awaitResponse"
          color="primary"
          label="Запустить"
          title="Запустить"
          class="full-width"
          @click="submit"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
  import api from "@/api";

  export default {
    name: "BoxParsePdsDialog",

    emits: ["hide"],

    props: {
      box: {
        type: Object,
        required: false,
        default: null,
      },
      ids: {
        type: Array,
        required: false,
        default: [],
      },
      query: {
        type: Object,
        required: false,
        default: null,
      },
      queryRowsQty: {
        type: Number,
        required: true,
      },
    },

    computed: {
      multiMode() {
        return this.ids.length || this.query !== null;
      },
    },

    data() {
      return {
        awaitResponse: false,
        selectionMode: "selected",
        actualOne: false,
        forceParse: true,
        updateExisting: true,
        fromDate: "2021-01-01",
        field_names: [],
      };
    },

    methods: {
      async submit() {
        let payload = {
          actual_one: this.actualOne,
          force_parse: this.forceParse,
          update_existing: this.updateExisting,
          from_date: this.fromDate,
          field_names: this.field_names,
        };

        this.awaitResponse = true;

        let promise;

        if (this.multiMode) {
          payload.selection_mode = this.selectionMode;

          if (this.selectionMode === "selected") {
            payload.ids = this.ids;
          } else if (this.selectionMode === "query") {
            payload.q = this.query;
          }

          promise = api.box.parsePdsMulti(payload);
        } else {
          promise = api.box.parsePds(this.box.id, payload);
        }

        await promise
          .then(
            (res) => {
              this.$q.notify({
                color: "positive",
                message: res.data.message,
              });
            },
            (error) => {
              this.$q.notify({
                color: "negative",
                message: error.response.data.message,
              });
            }
          )
          .then(() => {
            this.awaitResponse = false;
            this.hide();
          });
      },

      // following method is REQUIRED
      // (don't change its name --> "show")
      show() {
        this.$refs.dialog.show();
      },

      // following method is REQUIRED
      // (don't change its name --> "hide")
      hide() {
        this.$refs.dialog.hide();
      },

      onDialogHide() {
        this.$emit("hide");
      },
    },
  };
</script>
