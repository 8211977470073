import { axiosInstance as axios } from "@/plugins/axios";

const auth = {
  login(payload) {
    return axios.post("/auth/login", payload);
  },

  me(include = "") {
    return include
      ? axios.post("/auth/me", null, { params: { include } })
      : axios.post("/auth/me");
  },

  logout() {
    return axios.post("/auth/logout");
  },

  refresh(refreshToken) {
    return axios.post("/auth/refresh", { refresh_token: refreshToken });
  },
};

export default auth;
