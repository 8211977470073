import auth from "@/router/middlewares/auth";
import checkPermissions from "@/router/middlewares/checkPermissions";

import Account from "@/pages/account/Account";
import AccountFiles from "@/pages/account/AccountFiles";

const accountRoutes = [
  {
    path: "/account",
    name: "Account",
    component: Account,
    beforeEnter: [auth, checkPermissions],
    meta: {
      layout: "main",
      permissions: ["user.view"],
      crumbs: "/Аккаунт",
    },
  },
  {
    path: "/account/files",
    name: "AccountFiles",
    component: AccountFiles,
    beforeEnter: [auth, checkPermissions],
    meta: {
      layout: "main",
      permissions: ["user.view"],
      crumbs: "/Аккаунт/Файлы",
    },
  },
];

export default accountRoutes;
