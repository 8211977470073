<template>
  <div v-if="!loading" class="col bg-white rounded-borders shadow-2">
    <div style="height: 2px">
      <q-linear-progress
        v-show="awaitDeclarationCorrections"
        indeterminate
        size="2px"
      />
    </div>

    <div class="row q-pa-md">
      <div class="col-24 flex items-center justify-between">
        <q-btn
          :to="{ name: 'DeclarationCorrectionAdd' }"
          icon="mdi-plus"
          label="Добавить"
        />

        <div
          class="flex"
          :class="$q.screen.xs || $q.screen.sm ? '' : 'justify-end'"
        >
          <q-btn
            icon="mdi-information-outline"
            :label="$q.screen.xs || $q.screen.sm ? '' : 'Справка'"
            flat
            @click="showDeclarationCorrectionInfoDialog"
          />

          <q-btn
            icon="mdi-cog"
            :label="$q.screen.xs || $q.screen.sm ? '' : 'Параметры'"
          >
            <q-menu touch-position transition-hide="" transition-show="">
              <q-list separator>
                <q-item clickable>
                  <q-item-section>
                    <q-select
                      v-model="visibleColumns"
                      :options="columns"
                      borderless
                      dense
                      display-value="Показывать столбцы"
                      emit-value
                      map-options
                      multiple
                      option-value="name"
                      options-dense
                      @update:model-value="onInputVisibleColumns"
                    >
                      <template v-slot:before>
                        <q-icon class="q-mr-sm" name="mdi-eye-check" />
                      </template>

                      <template v-slot:option="scope">
                        <q-item v-bind="scope.itemProps">
                          <q-item-section>
                            <q-item-label>{{ scope.opt.label }}</q-item-label>
                          </q-item-section>
                          <q-item-section side>
                            <q-icon v-if="scope.selected" name="mdi-check" />
                          </q-item-section>
                        </q-item>
                      </template>
                    </q-select>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
      </div>
    </div>

    <div class="row bg-white">
      <div class="col-24 q-px-md">
        <div
          aria-label="Корректировки"
          class="l-table l-table--striped q-pb-sm"
          role="table"
        >
          <div class="l-table__row min-w-full" role="rowgroup">
            <template v-for="item in columns" :key="item.name">
              <div
                v-if="visibleColumns.includes(item.name)"
                :style="getCellStyle(item.name)"
                class="l-table__cell"
                role="columnheader"
              >
                <div
                  :class="item.sortName && 'cursor-pointer'"
                  class="text-body3"
                  @click="item.sortName && sortByColumn(item.sortName)"
                >
                  {{ item.label }}
                  <q-icon
                    v-if="pagination.sortBy === item.sortName"
                    :name="
                      pagination.descending ? 'mdi-arrow-down' : 'mdi-arrow-up'
                    "
                  />
                </div>
              </div>
            </template>
          </div>

          <div class="l-table__row min-w-full" role="rowgroup">
            <div
              v-if="visibleColumns.includes('id')"
              :style="getCellStyle('id')"
              class="l-table__cell"
            >
              <q-input
                v-model.number="search.id.v"
                class="appearance-none"
                type="number"
                v-bind="searchInputProps"
                @update:model-value="onSearchInput"
              >
                <template v-slot:prepend>
                  <ComparisonSelect
                    v-model="search.id.c"
                    :options="compareNumberOptions"
                    :value="search.id.c"
                    @update:model-value="onSearchCompareInput"
                  />
                </template>
              </q-input>
            </div>

            <div
              v-if="visibleColumns.includes('field')"
              :style="getCellStyle('field')"
              class="l-table__cell"
            >
              <q-select
                v-model="search.field"
                :options="pdFields"
                clearable
                emit-value
                option-value="name"
                map-options
                v-bind="searchInputProps"
                @update:model-value="onSearchInput"
              >
                <template v-slot:selected-item="scope">
                  {{ scope.opt.name }}
                </template>

                <template v-slot:option="scope">
                  <q-item dense v-bind="scope.itemProps">
                    <q-item-section>
                      <q-item-label>{{ scope.opt.name }}</q-item-label>
                      <q-item-label caption>{{
                        scope.opt.name_readable
                      }}</q-item-label>
                      <q-item-label caption
                        >Артикул в ПД: {{ scope.opt.pd_article }}</q-item-label
                      >
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
            </div>

            <div
              v-if="visibleColumns.includes('field_readable')"
              :style="getCellStyle('field_readable')"
              class="l-table__cell"
            >
              <q-select
                v-model="search.field_readable"
                :options="pdFields"
                clearable
                emit-value
                map-options
                option-value="name"
                v-bind="searchInputProps"
                @update:model-value="onSearchInput"
              >
                <template v-slot:selected-item="scope">
                  {{ scope.opt.name_readable }}
                </template>

                <template v-slot:option="scope">
                  <q-item dense v-bind="scope.itemProps">
                    <q-item-section>
                      <q-item-label>{{ scope.opt.name_readable }}</q-item-label>
                      <q-item-label caption>{{ scope.opt.name }}</q-item-label>
                      <q-item-label caption
                        >Артикул в ПД: {{ scope.opt.pd_article }}</q-item-label
                      >
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
            </div>

            <div
              v-if="visibleColumns.includes('search')"
              :style="getCellStyle('search')"
              class="l-table__cell"
            >
              <q-input
                v-model="search.search.v"
                v-bind="searchInputProps"
                @update:model-value="onSearchInput"
              >
                <template v-slot:prepend>
                  <ComparisonSelect
                    v-model="search.search.c"
                    :options="compareStringOptions"
                    :value="search.search.c"
                    @update:model-value="onSearchCompareInput"
                  />
                </template>
              </q-input>
            </div>

            <div
              v-if="visibleColumns.includes('replace')"
              :style="getCellStyle('replace')"
              class="l-table__cell"
            >
              <q-input
                v-model="search.replace.v"
                v-bind="searchInputProps"
                @update:model-value="onSearchInput"
              >
                <template v-slot:prepend>
                  <ComparisonSelect
                    v-model="search.replace.c"
                    :options="compareStringOptions"
                    :value="search.replace.c"
                    @update:model-value="onSearchCompareInput"
                  />
                </template>
              </q-input>
            </div>

            <div
              v-if="visibleColumns.includes('manual')"
              :style="getCellStyle('manual')"
              class="l-table__cell"
            >
              <q-select
                v-model="search.manual"
                :options="manualFilterOptions"
                dense
                emit-value
                map-options
                options-dense
                @update:model-value="onSearchInput"
              />
            </div>

            <div
              v-if="visibleColumns.includes('box_id')"
              :style="getCellStyle('box_id')"
              class="l-table__cell"
            >
              <q-input
                v-model.number="search.box_id"
                class="appearance-none"
                type="number"
                v-bind="searchInputProps"
                @update:model-value="onSearchInput"
              />
            </div>

            <div
              v-if="visibleColumns.includes('box_serial')"
              :style="getCellStyle('box_serial')"
              class="l-table__cell"
            >
              <q-input
                v-model="search.box_serial.v"
                v-bind="searchInputProps"
                @update:model-value="onSearchInput"
              >
                <template v-slot:prepend>
                  <ComparisonSelect
                    v-model="search.box_serial.c"
                    :options="compareStringOptions"
                    :value="search.box_serial.c"
                    @update:model-value="onSearchCompareInput"
                  />
                </template>
              </q-input>
            </div>

            <div
              v-if="visibleColumns.includes('pd_file_id')"
              :style="getCellStyle('pd_file_id')"
              class="l-table__cell"
            >
              <q-input
                v-model.number="search.pd_file_id"
                class="appearance-none"
                type="number"
                v-bind="searchInputProps"
                @update:model-value="onSearchInput"
              />
            </div>

            <div
              v-if="visibleColumns.includes('pd_file_cdn_url')"
              :style="getCellStyle('pd_file_cdn_url')"
              class="l-table__cell"
            />

            <div
              v-if="visibleColumns.includes('created_by')"
              :style="getCellStyle('created_by')"
              class="l-table__cell"
            >
              <q-select
                v-model="search.created_by"
                :options="users"
                clearable
                emit-value
                option-value="id"
                option-label="name"
                v-bind="searchSelectProps"
                @update:model-value="onSearchInput"
              />
            </div>

            <div
              v-if="visibleColumns.includes('created_at')"
              :style="getCellStyle('created_at')"
              class="l-table__cell"
            >
              <div class="full-width">
                <div class="flex justify-between items-center">
                  <div>
                    {{ search.created_at.from || "от" }}
                  </div>
                  <div>
                    <q-icon
                      v-if="search.created_at.from"
                      class="cursor-pointer"
                      color="grey-7"
                      name="mdi-close-circle"
                      size="sm"
                      @click="search.created_at.from = null"
                    />

                    <q-icon
                      class="cursor-pointer"
                      color="grey-7"
                      name="mdi-calendar"
                      size="sm"
                    >
                      <q-popup-proxy ref="datePickerCreatedFrom">
                        <q-date
                          v-model="search.created_at.from"
                          mask="YYYY-MM-DD"
                          @mouseleave="$refs.datePickerCreatedFrom.hide()"
                        />
                      </q-popup-proxy>
                    </q-icon>
                  </div>
                </div>

                <div class="flex justify-between items-center">
                  <div>
                    {{ search.created_at.to || "до" }}
                  </div>
                  <div>
                    <q-icon
                      v-if="search.created_at.to"
                      class="cursor-pointer"
                      color="grey-7"
                      name="mdi-close-circle"
                      size="sm"
                      @click="search.created_at.to = null"
                    />

                    <q-icon
                      class="cursor-pointer"
                      color="grey-7"
                      name="mdi-calendar"
                      size="sm"
                    >
                      <q-popup-proxy ref="datePickerCreatedTo">
                        <q-date
                          v-model="search.created_at.to"
                          mask="YYYY-MM-DD"
                          @mouseleave="$refs.datePickerCreatedTo.hide()"
                        />
                      </q-popup-proxy>
                    </q-icon>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="visibleColumns.includes('actions')"
              :style="getCellStyle('actions')"
              class="l-table__cell"
            />
          </div>

          <div
            v-for="declarationCorrection in declarationCorrections"
            :key="declarationCorrection.id"
            class="l-table__row min-w-full"
            role="rowgroup"
          >
            <div
              v-if="visibleColumns.includes('id')"
              :style="getCellStyle('id')"
              class="l-table__cell"
            >
              {{ declarationCorrection.id }}
            </div>

            <div
              v-if="visibleColumns.includes('field')"
              :style="getCellStyle('field')"
              class="l-table__cell"
            >
              {{ declarationCorrection.field }}
            </div>

            <div
              v-if="visibleColumns.includes('field_readable')"
              :style="getCellStyle('field_readable')"
              class="l-table__cell"
            >
              {{ declarationCorrection.field_readable }}
            </div>

            <div
              v-if="visibleColumns.includes('search')"
              :style="getCellStyle('search')"
              class="l-table__cell"
            >
              {{ declarationCorrection.search }}
            </div>

            <div
              v-if="visibleColumns.includes('replace')"
              :style="getCellStyle('replace')"
              class="l-table__cell"
            >
              {{ declarationCorrection.replace }}
            </div>

            <div
              v-if="visibleColumns.includes('manual')"
              :style="getCellStyle('manual')"
              class="l-table__cell"
            >
              <q-icon v-if="declarationCorrection.manual" name="mdi-check" />
              <q-icon v-else name="mdi-close" />&nbsp;
              <span
                :class="declarationCorrection.manual ? 'text-green-7' : ''"
                >{{ declarationCorrection.manual ? "да" : "нет" }}</span
              >
            </div>

            <div
              v-if="visibleColumns.includes('box_id')"
              :style="getCellStyle('box_id')"
              class="l-table__cell"
            >
              {{ declarationCorrection.box && declarationCorrection.box.id }}
            </div>

            <div
              v-if="visibleColumns.includes('box_serial')"
              :style="getCellStyle('box_serial')"
              class="l-table__cell"
            >
              {{
                declarationCorrection.box && declarationCorrection.box.serial
              }}
            </div>

            <div
              v-if="visibleColumns.includes('pd_file_id')"
              :style="getCellStyle('pd_file_id')"
              class="l-table__cell"
            >
              {{
                declarationCorrection.pd_file &&
                declarationCorrection.pd_file.id
              }}
            </div>

            <div
              v-if="visibleColumns.includes('pd_file_cdn_url')"
              :style="getCellStyle('pd_file_cdn_url')"
              class="l-table__cell"
            >
              <a
                v-if="declarationCorrection.pd_file"
                :href="declarationCorrection.pd_file.cdn_url"
                class="l-link"
                target="_blank"
                >ссылка</a
              >
            </div>

            <div
              v-if="visibleColumns.includes('created_by')"
              :style="getCellStyle('created_by')"
              class="l-table__cell"
            >
              {{
                declarationCorrection.creator &&
                declarationCorrection.creator.name
              }}
            </div>

            <div
              v-if="visibleColumns.includes('created_at')"
              :style="getCellStyle('created_at')"
              class="l-table__cell"
            >
              {{ declarationCorrection.created_at }}
            </div>

            <div
              v-if="visibleColumns.includes('actions')"
              :style="getCellStyle('actions')"
              class="l-table__cell"
            >
              <div class="flex no-wrap">
                <q-btn
                  :disabled="awaitApplying"
                  :loading="awaitApplying"
                  alt="Применить"
                  flat
                  title="Применить"
                  icon="mdi-play"
                  @click="applyCorrection(declarationCorrection.id)"
                />

                <q-btn
                  :to="{
                    name: 'DeclarationCorrectionEdit',
                    params: { id: declarationCorrection.id },
                  }"
                  alt="Редактировать"
                  flat
                  icon="mdi-pencil"
                />

                <q-btn
                  :disabled="awaitDeleting"
                  :loading="awaitDeleting"
                  alt="Удалить"
                  flat
                  title="удалить правило"
                  icon="mdi-delete-outline"
                  @click="removeDeclarationCorrection(declarationCorrection.id)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="
          !declarationCorrections ||
          (Array.isArray(declarationCorrections) &&
            declarationCorrections.length === 0)
        "
        class="q-pa-md"
      >
        Нет данных
      </div>

      <div
        v-if="declarationCorrections && declarationCorrections.length"
        class="col-24 flex justify-end items-center q-px-md q-py-sm bg-white sticky-bottom shadow-up-3"
      >
        <div class="text-body3 q-mr-md">
          Всего: <span class="text-body1">{{ pagination.rowsNumber }}</span>
        </div>

        <q-pagination
          v-model="pagination.page"
          :disable="loading"
          :input="true"
          :max="Math.ceil(pagination.rowsNumber / pagination.rowsPerPage)"
          @update:model-value="onPaginationInput"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import api from "@/api";
  import {
    normalizeQueryForRequest,
    COMPARE_NUMBER_OPTIONS,
    COMPARE_STRING_OPTIONS,
    SEARCH_INPUT_PROPS,
    SEARCH_SELECT_PROPS,
  } from "@/utils/batch";
  import lf from "@/plugins/localforage";
  import ComparisonSelect from "@/components/ComparisonSelect";
  import DeclarationCorrectionInfoDialog from "@/components/dialogs/DeclarationCorrectionInfoDialog";
  import { createMetaMixin } from "quasar";

  export default {
    name: "DeclarationCorrectionList",

    mixins: [
      createMetaMixin(function () {
        return {
          title: "Правила корректировок ПД",
        };
      }),
    ],

    components: {
      ComparisonSelect,
    },

    async mounted() {
      await Promise.all([this.setUsers(), this.setDeclarationCorrections()]);

      await this.checkVisibleColumns();
      this.updateColumnStyles(this.columns);

      this.loading = false;
    },

    data() {
      return {
        loading: true,
        awaitDeclarationCorrections: false,
        awaitDeleting: false,
        awaitApplying: false,
        users: [],
        declarationCorrections: [],
        pagination: {
          rowsNumber: null,
          rowsPerPage: 15,
          sortBy: "created_at",
          descending: true,
          page: 1,
        },
        visibleColumns: [
          "field_readable",
          "search",
          "replace",
          "manual",
          "box_serial",
          "pd_file_cdn_url",
          "created_at",
          "actions",
        ],
        columns: [
          {
            name: "id",
            label: "ID",
            sortName: "id",
            style: "width: 80px;",
          },
          {
            name: "field",
            label: "Field",
            sortName: "field",
            style: "width: 120px;",
          },
          {
            name: "field_readable",
            label: "Поле",
            sortName: "field",
            style: "width: 160px;",
          },
          {
            name: "search",
            label: "Поиск",
            sortName: "search",
            style: "width: 240px;",
          },
          {
            name: "replace",
            label: "Замена",
            sortName: "replace",
            style: "width: 240px;",
          },
          {
            name: "manual",
            label: "Ручной запуск",
            style: "width: 80px;",
          },
          {
            name: "box_id",
            label: "Box ID",
            sortName: "box_id",
            style: "width: 80px;",
          },
          {
            name: "box_serial",
            label: "Номер ДомРФ",
            style: "width: 120px;",
          },
          {
            name: "pd_file_id",
            label: "PD File ID",
            sortName: "pd_file_id",
            style: "width: 80px;",
          },
          {
            name: "pd_file_cdn_url",
            label: "Файл CDN",
            style: "width: 80px;",
          },
          {
            name: "created_by",
            label: "Кем создано",
            style: "width: 120px;",
          },
          {
            name: "created_at",
            label: "Создано",
            sortName: "created_at",
            style: "width: 140px;",
          },
          {
            name: "actions",
            label: "",
            style: "width: 140px;",
          },
        ],
        search: {
          id: { c: "eq", v: null },
          field: null,
          field_readable: null,
          search: { c: "ctn", v: null },
          replace: { c: "ctn", v: null },
          manual: null,
          box_id: null,
          box_serial: { c: "ctn", v: null },
          pd_file_id: null,
          created_by: null,
          created_at: {
            from: null,
            to: null,
          },
        },
        cellStyles: {},
        searchSelectProps: SEARCH_SELECT_PROPS,
        searchInputProps: SEARCH_INPUT_PROPS,
        compareNumberOptions: COMPARE_NUMBER_OPTIONS,
        compareStringOptions: COMPARE_STRING_OPTIONS,
      };
    },

    computed: {
      manualFilterOptions() {
        return [
          {
            label: "Все",
            value: null,
          },
          {
            label: "Да",
            value: true,
          },
          {
            label: "Нет",
            value: false,
          },
        ];
      },

      pdFields() {
        return Object.freeze(
          this.$store.state.pdFields.filter((f) => f.correctable)
        );
      },
    },

    methods: {
      async filterDeclarationCorrections() {
        await this.setDeclarationCorrections(true);
      },

      async setDeclarationCorrections(isFiltering = false) {
        this.awaitDeclarationCorrections = true;

        // we can't send filtering request from page greater then 1
        if (isFiltering) {
          this.pagination.page = 1;
        }

        let paginateOptions = {
          q: normalizeQueryForRequest(this.search),
          sort_by: this.pagination.sortBy,
          descending: this.pagination.descending,
          limit: this.pagination.rowsPerPage,
          page: this.pagination.page,
        };

        let res = await api.declarationCorrection.find(
          paginateOptions,
          "box,pd_file,creator"
        );

        if (res.status === 200 && res.data.declarationcorrections) {
          this.declarationCorrections = res.data.declarationcorrections;
          this.pagination.rowsNumber = res.data.meta.pagination.total;
        }

        if (res.status === 204) {
          this.declarationCorrections = [];
        }

        this.awaitDeclarationCorrections = false;
      },

      async setUsers() {
        const res = await api.user.find();
        this.users = res.data;
      },

      onPaginationInput(page) {
        this.setDeclarationCorrections();

        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      },

      getCellStyle(columnName) {
        return this.cellStyles[columnName];
      },

      updateColumnStyles(columns) {
        let obj = {};

        columns.forEach((item) => {
          obj[item.name] = item.style;
        });

        this.cellStyles = obj;
      },

      onInputVisibleColumns(values) {
        this.updateColumnStyles(this.columns);
        this.saveVisibleColumnsInStorage(values);
      },

      async saveVisibleColumnsInStorage(values) {
        try {
          await lf.setItem(
            "declaration_corrections_table_visible_columns",
            values
          );
        } catch (e) {
          this.$q.notify({
            color: "negative",
            message:
              "Хранилище браузера недоступно. Пожалуйста, проверьте настройки.",
            timeout: 60000,
          });
        }
      },

      async sortByColumn(field) {
        this.pagination.sortBy = field;
        this.pagination.descending = !this.pagination.descending;
        await this.setDeclarationCorrections();
      },

      async onSearchInput(val) {
        await this.filterDeclarationCorrections();
      },

      async onSearchCompareInput(val) {
        if (!val) {
          return;
        }

        await this.filterDeclarationCorrections();
      },

      async checkVisibleColumns() {
        let visibleColumns = await lf.getItem(
          "declaration_corrections_table_visible_columns"
        );

        if (!visibleColumns) {
          return;
        }

        const columnNames = this.columns.map((item) => item.name);

        // remove old column names
        visibleColumns.forEach((name, index) => {
          if (!columnNames.includes(name)) {
            delete visibleColumns[index];
          }
        });

        this.visibleColumns = visibleColumns;
        await this.saveVisibleColumnsInStorage(visibleColumns);
      },

      async removeDeclarationCorrection(id) {
        this.$q
          .dialog({
            title: "Удаление правила корректировки",
            message: "Вы уверены?",
            ok: {
              label: "Удалить",
            },
          })
          .onOk(async () => {
            await api.declarationCorrection.delete(id);
            await this.setDeclarationCorrections();
          });
      },

      applyCorrection(id) {
        this.$q
          .dialog({
            title: `Применение корректировки ID: ${id}`,
            message: "Вы уверены?",
            ok: {
              label: "Применить",
            },
          })
          .onOk(async () => {
            this.awaitApplying = true;

            await api.declarationCorrection
              .apply(id)
              .then(
                (res) => {
                  this.$q.notify({
                    color: "positive",
                    message: res.data.message,
                  });
                },
                (error) => {
                  this.$q.notify({
                    color: "negative",
                    message: error.response.data.message,
                  });
                }
              )
              .then(() => {
                this.awaitApplying = false;
              });
          });
      },

      showDeclarationCorrectionInfoDialog() {
        this.$q.dialog({
          component: DeclarationCorrectionInfoDialog,
        });
      },
    },
  };
</script>
