<template>
  <div class="l-emoji">
    <div
      v-for="(emoji, index) in emojis"
      :key="index"
      class="l-emoji__item"
      @click="$emit('select', emoji)"
      v-html="emoji"
    />
  </div>
</template>

<script>
  export default {
    name: "EmojiPicker",

    created() {
      this.emojis = [
        "😀",
        "😃",
        "😄",
        "😁",
        "😆",
        "😅",
        "🤣",
        "😂",
        "🙂",
        "🙃",
        "😉",
        "😊",
        "😇",
        "🥰",
        "😍",
        "🤩",
        "😘",
        "😗",
        "😚",
        "😙",
        "😋",
        "😛",
        "😜",
        "🤪",
        "😝",
        "🤑",
        "🤗",
        "🤭",
        "🤫",
        "🤔",
        "🤐",
        "🤨",
        "😐",
        "😑",
        "😶",
        "😏",
        "😒",
        "🙄",
        "😬",
        "🤥",
        "😌",
        "😔",
        "😪",
        "🤤",
        "😴",
        "😷",
        "🤒",
        "🤕",
        "🤢",
        "🤮",
        "🤧",
        "🥵",
        "🥶",
        "🥴",
        "😵",
        "🤯",
        "🤠",
        "🥳",
        "😎",
        "🤓",
        "🧐",
        "😕",
        "😟",
        "😮",
        "😯",
        "😲",
        "😳",
        "🥺",
        "😦",
        "😨",
        "😰",
        "😥",
        "😢",
        "😭",
        "😱",
        "😖",
        "😣",
        "😞",
        "😓",
        "😩",
        "😫",
        "😤",
        "😠",
        "🤬",
        "👌",
        "👇",
        "☝",
        "🙏",
        "🏠",
        "🚧",
        "🏗",
        "⛔",
        "🚊",
        "🚎",
        "🚂",
      ];
    },
  };
</script>

<style lang="scss">
  .l-emoji {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
    font-size: 2rem;
    width: 290px;
    height: 300px;
    overflow-x: hidden;
    font-family: "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      sans-serif;

    &__item {
      cursor: pointer;

      &:hover {
        background-color: lightgrey;
      }
    }
  }
</style>