import { axiosInstance as axios } from "@/plugins/axios";
import { deleteKeysWithEmptyValues } from "@/utils/batch";

const apBox = {
  find(params, include = "") {
    deleteKeysWithEmptyValues(params.q);

    return axios.get("/ap-boxes", {
      params: {
        q: params.q,
        sort_by: params.sort_by,
        descending: params.descending,
        page: params.page,
        limit: params.limit,
        transformer: params.transformer ? params.transformer : null,
        include: include,
      },
    });
  },

  unlink(id, boxSerial) {
    return axios.patch(`/ap-boxes/${id}/unlink`, { box_serial: boxSerial });
  },

  link(id, boxSerials) {
    return axios.patch(`/ap-boxes/${id}/link`, { box_serials: boxSerials });
  },

  reCompare(id) {
    return axios.patch(`/ap-boxes/${id}/re-compare`);
  },

  delete(id) {
    return axios.delete(`/ap-boxes/${id}`);
  },
};

export default apBox;
