<template>
  <div v-if="!loading" class="col bg-white rounded-borders shadow-2">
    <div style="height: 2px">
      <q-linear-progress v-show="awaitApProjects" indeterminate size="2px" />
    </div>

    <div class="row q-px-md q-py-sm">
      <div class="col-24 flex justify-between items-center">
        <div class="flex q-gutter-x-md">
          <q-select
            v-model="search.declaration_composites"
            :disable="awaitApProjects"
            :options="declarationCompositeFilterOptions"
            emit-value
            label="Агрегированные ПД"
            map-options
            option-label="label"
            option-value="value"
            style="min-width: 200px"
            @update:model-value="filterApProjects"
          />
        </div>

        <div class="flex items-center justify-end">
          <q-btn
            icon="mdi-cog"
            :label="$q.screen.xs || $q.screen.sm ? '' : 'Параметры'"
          >
            <q-menu touch-position transition-hide="" transition-show="">
              <q-list separator>
                <q-item clickable>
                  <q-item-section>
                    <q-select
                      v-model="visibleColumns"
                      :options="columns"
                      borderless
                      dense
                      display-value="Показывать столбцы"
                      emit-value
                      map-options
                      multiple
                      option-value="name"
                      options-dense
                      @update:model-value="onInputVisibleColumns"
                    >
                      <template v-slot:before>
                        <q-icon class="q-mr-sm" name="mdi-eye-check" />
                      </template>

                      <template v-slot:option="scope">
                        <q-item v-bind="scope.itemProps">
                          <q-item-section>
                            <q-item-label>{{ scope.opt.label }}</q-item-label>
                          </q-item-section>
                          <q-item-section side>
                            <q-icon v-if="scope.selected" name="mdi-check" />
                          </q-item-section>
                        </q-item>
                      </template>
                    </q-select>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
      </div>
    </div>

    <div class="row bg-white">
      <div class="col-24 q-px-md">
        <div
          aria-label="Корпуса АП"
          class="l-table l-table--sticky-first-column q-pb-sm"
          role="table"
          :style="getStylesForApProjectsTable()"
        >
          <div
            class="l-table__row min-w-full sticky-top bg-white"
            role="rowgroup"
            style="z-index: 2"
          >
            <template v-for="item in columns" :key="item.name">
              <div
                v-if="visibleColumns.includes(item.name)"
                :style="getCellStyle(item.name)"
                class="l-table__cell"
                role="columnheader"
              >
                <div
                  :class="item.sortName && 'cursor-pointer'"
                  class="text-body3"
                  @click="item.sortName && sortByColumn(item.sortName)"
                >
                  {{ item.label }}
                  <q-icon
                    v-if="pagination.sortBy === item.sortName"
                    :name="
                      pagination.descending ? 'mdi-arrow-down' : 'mdi-arrow-up'
                    "
                  />
                </div>
              </div>
            </template>
          </div>

          <ApProjectTableFiltersRow
            :cellStyles="cellStyles"
            :search="search"
            :visibleColumns="visibleColumns"
            @filter-projects="filterApProjects"
            @set-projects="setApProjects"
          />

          <div
            v-for="apProject in apProjects"
            :key="apProject.id"
            class="l-table__row min-w-full wrap"
          >
            <div class="l-table__row min-w-full" role="rowgroup">
              <div
                v-if="visibleColumns.includes('id')"
                :style="getCellStyle('id')"
                class="l-table__cell"
              >
                {{ apProject.id }}
              </div>

              <div
                v-if="visibleColumns.includes('serial')"
                :style="getCellStyle('serial')"
                class="l-table__cell"
              >
                {{ apProject.serial }}
              </div>

              <div
                v-if="visibleColumns.includes('region_name')"
                :style="getCellStyle('region_name')"
                class="l-table__cell"
              >
                {{ apProject.region_name }}
              </div>

              <div
                v-if="visibleColumns.includes('name')"
                :style="getCellStyle('name')"
                :title="apProject.name"
                class="l-table__cell"
              >
                {{ apProject.name }}
              </div>

              <div
                v-if="visibleColumns.includes('address')"
                :style="getCellStyle('address')"
                :title="apProject.address"
                class="l-table__cell"
              >
                {{ apProject.address }}
              </div>

              <div
                v-if="visibleColumns.includes('actions')"
                :style="getCellStyle('actions')"
                class="l-table__cell"
              >
                <div class="flex no-wrap">
                  <q-btn
                    :href="getProjectLink(apProject.serial)"
                    alt="Перейти на страницу проекта"
                    flat
                    icon="mdi-open-in-new"
                    target="_blank"
                    type="a"
                  />

                  <q-btn
                    :disable="awaitReComposeDeclarations"
                    flat
                    icon="mdi-database-refresh"
                    title="Запустить пересчет агрегированных данных по пд"
                    @click="reComposeDeclarations(apProject.id)"
                  />

                  <q-btn
                    v-if="+$can(['ap-project.update'])"
                    icon="mdi-pencil"
                    flat
                    title="Редактировать"
                    :to="{
                      name: 'ApProjectEdit',
                      params: { serial: apProject.serial },
                    }"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="
          !apProjects || (Array.isArray(apProjects) && apProjects.length === 0)
        "
        class="q-pa-md"
      >
        Нет данных
      </div>

      <div
        v-if="apProjects && apProjects.length"
        class="col-24 flex justify-end items-center q-px-md q-py-sm bg-white sticky-bottom shadow-up-3"
        style="z-index: 2"
      >
        <div class="text-body3 q-mr-md">
          Всего: <span class="text-body1">{{ pagination.rowsNumber }}</span>
        </div>

        <q-select
          v-model="pagination.rowsPerPage"
          :disable="awaitApProjects"
          :options="[15, 50]"
          dense
          borderless
          @update:model-value="onRowsPerPageInput"
        >
          <template v-slot:before>
            <q-icon name="mdi-eye-outline" size="sm" />
          </template>
        </q-select>

        <q-pagination
          v-model="pagination.page"
          :disable="awaitApProjects"
          :input="true"
          :max="Math.ceil(pagination.rowsNumber / pagination.rowsPerPage)"
          :model-value="pagination.page"
          @update:model-value="onPaginationInput"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import lf from "@/plugins/localforage";
  import api from "@/api";
  import {
    normalizeQueryForRequest,
    getApProjectLink,
    unidecode,
    deleteKeysWithEmptyValues,
    uniencode,
  } from "@/utils/batch";
  import { shortness } from "@/plugins/filters";
  import { createMetaMixin } from "quasar";
  import ApProjectTableFiltersRow from "@/components/apProjects/ApProjectTableFiltersRow";

  export default {
    name: "apProjectList",

    components: {
      ApProjectTableFiltersRow,
    },

    mixins: [
      createMetaMixin(function () {
        return {
          title: "АП проекты",
        };
      }),
    ],

    computed: {
      declarationCompositeFilterOptions() {
        return [
          { label: "Любые", value: null },
          { label: "Да", value: "yes" },
          { label: "Нет", value: "no" },
        ];
      },
    },

    data() {
      return {
        loading: true,
        awaitApProjects: false,
        awaitReComposeDeclarations: false,
        apProjects: [],
        pagination: {
          rowsNumber: null,
          rowsPerPage: 15,
          sortBy: "serial",
          descending: true,
          page: 1,
        },
        visibleColumns: [
          "id",
          "serial",
          "region_name",
          "name",
          "address",
          "actions",
        ],
        columns: [
          {
            name: "id",
            label: "ID",
            sortName: "id",
            style: "width: 80px;",
          },
          {
            name: "serial",
            label: "Номер",
            sortName: "serial",
            style: "width: 80px;",
          },
          {
            name: "region_name",
            label: "Регион",
            sortName: "region_name",
            style: "width: 160px;",
          },
          {
            name: "name",
            label: "Название",
            sortName: "name",
            style: "width: 160px;",
          },
          {
            name: "address",
            label: "Адрес",
            sortName: "address",
            style: "width: 300px;",
          },
          {
            name: "actions",
            label: "Действия",
            style: "width: 100px;",
          },
        ],
        search: {
          id: { c: "eq", v: null },
          serial: { c: "ctn", v: null },
          region_name: { c: "ctn", v: null },
          name: { c: "ctn", v: null },
          address: { c: "ctn", v: null },
          declaration_composites: null,
        },
        cellStyles: {},
      };
    },

    async mounted() {
      if (this.$store.state.apProjectSearch) {
        await this.$router.replace({
          query: {
            search: this.$store.state.apProjectSearch,
          },
        });
      }

      if (this.$route.query.search) {
        const search = unidecode(this.$route.query.search);

        Object.keys(search).forEach((key) => {
          this.search[key] = search[key];
        });
      }

      await this.checkVisibleColumns();
      this.updateColumnStyles(this.columns);
      await this.loadRowsPerPageParamFromStorage();

      this.loading = false;
    },

    methods: {
      async filterApProjects() {
        await this.updateQuery(true);
        await this.setApProjects(true);
      },

      async setApProjects(isFiltering = false) {
        this.awaitApProjects = true;

        // we can't send filtering request from page greater then 1
        if (isFiltering) {
          this.pagination.page = 1;
        }

        let paginateOptions = {
          q: normalizeQueryForRequest(this.search),
          sort_by: this.pagination.sortBy,
          descending: this.pagination.descending,
          limit: this.pagination.rowsPerPage,
          page: this.pagination.page,
        };

        const res = await api.apProject.find(
          paginateOptions,
          "boxes.declarations"
        );

        if (res.status === 200 && res.data.approjects) {
          this.apProjects = res.data.approjects;
          this.pagination.rowsNumber = res.data.meta.pagination.total;
        }

        if (res.status === 204) {
          this.apProjects = [];
        }

        this.awaitApProjects = false;
      },

      sortByColumn(field) {
        this.pagination.sortBy = field;
        this.pagination.descending = !this.pagination.descending;

        this.setApProjects();
      },

      onPaginationInput(page) {
        this.setApProjects();

        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      },

      getCellStyle(columnName) {
        return this.cellStyles[columnName];
      },

      updateColumnStyles(columns) {
        let obj = {};

        columns.forEach((item) => {
          obj[item.name] = item.style;
        });

        this.cellStyles = obj;
      },

      async checkVisibleColumns() {
        let visibleColumns = await lf.getItem(
          "ap_projects_table_visible_columns"
        );

        if (visibleColumns) {
          const columnNames = this.columns.map((item) => item.name);

          // remove old column names
          visibleColumns.forEach((name, index) => {
            if (!columnNames.includes(name)) {
              delete visibleColumns[index];
            }
          });

          this.visibleColumns = visibleColumns;
          await this.saveVisibleColumnsInStorage(visibleColumns);
        }
      },

      async saveVisibleColumnsInStorage(values) {
        try {
          await lf.setItem("ap_projects_table_visible_columns", values);
        } catch (e) {
          this.$q.notify({
            color: "negative",
            message:
              "Хранилище браузера недоступно. Пожалуйста, проверьте настройки.",
            timeout: 60000,
          });
        }
      },

      onInputVisibleColumns(values) {
        this.updateColumnStyles(this.columns);
        this.saveVisibleColumnsInStorage(values);
      },

      async loadRowsPerPageParamFromStorage() {
        const rowsPerPage = await lf.getItem("ap_projects_table_rows_per_page");

        if (rowsPerPage && rowsPerPage !== this.pagination.rowsPerPage) {
          this.pagination.rowsPerPage = rowsPerPage;
        }
      },

      async onRowsPerPageInput(val) {
        try {
          await lf.setItem("ap_projects_table_rows_per_page", val);
        } catch (e) {
          this.$q.notify({
            color: "negative",
            message:
              "Хранилище браузера недоступно. Пожалуйста, проверьте настройки.",
            timeout: 60000,
          });
        }

        await this.setApProjects();
      },

      getProjectLink(serial) {
        return getApProjectLink(serial);
      },

      reComposeDeclarations(apProjectId) {
        this.awaitReComposeDeclarations = true;

        api.apProject
          .reComposeDeclarations(apProjectId)
          .then(
            (res) => {
              this.$q.notify({
                color: "positive",
                message: res.data.message,
              });
            },
            (error) => {
              this.$q.notify({
                color: "negative",
                message: error.response.data.message,
              });
            }
          )
          .finally(() => {
            this.awaitReComposeDeclarations = false;
          });
      },

      shortness(value, length) {
        return shortness(value, length);
      },

      getStylesForApProjectsTable() {
        if (this.$q.screen.xs || this.$q.screen.sm) {
          return "";
        }

        const height =
          this.$store.state.windowInnerHeight - (this.$q.screen.md ? 245 : 210);

        return `max-height: ${height}px`;
      },

      async updateQuery(filtering) {
        let search = normalizeQueryForRequest(this.search);
        deleteKeysWithEmptyValues(search);
        this.$store.commit("SET_AP_PROJECT_SEARCH", uniencode(search));

        await this.$router.replace({
          query: {
            search: this.$store.state.apProjectSearch,
            page: filtering ? 1 : this.pagination.page,
          },
        });
      },
    },
  };
</script>
