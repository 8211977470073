import { axiosInstance as axios } from "@/plugins/axios";
import { deleteKeysWithEmptyValues } from "@/utils/batch";

const apProject = {
  find(params, include = "") {
    deleteKeysWithEmptyValues(params.q);

    return axios.get("/ap-projects", {
      params: {
        q: params.q,
        sort_by: params.sort_by,
        descending: params.descending,
        page: params.page,
        limit: params.limit,
        transformer: params.transformer ? params.transformer : null,
        include: include,
      },
    });
  },

  reComposeDeclarations(apProjectId) {
    return axios.patch(`/ap-projects/${apProjectId}/re-compose-declarations`);
  },
};

export default apProject;
