<template>
  <div v-if="!loading && Object.keys(apProject).length" class="row q-pb-lg">
    <div class="col-24">
      <ApProjectHeader :apProject="apProject" />
    </div>

    <div class="col-24 bg-white q-pl-sm">
      <div
        aria-label="Агрегированные декларации"
        class="l-table l-table--bordered-cells l-table--sticky-first-column q-pb-sm"
        role="table"
        :style="getStylesForApProjectDeclarationCompositesTable()"
      >
        <div
          class="l-table__row min-w-full sticky-top bg-white"
          role="rowgroup"
          style="z-index: 2"
        >
          <template v-for="column in columns" :key="column.name">
            <div
              :style="getCellStyle(column.name)"
              class="l-table__cell flex items-start"
              role="columnheader"
            >
              <div class="flex items-center justify-between text-body3">
                <div>
                  {{ column.label }}
                </div>
                <q-icon
                  v-if="column.tooltip_note"
                  name="mdi-information-outline"
                  class="q-ml-xs"
                  color="primary"
                  size="xs"
                  @click="copyToBuffer(column.tooltip_note)"
                >
                  <q-tooltip max-width="24rem">
                    {{ column.tooltip_note }}
                  </q-tooltip>
                </q-icon>
              </div>
            </div>
          </template>
        </div>

        <div
          v-for="declarationComposite in apProject.declaration_composites"
          :key="declarationComposite.id"
          role="rowgroup"
          style="width: fit-content"
        >
          <q-menu
            auto-close
            context-menu
            touch-position
            transition-hide=""
            transition-show=""
          >
            <q-list separator>
              <q-item
                clickable
                @click="toggleExpandedRow(declarationComposite.id)"
              >
                <q-item-section
                  v-if="!expandedRows.includes(declarationComposite.id)"
                  >Показать декларации
                </q-item-section>
                <q-item-section v-else>Скрыть декларации</q-item-section>
              </q-item>

              <q-item clickable @click="expandedRows = []">
                <q-item-section>Скрыть все</q-item-section>
              </q-item>
            </q-list>
          </q-menu>

          <div class="l-table__row" role="rowgroup">
            <div
              :style="getCellStyle('actions')"
              class="l-table__cell l-table__cell--bordered"
            >
              <q-btn
                :icon="
                  expandedRows.includes(declarationComposite.id)
                    ? 'mdi-chevron-up'
                    : 'mdi-chevron-down'
                "
                flat
                title="Показать/свернуть входящие декларации"
                @click="toggleExpandedRow(declarationComposite.id)"
              />
            </div>

            <div
              :style="getCellStyle('id')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.id }}
            </div>

            <div
              :style="getCellStyle('dated_at')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.dated_at }}
            </div>

            <div
              :style="getCellStyle('box_serial')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.box_serial &&
                declarationComposite.box_serial.join("\n")
              }}
            </div>

            <div
              :style="getCellStyle('box_mark')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.box_mark }}
            </div>

            <div
              :style="getCellStyle('ap_box_serial')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.ap_box_serial &&
                declarationComposite.ap_box_serial.join("\n")
              }}
            </div>

            <div
              :style="getCellStyle('box_name')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                shortness(
                  declarationComposite.box_name &&
                    declarationComposite.box_name.join("\n"),
                  120
                )
              }}
            </div>

            <div
              :style="getCellStyle('pd_number')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.pd_number &&
                declarationComposite.pd_number.join("\n")
              }}
            </div>

            <div
              :style="getCellStyle('published_at')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.published_at &&
                declarationComposite.published_at.join("\n")
              }}
            </div>

            <div
              :style="getCellStyle('company')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.company &&
                declarationComposite.company.join("\n")
              }}
            </div>

            <div
              :style="getCellStyle('builder_inn')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.builder_inn &&
                declarationComposite.builder_inn.join("\n")
              }}
            </div>

            <div
              :style="getCellStyle('reported_at')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.reported_at }}
            </div>

            <div
              :style="getCellStyle('buildings_qty')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.buildings_qty }}
            </div>

            <div
              :style="getCellStyle('floors_min')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.floors_min }}
            </div>

            <div
              :style="getCellStyle('floors_max')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.floors_max }}
            </div>

            <div
              :style="getCellStyle('object_square')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.object_square }}
            </div>

            <div
              :style="getCellStyle('wall_frame_material')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.wall_frame_material &&
                declarationComposite.wall_frame_material.join("\n")
              }}
            </div>

            <div
              :style="getCellStyle('floor_material')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.floor_material &&
                declarationComposite.floor_material.join("\n")
              }}
            </div>

            <div
              :style="getCellStyle('living_square')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.living_square }}
            </div>

            <div
              :style="getCellStyle('nonliving_square')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.nonliving_square }}
            </div>

            <div
              :style="getCellStyle('total_square')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.total_square }}
            </div>

            <div
              :style="getCellStyle('general_contractors')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.general_contractor &&
                declarationComposite.general_contractor.join("\n")
              }}
              ИНН:
              {{
                declarationComposite.general_contractor_inn &&
                declarationComposite.general_contractor_inn.join("\n")
              }}
            </div>

            <div
              :style="getCellStyle('construction_permit_number')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.construction_permit_number &&
                declarationComposite.construction_permit_number.join("\n")
              }}
            </div>

            <div
              :style="getCellStyle('construction_permit_issued_at')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.construction_permit_issued_at &&
                declarationComposite.construction_permit_issued_at.join("\n")
              }}
            </div>

            <div
              :style="getCellStyle('construction_permit_valid_until')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.construction_permit_valid_until &&
                declarationComposite.construction_permit_valid_until.join("\n")
              }}
            </div>

            <div
              :style="
                getCellStyle('construction_permit_valid_until_updated_at')
              "
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.construction_permit_valid_until_updated_at &&
                declarationComposite.construction_permit_valid_until_updated_at.join(
                  "\n"
                )
              }}
            </div>

            <div
              :style="getCellStyle('builder_rights_ground_valid_until')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.builder_rights_ground_valid_until &&
                declarationComposite.builder_rights_ground_valid_until.join(
                  "\n"
                )
              }}
            </div>

            <div
              :style="getCellStyle('builder_rights_contract_updated_at')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.builder_rights_contract_updated_at &&
                declarationComposite.builder_rights_contract_updated_at.join(
                  "\n"
                )
              }}
            </div>

            <div
              :style="getCellStyle('cadastrals')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{ getCadastrals(declarationComposite.cadastrals) }}
            </div>

            <div
              :style="getCellStyle('living_total_qty')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.living_total_qty }}
            </div>

            <div
              :style="getCellStyle('nonliving_total_qty')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.nonliving_total_qty }}
            </div>

            <div
              :style="getCellStyle('nonliving_total_parking_qty')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.nonliving_total_parking_qty }}
            </div>

            <div
              :style="getCellStyle('nonliving_total_other_qty')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.nonliving_total_other_qty }}
            </div>

            <div
              :style="getCellStyle('construction_stages')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                getConstructionStages(declarationComposite.construction_stages)
              }}
            </div>

            <div
              :style="getCellStyle('keys_initial_issue_at')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.keys_initial_issue_at &&
                declarationComposite.keys_initial_issue_at.join("\n")
              }}
            </div>

            <div
              :style="getCellStyle('keys_planned_issue_at')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.keys_planned_issue_at &&
                declarationComposite.keys_planned_issue_at.join("\n")
              }}
            </div>

            <div
              :style="getCellStyle('builder_obligations_securing')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.builder_obligations_securing &&
                declarationComposite.builder_obligations_securing.join("\n")
              }}
            </div>

            <div
              :style="getCellStyle('escrow_bank')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.escrow_bank &&
                declarationComposite.escrow_bank.join("\n")
              }}
            </div>

            <div
              :style="getCellStyle('payment_contributions_fund')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.payment_contributions_fund &&
                declarationComposite.payment_contributions_fund.join("\n")
              }}
            </div>

            <div
              :style="getCellStyle('fund_bank')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.fund_bank &&
                declarationComposite.fund_bank.join("\n")
              }}
            </div>

            <div
              :style="getCellStyle('builder_raising_money')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.builder_raising_money &&
                declarationComposite.builder_raising_money.join("\n")
              }}
            </div>

            <div
              :style="getCellStyle('financing_bank')"
              class="l-table__cell l-table__cell--bordered pre-line"
            >
              {{
                declarationComposite.financing_bank &&
                declarationComposite.financing_bank.join("\n")
              }}
            </div>

            <div
              :style="getCellStyle('living_escrow_qty')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.living_escrow_qty }}
            </div>

            <div
              :style="getCellStyle('living_fund_qty')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.living_fund_qty }}
            </div>

            <div
              :style="getCellStyle('living_other_qty')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.living_other_qty }}
            </div>

            <div
              :style="getCellStyle('nonliving_escrow_qty')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.nonliving_escrow_qty }}
            </div>

            <div
              :style="getCellStyle('nonliving_fund_qty')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.nonliving_fund_qty }}
            </div>

            <div
              :style="getCellStyle('nonliving_other_qty')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.nonliving_other_qty }}
            </div>

            <div
              :style="getCellStyle('parking_escrow_qty')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.parking_escrow_qty }}
            </div>

            <div
              :style="getCellStyle('parking_fund_qty')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.parking_fund_qty }}
            </div>

            <div
              :style="getCellStyle('parking_other_qty')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.parking_other_qty }}
            </div>

            <div
              :style="getCellStyle('living_escrow_square')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.living_escrow_square }}
            </div>

            <div
              :style="getCellStyle('living_fund_square')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.living_fund_square }}
            </div>

            <div
              :style="getCellStyle('living_other_square')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.living_other_square }}
            </div>

            <div
              :style="getCellStyle('nonliving_escrow_square')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.nonliving_escrow_square }}
            </div>

            <div
              :style="getCellStyle('nonliving_fund_square')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.nonliving_fund_square }}
            </div>

            <div
              :style="getCellStyle('nonliving_other_square')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.nonliving_other_square }}
            </div>

            <div
              :style="getCellStyle('parking_escrow_square')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.parking_escrow_square }}
            </div>

            <div
              :style="getCellStyle('parking_fund_square')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.parking_fund_square }}
            </div>

            <div
              :style="getCellStyle('parking_other_square')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.parking_other_square }}
            </div>

            <div
              :style="getCellStyle('living_escrow_price')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ rubF(declarationComposite.living_escrow_price) }}
            </div>

            <div
              :style="getCellStyle('living_fund_price')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ rubF(declarationComposite.living_fund_price) }}
            </div>

            <div
              :style="getCellStyle('living_other_price')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ rubF(declarationComposite.living_other_price) }}
            </div>

            <div
              :style="getCellStyle('nonliving_escrow_price')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ rubF(declarationComposite.nonliving_escrow_price) }}
            </div>

            <div
              :style="getCellStyle('nonliving_fund_price')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ rubF(declarationComposite.nonliving_fund_price) }}
            </div>

            <div
              :style="getCellStyle('nonliving_other_price')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ rubF(declarationComposite.nonliving_other_price) }}
            </div>

            <div
              :style="getCellStyle('parking_escrow_price')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ rubF(declarationComposite.parking_escrow_price) }}
            </div>

            <div
              :style="getCellStyle('parking_fund_price')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ rubF(declarationComposite.parking_fund_price) }}
            </div>

            <div
              :style="getCellStyle('parking_other_price')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ rubF(declarationComposite.parking_other_price) }}
            </div>

            <div
              :style="getCellStyle('lift_qty')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.lift_qty }}
            </div>

            <div
              :style="getCellStyle('lift_cargo_qty')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.lift_cargo_qty }}
            </div>

            <div
              :style="getCellStyle('lift_wheelchair_qty')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.lift_wheelchair_qty }}
            </div>

            <div
              :style="getCellStyle('created_at')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.created_at }}
            </div>

            <div
              :style="getCellStyle('updated_at')"
              class="l-table__cell l-table__cell--bordered"
            >
              {{ declarationComposite.updated_at }}
            </div>
          </div>

          <div
            v-show="expandedRows.includes(declarationComposite.id)"
            class="l-table__row bg-grey-3 inset-shadow min-w-full"
            role="rowgroup"
          >
            <ApProjectDeclarationCompositesExtendedRow
              :declarations="declarationComposite.declarations"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { markRaw } from "vue";
  import ApProjectHeader from "@/components/apProjects/ApProjectHeader";
  import { columns as declarationCompositeColumns } from "@/utils/declarationCompositeForApProject";
  import { copyToClipboard } from "quasar";
  import { customSort, normalizeQueryForRequest } from "@/utils/batch";
  import { shortness } from "@/plugins/filters";
  import { numberFormat, rubFormat } from "@/utils/numberFormatter";
  import api from "@/api";
  import ApProjectDeclarationCompositesExtendedRow from "@/components/apProjects/edit/ApProjectDeclarationCompositesExtendedRow";

  export default {
    name: "ApProjectDeclarationComposites",

    components: { ApProjectDeclarationCompositesExtendedRow, ApProjectHeader },

    props: {
      serial: {
        type: String,
        required: true,
      },
    },

    created() {
      const pdFieldNames = this.pdFields.map((f) => f.name);

      this.columns.forEach((column, index) => {
        if (column.label) {
          return;
        }

        if (pdFieldNames.includes(column.name)) {
          let pdFieldColumn = this.pdFields.filter(
            (f) => f.name === column.name
          )[0];
          this.columns[index].name_db = pdFieldColumn.name;
          this.columns[index].label = pdFieldColumn.name_readable;
          this.columns[index].pd_name = pdFieldColumn.pd_name;
          this.columns[index].pd_article = pdFieldColumn.pd_article;
          this.columns[index].tooltip_note = this.getColumnTooltipText(
            this.columns[index]
          );
        }
      });

      this.updateColumnStyles(this.columns);
    },

    async mounted() {
      await this.setApProject();

      this.loading = false;
    },

    computed: {
      pdFields() {
        return this.$store.state.pdFields;
      },
    },

    data() {
      return {
        loading: true,
        cellStyles: {},
        columns: markRaw(declarationCompositeColumns),
        expandedRows: [],
        apProject: {},
      };
    },

    methods: {
      async setApProject() {
        let params = {
          q: normalizeQueryForRequest({ serial: { c: "eq", v: this.serial } }),
        };

        const res = await api.apProject.find(
          params,
          "declaration_composites.declarations.box.ap_boxes,declaration_composites.declarations.box.box_mark"
        );

        if (res.status === 200 && res.data) {
          this.apProject = res.data[0];

          if (this.apProject.declaration_composites) {
            this.apProject.declaration_composites = customSort(
              this.apProject.declaration_composites,
              "dated_at",
              "asc"
            );
          }
        }

        if (res.status === 204) {
          this.apProject = {};
        }
      },

      getCellStyle(columnName) {
        return this.cellStyles[columnName];
      },

      updateColumnStyles(columns) {
        let obj = {};

        columns.forEach((item) => {
          obj[item.name] = item.style;
        });

        this.cellStyles = obj;
      },

      getColumnTooltipText(column) {
        let text = "";

        if (column.pd_article) {
          if (Array.isArray(column.pd_article)) {
            text += column.pd_article.join(", ") + ` ${column.pd_name}`;
          } else {
            text += `${column.pd_article} ${column.pd_name}`;
          }
        }

        if (column.name_db) {
          text += ` (${column.name_db})`;
        }

        return text.trim();
      },

      copyToBuffer(val) {
        copyToClipboard(val)
          .then(() => {
            this.$q.notify({
              color: "positive",
              message: "Скопировано в буфер!",
            });
          })
          .catch(() => {
            this.$q.notify({
              color: "negative",
              message: "Копирование в буфер не выполнено",
            });
          });
      },

      getStylesForApProjectDeclarationCompositesTable() {
        if (this.$q.screen.xs || this.$q.screen.sm) {
          return "";
        }

        const height =
          this.$store.state.windowInnerHeight - (this.$q.screen.md ? 215 : 180);

        return `max-height: ${height}px`;
      },

      toggleExpandedRow(id) {
        if (this.expandedRows.includes(id)) {
          let index = this.expandedRows.indexOf(id);
          this.expandedRows.splice(index, 1);
        } else {
          this.expandedRows.push(id);
        }
      },

      getCadastrals(cadastrals) {
        if (!cadastrals) {
          return "";
        }

        const numbers = cadastrals.numbers.join("\n");

        return `Номера:\n${numbers}\nПлощадь: ${cadastrals.square} м²`;
      },

      getConstructionStages(stages) {
        if (!stages) {
          return "";
        }

        let text = "";

        stages.forEach((i) => {
          text += `${i.ending_at} ${i.percent}%\n`;
        });

        return text;
      },

      shortness(value, length) {
        return shortness(value, length);
      },

      numF(val) {
        return numberFormat(val);
      },

      rubF(val) {
        return rubFormat(val);
      },
    },
  };
</script>
