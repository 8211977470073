<template>
  <div v-if="!loading" class="row justify-center">
    <div class="col col-md-10 shadow-2 bg-white">
      <div class="q-pa-md">
        <DeclarationCorrectionUpdateForm
          :declaration-correction-initial="declarationCorrection"
          @correction-saved="onCorrectionSaved"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import api from "@/api";
  import { createMetaMixin } from "quasar";
  import DeclarationCorrectionUpdateForm from "@/components/DeclarationCorrectionUpdateForm";

  export default {
    name: "DeclarationCorrectionEdit",

    components: { DeclarationCorrectionUpdateForm },

    props: {
      id: {
        type: Number,
        required: true,
      },
    },

    mixins: [
      createMetaMixin(function () {
        return {
          title: "Изменить правило корректировки",
        };
      }),
    ],

    async mounted() {
      await this.setDeclarationCorrection();
      this.loading = false;
    },

    data() {
      return {
        loading: true,
        declarationCorrection: null,
      };
    },

    methods: {
      async setDeclarationCorrection() {
        let paginateOptions = {
          q: { id: { c: "eq", v: this.id } },
        };

        const res = await api.declarationCorrection.find(
          paginateOptions,
          "box,pd_file"
        );

        if (res.status === 200 && res.data) {
          this.declarationCorrection = res.data[0];
        }
      },

      onCorrectionSaved() {
        this.$router.push({ name: "DeclarationCorrectionList" });
      },
    },
  };
</script>
