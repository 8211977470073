<template>
  <div class="row q-col-gutter-x-md q-pb-lg">
    <div class="col-24">
      <BoxHeader :box="box">
        <template v-slot:buttons>
          <q-btn
            v-if="+$can(['box.update'])"
            :disable="awaitSaving"
            :loading="awaitSaving"
            class="q-ml-md"
            color="positive"
            icon="mdi-content-save"
            label="Сохранить Общее"
            no-caps
            @click="save"
          />
        </template>
      </BoxHeader>
    </div>

    <div class="col-24 col-md-8">
      <div
        class="l-card l-card--blue rounded-borders shadow-2 q-pa-md bg-white"
      >
        <q-input
          v-model.number="box.pd_order"
          :disable="awaitSaving"
          :error="v$.box.pd_order.$error"
          :error-message="
            v$.box.pd_order.$error ? v$.box.pd_order.$errors[0].$message : ''
          "
          bottom-slots
          label="Номер корпуса в ПД"
          type="number"
        />
      </div>
    </div>


    <div class="col-24 col-md-8">
      <div
          class="l-card l-card--red rounded-borders shadow-2 q-pa-md bg-white"
      >
        <div class="q-pa-md">
          <q-list style="max-height: 400px; overflow-y: auto">
            <q-item-label header>Комментарии</q-item-label>

            <div v-if="comments">
              <Comment
                  v-for="item in comments"
                  :key="item.id"
                  :comment="item"
                  :entityId="box.id"
                  :insetLevel="0"
                  entity="box"
              />
            </div>

            <div v-if="!comments" class="text-grey-6 q-ml-md">Нет данных</div>
          </q-list>

          <q-separator/>

          <WriteComment

              :entityId="box.id"
              entity="box"
          />
        </div>
      </div>
    </div>

    <div class="col-24 col-md-8"></div>
    <div class="col-24 col-md-8"></div>
  </div>
</template>

<script>
  import BoxHeader from "@/components/boxes/BoxHeader";
  import api from "@/api";
  import useVuelidate from "@vuelidate/core";
  import { minValue } from "@/utils/i18n-validators";
  import Comment from "@/components/comment/Comment";
  import WriteComment from "@/components/comment/WriteComment";

  import emitter from "@/plugins/mitt";

  export default {
    name: "BoxCommon",

    setup() {
      return { v$: useVuelidate() };
    },

    components: {
      BoxHeader,
      Comment,
      WriteComment
    },

    props: {
      box: {
        type: Object,
        required: true,
      },
    },

    mounted() {

    },
    created() {
      emitter.on("comment-added", this.onCommentAdded);
      emitter.on("comment-deleted", this.onCommentDeleted);
      emitter.on("comment-updated", this.onCommentUpdated);

      this.setComments();
    },
    data() {
      return {
        awaitSaving: false,
        comments: null,
      };
    },

    methods: {
      onCommentDeleted(commentId) {
        this.setComments();
      },

      onCommentAdded(payload) {
        this.setComments();
      },

      onCommentUpdated(payload) {
        this.setComments();
      },
      setComments() {
        return api.box
            .findById(this.box.id, "comments")
            .then((res) => {
              let comments = res.data.comments
                  ? res.data.comments.filter((item) => item.category === null)
                  : null;


                this.comments = comments;

            });
      },


      async save() {
        this.awaitSaving = true;

        const valid = await this.v$.$validate();

        if (!valid) {
          this.awaitSaving = false;

          return;
        }

        let payload = {
          pd_order: this.box.pd_order === "" ? null : this.box.pd_order,
        };

        api.box
          .updateCommon(this.box.id, payload)
          .then(
            (res) => {
              this.$q.notify({
                color: "positive",
                message: res.data.message,
              });
            },
            (error) => {
              this.$q.notify({
                color: "negative",
                message: error.response.data.message,
              });
            }
          )
          .then(() => {
            this.awaitSaving = false;
          });
      },
    },

    validations() {
      return {
        box: {
          pd_order: { minValue: minValue(1) },
        },
      };
    },
  };
</script>
