<template>
  <q-dialog
    ref="dialog"
    position="top"
    transition-hide="slide-up"
    transition-show="slide-down"
  >
    <q-card style="width: 600px; max-width: 600px">
      <div class="flex items-center justify-between q-px-md">
        <div>{{ columnLabel }} (ID: {{ declaration.id }})</div>
        <q-btn v-close-popup flat dense icon="mdi-close" size="lg" />
      </div>

      <q-card-section>
        <q-input
          v-model="value"
          :disable="awaitResponse"
          clearable
          bottom-slots
          :label="columnLabel"
        />
      </q-card-section>

      <q-card-actions>
        <q-btn
          :disable="awaitResponse"
          :loading="awaitResponse"
          color="primary"
          label="Сохранить"
          title="Сохранить"
          class="full-width"
          @click="save"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
  import api from "@/api";
  import emitter from "@/plugins/mitt";
  import cloneDeep from "lodash.clonedeep";

  export default {
    name: "DeclarationEditStringFieldDialog",

    props: {
      declaration: {
        type: Object,
        required: true,
      },
      columnName: {
        type: String,
        required: true,
      },
      columnLabel: {
        type: String,
        required: true,
      },
      // (`null` and `undefined` values will allow any type)
      fieldValue: [null, String],
    },

    created() {
      this.value = cloneDeep(this.fieldValue);
    },

    data() {
      return {
        awaitResponse: false,
        value: null,
      };
    },

    methods: {
      async save() {
        this.awaitResponse = true;

        api.declaration
          .updateField(this.declaration.id, this.columnName, this.value)
          .then(
            (res) => {
              this.$q.notify({
                color: "positive",
                message: res.data.message,
              });

              emitter.emit("declaration-updated");
              this.hide();
            },
            (error) => {
              this.$q.notify({
                color: "negative",
                message: error.response.data.message,
              });

              if (error.response.status === 422) {
                Object.keys(error.response.data.errors).forEach((name, i) => {
                  this.$q.notify({
                    color: "negative",
                    message: error.response.data.errors[name][i],
                    timeout: 10000,
                  });
                });
              }
            }
          )
          .then(() => {
            this.awaitResponse = false;
          });
      },

      // following method is REQUIRED
      // (don't change its name --> "show")
      show() {
        this.$refs.dialog.show();
      },

      // following method is REQUIRED
      // (don't change its name --> "hide")
      hide() {
        this.$refs.dialog.hide();
      },
    },
  };
</script>
