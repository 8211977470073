import { axiosInstance as axios } from "@/plugins/axios";

const utils = {
  queuesWorkload() {
    return axios.get("/utils/queues-workload");
  },
  parseInfo() {
    return axios.get("/utils/parse-info");
  },
};

export default utils;
