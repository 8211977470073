<template>
  <q-select
    :model-value="value"
    :options="options"
    borderless
    class="lq-input-no-border"
    dense
    emit-value
    hide-dropdown-icon
    map-options
    @update:model-value="onInput"
  >
    <template v-slot:selected-item="scope">
      <q-icon :name="scope.opt.icon" />
    </template>

    <template v-slot:option="scope">
      <q-item dense v-bind="scope.itemProps">
        <q-item-section side>
          <q-icon :name="scope.opt.icon" />
        </q-item-section>
        <q-item-section>
          <q-item-label>{{ scope.opt.label }}</q-item-label>
        </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>

<script>
  export default {
    name: "ComparisonSelect",

    emits: ["update:model-value"],

    props: {
      value: {
        required: true,
      },
      options: {
        type: Array,
        required: true,
      },
    },

    methods: {
      onInput(val) {
        this.$emit("update:model-value", val);
      },
    },
  };
</script>
