<template>
  <div class="flex justify-center q-px-md">
    <div class="row q-pa-xl bg-white rounded-borders" style="width: 500px">
      <div class="col-24 flex flex-center">
        <img alt="logo" src="@/static/img/logo.png" />
      </div>
      <div class="col-24 q-mt-md">
        <fieldset :disabled="awaitSignIn" class="no-border q-px-none">
          <q-input
            v-model="email"
            :rules="emailRules"
            ref="email"
            autofocus
            bottom-slots
            label="Email"
          />

          <q-input
            v-model="password"
            :rules="passwordRules"
            :type="isPwd ? 'password' : 'text'"
            ref="password"
            bottom-slots
            label="Пароль"
          >
            <template v-slot:append>
              <q-icon
                :name="isPwd ? 'mdi-eye-off' : 'mdi-eye'"
                class="cursor-pointer"
                @click="isPwd = !isPwd"
              />
            </template>
          </q-input>
        </fieldset>

        <q-btn
          :disable="awaitSignIn"
          :loading="awaitSignIn"
          class="q-mt-lg full-width"
          color="primary"
          label="Войти"
          @click="submitForm"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { createMetaMixin } from "quasar";
  import { actions as authActions } from "@/store/auth/actions";

  export default {
    name: "Login",

    mixins: [
      createMetaMixin(function () {
        return {
          title: "Вход",
        };
      }),
    ],

    data() {
      return {
        email: null,
        password: null,
        isPwd: true,
        awaitSignIn: false,
        emailRules: [
          (val) => !!val || "Заполните это поле",
          (val) => !(val && val.length < 5) || "Минимум 5 символов",
        ],
        passwordRules: [(val) => !!val || "Заполните это поле"],
      };
    },

    methods: {
      async submitForm() {
        this.awaitSignIn = true;

        const fieldsToValidate = ["email", "password"];

        fieldsToValidate.forEach((field) => {
          this.$refs[field].validate();
        });

        let isValid = true;

        fieldsToValidate.forEach((field) => {
          if (this.$refs[field].hasError) {
            isValid = false;
          }
        });

        if (!isValid) {
          this.awaitSignIn = false;

          return;
        }

        let payload = {
          email: this.email,
          password: this.password,
        };

        this.$store
          .dispatch(`auth/${authActions.login}`, payload)
          .then(
            (res) => {
              if (res.status === 200) {
                this.$router.push("/");
              }
            },
            (error) => {
              if (error.response && error.response.status) {
                // show other message
                if (error.response.status !== 500) {
                  this.$q.notify({
                    color: "negative",
                    message: error.response.data.message,
                  });
                }
              }
            }
          )
          .then(() => {
            this.awaitSignIn = false;
          });
      },
    },
  };
</script>
