import { axiosInstance as axios } from "@/plugins/axios";
import { deleteKeysWithEmptyValues } from "@/utils/batch";

const log = {
  find(params) {
    deleteKeysWithEmptyValues(params.q);

    return axios.get("/logs", {
      params: {
        q: params.q,
        sort_by: params.sort_by,
        descending: params.descending,
        page: params.page,
        limit: params.limit,
      },
    });
  },
};

export default log;
