import { createRouter, createWebHistory } from "vue-router";
import { isAccessTokenExpiresSoon } from "@/utils/batch";
import routes from "@/router/routes";
import store from "@/store";
import { actions as authActions } from "@/store/auth/actions";
import lf from "@/plugins/localforage";

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (await isAccessTokenExpiresSoon()) {
    const refreshToken = await lf.getItem("refresh_token");

    if (refreshToken) {
      await store.dispatch(`auth/${authActions.refreshToken}`, refreshToken);
    }
  }

  return next();
});

export default router;
