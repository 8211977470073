<template>
  <div class="l-table__row min-w-full" role="rowgroup">
    <div
      v-if="visibleColumns.includes('id')"
      :style="getCellStyle('id')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.id.v"
        v-bind="searchInputProps"
        type="number"
        class="appearance-none"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.id.c"
            :options="compareNumberOptions"
            :value="search.id.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('serial')"
      :style="getCellStyle('serial')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.serial.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.serial.c"
            :options="compareStringOptions"
            :value="search.serial.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('region_name')"
      :style="getCellStyle('region_name')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.region_name.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.region_name.c"
            :options="compareStringOptions"
            :value="search.region_name.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('name')"
      :style="getCellStyle('name')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.name.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.name.c"
            :options="compareStringOptions"
            :value="search.name.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('address')"
      :style="getCellStyle('address')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.address.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.address.c"
            :options="compareStringOptions"
            :value="search.address.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('coordinates')"
      :style="getCellStyle('coordinates')"
      class="l-table__cell"
    />

    <div
      v-if="visibleColumns.includes('builder')"
      :style="getCellStyle('builder')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.builder.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.builder.c"
            :options="compareStringOptions"
            :value="search.builder.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('developer')"
      :style="getCellStyle('developer')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.developer.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.developer.c"
            :options="compareStringOptions"
            :value="search.developer.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('pd_file_external_url')"
      :style="getCellStyle('pd_file_external_url')"
      class="l-table__cell"
    />

    <div
      v-if="visibleColumns.includes('floor_max')"
      :style="getCellStyle('floor_max')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.floor_max.v"
        v-bind="searchInputProps"
        type="number"
        class="appearance-none"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.floor_max.c"
            :options="compareNumberOptions"
            :value="search.floor_max.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('flat_qty')"
      :style="getCellStyle('flat_qty')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.flat_qty.v"
        v-bind="searchInputProps"
        type="number"
        class="appearance-none"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.flat_qty.c"
            :options="compareNumberOptions"
            :value="search.flat_qty.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('parking_qty')"
      :style="getCellStyle('parking_qty')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.parking_qty.v"
        v-bind="searchInputProps"
        type="number"
        class="appearance-none"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.parking_qty.c"
            :options="compareNumberOptions"
            :value="search.parking_qty.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('date_rve')"
      :style="getCellStyle('date_rve')"
      class="l-table__cell"
    >
      <div class="full-width">
        <div class="flex justify-between items-center">
          <div>
            {{ search.date_rve.from || "от" }}
          </div>
          <div>
            <q-icon
              v-if="search.date_rve.from"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.date_rve.from = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerDateRveFrom">
                <q-date
                  v-model="search.date_rve.from"
                  mask="YYYY-MM-DD"
                  @mouseleave="$refs.datePickerDateRveFrom.hide()"
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>

        <div class="flex justify-between items-center">
          <div>
            {{ search.date_rve.to || "до" }}
          </div>
          <div>
            <q-icon
              v-if="search.date_rve.to"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.date_rve.to = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerDateRveTo">
                <q-date
                  v-model="search.date_rve.to"
                  mask="YYYY-MM-DD"
                  @mouseleave="$refs.datePickerDateRveTo.hide()"
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>
      </div>
    </div>

      <div
      v-if="visibleColumns.includes('date_rve_received')"
      :style="getCellStyle('date_rve_received')"
      class="l-table__cell"
    >
      <div class="full-width">
        <div class="flex justify-between items-center">
          <div>
            {{ search.date_rve_received.from || "от" }}
          </div>
          <div>
            <q-icon
              v-if="search.date_rve_received.from"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.date_rve_received.from = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerDateRveFrom">
                <q-date
                  v-model="search.date_rve_received.from"
                  mask="YYYY-MM-DD"
                  @mouseleave="$refs.datePickerDateRveFrom.hide()"
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>

        <div class="flex justify-between items-center">
          <div>
            {{ search.date_rve_received.to || "до" }}
          </div>
          <div>
            <q-icon
              v-if="search.date_rve_received.to"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.date_rve_received.to = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerDateRveTo">
                <q-date
                  v-model="search.date_rve_received.to"
                  mask="YYYY-MM-DD"
                  @mouseleave="$refs.datePickerDateRveTo.hide()"
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>
      </div>
    </div>

      <div
        v-if="visibleColumns.includes('sold_out_percent')"
        :style="getCellStyle('sold_out_percent')"
        class="l-table__cell"
    >
      <div class="full-width">
        <div class="flex justify-between items-center">
          <div>
            <q-input
              v-model="search.sold_out_percent.from"
              label="от"
              size="sm"
              clearable
              dense
              stack-label
              class="sm-input-box"
            />
        </div>

        <div class="flex justify-between items-center">
          <div>
            <q-input
              v-model="search.sold_out_percent.to"
              label="до"
              size="xss"
              clearable
              dense
              stack-label
              class="sm-input-box"
            />
          </div>
        </div>
        </div>
      </div>
    </div>

    <div
      v-if="visibleColumns.includes('estate_class')"
      :style="getCellStyle('estate_class')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.estate_class.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.estate_class.c"
            :options="compareStringOptions"
            :value="search.estate_class.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('finishing_type')"
      :style="getCellStyle('finishing_type')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.finishing_type.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.finishing_type.c"
            :options="compareStringOptions"
            :value="search.finishing_type.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('living_square')"
      :style="getCellStyle('living_square')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.living_square.v"
        v-bind="searchInputProps"
        type="number"
        class="appearance-none"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.living_square.c"
            :options="compareNumberOptions"
            :value="search.living_square.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('ceiling_height')"
      :style="getCellStyle('ceiling_height')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.ceiling_height"
        v-bind="searchInputProps"
        type="number"
        class="appearance-none"
        @update:model-value="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('wall_type')"
      :style="getCellStyle('wall_type')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.wall_type.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.wall_type.c"
            :options="compareStringOptions"
            :value="search.wall_type.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('parsed_at')"
      :style="getCellStyle('parsed_at')"
      class="l-table__cell"
    >
      <div class="full-width">
        <div class="flex justify-between items-center">
          <div>
            {{ search.parsed_at.from || "от" }}
          </div>
          <div>
            <q-icon
              v-if="search.parsed_at.from"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.parsed_at.from = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerParsedAtFrom">
                <q-date
                  v-model="search.parsed_at.from"
                  mask="YYYY-MM-DD"
                  @mouseleave="$refs.datePickerParsedAtFrom.hide()"
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>

        <div class="flex justify-between items-center">
          <div>
            {{ search.parsed_at.to || "до" }}
          </div>
          <div>
            <q-icon
              v-if="search.parsed_at.to"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.parsed_at.to = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerParsedAtTo">
                <q-date
                  v-model="search.parsed_at.to"
                  mask="YYYY-MM-DD"
                  @mouseleave="$refs.datePickerParsedAtTo.hide()"
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="visibleColumns.includes('will_parsed_at')"
      :style="getCellStyle('will_parsed_at')"
      class="l-table__cell"
    >
      <div class="full-width">
        <div class="flex justify-between items-center">
          <div>
            {{ search.will_parsed_at.from || "от" }}
          </div>
          <div>
            <q-icon
              v-if="search.will_parsed_at.from"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.will_parsed_at.from = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerWillParsedAtFrom">
                <q-date
                  v-model="search.will_parsed_at.from"
                  mask="YYYY-MM-DD"
                  @mouseleave="$refs.datePickerWillParsedAtFrom.hide()"
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>

        <div class="flex justify-between items-center">
          <div>
            {{ search.will_parsed_at.to || "до" }}
          </div>
          <div>
            <q-icon
              v-if="search.will_parsed_at.to"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.will_parsed_at.to = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerWillParsedAtTo">
                <q-date
                  v-model="search.will_parsed_at.to"
                  mask="YYYY-MM-DD"
                  @mouseleave="$refs.datePickerWillParsedAtTo.hide()"
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="visibleColumns.includes('pd_order')"
      :style="getCellStyle('pd_order')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.pd_order.v"
        v-bind="searchInputProps"
        type="number"
        class="appearance-none"
        @update:model-value="(val) => onSearchNumber(val, 199)"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.pd_order.c"
            :options="compareNumberOptions"
            :value="search.pd_order.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
        v-if="visibleColumns.includes('comment')"
        :style="getCellStyle('comment')"
        class="l-table__cell"
    />
    <div
      v-if="visibleColumns.includes('cadastrals')"
      :style="getCellStyle('cadastrals')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.cadastrals"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('pds')"
      :style="getCellStyle('pds')"
      class="l-table__cell"
    />

    <div
      v-if="visibleColumns.includes('ap_project_serial')"
      :style="getCellStyle('ap_project_serial')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.ap_project_serial.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.ap_project_serial.c"
            :options="compareStringOptions"
            :value="search.ap_project_serial.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('created_at')"
      :style="getCellStyle('created_at')"
      class="l-table__cell"
    >
      <div class="full-width">
        <div class="flex justify-between items-center">
          <div>
            {{ search.created_at.from || "от" }}
          </div>
          <div>
            <q-icon
              v-if="search.created_at.from"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.created_at.from = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerCreatedFrom">
                <q-date
                  v-model="search.created_at.from"
                  mask="YYYY-MM-DD"
                  @mouseleave="$refs.datePickerCreatedFrom.hide()"
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>

        <div class="flex justify-between items-center">
          <div>
            {{ search.created_at.to || "до" }}
          </div>
          <div>
            <q-icon
              v-if="search.created_at.to"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.created_at.to = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerCreatedTo">
                <q-date
                  v-model="search.created_at.to"
                  mask="YYYY-MM-DD"
                  @mouseleave="$refs.datePickerCreatedTo.hide()"
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="visibleColumns.includes('actions')"
      :style="getCellStyle('actions')"
      class="l-table__cell"
    />
  </div>
</template>

<script>
  import ComparisonSelect from "@/components/ComparisonSelect";
  import {
    COMPARE_NUMBER_OPTIONS,
    COMPARE_STRING_OPTIONS,
    SEARCH_SELECT_PROPS,
    SEARCH_INPUT_PROPS,
  } from "@/utils/batch";

  export default {
    name: "BoxTableFiltersRow",

    emits: ["filter-boxes", "set-boxes"],

    props: {
      cellStyles: {
        type: Object,
        required: true,
      },
      visibleColumns: {
        type: Array,
        required: true,
      },
      search: {
        type: Object,
        required: true,
      },
    },

    components: {
      ComparisonSelect,
    },

    data() {
      return {
        searchSelectProps: SEARCH_SELECT_PROPS,
        searchInputProps: SEARCH_INPUT_PROPS,
        compareNumberOptions: COMPARE_NUMBER_OPTIONS,
        compareStringOptions: COMPARE_STRING_OPTIONS,
      };
    },

    watch: {
      "search.date_rve.from"() {
        this.$emit("filter-boxes");
      },
      "search.date_rve.to"() {
        this.$emit("filter-boxes");
      },
      "search.date_rve_received.from"() {
        this.$emit("filter-boxes");
      },
      "search.date_rve_received.to"() {
        this.$emit("filter-boxes");
      },
      "search.sold_out_percent.from"() {
        this.$emit("filter-boxes");
      },
      "search.sold_out_percent.to"() {
        this.$emit("filter-boxes");
      },
      "search.parsed_at.from"() {
        this.$emit("filter-boxes");
      },
      "search.parsed_at.to"() {
        this.$emit("filter-boxes");
      },
      "search.will_parsed_at.from"() {
        this.$emit("filter-boxes");
      },
      "search.will_parsed_at.to"() {
        this.$emit("filter-boxes");
      },
      "search.created_at.from"() {
        this.$emit("filter-boxes");
      },
      "search.created_at.to"() {
        this.$emit("filter-boxes");
      },
    },

    async mounted() {
      this.$emit("set-boxes");
    },

    methods: {
      onSearchInput(val) {
        this.$emit("filter-boxes");
      },

      onSearchCompareInput(val) {
        this.$emit("filter-boxes");
      },

      getCellStyle(columnName) {
        return this.cellStyles[columnName];
      },

      onSearchNumber(val, max) {
        if (val < max) {
          this.onSearchInput(val);
        } else {
          this.$q.notify({
            color: "negative",
            message: `Значение не может быть больше ${max}`,
          });
        }
      },
    },
  };
</script>

<style scoped>
:deep(.sm-input-box .q-field__control),
:deep(.sm-input-box .q-field__marginal) {
    height: 20px;
    font-size: 14px!important;
}
:deep(.sm-input-box input) {
    padding-top: 0!important;
    padding-left: 14px;
}
</style>