<template>
  <q-dialog
    ref="dialog"
    position="top"
    transition-hide="slide-up"
    transition-show="slide-down"
    @hide="onDialogHide"
  >
    <q-card style="width: 920px; max-width: 920px">
      <div class="flex items-center justify-between q-px-md">
        <div>История изменений Декларации ID: {{ declarationId }}</div>
        <q-btn v-close-popup flat dense icon="mdi-close" size="lg" />
      </div>

      <q-card-section v-if="loading" class="flex justify-center">
        <q-spinner color="primary" size="3em" />
      </q-card-section>

      <q-card-section v-else>
        <q-list>
          <q-item v-for="item in history" :key="item.id" class="q-pa-none">
            <q-item-section top>
              <q-item-label class="q-mb-sm">
                <span class="q-mr-sm">{{
                  item.creator ? item.creator.name : ""
                }}</span>
                <q-icon
                  v-if="!item.creator"
                  class="q-mr-md"
                  name="mdi-account-question"
                  size="sm"
                />
                <span class="text-grey-9 q-mr-md">{{ item.created_at }}</span>
                <q-btn
                  color="white"
                  dense
                  flat
                  label="Детализация"
                  text-color="primary"
                  @click="showDeclarationHistoryDetailsDialog(item)"
                />
              </q-item-label>

              <q-item-label class="text-body2 q-mb-sm">
                <template v-for="(value, key) in item.formatted_diff">
                  <div
                    v-if="
                      !(value == null && item.formatted_diff_old[key] == null)
                    "
                    :key="key"
                    class="q-mb-sm"
                  >
                    <span
                      class="text-primary cursor-pointer"
                      @click="showDeclarationParameterHistoryDialog(key)"
                    >
                      {{ getReadableKey(key) }}
                    </span>
                    :
                    <span class="bg-deep-orange-2">{{
                      item.formatted_diff_old[key]
                    }}</span>
                    <q-icon name="mdi-arrow-right" />
                    <span class="bg-green-2">{{ value }}</span>
                  </div>
                </template>
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
  import { customSort, normalizeQueryForRequest } from "@/utils/batch";
  import api from "@/api";
  import DeclarationParameterHistoryDialog from "@/components/dialogs/DeclarationParameterHistoryDialog";
  import DeclarationHistoryDetailsDialog from "@/components/dialogs/DeclarationHistoryDetailsDialog";

  export default {
    name: "DeclarationHistoryDialog",

    emits: ["hide"],

    props: {
      declarationId: {
        type: Number,
        required: true,
      },
    },

    async mounted() {
      await this.setDeclarationHistory();
      this.loading = false;
    },

    computed: {
      pdFields() {
        return this.$store.state.pdFields;
      },
    },

    data() {
      return {
        loading: true,
        awaitDeclarationHistory: false,
        history: [],
      };
    },

    methods: {
      async setDeclarationHistory() {
        this.awaitDeclarationHistory = true;

        let paginateOptions = {
          q: normalizeQueryForRequest({
            id: { c: "eq", v: this.declarationId },
          }),
          limit: 1,
        };

        const res = await api.declaration.find(
          paginateOptions,
          "declaration_history.creator"
        );

        if (res.status === 200 && res.data.declarations) {
          this.history = customSort(
            res.data.declarations[0].declaration_history,
            "created_at",
            "asc"
          );
        }

        if (res.status === 204) {
          this.history = [];
        }

        this.awaitDeclarationHistory = false;
      },

      showDeclarationParameterHistoryDialog(key) {
        const paramName = this.getReadableKey(key);
        const paramHistory = this.history
          .filter((obj) => Object.keys(obj.formatted_diff).includes(key))
          .map((item) => {
            return {
              new_value: item.formatted_diff[key],
              old_value: item.formatted_diff_old[key]
                ? item.formatted_diff_old[key]
                : null,
              created_at: item.created_at,
            };
          });

        this.$q.dialog({
          component: DeclarationParameterHistoryDialog,
          componentProps: {
            paramHistory,
            paramName,
          },
        });
      },

      showDeclarationHistoryDetailsDialog(history) {
        this.$q.dialog({
          component: DeclarationHistoryDetailsDialog,
          componentProps: {
            history,
          },
        });
      },

      getReadableKey(key) {
        const mapping = {
          boxSerial: "Номер ДомРФ",
          declarationGradeName: "Состояние",
          creatorName: "Кто создал",
          editorName: "Кто изменил",
        };

        try {
          return this.pdFields.filter((f) => f.name === key)[0].name_readable;
        } catch (err) {
          return mapping[key];
        }
      },

      // following method is REQUIRED
      // (don't change its name --> "show")
      show() {
        this.$refs.dialog.show();
      },

      // following method is REQUIRED
      // (don't change its name --> "hide")
      hide() {
        this.$refs.dialog.hide();
      },

      onDialogHide() {
        this.$emit("hide");
      },
    },
  };
</script>
