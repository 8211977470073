<template>
  <div class="row justify-center">
    <div class="col col-md-10 shadow-2 bg-white">
      <div class="q-pa-md">
        <DeclarationCorrectionUpdateForm
          @correction-saved="onCorrectionSaved"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { createMetaMixin } from "quasar";
  import DeclarationCorrectionUpdateForm from "@/components/DeclarationCorrectionUpdateForm";

  export default {
    name: "DeclarationCorrectionAdd",

    components: {
      DeclarationCorrectionUpdateForm,
    },

    mixins: [
      createMetaMixin(function () {
        return {
          title: "Добавить правило корректировки",
        };
      }),
    ],

    methods: {
      onCorrectionSaved() {
        this.$router.push({ name: "DeclarationCorrectionList" });
      },
    },
  };
</script>
