export default {
  user: (state) => {
    return state.user ? state.user : null;
  },

  permissions: (state) => {
    return state.permissions.length > 0 ? state.permissions : [];
  },

  userId: (state) => {
    return state.user ? state.user.id : null;
  },

  firstRole: (state) => {
    return state.user ? state.user.roles[0] : null;
  },

  firstRoleName: (state, getters) => {
    return state.user ? getters.firstRole.name : null;
  },

  isAuth: (state) => {
    return state.user !== null;
  },
};
