<template>
  <q-dialog
    ref="dialog"
    position="top"
    transition-hide="slide-up"
    transition-show="slide-down"
  >
    <q-card style="width: 1120px; max-width: 1120px">
      <div class="flex items-center justify-between q-px-md">
        <div>
          Примененные Корректировки для Декларации ID: {{ declarationId }}
        </div>
        <q-btn v-close-popup flat dense icon="mdi-close" size="lg" />
      </div>

      <q-card-section
        v-for="(appliedCorrections, field) in fields"
        :key="field"
      >
        <div class="text-h6 q-mb-sm w-full">
          {{ getFieldReadableName(field) }}
        </div>
        <q-table
          :columns="columns"
          :rows="appliedCorrections"
          :pagination="pagination"
          wrap-cells
          binary-state-sort
          hide-pagination
          row-key="id"
        >
          <template v-slot:body="props">
            <q-tr
              :props="props"
              style="transition: background-color 0.2s"
              :style="
                highlightedAppliedCorrectionIds.includes(props.row.id)
                  ? 'background-color: rgb(255, 205, 205)'
                  : ''
              "
            >
              <q-td key="created_at" :props="props"
                >{{ props.row.created_at }}
              </q-td>
              <q-td key="before" :props="props">
                {{ props.row.before }}
              </q-td>
              <q-td key="after" :props="props">{{ props.row.after }}</q-td>
              <q-td key="creator_name" :props="props"
                >{{ (props.row.creator && props.row.creator.name) || "system" }}
              </q-td>
              <q-td key="actions" :props="props">
                <q-btn
                  icon="mdi-delete"
                  flat
                  :color="
                    highlightedAppliedCorrectionIds.includes(props.row.id)
                      ? 'red-8'
                      : 'black'
                  "
                  @mouseenter="
                    onMouseEnterDeleteIcon(props.row.field, props.row.id)
                  "
                  @mouseleave="highlightedAppliedCorrectionIds = []"
                  @click="rollback"
                />
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
  import api from "@/api";
  import emitter from "@/plugins/mitt";

  export default {
    name: "DeclarationAppliedCorrectionsDialog",

    props: {
      declarationId: {
        type: Number,
        required: true,
      },
      fields: {
        type: Object,
        required: true,
      },
    },

    computed: {
      pdFields() {
        return Object.freeze(
          this.$store.state.pdFields.filter((f) => f.correctable)
        );
      },

      columns() {
        return [
          {
            name: "created_at",
            required: true,
            label: "Дата",
            align: "left",
            field: (row) => row.created_at,
            sortable: true,
            style: "width: 210px",
          },
          {
            name: "before",
            label: "До",
            align: "left",
            field: (row) => row.before,
            style: "width: 300px;",
          },
          {
            name: "after",
            label: "После",
            align: "left",
            field: (row) => row.after,
            style: "width: 300px",
          },
          {
            name: "creator_name",
            label: "Кто применил",
            align: "left",
            field: (row) => row.creator && row.creator.name,
            style: "width: 160px",
          },
          {
            name: "actions",
            label: "",
            align: "left",
            style: "width: 80px",
          },
        ];
      },

      pagination() {
        return {
          sortBy: "created_at",
          descending: true,
        };
      },

      fieldHasAppliedCorrectionIds() {
        let test = {};

        Object.keys(this.fields).forEach((field) => {
          test[field] = this.fields[field].map(
            (appliedCorrection) => appliedCorrection.id
          );
        });

        return test;
      },
    },

    data() {
      return {
        awaitRollback: false,
        highlightedAppliedCorrectionIds: [],
      };
    },

    methods: {
      onMouseEnterDeleteIcon(field, hoveredId) {
        this.fieldHasAppliedCorrectionIds[field].forEach((id) => {
          if (hoveredId <= id) {
            this.highlightedAppliedCorrectionIds.push(id);
          }
        });
      },

      rollback() {
        const ids = this.highlightedAppliedCorrectionIds;
        const minId = Math.min(...ids);
        let before = null;

        for (let field of Object.keys(this.fields)) {
          let ac = this.fields[field].find((i) => i.id === minId);

          if (ac) {
            before = ac.before;
            break;
          }
        }

        this.$q
          .dialog({
            title: `Откат ${ids.length} корректировок`,
            message: `Значение после отката: ${before}. Продолжить?`,
          })
          .onOk(async () => {
            this.awaitRollback = true;

            await api.declarationCorrection
              .rollback(ids)
              .then(
                (res) => {
                  emitter.emit("declaration-applied-corrections-rollbacked");
                  this.hide();
                },
                (error) => {
                  this.$q.notify({
                    color: "negative",
                    message: error.response.data.message,
                  });
                }
              )
              .then(() => {
                this.awaitRollback = false;
              });
          });
      },

      getFieldReadableName(field) {
        return this.pdFields.filter((f) => f.name === field)[0].name_readable;
      },

      // following method is REQUIRED
      // (don't change its name --> "show")
      show() {
        this.$refs.dialog.show();
      },

      // following method is REQUIRED
      // (don't change its name --> "hide")
      hide() {
        this.$refs.dialog.hide();
      },
    },
  };
</script>
