<template>
  <div class="col bg-white rounded-borders shadow-2">
    <div style="height: 2px">
      <q-linear-progress v-show="loading" indeterminate size="2px" />
    </div>

    <div class="row bg-white">
      <div class="col-24 q-px-md q-pt-md">
        <div aria-label="Логи" class="l-table" role="table">
          <div class="l-table__row" role="rowgroup">
            <div
              v-for="item in columns"
              :key="item.name"
              :style="getCellStyle(item.name)"
              class="l-table__cell"
              role="columnheader"
            >
              <div
                :class="item.sortName && 'cursor-pointer'"
                class="text-body3"
                @click="item.sortName && sortByColumn(item.sortName)"
              >
                {{ item.label }}
                <q-icon
                  v-if="pagination.sortBy === item.sortName"
                  :name="
                    pagination.descending ? 'mdi-arrow-down' : 'mdi-arrow-up'
                  "
                />
              </div>
            </div>
          </div>

          <LogsTableFiltersRow
            :cellStyles="cellStyles"
            :search="search"
            @filter-logs="filterLogs"
            @set-logs="setLogs"
          />

          <div
            v-for="log in logs"
            :key="log.id"
            class="l-table__row"
            role="rowgroup"
          >
            <div :style="getCellStyle('status')" class="l-table__cell">
              {{ log.status }}
            </div>

            <div :style="getCellStyle('message')" class="l-table__cell">
              {{ log.message }}
            </div>

            <div :style="getCellStyle('created_at')" class="l-table__cell">
              {{ log.created_at }}
            </div>

            <div :style="getCellStyle('tags')" class="l-table__cell pre-line">
              {{ log.tags && log.tags.join("\n") }}
            </div>

            <div :style="getCellStyle('region_name')" class="l-table__cell">
              {{ log.region_name }}
            </div>

            <div :style="getCellStyle('context')">
              <q-scroll-area
                v-if="log.context"
                visible
                style="height: 120px; white-space: pre"
              >
                {{ log.context }}
              </q-scroll-area>
            </div>
          </div>
        </div>

        <div
          v-if="!logs || (Array.isArray(logs) && logs.length === 0)"
          class="q-pa-md"
        >
          Нет данных
        </div>
      </div>

      <div
        v-if="logs && logs.length"
        class="col-24 flex justify-end items-center q-px-md q-py-sm bg-white sticky-bottom shadow-up-3"
      >
        <div class="text-body3 q-mr-md">
          Всего: <span class="text-body1">{{ pagination.rowsNumber }}</span>
        </div>

        <q-pagination
          v-model="pagination.page"
          :disable="loading"
          :input="true"
          :max="Math.ceil(pagination.rowsNumber / pagination.rowsPerPage)"
          @update:model-value="onPaginationInput"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import LogsTableFiltersRow from "@/components/logs/LogsTableFiltersRow";
  import { normalizeQueryForRequest } from "@/utils/batch";
  import api from "@/api";
  import { createMetaMixin } from "quasar";

  export default {
    name: "Logs",

    mixins: [
      createMetaMixin(function () {
        return {
          title: "Логи",
        };
      }),
    ],

    components: {
      LogsTableFiltersRow,
    },

    data() {
      return {
        loading: false,
        logs: [],
        pagination: {
          rowsNumber: null,
          rowsPerPage: 30,
          sortBy: "created_at",
          descending: true,
          page: 1,
        },
        columns: [
          {
            name: "status",
            label: "Статус",
            sortName: "status",
            style: "width: 120px;",
          },
          {
            name: "message",
            label: "Сообщение",
            style: "width: 900px;",
          },
          {
            name: "created_at",
            label: "Создан",
            sortName: "created_at",
            style: "width: 210px;",
          },
          {
            name: "tags",
            label: "Теги",
            sortName: "tags",
            style: "width: 120px;",
          },
          {
            name: "region_name",
            label: "Регион",
            style: "width: 190px;",
          },
          {
            name: "context",
            label: "Контекст",
            style: "width: 320px;",
          },
        ],
        search: {
          status: null,
          tags: null,
          message: { c: "ctn", v: null },
          region_name: null,
          created_at: {
            from: null,
            to: null,
          },
        },
        cellStyles: {},
      };
    },

    async mounted() {
      this.updateColumnStyles(this.columns);
    },

    methods: {
      filterLogs() {
        this.setLogs(true);
      },

      async setLogs(isFiltering = false) {
        this.loading = true;

        // we can't send filtering request from page greater then 1
        if (isFiltering) {
          this.pagination.page = 1;
        }

        let paginateOptions = {
          q: normalizeQueryForRequest(this.search),
          sort_by: this.pagination.sortBy,
          descending: this.pagination.descending,
          limit: this.pagination.rowsPerPage,
          page: this.pagination.page,
        };

        const res = await api.log.find(paginateOptions);

        if (res.data.logs) {
          this.logs = res.data.logs;
          this.pagination.rowsNumber = res.data.meta.pagination.total;
        } else {
          this.logs = [];
        }

        this.loading = false;
      },

      sortByColumn(field) {
        this.pagination.sortBy = field;
        this.pagination.descending = !this.pagination.descending;

        this.setLogs();
      },

      onPaginationInput(page) {
        this.setLogs();

        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      },

      getCellStyle(columnName) {
        return this.cellStyles[columnName];
      },

      updateColumnStyles(columns) {
        let obj = {};

        columns.forEach((item) => {
          obj[item.name] = item.style;
        });

        this.cellStyles = obj;
      },
    },
  };
</script>
