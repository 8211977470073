import { createApp } from "vue";
import Quasar from 'quasar/src/vue-plugin.js';;
import router from "@/router";
import store from "@/store";
import { canAbility } from "@/utils/ability";
import App from "@/App.vue";
import quasarOptions from "@/plugins/quasar";
import BlankLayout from "@/layouts/BlankLayout";
import MainLayout from "@/layouts/MainLayout";
import i18n from "@/plugins/i18n";
import "@/plugins/sentry";
import "@/plugins/localforage";

const app = createApp(App);

app.config.globalProperties.$can = canAbility;

app.use(i18n);
app.use(Quasar, quasarOptions);
app.use(router);
app.use(store);

app.component("blank-layout", BlankLayout);
app.component("main-layout", MainLayout);

app.mount("#app");

export default app;
