<template>
  <div class="l-table__row min-w-full" role="rowgroup">
    <div
      v-if="visibleColumns.includes('id')"
      :style="getCellStyle('id')"
      class="l-table__cell"
    >
      <q-input
        v-model.number="search.id.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.id.c"
            :options="compareNumberOptions"
            :value="search.id.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('serial')"
      :style="getCellStyle('serial')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.serial.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.serial.c"
            :options="compareStringOptions"
            :value="search.serial.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('region_name')"
      :style="getCellStyle('region_name')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.region_name.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.region_name.c"
            :options="compareStringOptions"
            :value="search.region_name.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('name')"
      :style="getCellStyle('name')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.name.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.name.c"
            :options="compareStringOptions"
            :value="search.name.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('project')"
      :style="getCellStyle('project')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.project.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.project.c"
            :options="compareStringOptions"
            :value="search.project.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('address')"
      :style="getCellStyle('address')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.address.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.address.c"
            :options="compareStringOptions"
            :value="search.address.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('coordinates')"
      :style="getCellStyle('coordinates')"
      class="l-table__cell"
    />

    <div
      v-if="visibleColumns.includes('developer')"
      :style="getCellStyle('developer')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.developer.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.developer.c"
            :options="compareStringOptions"
            :value="search.developer.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('builder')"
      :style="getCellStyle('builder')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.builder.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.builder.c"
            :options="compareStringOptions"
            :value="search.builder.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('floor_max')"
      :style="getCellStyle('floor_max')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.floor_max.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.floor_max.c"
            :options="compareNumberOptions"
            :value="search.floor_max.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('flat_qty')"
      :style="getCellStyle('flat_qty')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.flat_qty.v"
        v-bind="searchInputProps"
        type="number"
        class="appearance-none"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.flat_qty.c"
            :options="compareNumberOptions"
            :value="search.flat_qty.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('parking_qty')"
      :style="getCellStyle('parking_qty')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.parking_qty.v"
        v-bind="searchInputProps"
        type="number"
        class="appearance-none"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.parking_qty.c"
            :options="compareNumberOptions"
            :value="search.parking_qty.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('date_rve')"
      :style="getCellStyle('date_rve')"
      class="l-table__cell"
    >
      <div class="full-width">
        <div class="flex justify-between items-center">
          <div>
            {{ search.date_rve.from || "от" }}
          </div>
          <div>
            <q-icon
              v-if="search.date_rve.from"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.date_rve.from = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerDateRveFrom">
                <q-date
                  v-model="search.date_rve.from"
                  mask="YYYY-MM-DD"
                  @mouseleave="$refs.datePickerDateRveFrom.hide()"
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>

        <div class="flex justify-between items-center">
          <div>
            {{ search.date_rve.to || "до" }}
          </div>
          <div>
            <q-icon
              v-if="search.date_rve.to"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.date_rve.to = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerDateRveTo">
                <q-date
                  v-model="search.date_rve.to"
                  mask="YYYY-MM-DD"
                  @mouseleave="$refs.datePickerDateRveTo.hide()"
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="visibleColumns.includes('finishing_type')"
      :style="getCellStyle('finishing_type')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.finishing_type.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.finishing_type.c"
            :options="compareStringOptions"
            :value="search.finishing_type.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('agreement_type')"
      :style="getCellStyle('agreement_type')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.agreement_type.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.agreement_type.c"
            :options="compareStringOptions"
            :value="search.agreement_type.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('living_square')"
      :style="getCellStyle('living_square')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.living_square.v"
        v-bind="searchInputProps"
        type="number"
        class="appearance-none"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.living_square.c"
            :options="compareNumberOptions"
            :value="search.living_square.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('ceiling_height')"
      :style="getCellStyle('ceiling_height')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.ceiling_height"
        v-bind="searchInputProps"
        type="number"
        class="appearance-none"
        @update:model-value="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('cadastrals')"
      :style="getCellStyle('cadastrals')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.cadastrals"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('actions')"
      :style="getCellStyle('actions')"
      class="l-table__cell"
    />
  </div>
</template>

<script>
  import ComparisonSelect from "@/components/ComparisonSelect";
  import {
    COMPARE_NUMBER_OPTIONS,
    COMPARE_STRING_OPTIONS,
    SEARCH_SELECT_PROPS,
    SEARCH_INPUT_PROPS,
  } from "@/utils/batch";

  export default {
    name: "ApBoxTableFiltersRow",

    emits: ["filter-boxes", "set-boxes"],

    props: {
      cellStyles: {
        type: Object,
        required: true,
      },
      visibleColumns: {
        type: Array,
        required: true,
      },
      search: {
        type: Object,
        required: true,
      },
    },

    components: {
      ComparisonSelect,
    },

    data() {
      return {
        searchSelectProps: SEARCH_SELECT_PROPS,
        searchInputProps: SEARCH_INPUT_PROPS,
        compareNumberOptions: COMPARE_NUMBER_OPTIONS,
        compareStringOptions: COMPARE_STRING_OPTIONS,
      };
    },

    watch: {
      "search.date_rve.from"() {
        this.$emit("filter-boxes");
      },
      "search.date_rve.to"() {
        this.$emit("filter-boxes");
      },
      "search.parsed_at.from"() {
        this.$emit("filter-boxes");
      },
      "search.parsed_at.to"() {
        this.$emit("filter-boxes");
      },
      "search.created_at.from"() {
        this.$emit("filter-boxes");
      },
      "search.created_at.to"() {
        this.$emit("filter-boxes");
      },
    },

    async mounted() {
      this.$emit("set-boxes");
    },

    methods: {
      onSearchInput(val) {
        this.$emit("filter-boxes");
      },

      onSearchCompareInput(val) {
        this.$emit("filter-boxes");
      },

      getCellStyle(columnName) {
        return this.cellStyles[columnName];
      },
    },
  };
</script>
