import { canAbility as can } from "@/utils/ability";

export default function (to) {
  if (!to.meta.permissions) {
    throw new Error("route permission cant be undefined");
  }

  if (+can(to.meta.permissions)) {
    return true;
  } else {
    return { name: "NotFound", params: { pathMatch: ["404"] } };
  }
}
