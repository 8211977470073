export function shortness(value, length) {
  if (!value) return "";

  value = value.toString();

  return value.length > length ? value.slice(0, length - 1) + "..." : value;
}

export function lowercase(value) {
  if (!value) return "";

  value = value.toString();

  return value.toLowerCase();
}
