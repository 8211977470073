import { axiosInstance as axios } from "@/plugins/axios";
import { deleteKeysWithEmptyValues } from "@/utils/batch";
import store from "@/store";

const user = {
  findUserById(id, include = "") {
    return include
      ? axios.get(`/users/${id}`, { params: { include } })
      : axios.get(`/users/${id}`);
  },

  find(include = "") {
    return include
      ? axios.get(`/users`, { params: { include } })
      : axios.get(`/users`);
  },

  create(payload, include = "") {
    return include
      ? axios.post("/users", payload, { params: { include } })
      : axios.post("/users", payload);
  },

  update(id, payload, include = "") {
    return include
      ? axios.patch(`/users/${id}`, payload, { params: { include } })
      : axios.patch(`/users/${id}`, payload);
  },

  updateSettings(id, payload, include = "") {
    return include
      ? axios.patch(`/users/${id}/settings`, payload, { params: { include } })
      : axios.patch(`/users/${id}/settings`, payload);
  },

  updatePermissions(id, payload, include = "") {
    return include
      ? axios.patch(`/users/${id}/permissions`, payload, {
          params: { include },
        })
      : axios.patch(`/users/${id}/permissions`, payload);
  },

  delete(id) {
    return axios.delete(`/users/${id}`);
  },

  cdnFiles(params) {
    deleteKeysWithEmptyValues(params.q);

    return axios.get(`/users/${store.getters["auth/userId"]}/cdn-files`, {
      params: {
        q: params.q,
        sort_by: params.sort_by,
        descending: params.descending,
        page: params.page,
        limit: params.limit,
      },
    });
  },

  downloadCdnFile(cdnFileId) {
    return axios.get(
      `/users/${store.getters["auth/userId"]}/cdn-files/${cdnFileId}/download`,
      {
        responseType: "arraybuffer",
      }
    );
  },
};

export default user;
