import { axiosInstance as axios } from "@/plugins/axios";
import { deleteKeysWithEmptyValues } from "@/utils/batch";

const declaration = {
  find(params, include = "") {
    deleteKeysWithEmptyValues(params.q);

    return axios.get("/declarations", {
      params: {
        q: params.q,
        sort_by: params.sort_by,
        descending: params.descending,
        page: params.page,
        limit: params.limit,
        include: include,
      },
    });
  },

  updateField(id, field, value) {
    return axios.patch(`/declarations/${id}/field`, { field, value });
  },

  updateGrade(id, declarationGradeId, include = "") {
    const payload = {
      declaration_grade_id: declarationGradeId,
    };

    return include
      ? axios.patch(`/declarations/${id}/grade`, payload, {
          params: { include },
        })
      : axios.patch(`/declarations/${id}/grade`, payload);
  },

  delete(id) {
    return axios.delete(`/declarations/${id}`);
  },

  fields() {
    return axios.get("/declarations/fields");
  },

  grades() {
    return axios.get("/declarations/grades");
  },

  exportExcel(params) {
    deleteKeysWithEmptyValues(params.q);

    return axios.post(
      "/declarations/export/excel",
      {
        q: params.q,
        sort_by: params.sort_by,
        descending: params.descending,
        page: params.page,
        include: params.include,
        columns: params.columns,
        title: params.title,
        rows_qty: params.rows_qty,
      },
      {
        responseType: "arraybuffer",
      }
    );
  },

  stats() {
    return axios.get("/declarations/stats");
  },

  filters(q) {
    deleteKeysWithEmptyValues(q);

    return axios.get("/declarations/filters", { params: { q } });
  },

  dynamicFilter(field, q) {
    deleteKeysWithEmptyValues(q);

    return axios.get("/declarations/dynamic-filter", { params: { field, q } });
  },

  reParse(id, field_names) {
    return axios.patch(`/declarations/${id}/re-parse`, { field_names });
  },
};

export default declaration;
