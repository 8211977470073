<template>
  <q-dialog
    ref="dialog"
    transition-hide="slide-up"
    transition-show="slide-down"
    @hide="onDialogHide"
  >
    <q-card class="q-pa-md bg-white" style="width: 400px; max-width: 400px">
      <draggable
        v-model="myColumns"
        :component-data="{ name: 'flip-list' }"
        :move="checkMove"
        item-key="name"
        tag="transition-group"
        @end="drag = false"
        @start="drag = true"
        @change="onChange"
      >
        <template #item="{ element }">
          <div
            class="bg-white q-pa-xs full-width rounded-borders"
            style="border: 1px solid #c0c0c0; cursor: move"
          >
            {{ element.label }}
          </div>
        </template>
      </draggable>
    </q-card>
  </q-dialog>
</template>

<script>
  import { customSort } from "@/utils/batch";
  import draggable from "vuedraggable";
  import emitter from "@/plugins/mitt";
  import lf from "@/plugins/localforage";

  export default {
    name: "DeclarationCompositeTableColumnOrderDialog",

    emits: ["hide"],

    props: {
      columns: {
        type: Array,
        required: true,
      },
    },

    components: {
      draggable,
    },

    data() {
      return {
        drag: false,
        myColumns: [],
      };
    },

    created() {
      this.myColumns = customSort(this.columns, "order");
    },

    methods: {
      async onChange(event) {
        this.myColumns = this.myColumns.map((item, index) => {
          item.order = index;

          return item;
        });

        await lf.setItem(
          "declaration_composites_column_order",
          this.myColumns.map((item) => item.name)
        );

        emitter.emit("declaration-composites-change-column-order", {
          columns: this.myColumns,
        });
      },

      checkMove(evt) {
        return (
          evt.draggedContext.element.name !== "actions" &&
          evt.relatedContext.element.name !== "actions"
        );
      },

      // following method is REQUIRED
      // (don't change its name --> "show")
      show() {
        this.$refs.dialog.show();
      },

      // following method is REQUIRED
      // (don't change its name --> "hide")
      hide() {
        this.$refs.dialog.hide();
      },

      onDialogHide() {
        this.$emit("hide");
      },
    },
  };
</script>

<style lang="scss">
  .flip-list-move {
    transition: transform 0.5s;
  }
</style>
