<template>
  <div :class="{ 'q-px-md': $q.screen.xs || $q.screen.sm }" class="row q-py-lg">
    <div class="col-24 col-md-16">
      <ApProjectTitle :apProject="apProject" />
    </div>
    <div class="col-24 col-md-8 flex items-center justify-end">
      <slot name="buttons"></slot>
    </div>
  </div>
</template>

<script>
  import ApProjectTitle from "@/components/apProjects/ApProjectTitle";

  export default {
    name: "ApProjectHeader",

    components: { ApProjectTitle },

    props: {
      apProject: {
        type: Object,
        required: true,
      },
    },
  };
</script>
