<template>
  <q-dialog
    ref="dialog"
    position="top"
    transition-hide="slide-up"
    transition-show="slide-down"
    @hide="onDialogHide"
  >
    <q-card style="width: 600px; max-width: 600px">
      <div class="flex items-center justify-between q-px-md">
        <div>Список ПД ДомРФ корпуса {{ serial }}</div>
        <q-btn v-close-popup flat dense icon="mdi-close" size="lg" />
      </div>

      <q-card-section>
        <q-table
          :columns="columns"
          :rows="pds"
          :rows-per-page-options="[0]"
          dense
          row-key="id"
        >
          <template v-slot:body="props">
            <q-tr :props="props">
              <q-td key="published_at" :props="props">{{
                props.row.published_at
              }}</q-td>
              <q-td key="external_url" :props="props">
                <a :href="props.row.external_url" target="_blank" class="l-link"
                  >ссылка</a
                >
              </q-td>
              <q-td key="pd_number" :props="props"
                >{{ props.row.pd_number }}
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </q-card-section>

      <q-card-actions class="q-pa-md">
        <q-btn v-close-popup label="Закрыть" title="Закрыть окно" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
  export default {
    name: "BoxPdsDialog",

    emits: ["hide"],

    props: {
      serial: {
        type: String,
        required: true,
      },
      pds: {
        type: Array,
        required: true,
      },
    },

    computed: {
      columns() {
        return [
          {
            name: "published_at",
            label: "Дата размещения",
            align: "left",
            field: (row) => row.published_at,
            sortable: true,
          },
          {
            name: "external_url",
            label: "Ссылка",
            align: "left",
            field: (row) => row.external_url,
            sortable: true,
          },
          {
            name: "pd_number",
            label: "Номер ПД",
            align: "left",
            field: (row) => row.pd_number,
            sortable: true,
          },
        ];
      },
    },

    methods: {
      // following method is REQUIRED
      // (don't change its name --> "show")
      show() {
        this.$refs.dialog.show();
      },

      // following method is REQUIRED
      // (don't change its name --> "hide")
      hide() {
        this.$refs.dialog.hide();
      },

      onDialogHide() {
        this.$emit("hide");
      },
    },
  };
</script>
