import { axiosInstance as axios } from "@/plugins/axios";

const role = {
  find(include = "") {
    return include
      ? axios.get("/roles", { params: { include } })
      : axios.get("/roles");
  },

  findById(id, include = "") {
    return include
      ? axios.get(`/roles/${id}`, { params: { include } })
      : axios.get(`/roles/${id}`);
  },

  update(id, payload, include = "") {
    return include
      ? axios.patch(`/roles/${id}`, payload, { params: { include } })
      : axios.patch(`/roles/${id}`);
  },
};

export default role;
