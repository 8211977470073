import auth from "@/router/middlewares/auth";
import checkPermissions from "@/router/middlewares/checkPermissions";

// base components
import Login from "@/pages/Login";
import Compare from "@/pages/Compare";
import Logs from "@/pages/Logs";
import NotFound from "@/pages/NotFound";

// route batches
import boxRoutes from "./nested/boxRoutes";
import apBoxRoutes from "./nested/apBoxRoutes";
import apProjectRoutes from "./nested/apProjectRoutes";
import settingsRoutes from "./nested/settingsRoutes";
import declarationRoutes from "./nested/declarationRoutes";
import accountRoutes from "./accountRoutes";
import Cadastrals from "../pages/cadastrals/Cadastrals.vue";
import CadastralEdit from "../pages/cadastrals/CadastralEdit.vue";

const baseRoutes = [
  {
    path: "/",
    redirect: { name: "Compare" },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: [auth],
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/compare",
    name: "Compare",
    component: Compare,
    beforeEnter: [auth, checkPermissions],
    meta: {
      layout: "main",
      permissions: ["compound.view"],
      crumbs: "/Сравнение",
    },
  },
  {
    path: "/cadastrals",
    name: "Cadastrals",
    component: Cadastrals,
    beforeEnter: [auth, checkPermissions],
    meta: {
      layout: "main",
      permissions: ["log.view"],
      crumbs: "/Кадастры",
    },
  },
  {
    path: "/cadastrals/edit",
    name: "CadastralEdit",
    component: CadastralEdit,
    beforeEnter: [auth, checkPermissions],
    meta: {
      layout: "main",
      permissions: ["log.view"],
      crumbs: "/Кадастры/Редактирование",
    },
  },
  {
    path: "/logs",
    name: "Logs",
    component: Logs,
    beforeEnter: [auth, checkPermissions],
    meta: {
      layout: "main",
      permissions: ["log.view"],
      crumbs: "/Логи",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound,
    meta: {
      layout: "blank",
    },
  },
];

let routes = baseRoutes.concat(
  boxRoutes,
  apBoxRoutes,
  apProjectRoutes,
  settingsRoutes,
  declarationRoutes,
  accountRoutes
);

export default routes;
