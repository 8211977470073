import "@/styles/app.scss";
import iconSet from "quasar/icon-set/mdi-v7";
import lang from "quasar/lang/ru";
import "@quasar/extras/mdi-v5/mdi-v5.css";
import Dialog from 'quasar/src/plugins/Dialog.js';import Meta from 'quasar/src/plugins/Meta.js';import Notify from 'quasar/src/plugins/Notify.js';;

export default {
  config: {
    notify: {
      message: "",
      timeout: 1000,
      actions: [{ icon: "mdi-close", color: "white" }],
      position: "top",
    },
  },
  plugins: {
    Dialog,
    Meta,
    Notify,
  },
  lang: lang,
  iconSet: iconSet,
};
