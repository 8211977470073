/**
 * Children of Settings Routes
 */

import RoleEdit from "@/pages/settings/roles/RoleEdit";
import RoleList from "@/pages/settings/roles/RoleList";

const roleRoutes = [
  {
    path: "",
    name: "RoleList",
    component: RoleList,
    meta: {
      layout: "main",
      permissions: ["role.update"],
      crumbs: "/Настройки/Роли",
    },
  },
  {
    path: ":id",
    redirect: { name: "RegionEdit" },
  },
  {
    path: ":id/edit",
    name: "RoleEdit",
    component: RoleEdit,
    meta: {
      layout: "main",
      permissions: ["role.update"],
      crumbs: "/Настройки/Роли/{:}/Изменить",
    },
    props: (route) => ({ id: Number(route.params.id) }),
  },
];

export default roleRoutes;
