<template>
  <div class="l-table__row" role="rowgroup">
    <div :style="getCellStyle('serial')" class="l-table__cell" />
    <div :style="getCellStyle('region_name')" class="l-table__cell" />
    <div :style="getCellStyle('project')" class="l-table__cell" />
    <div :style="getCellStyle('builder')" class="l-table__cell">
      <q-select
        v-model="search.builder_compared"
        :options="compareOptions"
        dense
        emit-value
        map-options
        options-dense
        @update:model-value="onSearchInput"
      />
    </div>

    <div :style="getCellStyle('developer')" class="l-table__cell">
      <q-select
        v-model="search.developer_compared"
        :options="compareOptions"
        dense
        emit-value
        map-options
        options-dense
        @update:model-value="onSearchInput"
      />
    </div>

    <div :style="getCellStyle('floor_max')" class="l-table__cell">
      <q-select
        v-model="search.floor_max_compared"
        :options="compareOptions"
        dense
        emit-value
        map-options
        options-dense
        @update:model-value="onSearchInput"
      />
    </div>

    <div :style="getCellStyle('flat_qty')" class="l-table__cell">
      <q-select
        v-model="search.flat_qty_compared"
        :options="compareOptions"
        dense
        emit-value
        map-options
        options-dense
        @update:model-value="onSearchInput"
      />
    </div>

    <div :style="getCellStyle('parking_qty')" class="l-table__cell">
      <q-select
        v-model="search.parking_qty_compared"
        :options="compareOptions"
        dense
        emit-value
        map-options
        options-dense
        @update:model-value="onSearchInput"
      />
    </div>

    <div :style="getCellStyle('date_rve')" class="l-table__cell">
      <q-select
        v-model="search.date_rve_compared"
        :options="compareOptions"
        dense
        emit-value
        map-options
        options-dense
        @update:model-value="onSearchInput"
      />
    </div>

    <div :style="getCellStyle('ceiling_height')" class="l-table__cell">
      <q-select
        v-model="search.ceiling_height_compared"
        :options="compareOptions"
        dense
        emit-value
        map-options
        options-dense
        @update:model-value="onSearchInput"
      />
    </div>

    <div :style="getCellStyle('finishing_type')" class="l-table__cell">
      <q-select
        v-model="search.finishing_type_compared"
        :options="compareOptions"
        dense
        emit-value
        map-options
        options-dense
        @update:model-value="onSearchInput"
      />
    </div>

    <div :style="getCellStyle('living_square')" class="l-table__cell">
      <q-select
        v-model="search.living_square_compared"
        :options="compareOptions"
        dense
        emit-value
        map-options
        options-dense
        @update:model-value="onSearchInput"
      />
    </div>

    <div :style="getCellStyle('cadastrals')" class="l-table__cell">
      <q-select
        v-model="search.cadastrals_compared"
        :options="compareOptions"
        dense
        emit-value
        map-options
        options-dense
        @update:model-value="onSearchInput"
      />
    </div>

    <div :style="getCellStyle('reviewed_by')" class="l-table__cell" />
    <div :style="getCellStyle('reviewed_at')" class="l-table__cell" />
    <div :style="getCellStyle('reviewed_comment')" class="l-table__cell" />
    <div :style="getCellStyle('actions')" class="l-table__cell" />
  </div>
</template>

<script>
  export default {
    name: "CompareTableTopFiltersRow",

    emits: ["filter-compounds"],

    props: {
      cellStyles: {
        type: Object,
        required: true,
      },
      search: {
        type: Object,
        required: true,
      },
    },

    computed: {
      compareOptions() {
        return [
          {
            value: null,
            label: "Все",
          },
          {
            value: "diff",
            label: "Есть отличия",
          },
          {
            value: "no_diff",
            label: "Нет отличий",
          },
        ];
      },
    },

    data() {
      return {};
    },

    methods: {
      onSearchInput(val) {
        this.$emit("filter-compounds");
      },

      getCellStyle(columnName) {
        return this.cellStyles[columnName];
      },
    },
  };
</script>
