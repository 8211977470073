<template>
  <q-dialog
    ref="dialog"
    position="top"
    transition-hide="slide-up"
    transition-show="slide-down"
    @hide="onDialogHide"
  >
    <q-card style="width: 960px; max-width: 960px">
      <div class="flex items-center justify-between q-px-md">
        <div>Привязка АП корпусов</div>
        <q-btn v-close-popup flat dense icon="mdi-close" size="lg" />
      </div>

      <q-card-section>
        <q-select
          v-model="boxesSelected"
          :options="boxes"
          bottom-slots
          class="lq-select-chip-multiline"
          filled
          hint="Поиск по номеру. Минимум 2 символа."
          input-debounce="300"
          label="Выбранные корпуса"
          multiple
          option-label="name"
          option-value="id"
          options-dense
          use-input
          @filter="asyncFindBox"
        >
          <template v-slot:option="scope">
            <q-item v-bind="scope.itemProps">
              <q-item-section>
                <q-item-label
                  >{{ scope.opt.name || scope.opt.address }}
                </q-item-label>
                <q-item-label class="text-subtitle2"
                  >{{ scope.opt.serial }}
                </q-item-label>
              </q-item-section>
            </q-item>
          </template>

          <template v-slot:selected-item="scope">
            <q-chip
              :tabindex="scope.tabindex"
              color="primary"
              outline
              removable
              @remove="scope.removeAtIndex(scope.index)"
            >
              {{ scope.opt.name || scope.opt.address }}
            </q-chip>
          </template>

          <template v-slot:no-option>
            <q-item>
              <q-item-section class="text-grey">Нет данных</q-item-section>
            </q-item>
          </template>
        </q-select>
      </q-card-section>

      <q-card-actions class="q-pa-md justify-between">
        <q-btn
          v-close-popup
          :disable="awaitLinking"
          label="Отмена"
          title="Закрыть окно"
        />
        <q-btn
          :disable="awaitLinking"
          :loading="awaitLinking"
          color="primary"
          label="Привязать"
          title="Привязать"
          @click="linkNow"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
  import api from "@/api";
  import emitter from "@/plugins/mitt";

  export default {
    name: "LinkApBoxesDialog",

    emits: ["hide"],

    props: {
      boxId: {
        type: Number,
        required: true,
      },
    },

    data() {
      return {
        awaitLinking: false,
        boxesSelected: [],
        boxes: [],
      };
    },

    methods: {
      linkNow() {
        if (!(this.boxesSelected && this.boxesSelected.length)) {
          this.$q.notify({
            color: "negative",
            message: "Пожалуйста, выберите корпуса.",
          });
        } else {
          this.awaitLinking = true;

          let apBoxSerials = this.boxesSelected.map((box) => box.serial);

          api.box
            .link(this.boxId, apBoxSerials)
            .then((res) => {
              emitter.emit("ap-boxes-linked");

              this.$q.notify({
                color: "positive",
                message: res.data.message,
              });

              this.hide();
            })
            .then(() => {
              this.awaitLinking = false;
            });
        }
      },

      asyncFindBox(val, update, abort) {
        let params = {
          q: { serial: { c: "ctn", v: val } },
          sort_by: "serial",
          descending: false,
          page: 1,
          limit: 10,
        };

        if (val.length > 1) {
          api.apBox.find(params).then((res) => {
            update(() => {
              this.boxes = res.data.apboxes;
            });
          });
        } else {
          abort();
        }
      },

      // following method is REQUIRED
      // (don't change its name --> "show")
      show() {
        this.$refs.dialog.show();
      },

      // following method is REQUIRED
      // (don't change its name --> "hide")
      hide() {
        this.$refs.dialog.hide();
      },

      onDialogHide() {
        this.$emit("hide");
      },
    },
  };
</script>
