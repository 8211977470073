/**
 * Children of Settings Routes
 */

import UserEdit from "@/pages/settings/users/UserEdit";
import UserEditPermissions from "@/pages/settings/users/UserEditPermissions";
import UserList from "@/pages/settings/users/UserList";
import UserDetails from "@/pages/settings/users/UserDetails";
import UserAdd from "@/pages/settings/users/UserAdd";

const userRoutes = [
  {
    path: "",
    name: "UserList",
    component: UserList,
    meta: {
      layout: "main",
      permissions: ["user.update"],
      crumbs: "/Настройки/Пользователи",
    },
  },
  {
    path: "add",
    name: "UserAdd",
    component: UserAdd,
    meta: {
      layout: "main",
      permissions: ["user.update"],
      crumbs: "/Настройки/Пользователи/Добавить",
    },
  },
  {
    path: ":id",
    name: "UserDetails",
    component: UserDetails,
    meta: {
      layout: "main",
      permissions: ["user.update"],
      crumbs: "/Настройки/Пользователи/{:}",
    },
    props: (route) => ({ id: Number(route.params.id) }),
  },
  {
    path: ":id/edit",
    name: "UserEdit",
    component: UserEdit,
    meta: {
      layout: "main",
      permissions: ["user.update"],
      crumbs: "/Настройки/Пользователи/{:}/Изменить",
    },
    props: (route) => ({ id: Number(route.params.id) }),
  },
  {
    path: ":id/edit/permissions",
    name: "UserEditPermissions",
    component: UserEditPermissions,
    meta: {
      layout: "main",
      permissions: ["user.update"],
      crumbs: "/Настройки/Пользователи/{:}/Изменить/Разрешения",
    },
    props: (route) => ({ id: Number(route.params.id) }),
  },
];

export default userRoutes;
