export default {
  "validations": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, заполните это поле"])},
    "requiredUnless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поле не может быть пустым"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Некорректный email"])},
    "numeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поле должно быть числом"])},
    "minValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Минимальное значение ", _interpolate(_named("min"))])},
    "maxValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Максимальное значение ", _interpolate(_named("max"))])},
    "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Минимальная длина ", _interpolate(_named("min"))])},
    "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Максимальная длина ", _interpolate(_named("max"))])}
  }
}