/**
 * Children of Settings Routes
 */

import PermissionAdd from "@/pages/settings/permissions/PermissionAdd";
import PermissionList from "@/pages/settings/permissions/PermissionList";

const permissionRoutes = [
  {
    path: "",
    name: "PermissionList",
    component: PermissionList,
    meta: {
      layout: "main",
      permissions: ["permission.update"],
      crumbs: "/Настройки/Разрешения",
    },
  },
  {
    path: "add",
    name: "PermissionAdd",
    component: PermissionAdd,
    meta: {
      layout: "main",
      permissions: ["permission.add"],
      crumbs: "/Настройки/Разрешения/Добавить",
    },
  },
];

export default permissionRoutes;
