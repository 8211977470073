/**
 * Children of Region Routes
 */

import RegionEdit from "@/pages/settings/regions/RegionEdit";
import RegionList from "@/pages/settings/regions/RegionList";
import RegionAdd from "@/pages/settings/regions/RegionAdd";

const regionRoutes = [
  {
    path: "",
    name: "RegionList",
    component: RegionList,
    meta: {
      layout: "main",
      permissions: ["region.update"],
      crumbs: "/Настройки/Регионы",
    },
  },
  {
    path: "add",
    name: "RegionAdd",
    component: RegionAdd,
    meta: {
      layout: "main",
      permissions: ["region.add"],
      crumbs: "/Настройки/Регионы/Добавить",
    },
  },
  {
    path: ":id",
    redirect: { name: "RegionEdit" },
  },
  {
    path: ":id/edit",
    name: "RegionEdit",
    component: RegionEdit,
    props: (route) => ({ id: Number(route.params.id) }),
    meta: {
      layout: "main",
      permissions: ["region.update"],
      crumbs: "/Настройки/Регионы/{:}/Изменить",
    },
  },
];

export default regionRoutes;
