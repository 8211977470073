import { axiosInstance as axios } from "@/plugins/axios";
import { deleteKeysWithEmptyValues } from "@/utils/batch";

const declarationComposite = {
  find(params, include = "") {
    deleteKeysWithEmptyValues(params.q);

    return axios.get("/declaration-composites", {
      params: {
        q: params.q,
        sort_by: params.sort_by,
        descending: params.descending,
        page: params.page,
        limit: params.limit,
        include: include,
      },
    });
  },

  delete(id) {
    return axios.delete(`/declaration-composites/${id}`);
  },

  exportExcel(params) {
    deleteKeysWithEmptyValues(params.q);

    return axios.post(
      "/declaration-composites/export/excel",
      {
        q: params.q,
        sort_by: params.sort_by,
        descending: params.descending,
        page: params.page,
        include: params.include,
        columns: params.columns,
        title: params.title,
        rows_qty: params.rows_qty,
      },
      {
        responseType: "arraybuffer",
      }
    );
  },

  filters(q) {
    deleteKeysWithEmptyValues(q);

    return axios.get("/declaration-composites/filters", { params: { q } });
  },

  dynamicFilters(q) {
    deleteKeysWithEmptyValues(q);

    return axios.get("/declaration-composites/dynamic-filters", {
      params: { q },
    });
  },
};

export default declarationComposite;
