<template>
  <div v-if="!loading" class="row justify-center">
    <div class="col col-md-12 bg-white rounded-borders shadow-2">
      <div style="height: 2px">
        <q-linear-progress v-show="awaitCdnFiles" indeterminate size="2px" />
      </div>

      <div class="row q-px-md q-py-sm">
        <div class="col-24">
          <div aria-label="CDN файлы" class="l-table q-pb-sm" role="table">
            <div class="l-table__row min-w-full" role="rowgroup">
              <div
                v-for="item in columns"
                :key="item.name"
                :style="getCellStyle(item.name)"
                class="l-table__cell"
                role="columnheader"
              >
                <div
                  :class="item.sortName && 'cursor-pointer'"
                  class="text-body3"
                  @click="item.sortName && sortByColumn(item.sortName)"
                >
                  {{ item.label }}
                  <q-icon
                    v-if="pagination.sortBy === item.sortName"
                    :name="
                      pagination.descending ? 'mdi-arrow-down' : 'mdi-arrow-up'
                    "
                  />
                </div>
              </div>
            </div>

            <div class="l-table__row min-w-full" role="rowgroup">
              <div :style="getCellStyle('title')" class="l-table__cell">
                <q-input
                  v-model.trim="search.title.v"
                  v-bind="searchInputProps"
                  @update:model-value="onSearchInput"
                >
                  <template v-slot:prepend>
                    <ComparisonSelect
                      v-model="search.title.c"
                      :options="compareStringOptions"
                      :value="search.title.c"
                      @update:model-value="onSearchCompareInput"
                    />
                  </template>
                </q-input>
              </div>

              <div :style="getCellStyle('created_at')" class="l-table__cell">
                <div class="full-width">
                  <div class="flex justify-between items-center">
                    <div>
                      {{ search.created_at.from || "от" }}
                    </div>
                    <div>
                      <q-icon
                        v-if="search.created_at.from"
                        class="cursor-pointer"
                        color="grey-7"
                        name="mdi-close-circle"
                        size="sm"
                        @click="search.created_at.from = null"
                      />

                      <q-icon
                        class="cursor-pointer"
                        color="grey-7"
                        name="mdi-calendar"
                        size="sm"
                      >
                        <q-popup-proxy ref="datePickerCreatedFrom">
                          <q-date
                            v-model="search.created_at.from"
                            mask="YYYY-MM-DD"
                            @mouseleave="$refs.datePickerCreatedFrom.hide()"
                          />
                        </q-popup-proxy>
                      </q-icon>
                    </div>
                  </div>

                  <div class="flex justify-between items-center">
                    <div>
                      {{ search.created_at.to || "до" }}
                    </div>
                    <div>
                      <q-icon
                        v-if="search.created_at.to"
                        class="cursor-pointer"
                        color="grey-7"
                        name="mdi-close-circle"
                        size="sm"
                        @click="search.created_at.to = null"
                      />

                      <q-icon
                        class="cursor-pointer"
                        color="grey-7"
                        name="mdi-calendar"
                        size="sm"
                      >
                        <q-popup-proxy ref="datePickerCreatedTo">
                          <q-date
                            v-model="search.created_at.to"
                            mask="YYYY-MM-DD"
                            @mouseleave="$refs.datePickerCreatedTo.hide()"
                          />
                        </q-popup-proxy>
                      </q-icon>
                    </div>
                  </div>
                </div>
              </div>

              <div :style="getCellStyle('actions')" class="l-table__cell" />
            </div>

            <div
              v-for="cdnFile in cdnFiles"
              :key="cdnFile.id"
              class="l-table__row min-w-full wrap"
              role="rowgroup"
            >
              <div class="l-table__row min-w-full" role="rowgroup">
                <div :style="getCellStyle('title')" class="l-table__cell">
                  {{ cdnFile.title || "-" }}
                </div>

                <div :style="getCellStyle('created_at')" class="l-table__cell">
                  {{ cdnFile.created_at }}
                </div>

                <div :style="getCellStyle('actions')" class="l-table__cell">
                  <div class="flex no-wrap">
                    <q-btn
                      :disable="awaitDownloadCdnFile"
                      :loading="awaitDownloadCdnFile"
                      icon="mdi-download"
                      flat
                      title="Скачать файл"
                      @click="download(cdnFile.id)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="!cdnFiles || (Array.isArray(cdnFiles) && cdnFiles.length === 0)"
        >
          Нет данных
        </div>
      </div>

      <div
        v-if="cdnFiles && cdnFiles.length"
        class="col-24 flex justify-end items-center q-px-md q-py-sm bg-white"
      >
        <div class="text-body3 q-mr-md">
          Всего: <span class="text-body1">{{ pagination.rowsNumber }}</span>
        </div>

        <q-pagination
          v-model="pagination.page"
          :disable="loading"
          :input="true"
          :max="Math.ceil(pagination.rowsNumber / pagination.rowsPerPage)"
          @update:model-value="onPaginationInput"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import api from "@/api";
  import { createMetaMixin, exportFile } from "quasar";
  import {
    COMPARE_NUMBER_OPTIONS,
    COMPARE_STRING_OPTIONS,
    normalizeQueryForRequest,
    SEARCH_INPUT_PROPS,
    SEARCH_SELECT_PROPS,
  } from "@/utils/batch";
  import ComparisonSelect from "@/components/ComparisonSelect";

  export default {
    name: "AccountFiles",

    mixins: [
      createMetaMixin(function () {
        return {
          title: "Личные файлы",
        };
      }),
    ],

    components: {
      ComparisonSelect,
    },

    async mounted() {
      await this.setCdnFiles();
      this.updateColumnStyles(this.columns);
      this.loading = false;
    },

    data() {
      return {
        loading: true,
        awaitCdnFiles: false,
        awaitDownloadCdnFile: false,
        cdnFiles: [],
        pagination: {
          rowsNumber: null,
          rowsPerPage: 15,
          sortBy: "created_at",
          descending: true,
          page: 1,
        },
        columns: [
          {
            name: "title",
            label: "Название",
            style: "width: 600px;",
          },
          {
            name: "created_at",
            label: "Создан от",
            sortName: "created_at",
            style: "width: 140px;",
          },
          {
            name: "actions",
            label: "",
            style: "width: 30px;",
          },
        ],
        search: {
          title: { c: "ctn", v: null },
          created_at: {
            from: null,
            to: null,
          },
        },
        cellStyles: {},
        searchSelectProps: SEARCH_SELECT_PROPS,
        searchInputProps: SEARCH_INPUT_PROPS,
        compareNumberOptions: COMPARE_NUMBER_OPTIONS,
        compareStringOptions: COMPARE_STRING_OPTIONS,
      };
    },

    watch: {
      "search.created_at.from"() {
        this.setCdnFiles(true);
      },
      "search.created_at.to"() {
        this.setCdnFiles(true);
      },
    },

    methods: {
      async filterCdnFiles() {
        await this.setCdnFiles(true);
      },

      setCdnFiles(isFiltering = false) {
        // we can't send filtering request from page greater then 1
        if (isFiltering) {
          this.pagination.page = 1;
        }

        let paginateOptions = {
          q: normalizeQueryForRequest(this.search),
          sort_by: this.pagination.sortBy,
          descending: this.pagination.descending,
          limit: this.pagination.rowsPerPage,
          page: this.pagination.page,
        };

        return api.user.cdnFiles(paginateOptions).then((res) => {
          if (res.status === 200 && res.data.cdnfiles) {
            this.cdnFiles = res.data.cdnfiles;
            this.pagination.rowsNumber = res.data.meta.pagination.total;
          }

          if (res.status === 204) {
            this.cdnFiles = [];
          }
        });
      },

      download(cdnFileId) {
        this.awaitDownloadCdnFile = true;

        api.user
          .downloadCdnFile(cdnFileId)
          .then((res) => {
            const fileName = res.headers["content-disposition"].slice(21);

            let blob = new Blob([res.data], {
              type: res.headers["content-type"],
            });

            exportFile(fileName, blob);
          })
          .then(() => {
            this.awaitDownloadCdnFile = false;
          });
      },

      onPaginationInput(page) {
        this.setCdnFiles();

        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      },

      async sortByColumn(field) {
        this.pagination.sortBy = field;
        this.pagination.descending = !this.pagination.descending;
        await this.setCdnFiles();
      },

      async onSearchInput(val) {
        await this.filterCdnFiles();
      },

      async onSearchCompareInput(val) {
        if (!val) {
          return;
        }

        await this.filterCdnFiles();
      },

      getCellStyle(columnName) {
        return this.cellStyles[columnName];
      },

      updateColumnStyles(columns) {
        let obj = {};

        columns.forEach((item) => {
          obj[item.name] = item.style;
        });

        this.cellStyles = obj;
      },
    },
  };
</script>
