import auth from "@/router/middlewares/auth";
import checkPermissions from "@/router/middlewares/checkPermissions";

import Boxes from "@/pages/boxes/Boxes";
import BoxList from "@/pages/boxes/BoxList";
import BoxEdit from "@/pages/boxes/BoxEdit";

const boxRoutes = [
  {
    path: "/boxes",
    component: Boxes,
    beforeEnter: [auth, checkPermissions],
    children: [
      {
        path: "",
        component: BoxList,
        name: "BoxList",
        meta: {
          layout: "main",
          permissions: ["box.view"],
          crumbs: "/ДомРФ корпуса",
        },
      },
      {
        path: ":id",
        name: "BoxEdit",
        component: BoxEdit,
        meta: {
          layout: "main",
          permissions: ["box.update"],
          crumbs: "/ДомРФ корпуса/{:}",
        },
        props: (route) => ({ id: Number(route.params.id) }),
      },
    ],
  },
];

export default boxRoutes;
