export default {
  "validations": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field is required"])},
    "requiredUnless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field cant be empty"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email is incorrect"])},
    "numeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field must be numeric"])},
    "minValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Min value ", _interpolate(_named("min"))])},
    "maxValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Max value ", _interpolate(_named("max"))])},
    "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Min length ", _interpolate(_named("min"))])},
    "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Max length ", _interpolate(_named("max"))])}
  }
}