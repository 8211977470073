<template>
  <div>
    <div class="flex items-center q-gutter-x-md">
      <span class="text-grey-10 text-h6">{{
        box.address || box.name || "нет данных"
      }}</span>
    </div>

    <div class="flex">
      <div class="text-body3">
        <span>Добавлен {{ box.created_at }} </span>
        |
        <span>Обновлен {{ box.updated_at }} </span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "BoxTitle",

    props: {
      box: {
        type: Object,
        required: true,
      },
    },
  };
</script>