<template>
  <div
    v-if="Object.keys(region).length"
    class="col col-md-10 q-pa-md bg-white rounded-borders shadow-2"
  >
    <div class="row flex items-center">
      <div class="col-9 col-md-6 text-grey-8">Название</div>
      <div class="col-15 col-md-18">
        <q-input
          v-model="region.name"
          :disable="awaitResponse"
          :error="v$.region.name.$error"
          :error-message="
            v$.region.name.$error ? v$.region.name.$errors[0].$message : ''
          "
          bottom-slots
          dense
        />
      </div>

      <div class="col-9 col-md-6 text-grey-8">Коды</div>
      <div class="col-15 col-md-18">
        <q-select
          v-model="region.codes"
          :disable="awaitResponse"
          :error="v$.region.codes.$error"
          :error-message="
            v$.region.codes.$error ? v$.region.codes.$errors[0].$message : ''
          "
          label="Коды"
          multiple
          new-value-mode="add-unique"
          use-chips
          use-input
        />
      </div>
    </div>

    <div class="row">
      <q-btn
        :disable="awaitSaving"
        :loading="awaitSaving"
        color="primary"
        label="Сохранить"
        @click="save"
      />
      <q-space />
      <q-btn
        v-if="+$can(['region.delete'])"
        :disable="awaitDeleting"
        :loading="awaitDeleting"
        color="negative"
        icon="mdi-delete-outline"
        outline
        round
        @click="remove"
      />
    </div>
  </div>
</template>

<script>
  import api from "@/api";
  import useVuelidate from "@vuelidate/core";
  import { maxLength, minLength, required } from "@/utils/i18n-validators";
  import { createMetaMixin } from "quasar";

  export default {
    name: "RegionEdit",

    setup() {
      return { v$: useVuelidate() };
    },

    mixins: [
      createMetaMixin(function () {
        return {
          title: this.title,
        };
      }),
    ],

    props: {
      id: {
        type: Number,
        required: true,
      },
    },

    data() {
      return {
        awaitResponse: false,
        awaitSaving: false,
        awaitDeleting: false,
        title: "",
        region: {},
      };
    },

    async created() {
      this.awaitResponse = true;

      const res = await api.region.find();

      if (res.status === 200) {
        this.region = res.data.filter((i) => i.id === this.id)[0];
        this.title = "Редактирование " + res.data.name;
      }

      this.awaitResponse = false;
    },

    methods: {
      async save() {
        this.awaitSaving = true;

        const valid = await this.v$.$validate();

        if (!valid) {
          this.awaitSaving = false;

          return;
        }

        let payload = {
          name: this.region.name,
          codes: this.region.codes,
        };

        api.region
          .update(this.region.id, payload)
          .then(
            (res) => {
              if (res.status === 200) {
                this.$q.notify({
                  color: "positive",
                  message: res.data.message,
                });

                this.region = res.data.region;
              }
            },
            (error) => {
              this.$q.notify({
                color: "negative",
                message: error.response.data.message,
              });
            }
          )
          .then(() => {
            this.awaitSaving = false;
          });
      },

      remove() {
        this.$q
          .dialog({
            title: `Удаление региона ${this.region.name}`,
            message: "Вы уверены?",
            ok: {
              label: "Удалить",
            },
          })
          .onOk(async () => {
            this.awaitDeleting = true;

            await api.region
              .delete(this.region.id)
              .then(
                (res) => {
                  if (res.status === 200) {
                    this.$q.notify({
                      color: "positive",
                      message: res.data.message,
                    });

                    this.$router.push({ name: "RegionList" });
                  }
                },
                (error) => {
                  this.$q.notify({
                    color: "negative",
                    message: error.response.data.message,
                  });
                }
              )
              .then(() => {
                this.awaitDeleting = false;
              });
          });
      },
    },

    validations() {
      return {
        region: {
          name: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(191),
          },
          codes: { required, minLength: minLength(1) },
        },
      };
    },
  };
</script>
