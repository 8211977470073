<template>
  <div class="row">
    <router-view />
  </div>
</template>

<script>
  export default {
    name: "ApProjects",
  };
</script>
