<template>
  <div>
    <q-item :inset-level="insetLevel" class="q-mb-sm" dense>
      <q-item-section top>
        <q-item-label class="q-gutter-x-sm">
          <span class="q-px-none text-bold text-body2">
            {{ (comment.commented && comment.commented.name) || '?' | shortness(20) }}
          </span>
          <span class="text-body3 text-grey-9">{{ comment.updated_at }}</span>
          <span
            v-if="comment.created_at !== comment.updated_at"
            class="text-body3 text-grey-9"
            >изм.</span
          >

        </q-item-label>
        <q-item-label class="text-body2">
          {{comment.comment}}
          <EditComment
              v-if="isCommentEditing"
              :commentId="comment.id"
              :commentText="comment.comment"
          />
        </q-item-label>
      </q-item-section>
      <q-item-section side top>
        <q-btn
            v-if="canViewCommentMenu"
            color="white"
            dense
            flat
            icon="mdi-dots-horizontal"
            round
            text-color="grey-10"
        >
          <q-menu anchor="top middle" self="top middle">
            <q-list>
              <q-item
                  v-if="+$can(['comment.update'])"
                  v-close-popup
                  clickable
                  @click="
                  $store.commit('SET_COMMENT_EDITING', {
                    state: true,
                    id: comment.id,
                  })
                "
              >
                <q-item-section>
                  <q-item-label>Редактировать</q-item-label>
                </q-item-section>
              </q-item>

              <q-item
                  v-if="+$can(['comment.delete'])"
                  v-close-popup
                  clickable
                  @click="deleteComment"
              >
                <q-item-section>
                  <q-item-label>Удалить</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
      </q-item-section>
    </q-item>

    <Comment
        v-for="item in comment.comments"
        :key="item.id"
        :comment="item"
        :entityId="item.id"
        :insetLevel="insetLevel + 1"
        entity="comment"
    />
  </div>
</template>

<script>
import api from "@/api";
import EditComment from "@/components/comment/EditComment";
import store from "@/store";
import emitter from "@/plugins/mitt";

export default {
  name: "Comment",

  props: {
    comment: Object,
    entity: String,
    entityId: Number,
    insetLevel: {
      type: Number,
      required: true,
    },
  },

  components: {
    EditComment,
  },

  computed: {
    isCommentEditing() {
      return (
          store.state.commentEditing &&
          this.comment.id === store.state.commentEditing.id
      );
    },

    canViewCommentMenu() {
      return true
    },
  },

  methods: {
    deleteComment() {
      api.comment
          .delete(this.entity, this.entityId, this.comment.id)
          .then((res) => {
            if (res.status === 200) {
              emitter.emit("comment-deleted", this.comment.id);
            }
          });
    },
  },
};
</script>