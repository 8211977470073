<template>
  <q-icon
    name="mdi-alert-outline"
    color="red-9"
    size="xs"
    class="absolute-top-right"
  >
    <q-tooltip max-width="24rem" :delay="350">
      {{ reason }}
    </q-tooltip>
  </q-icon>
</template>

<script>
  export default {
    name: "DeclarationCellAlertTooltip",

    props: ["reason"],
  };
</script>
