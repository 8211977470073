<template>
  <div class="col col-md-18">
    <div class="bg-white">
      <q-table
        :columns="columns"
        :filter="filter"
        :loading="loading"
        :pagination.sync="pagination"
        :rows="regions"
        dense
        hide-pagination
        row-key="id"
      >
        <template v-slot:top-left>
          <q-btn
            v-if="+$can(['region.add'])"
            icon="mdi-plus"
            label="Добавить"
            @click="$router.push({ name: 'RegionAdd' })"
          />
        </template>

        <template v-slot:top-right>
          <q-input
            v-model="filter"
            color="primary"
            debounce="300"
            dense
            outlined
          >
            <template v-slot:append>
              <q-icon name="mdi-magnify" />
            </template>
          </q-input>
        </template>

        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td key="id" :props="props">{{ props.row.id }}</q-td>
            <q-td key="name" :props="props">
              {{ props.row.name }}
            </q-td>
            <q-td key="codes" :props="props"
              >{{ props.row.codes.join(", ") }}
            </q-td>
            <q-td key="total_users" :props="props"
              >{{
                props.row.users && props.row.users.length
                  ? props.row.users.length
                  : 0
              }}
            </q-td>
            <q-td key="actions" :props="props">
              <q-btn
                :to="{ name: 'RegionEdit', params: { id: props.row.id } }"
                :v-if="+$can(['region.update'])"
                dense
                flat
                icon="mdi-pencil"
              />
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>
  </div>
</template>

<script>
  import api from "@/api";
  import { createMetaMixin } from "quasar";

  export default {
    name: "RegionList",

    mixins: [
      createMetaMixin(function () {
        return {
          title: "Регионы",
        };
      }),
    ],

    async mounted() {
      const res = await api.region.find("users");
      this.regions = res.data;
      this.loading = false;
    },

    computed: {
      columns() {
        return [
          {
            name: "id",
            required: true,
            label: "ID",
            align: "left",
            field: (row) => row.id,
            sortable: true,
          },
          {
            name: "name",
            required: true,
            label: "Название",
            align: "left",
            field: (row) => row.name,
            sortable: true,
          },
          {
            name: "codes",
            required: true,
            label: "Коды",
            align: "left",
            field: (row) => row.codes,
            sortable: true,
          },
          {
            name: "total_users",
            required: true,
            label: "Пользователей",
            align: "left",
            field: (row) => row.users,
            sortable: true,
          },
          {
            name: "actions",
            label: "",
            align: "right",
          },
        ];
      },
    },

    data() {
      return {
        loading: true,
        filter: "",
        pagination: {
          rowsPerPage: 0,
        },
        regions: [],
      };
    },
  };
</script>
