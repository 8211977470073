<template>
  <q-dialog
    ref="dialog"
    position="top"
    transition-hide="slide-up"
    transition-show="slide-down"
    @hide="onDialogHide"
  >
    <q-card style="width: 600px; max-width: 600px">
      <div class="flex items-center justify-between q-px-md">
        <div>Перепарсить ПД {{ id }}</div>
        <q-btn v-close-popup flat dense icon="mdi-close" size="lg" />
      </div>

      <q-card-section>
        <q-select
          v-model="field_names"
          :options="$store.state.pdFields"
          options-dense
          use-chips
          multiple
          dense
          map-options
          emit-value
          option-label="name_readable"
          option-value="name"
          label="Только эти поля ПД"
          class="full-width"
        />
      </q-card-section>

      <q-card-actions class="q-pa-md">
        <q-btn
          :disable="awaitResponse"
          :loading="awaitResponse"
          color="primary"
          label="Запустить"
          title="Запустить"
          class="full-width"
          @click="submit"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
  import api from "@/api";

  export default {
    name: "DeclarationReParseDialog",

    emits: ["hide"],

    props: {
      id: {
        type: Number,
        required: true,
      },
    },

    data() {
      return {
        awaitResponse: false,
        field_names: [],
      };
    },

    methods: {
      async submit() {
        this.awaitResponse = true;

        api.declaration
          .reParse(this.id, this.field_names)
          .then(
            (res) => {
              this.$q.notify({
                color: "positive",
                message: res.data.message,
              });
            },
            (error) => {
              this.$q.notify({
                color: "negative",
                message: error.response.data.message,
              });
            }
          )
          .then(() => {
            this.awaitResponse = false;
            this.hide();
          });
      },

      // following method is REQUIRED
      // (don't change its name --> "show")
      show() {
        this.$refs.dialog.show();
      },

      // following method is REQUIRED
      // (don't change its name --> "hide")
      hide() {
        this.$refs.dialog.hide();
      },

      onDialogHide() {
        this.$emit("hide");
      },
    },
  };
</script>
