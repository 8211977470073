<template>
  <div class="col">
    <div class="row">
      <div class="col-24 col-md-5 col-lg-3 bg-white">
        <q-list class="l-menu q-py-md rounded-borders text-primary">
          <q-item
              v-for="item in menuItems"
              :key="item.component"
              :active="component === item.component"
              :disable="loading"
              active-class="l-menu__item--active"
              clickable
              @click="component = item.component"
          >
            <q-item-section>{{ item.label }}</q-item-section>
          </q-item>
        </q-list>
      </div>

      <div class="col-24 col-md-19 col-lg-21">
        <div class="row justify-center">
          <div
              v-if="Object.keys(box).length && !loading"
              :class="!($q.screen.xs || $q.screen.sm) ? 'q-px-xl' : ''"
              class="col-24"
          >
            <component :is="component" :box="box"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BoxCommon from "@/components/boxes/edit/BoxCommon";
import BoxLogs from "@/components/boxes/edit/BoxLogs";
import {createMetaMixin, dom} from "quasar";
import api from "@/api";

const {height} = dom;

export default {
  name: "BoxEdit",

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  components: {
    BoxCommon,
    BoxLogs,
  },

  mixins: [
    createMetaMixin(function () {
      return {
        title: this.title,
      };
    }),
  ],

  data() {
    return {
      loading: true,
      title: "Редактирование корпуса",
      component: "BoxCommon",
      box: {}
    };
  },

  computed: {
    menuItems() {
      return [
        {
          component: "BoxCommon",
          label: "Общее",
        },
        {
          component: "BoxLogs",
          label: "Логи",
        },
      ];
    },
  },

  async mounted() {
    await this.setBox();
    this.loading = false;
  },

  methods: {

    setBox() {
      let params = {
        q: {id: {c: "eq", v: this.id}},
      };

      return api.box.find(params, "logs").then((res) => {
        if (res.status === 200) {
          this.box = res.data[0];
          this.title = res.data[0].address || "Редактирование корпуса";
        }

        if (res.status === 204) {
          this.$router.push({name: "BoxList"});
        }
      });
    },

    getQPageHeight() {
      return height(document.getElementsByClassName("q-page")[0]);
    },

    getStylesForSubmenu() {
      let styles = "";

      if (this.$q.screen.md || this.$q.screen.lg || this.$q.screen.xl) {
        styles += "min-height:" + this.getQPageHeight() + "px";
      }

      return styles;
    },
  },
};
</script>
