<template>
  <div v-if="Object.keys(user).length" class="col col-16">
    <q-table
      v-model:selected="selected"
      :columns="columns"
      :filter="filter"
      :loading="awaitResponse"
      :pagination.sync="pagination"
      :rows="permissions"
      dense
      row-key="id"
      selection="multiple"
      @update:selected="handleSelectionEvent"
    >
      <template v-slot:top>
        <div v-show="!awaitResponse" class="row items-center q-gutter-md">
          <div>{{ user.name }}</div>
          <q-btn
            :disable="!+$can(['user.update']) || awaitSaving"
            :loading="awaitSaving"
            color="positive"
            label="Сохранить"
            @click="save"
          />
        </div>

        <q-space />

        <q-input v-model="filter" color="primary" debounce="300" dense outlined>
          <template v-slot:append>
            <q-icon name="mdi-magnify" />
          </template>
        </q-input>
      </template>
    </q-table>
  </div>
</template>

<script>
  import api from "@/api";
  import { createMetaMixin } from "quasar";

  export default {
    name: "UserEditPermissions",

    props: {
      id: {
        type: Number,
        required: true,
      },
    },

    mixins: [
      createMetaMixin(function () {
        return {
          title: this.title,
        };
      }),
    ],

    async created() {
      this.awaitResponse = true;

      let [resUser, resAuthUser] = await Promise.all([
        api.user.findUserById(this.id, "permissions,roles.permissions"),
        api.user.findUserById(
          this.$store.getters["auth/userId"],
          "permissions"
        ),
      ]);

      this.title = "Изменить разрешения " + resUser.data.name;
      this.user = resUser.data;
      this.selected = this.user.permissions;
      // disable permissions to select inherited from roles
      this.rolePermissions = resUser.data.roles[0].permissions;

      const merged = [...resAuthUser.data.permissions, ...this.selected];
      this.permissions = [
        ...merged
          .reduce((map, obj) => map.set(obj.id, obj), new Map())
          .values(),
      ];

      this.awaitResponse = false;
    },

    data() {
      return {
        awaitResponse: false,
        awaitSaving: false,
        title: "",
        user: {},
        permissions: [],
        rolePermissions: [],
        filter: "",
        selected: [],
        pagination: {
          rowsPerPage: 20,
        },
        columns: [
          {
            name: "readable_name",
            required: true,
            label: "Описание",
            align: "left",
            field: (row) => row.readable_name,
            sortable: true,
          },
          {
            name: "name",
            required: true,
            label: "Название",
            align: "left",
            field: (row) => row.name,
            sortable: true,
          },
        ],
      };
    },

    computed: {
      authUserNotHavePermission() {
        return !+this.$can(["user.update"]).andLevelGreater(
          this.user.roles[0].level
        );
      },
    },

    methods: {
      async save() {
        this.awaitSaving = true;

        let payload = {
          permission_ids: this.selected.map((r) => r.id),
        };

        api.user
          .updatePermissions(this.id, payload)
          .then(
            (res) => {
              if (res.status === 200) {
                this.$q.notify({
                  color: "positive",
                  message: res.data.message,
                });

                this.selected = res.data.user.permissions;
              }
            },
            (error) => {
              this.$q.notify({
                color: "negative",
                message: error.response.data.message,
              });
            }
          )
          .then(() => {
            this.awaitSaving = false;
          });
      },

      handleSelectionEvent(newSelected) {
        let selectedIds = newSelected.map((t) => t.id);

        this.rolePermissions.forEach((item) => {
          if (!selectedIds.includes(item.id)) {
            newSelected.push(item);

            this.$q.notify({
              color: "positive",
              message:
                "Нельзя выключить разрешение, которое наследуется от роли",
            });
          }
        });

        this.selected = newSelected;
      },
    },
  };
</script>
