<template>
  <q-dialog
    ref="dialog"
    position="top"
    transition-hide="slide-up"
    transition-show="slide-down"
  >
    <q-card style="width: 920px; max-width: 920px">
      <div class="flex items-center justify-between q-px-md">
        <div>Ошибки валидации Декларации ID: {{ declarationId }}</div>
        <q-btn v-close-popup flat dense icon="mdi-close" size="lg" />
      </div>

      <q-card-section v-for="(item, field) in validation" :key="field">
        <div class="text-h6">{{ getReadableKey(field) }}</div>
        <div>{{ item.reason }}</div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
  export default {
    name: "DeclarationValidationAlertsDialog",

    props: {
      declarationId: {
        type: Number,
        required: true,
      },
      validation: {
        type: Object,
        required: true,
      },
    },

    computed: {
      pdFields() {
        return this.$store.state.pdFields;
      },
    },

    methods: {
      getReadableKey(key) {
        return this.pdFields.filter((f) => f.name === key)[0].name_readable;
      },

      // following method is REQUIRED
      // (don't change its name --> "show")
      show() {
        this.$refs.dialog.show();
      },

      // following method is REQUIRED
      // (don't change its name --> "hide")
      hide() {
        this.$refs.dialog.hide();
      },
    },
  };
</script>
