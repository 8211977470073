<template>
    <div class="l-table__row min-w-full bg-white" role="rowgroup">

        <div v-if="isReport"  style="width:80px;max-width: 80px" class="l-table__cell">

        </div>

        <div v-if="visibleColumns.includes('id')"  :style="getCellStyle('id')" class="l-table__cell">
            <q-input
                    v-model="search.id.v"
                    v-bind="searchInputProps"
                    @update:model-value="onSearchInput"
            >
            </q-input>
        </div>

        <div v-if="visibleColumns.includes('cadastral')"  :style="getCellStyle('cadastral')" class="l-table__cell">
                <q-input
                    v-model="search.cadastral.v"
                    v-bind="searchInputProps"
                    @update:model-value="onSearchInput"
                >
                </q-input>
        </div>

        <div v-if="visibleColumns.includes('region_name')"  :style="getCellStyle('region_name')" class="l-table__cell">
            <q-select
                    :popup-content-style="{ width: '350px' }"
                    v-model="search.region_name.v"
                    v-bind="searchInputProps"
                    @update:model-value="onSearchInput"
                    new-value-mode="add-unique"
                    emit-value
                    map-options
                    use-chips
                    use-input
                    @filter="filterFn"
                    dense
                    multiple
                    :options="regionsOptions"
            >

            </q-select>
        </div>
        <div v-if="visibleColumns.includes('projects')"  :style="getCellStyle('projects')" class="l-table__cell">
            <q-select
                    v-model="search.projects.v"
                    v-bind="searchInputProps"
                    @update:model-value="onSearchInput"
                    new-value-mode="add-unique"
                    emit-value
                    map-options
                    use-chips
                    use-input
                    
                    dense
                    multiple
            >
            </q-select>
        </div>

        <div v-if="visibleColumns.includes('addresses')"  :style="getCellStyle('addresses')" class="l-table__cell">
            <q-input
                    v-model="search.addresses.v"
                    v-bind="searchInputProps"
                    @update:model-value="onSearchInput"
            >
            </q-input>
        </div>
        <div v-if="visibleColumns.includes('ap_id')"  :style="getCellStyle('ap_id')" class="l-table__cell">
            <q-select
                    v-model="search.ap_id.v"
                    v-bind="searchInputProps"
                    @update:model-value="onSearchInput"
                    new-value-mode="add-unique"
                    emit-value
                    map-options
                    use-chips
                    use-input
                    
                    dense
                    multiple
            >
            </q-select>
        </div>
        <div v-if="visibleColumns.includes('domrf_id')"  :style="getCellStyle('domrf_id')" class="l-table__cell">
            <q-select
                    v-model="search.domrf_id.v"
                    v-bind="searchInputProps"
                    @update:model-value="onSearchInput"
                    new-value-mode="add-unique"
                    emit-value
                    map-options
                    use-chips
                    use-input
                    
                    dense
                    multiple
            >
            </q-select>
        </div>
        <div v-if="visibleColumns.includes('ap_objectclass_id')"  :style="getCellStyle('ap_objectclass_id')" class="l-table__cell">
            <q-select
                    v-model="search.ap_objectclass_id.v"
                    v-bind="searchInputProps"
                    @update:model-value="onSearchInput"
                    new-value-mode="add-unique"
                    emit-value
                    map-options
                    use-chips
                    use-input
                    
                    dense
                    multiple
                    :options="[
                {label: 'Эконом', value: 'Эконом'},
                {label: 'Комфорт', value: 'Комфорт'},
                {label: 'Бизнес', value: 'Бизнес'},
                {label: 'Элитный', value: 'Элитный'},
                {label: 'Премиум', value: 'Премиум'},
                {label: 'Бизнес-', value: 'Бизнес-'},
                {label: 'Бизнес+', value: 'Бизнес+'}
            ]"
            >
            </q-select>
        </div>
        <div v-if="visibleColumns.includes('ap_stage_id')"  :style="getCellStyle('ap_stage_id')" class="l-table__cell">
            <q-select
                    v-model="search.ap_stage_id.v"
                    v-bind="searchInputProps"
                    @update:model-value="onSearchInput"
                    new-value-mode="add-unique"
                    emit-value
                    map-options
                    use-chips
                    use-input
                    
                    dense
                    multiple
                    :options="[
                {label: 'Строительство не начато', value: 'Строительство не начато'},
                {label: 'Введен в эксплуатацию', value: 'Введен в эксплуатацию'},
                {label: 'Начало монтажных работ', value: 'Начало монтажных работ'},
                {label: 'Монтажные и отделочные работы', value: 'Монтажные и отделочные работы'},
                {label: 'Получение РВЭ, благоустройство территории', value: 'Получение РВЭ, благоустройство территории'},
                {label: 'Работы нулевого цикла', value: 'Работы нулевого цикла'},
                {label: 'Реконструкция', value: 'Реконструкция'}
            ]"
            >
            </q-select>
        </div>
        <div v-if="visibleColumns.includes('ap_date_state_commission')"  :style="getCellStyle('ap_date_state_commission')" class="l-table__cell">
            <q-select
                    v-model="search.ap_date_state_commission.v"
                    v-bind="searchInputProps"
                    @update:model-value="onSearchInput"
                    new-value-mode="add-unique"
                    emit-value
                    map-options
                    use-chips
                    use-input
                    
                    dense
                    multiple
            >
            </q-select>
        </div>
        <div v-if="visibleColumns.includes('ap_agreement_id')"  :style="getCellStyle('ap_agreement_id')" class="l-table__cell">
            <q-select
                v-model="search.ap_agreement_id.v"
                v-bind="searchInputProps"
                @update:model-value="onSearchInput"
                new-value-mode="add-unique"
                emit-value
                map-options
                use-chips
                use-input
                
                dense
                multiple
                :options="[
                {label: 'КП', value: 'КП'},
                {label: 'Предварительный КП', value: 'Предварительный КП'},
                {label: 'Договор соинвестирования', value: 'Договор соинвестирования'},
                {label: 'ДДУ без эскроу', value: 'ДДУ без эскроу'},
                {label: 'Уступка прав от юрлица', value: 'Уступка прав от юрлица'},
                {label: 'ДДУ и уступка по ДДУ', value: 'ДДУ и уступка по ДДУ'},
                {label: 'Уступка прав по ДДУ', value: 'Уступка прав по ДДУ'},
                {label: 'ДДУ и соинвестирование', value: 'ДДУ и соинвестирование'},
                {label: 'ЖСК', value: 'ЖСК'},
                {label: 'Предварительный ДДУ', value: 'Предварительный ДДУ'},
                {label: 'Уступка прав от физлица', value: 'Уступка прав от физлица'},
                {label: 'Договор бронирования', value: 'Договор бронирования'},
                {label: 'Уступка прав по ДДУ, КП', value: 'Уступка прав по ДДУ, КП'},
                {label: 'Уступка прав по ДДУ, Предварительный КП', value: 'Уступка прав по ДДУ, Предварительный КП'},
                {label: 'ДВОУ', value: 'ДВОУ'},
                {label: 'Предварительный КП, ДДУ', value: 'Предварительный КП, ДДУ'},
                {label: 'Договор опциона', value: 'Договор опциона'},
                {label: 'Договор купли будущей недвижимой вещи', value: 'Договор купли будущей недвижимой вещи'},
                {label: 'Договор инвестирования', value: 'Договор инвестирования'},
                {label: 'ДДУ, КП', value: 'ДДУ, КП'},
                {label: 'Предварительный КП, КП', value: 'Предварительный КП, КП'},
                {label: 'ДДУ с эскроу', value: 'ДДУ с эскроу'},
                {label: 'Договор купли будущей недвижимой вещи, Предварительный КП', value: 'Договор купли будущей недвижимой вещи, Предварительный КП'},
            ]"
            >
            </q-select>
        </div>
        <div v-if="visibleColumns.includes('note')"  :style="getCellStyle('note')" class="l-table__cell">
            <q-input
                    v-model="search.note.v"
                    v-bind="searchInputProps"
                    @update:model-value="onSearchInput"
            >
            </q-input>
        </div>

        <div v-if="visibleColumns.includes('statement_status')"  :style="getCellStyle('statement_status')" class="l-table__cell">
            <q-select
                    v-model="search.statement_status.v"
                    v-bind="searchInputProps"
                    @update:model-value="onSearchInput"
                    new-value-mode="add-unique"
                    emit-value
                    map-options
                    use-chips
                    use-input
                    
                    dense
                    multiple
                    :options="[
                {label: 'Активный', value: 'Активный'},
                {label: 'Новый', value: 'Новый'},
                {label: 'Не активен', value: 'Не активен'},
                {label: 'Архив', value: 'Архив'},
                {label: 'Пустой', value: 'Пустой'},
            ]"
            />
        </div>

        <div v-if="visibleColumns.includes('rosreestr_status')"  :style="getCellStyle('rosreestr_status')" class="l-table__cell">
            <q-select
                    v-model="search.rosreestr_status.v"
                    v-bind="searchInputProps"
                    @update:model-value="onSearchInput"
                    new-value-mode="add-unique"
                    emit-value
                    map-options
                    use-chips
                    use-input
                    
                    dense
                    multiple
                    :options="[
                {label: 'Сделки', value: 'Сделки'},
                {label: 'Нет сделок', value: 'Нет сделок'},
                {label: 'Архив', value: 'Архив'},
            ]"
            />
        </div>

        <div v-if="visibleColumns.includes('ap_document_date')"  :style="getCellStyle('ap_document_date')" class="l-table__cell">
            <div class="full-width">
                <div class="flex justify-between items-center">
                    <div>
                        {{ search.ap_document_date.from || "от" }}
                    </div>
                    <div>
                        <q-icon
                            v-if="search.ap_document_date.from"
                            class="cursor-pointer"
                            color="grey-7"
                            name="mdi-close-circle"
                            size="sm"
                            @click="search.ap_document_date.from = null"
                        />

                        <q-icon
                            class="cursor-pointer"
                            color="grey-7"
                            name="mdi-calendar"
                            size="sm"
                        >
                            <q-popup-proxy ref="datePickerUpdatedFrom">
                                <q-date
                                    v-model="search.ap_document_date.from"
                                    mask="YYYY-MM-DD"
                                    @mouseleave="$refs.datePickerUpdatedFrom.hide()"
                                />
                            </q-popup-proxy>
                        </q-icon>
                    </div>
                </div>

                <div class="flex justify-between items-center">
                    <div>
                        {{ search.ap_document_date.to || "до" }}
                    </div>
                    <div>
                        <q-icon
                            v-if="search.ap_document_date.to"
                            class="cursor-pointer"
                            color="grey-7"
                            name="mdi-close-circle"
                            size="sm"
                            @click="search.ap_document_date.to = null"
                        />

                        <q-icon
                            class="cursor-pointer"
                            color="grey-7"
                            name="mdi-calendar"
                            size="sm"
                        >
                            <q-popup-proxy ref="datePickerUpdatedTo">
                                <q-date
                                    v-model="search.ap_document_date.to"
                                    mask="YYYY-MM-DD"
                                    @mouseleave="$refs.datePickerUpdatedTo.hide()"
                                />
                            </q-popup-proxy>
                        </q-icon>
                    </div>
                </div>
            </div>

        </div>
        <div v-if="visibleColumns.includes('ap_document_count')"  :style="getCellStyle('ap_document_count')" class="l-table__cell">
            <q-input
                v-model="search.ap_document_count.v"
                v-bind="searchInputProps"
                @update:model-value="onSearchInput"
            >
            </q-input>
        </div>
        <div v-if="visibleColumns.includes('ap_registration_date')"  :style="getCellStyle('ap_registration_date')" class="l-table__cell">
            <div class="full-width">
                <div class="flex justify-between items-center">
                    <div>
                        {{ search.ap_registration_date.from || "от" }}
                    </div>
                    <div>
                        <q-icon
                            v-if="search.ap_registration_date.from"
                            class="cursor-pointer"
                            color="grey-7"
                            name="mdi-close-circle"
                            size="sm"
                            @click="search.ap_registration_date.from = null"
                        />

                        <q-icon
                            class="cursor-pointer"
                            color="grey-7"
                            name="mdi-calendar"
                            size="sm"
                        >
                            <q-popup-proxy ref="datePickerUpdatedFrom">
                                <q-date
                                    v-model="search.ap_registration_date.from"
                                    mask="YYYY-MM-DD"
                                    @mouseleave="$refs.datePickerUpdatedFrom.hide()"
                                />
                            </q-popup-proxy>
                        </q-icon>
                    </div>
                </div>

                <div class="flex justify-between items-center">
                    <div>
                        {{ search.ap_registration_date.to || "до" }}
                    </div>
                    <div>
                        <q-icon
                            v-if="search.ap_registration_date.to"
                            class="cursor-pointer"
                            color="grey-7"
                            name="mdi-close-circle"
                            size="sm"
                            @click="search.ap_registration_date.to = null"
                        />

                        <q-icon
                            class="cursor-pointer"
                            color="grey-7"
                            name="mdi-calendar"
                            size="sm"
                        >
                            <q-popup-proxy ref="datePickerUpdatedTo">
                                <q-date
                                    v-model="search.ap_registration_date.to"
                                    mask="YYYY-MM-DD"
                                    @mouseleave="$refs.datePickerUpdatedTo.hide()"
                                />
                            </q-popup-proxy>
                        </q-icon>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="visibleColumns.includes('created_at')"  :style="getCellStyle('created_at')" class="l-table__cell">
            <div class="full-width">
                <div class="flex justify-between items-center">
                    <div>
                        {{ search.created_at.from || "от" }}
                    </div>
                    <div>
                        <q-icon
                            v-if="search.created_at.from"
                            class="cursor-pointer"
                            color="grey-7"
                            name="mdi-close-circle"
                            size="sm"
                            @click="search.created_at.from = null"
                        />

                        <q-icon
                            class="cursor-pointer"
                            color="grey-7"
                            name="mdi-calendar"
                            size="sm"
                        >
                            <q-popup-proxy ref="datePickerUpdatedFrom">
                                <q-date
                                    v-model="search.created_at.from"
                                    mask="YYYY-MM-DD"
                                    @mouseleave="$refs.datePickerUpdatedFrom.hide()"
                                />
                            </q-popup-proxy>
                        </q-icon>
                    </div>
                </div>

                <div class="flex justify-between items-center">
                    <div>
                        {{ search.created_at.to || "до" }}
                    </div>
                    <div>
                        <q-icon
                            v-if="search.created_at.to"
                            class="cursor-pointer"
                            color="grey-7"
                            name="mdi-close-circle"
                            size="sm"
                            @click="search.created_at.to = null"
                        />

                        <q-icon
                            class="cursor-pointer"
                            color="grey-7"
                            name="mdi-calendar"
                            size="sm"
                        >
                            <q-popup-proxy ref="datePickerUpdatedTo">
                                <q-date
                                    v-model="search.created_at.to"
                                    mask="YYYY-MM-DD"
                                    @mouseleave="$refs.datePickerUpdatedTo.hide()"
                                />
                            </q-popup-proxy>
                        </q-icon>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="visibleColumns.includes('updated_at')"  :style="getCellStyle('updated_at')" class="l-table__cell">
            <div class="full-width">
                <div class="flex justify-between items-center">
                    <div>
                        {{ search.updated_at.from || "от" }}
                    </div>
                    <div>
                        <q-icon
                            v-if="search.updated_at.from"
                            class="cursor-pointer"
                            color="grey-7"
                            name="mdi-close-circle"
                            size="sm"
                            @click="search.updated_at.from = null"
                        />

                        <q-icon
                            class="cursor-pointer"
                            color="grey-7"
                            name="mdi-calendar"
                            size="sm"
                        >
                            <q-popup-proxy ref="datePickerUpdatedFrom">
                                <q-date
                                    v-model="search.updated_at.from"
                                    mask="YYYY-MM-DD"
                                    @mouseleave="$refs.datePickerUpdatedFrom.hide()"
                                />
                            </q-popup-proxy>
                        </q-icon>
                    </div>
                </div>

                <div class="flex justify-between items-center">
                    <div>
                        {{ search.updated_at.to || "до" }}
                    </div>
                    <div>
                        <q-icon
                            v-if="search.updated_at.to"
                            class="cursor-pointer"
                            color="grey-7"
                            name="mdi-close-circle"
                            size="sm"
                            @click="search.updated_at.to = null"
                        />

                        <q-icon
                            class="cursor-pointer"
                            color="grey-7"
                            name="mdi-calendar"
                            size="sm"
                        >
                            <q-popup-proxy ref="datePickerUpdatedTo">
                                <q-date
                                    v-model="search.updated_at.to"
                                    mask="YYYY-MM-DD"
                                    @mouseleave="$refs.datePickerUpdatedTo.hide()"
                                />
                            </q-popup-proxy>
                        </q-icon>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="visibleColumns.includes('user_id')"  :style="getCellStyle('user_id')" class="l-table__cell">
            <q-input
                v-model="search.user_id.v"
                v-bind="searchInputProps"
                @update:model-value="onSearchInput"
            >
            </q-input>
        </div>

        <div v-if="visibleColumns.includes('last_deal_date')"  :style="getCellStyle('last_deal_date')" class="l-table__cell">
            <div class="full-width">
                <div class="flex justify-between items-center">
                    <div>
                        {{ search.last_deal_date.from || "от" }}
                    </div>
                    <div>
                        <q-icon
                            v-if="search.last_deal_date.from"
                            class="cursor-pointer"
                            color="grey-7"
                            name="mdi-close-circle"
                            size="sm"
                            @click="search.last_deal_date.from = null"
                        />

                        <q-icon
                            class="cursor-pointer"
                            color="grey-7"
                            name="mdi-calendar"
                            size="sm"
                        >
                            <q-popup-proxy ref="datePickerUpdatedFrom">
                                <q-date
                                    v-model="search.last_deal_date.from"
                                    mask="YYYY-MM-DD"
                                    @mouseleave="$refs.datePickerUpdatedFrom.hide()"
                                />
                            </q-popup-proxy>
                        </q-icon>
                    </div>
                </div>

                <div class="flex justify-between items-center">
                    <div>
                        {{ search.last_deal_date.to || "до" }}
                    </div>
                    <div>
                        <q-icon
                            v-if="search.last_deal_date.to"
                            class="cursor-pointer"
                            color="grey-7"
                            name="mdi-close-circle"
                            size="sm"
                            @click="search.last_deal_date.to = null"
                        />

                        <q-icon
                            class="cursor-pointer"
                            color="grey-7"
                            name="mdi-calendar"
                            size="sm"
                        >
                            <q-popup-proxy ref="datePickerUpdatedTo">
                                <q-date
                                    v-model="search.last_deal_date.to"
                                    mask="YYYY-MM-DD"
                                    @mouseleave="$refs.datePickerUpdatedTo.hide()"
                                />
                            </q-popup-proxy>
                        </q-icon>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="visibleColumns.includes('current_month_deals_count')"  :style="getCellStyle('current_month_deals_count')" class="l-table__cell">
            <q-input
                v-model="search.current_month_deals_count.v"
                v-bind="searchInputProps"
                @update:model-value="onSearchInput"
            >
            </q-input>
        </div>
        <div v-if="visibleColumns.includes('current_month_deals')"  :style="getCellStyle('current_month_deals')" class="l-table__cell">
            <q-select
                v-model="search.current_month_deals.v"
                v-bind="searchInputProps"
                @update:model-value="onSearchInput"
                new-value-mode="add-unique"
                emit-value
                map-options
                use-input
                dense
                :options="[
                {label: 'Да', value: true},
                {label: 'Нет', value: false},
                {label: 'Не выбрано', value: null},
            ]"
            />
        </div>
        <div v-if="visibleColumns.includes('three_months_deals')"  :style="getCellStyle('three_months_deals')" class="l-table__cell">
            <q-select
                v-model="search.three_months_deals.v"
                v-bind="searchInputProps"
                @update:model-value="onSearchInput"
                new-value-mode="add-unique"
                emit-value
                map-options
                use-input
                dense
                :options="[
                {label: 'Да', value: true},
                {label: 'Нет', value: false},
                {label: 'Не выбрано', value: null},
            ]"
            />
        </div>
        <div v-if="visibleColumns.includes('deals_count_diff_with_last_time')"  :style="getCellStyle('deals_count_diff_with_last_time')" class="l-table__cell">
            <q-input
                v-model="search.deals_count_diff_with_last_time.v"
                v-bind="searchInputProps"
                @update:model-value="onSearchInput"
            >
            </q-input>
        </div>
        <div v-if="visibleColumns.includes('rosreestr_updated_at')"  :style="getCellStyle('rosreestr_updated_at')" class="l-table__cell">
            <div class="full-width">
                <div class="flex justify-between items-center">
                    <div>
                        {{ search.rosreestr_updated_at.from || "от" }}
                    </div>
                    <div>
                        <q-icon
                            v-if="search.rosreestr_updated_at.from"
                            class="cursor-pointer"
                            color="grey-7"
                            name="mdi-close-circle"
                            size="sm"
                            @click="search.rosreestr_updated_at.from = null"
                        />

                        <q-icon
                            class="cursor-pointer"
                            color="grey-7"
                            name="mdi-calendar"
                            size="sm"
                        >
                            <q-popup-proxy ref="datePickerUpdatedFrom">
                                <q-date
                                    v-model="search.rosreestr_updated_at.from"
                                    mask="YYYY-MM-DD"
                                    @mouseleave="$refs.datePickerUpdatedFrom.hide()"
                                />
                            </q-popup-proxy>
                        </q-icon>
                    </div>
                </div>

                <div class="flex justify-between items-center">
                    <div>
                        {{ search.rosreestr_updated_at.to || "до" }}
                    </div>
                    <div>
                        <q-icon
                            v-if="search.rosreestr_updated_at.to"
                            class="cursor-pointer"
                            color="grey-7"
                            name="mdi-close-circle"
                            size="sm"
                            @click="search.rosreestr_updated_at.to = null"
                        />

                        <q-icon
                            class="cursor-pointer"
                            color="grey-7"
                            name="mdi-calendar"
                            size="sm"
                        >
                            <q-popup-proxy ref="datePickerUpdatedTo">
                                <q-date
                                    v-model="search.rosreestr_updated_at.to"
                                    mask="YYYY-MM-DD"
                                    @mouseleave="$refs.datePickerUpdatedTo.hide()"
                                />
                            </q-popup-proxy>
                        </q-icon>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import ComparisonSelect from "@/components/ComparisonSelect";
import {COMPARE_STRING_OPTIONS, SEARCH_INPUT_PROPS} from "@/utils/batch";
import {useRoute} from "vue-router";
import api from "../../api";

export default {
    name: "CadastralsTableFiltersRow",

    emits: ["filter-cadastrals", "set-cadastrals"],

    props: {
        cellStyles: {
            type: Object,
            required: true,
        },
        search: {
            type: Object,
            required: true,
        },

        visibleColumns: {
            type: Array,
            required: true,
        },
    },

    components: {
        ComparisonSelect,
    },

    data() {
        return {
            searchInputProps: SEARCH_INPUT_PROPS,
            compareStringOptions: COMPARE_STRING_OPTIONS,
            cadastrals: [],
            regions: [],
            regionsOptions: [],
            isReport: false
        };
    },

    watch: {
        "search.cadastral.v"() {
            this.$emit("filter-cadastrals");
        },
        "search.region_name.v"() {
            this.$emit("filter-cadastrals");
        },
        "search.projects.v"() {
            this.$emit("filter-cadastrals");
        },
        "search.addresses.v"() {
            this.$emit("filter-cadastrals");
        },
        "search.ap_id.v"() {
            this.$emit("filter-cadastrals");
        },
        "search.domrf_id.v"() {
            this.$emit("filter-cadastrals");
        },
        "search.ap_objectclass_id.v"() {
            this.$emit("filter-cadastrals");
        },
        "search.ap_stage_id.v"() {
            this.$emit("filter-cadastrals");
        },
        "search.ap_date_state_commission.v"() {
            this.$emit("filter-cadastrals");
        },
        "search.ap_agreement_id.v"() {
            this.$emit("filter-cadastrals");
        },
        "search.note.v"() {
            this.$emit("filter-cadastrals");
        },
        "search.statement_status.v"() {
            this.$emit("filter-cadastrals");
        },
        "search.rosreestr_status.v"() {
            this.$emit("filter-cadastrals");
        },
        "search.ap_document_date.from"() {
            this.$emit("filter-cadastrals");
        },
        "search.ap_document_date.to"() {
            this.$emit("filter-cadastrals");
        },
        "search.ap_registration_date.from"() {
            this.$emit("filter-cadastrals");
        },
        "search.ap_registration_date.to"() {
            this.$emit("filter-cadastrals");
        },
        "search.created_at.from"() {
            this.$emit("filter-cadastrals");
        },
        "search.created_at.to"() {
            this.$emit("filter-cadastrals");
        },
        "search.updated_at.from"() {
            this.$emit("filter-cadastrals");
        },
        "search.updated_at.to"() {
            this.$emit("filter-cadastrals");
        },
        "search.ap_document_count.v"() {
            this.$emit("filter-cadastrals");
        },
        "search.user_id.v"() {
            this.$emit("filter-cadastrals");
        },
        "search.last_deal_date.from"() {
            this.$emit("filter-cadastrals");
        },
        "search.last_deal_date.to"() {
            this.$emit("filter-cadastrals");
        },
        "search.current_month_deals_count.v"() {
            this.$emit("filter-cadastrals");
        },
        "search.current_month_deals.v"() {
            this.$emit("filter-cadastrals");
        },
        "search.three_months_deals.v"() {
            this.$emit("filter-cadastrals");
        },
        "search.deals_count_diff_with_last_time.v"() {
            this.$emit("filter-cadastrals");
        },
        "search.rosreestr_updated_at.from"() {
            this.$emit("filter-cadastrals");
        },
        "search.rosreestr_updated_at.to"() {
            this.$emit("filter-cadastrals");
        },

    },

    async mounted() {
        this.$emit("set-cadastrals");
        if (useRoute().query.report) {
            this.isReport = true
        }

        let regions = await api.cadastral.getRegionsList()
        if (regions) {
            this.regions = regions.data
        }

        this.regionsOptions = this.regions
    },

    methods: {
        onSearchInput(val) {
            // this.$emit("filter-cadastrals");
        },

        onSearchCompareInput(val) {
            if (!val) {
                return;
            }

            this.$emit("filter-cadastrals");
        },

        filterFn (val, update) {
            update(() => {
                const needle = val.toLowerCase()
                this.regionsOptions = this.regions.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
            })
        },

        getCellStyle(columnName) {
            return this.cellStyles[columnName];
        },
    },
};
</script>
