import store from "@/store";

/**
 * {Array} permissions Like ['project.add'] or ['project.delete', 'project.update']
 * {Object} object To check for is owner
 */
class Ability {
  constructor(permissions) {
    this.can = [];
    this.permissions = permissions || [];

    if (!Array.isArray(this.permissions) || !this.permissions.length) {
      throw new Error("permissions not an array");
    }

    let atLeastOne = [];

    for (const permission of this.permissions) {
      atLeastOne.push(store.getters["auth/permissions"].includes(permission));
    }

    this.can.push(atLeastOne.includes(true));

    return this;
  }

  andCreatorId(creatorUserId) {
    this.can.push(creatorUserId === store.getters["auth/userId"]);

    return this;
  }

  andLevelGreater(level) {
    this.can.push(store.getters["auth/firstRole"].level > level);

    return this;
  }

  valueOf() {
    return !this.can.includes(false);
  }
}

export function canAbility(permissions) {
  return new Ability(permissions);
}
