import lf from "@/plugins/localforage";
import Notify from 'quasar/src/plugins/Notify.js';;
import cloneDeep from "lodash.clonedeep";
import { toBase64, fromBase64 } from "js-base64";

/**
 * @return {Promise<void>}
 */
export async function removeAuthDataFromStorage() {
  try {
    await lf.removeItem("access_token");
    await lf.removeItem("refresh_token");
    await lf.removeItem("expires_in");
  } catch (e) {
    Notify.create({
      color: "negative",
      message:
        "Хранилище браузера недоступно. Пожалуйста, проверьте настройки.",
      timeout: 60000,
    });
  }
}

/**
 * @param {String} accessToken
 * @param {String} refreshToken
 * @param {Number} expiresIn
 * @return {Promise<void>}
 */
export async function setAuthDataToStorage(
  accessToken,
  refreshToken,
  expiresIn
) {
  try {
    await lf.setItem("access_token", accessToken);
    await lf.setItem("refresh_token", refreshToken);
    await lf.setItem("expires_in", expiresIn);
  } catch (e) {
    Notify.create({
      color: "negative",
      message:
        "Хранилище браузера недоступно. Пожалуйста, проверьте настройки.",
      timeout: 60000,
    });
  }
}

/**
 * @returns {Promise<boolean>}
 */
export async function isAccessTokenExpiresSoon() {
  const expiresIn = 1800; // update tokens if access token expires soon (less then 30 min)
  const accessTokenExpiresIn = await lf.getItem("expires_in"); // expires_in as timestamp
  const timeLeft = accessTokenExpiresIn - Math.ceil(Date.now() / 1000);

  return timeLeft < expiresIn;
}

/**
 * Reload page if access token expire soon.
 */
export function reloadPageIfTokenExpireSoonInterval() {
  setInterval(async function () {
    const accessToken = await lf.getItem("access_token");
    const refreshToken = await lf.getItem("refresh_token");

    if (accessToken && refreshToken && (await isAccessTokenExpiresSoon())) {
      window.location.reload();
    }
  }, 60000);
}

/**
 * @param {Object} obj
 */
export function deleteKeysWithEmptyValues(obj) {
  Object.keys(obj).forEach((key) => {
    if (
      obj[key] === null ||
      obj[key] === "" ||
      (Array.isArray(obj[key]) && obj[key].length === 0)
    ) {
      delete obj[key];
    }
  });
}

/**
 * @param {String} fileName
 * @return {string|null}
 */
export function extractFileExtension(fileName) {
  if (/[.]/.test(fileName)) {
    return fileName.split(".").reverse()[0].toLowerCase();
  }

  return null;
}

/**
 * @param {String} fileName
 * @return {boolean}
 */
export function isImage(fileName) {
  const ext = extractFileExtension(fileName);

  if (!ext) {
    return false;
  }

  const imageExtensions = ["jpg", "jpeg", "png", "bmp", "gif"];

  return imageExtensions.includes(ext.toLowerCase());
}

/**
 * @param {Array} data
 * @param {String} property
 * @param {String} direction
 * @return {Array}
 */
export function customSort(data, property, direction = "desc") {
  if (!data || (Array.isArray(data) && data.length === 0)) {
    return [];
  }

  if (direction === "asc") {
    return data.sort((a, b) => {
      return a[property] < b[property] ? 1 : a[property] > b[property] ? -1 : 0;
    });
  }

  return data.sort((a, b) => {
    return a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
  });
}

export function normalizeNumber(number) {
  let temp = number.split(":");

  temp.forEach((part, index) => {
    let splitted = part.split("");

    for (let i = 0; i < splitted.length; i++) {
      if (Number(splitted[i]) > 0 || i === splitted.length - 1) {
        break;
      }

      delete splitted[i];
    }

    temp[index] = splitted.join("");
  });

  return temp.join(":");
}

export const SOURCE_HAS_HOST = {
  spb: "spb.bndev.it",
  msk: "api.bndev.it",
  18: "ap18.bndev.it",
  25: "ap25.bndev.it",
  33: "ap33.bndev.it",
  36: "ap36.bndev.it",
  40: "ap40.bndev.it",
  54: "ap54.bndev.it",
  55: "ap55.bndev.it",
  59: "ap59.bndev.it",
  66: "ap66.bndev.it",
  67: "ap67.bndev.it",
  69: "ap69.bndev.it",
  71: "ap71.bndev.it",
  72: "ap72.bndev.it",
  76: "ap76.bndev.it",
  82: "ap82.bndev.it",
  krd: "krnd.bndev.it",
  rzn: "ryazan.bndev.it",
  pfo: "ul.bndev.it",
  rsa: "apall.bndev.it",
  edn: "eden.bndev.it",
};

export function getApBuildingLink(serial) {
  const splitted = serial.split("_");
  const host = SOURCE_HAS_HOST[splitted[0]];

  return `https://${host}/parser/building/update/${splitted[1]}`;
}

export function getApProjectLink(serial) {
  const splitted = serial.split("_");
  const host = SOURCE_HAS_HOST[splitted[0]];

  return `https://${host}/parser/housing-complex/update/${splitted[1]}`;
}

export function getDomRfBuildingLink(serial) {
  if (serial.includes("-z")) {
    serial = serial.replace("-z", "");

    return `https://xn--80az8a.xn--d1aqf.xn--p1ai/сервисы/каталог-новостроек-жск/объект/${serial}`;
  }

  return `https://xn--80az8a.xn--d1aqf.xn--p1ai/сервисы/каталог-новостроек/объект/${serial}`;
}

export const COMPARE_NUMBER_OPTIONS = [
  {
    label: "Равно",
    icon: "mdi-equal",
    value: "eq",
  },
  {
    label: "Не равно",
    icon: "mdi-not-equal-variant",
    value: "neq",
  },
  {
    label: "Больше",
    icon: "mdi-greater-than",
    value: "gt",
  },
  {
    label: "Больше или равно",
    icon: "mdi-greater-than-or-equal",
    value: "gte",
  },
  {
    label: "Меньше",
    icon: "mdi-less-than",
    value: "lt",
  },
  {
    label: "Меньше или равно",
    icon: "mdi-less-than-or-equal",
    value: "lte",
  },
];

export const COMPARE_STRING_OPTIONS = [
  {
    label: "Содержит",
    icon: "mdi-contain",
    value: "ctn",
  },
  {
    label: "Начинается с",
    icon: "mdi-contain-start",
    value: "sw",
  },
  {
    label: "Заканчивается на",
    icon: "mdi-contain-end",
    value: "ew",
  },
  {
    label: "Равно",
    icon: "mdi-equal",
    value: "eq",
  },
  {
    label: "Не равно",
    icon: "mdi-not-equal-variant",
    value: "neq",
  },
];

export const SEARCH_SELECT_PROPS = {
  "option-value": "id",
  "option-label": "name",
  "options-dense": true,
  "emit-value": true,
  "map-options": true,
  dense: true,
};

export const SEARCH_INPUT_PROPS = {
  debounce: 500,
  dense: true,
  color: "primary",
};

/**
 * Modify search params for use in GET request
 */
export function normalizeQueryForRequest(values) {
  let q = cloneDeep(values);

  Object.keys(q).forEach((prop) => {
    if (!q[prop]) {
      return;
    }

    if (
      (q[prop].hasOwnProperty("c") &&
        q[prop].hasOwnProperty("v") &&
        (q[prop].v === null || q[prop].v === "")) ||
      (q[prop].hasOwnProperty("from") &&
        q[prop].hasOwnProperty("to") &&
        q[prop].from === null &&
        q[prop].to === null)
    ) {
      delete q[prop];
    }
  });

  // remove all empty arrays
  Object.keys(q).forEach((prop) => {
    if (Array.isArray(q[prop]) && !q[prop].length) {
      delete q[prop];
    }
  });

  return q;
}

export const groupBy = (x, f) =>
  x.reduce((a, b) => ((a[f(b)] ||= []).push(b), a), {});

export function uniencode(obj) {
  return toBase64(JSON.stringify(obj), true);
}

export function unidecode(str) {
  return JSON.parse(fromBase64(str));
}

export function formatCeilingHeight(value) {
  if (!value) {
    return null;
  }

  if (value.min && value.max && value.min !== value.max) {
    return `${value.min} - ${value.max}`;
  }

  if (value.min) {
    return value.min;
  }

  if (value.max) {
    return value.max;
  }

  return null;
}

export function formatCeilingHeightArray(array) {
  if (!array || !(array && array.length)) {
    return null;
  }

  return array
    .map((str) => {
      let json;

      try {
        json = JSON.parse(str);
      } catch (e) {
        return "json-error";
      }

      if (!json || !(json && json.length)) {
        return null;
      }

      if (json.length === 1) {
        return json[0];
      }

      if (json.length === 2) {
        if (json[0] === json[1]) {
          return json[0];
        }

        return `${json[0]} - ${json[1]}`;
      }

      return null;
    })
    .join("\n");
}

export function boxBellColor(alias) {
  switch (alias) {
    case "awaiting":
      return "orange-7";
    case "obsolete":
      return "red-7";
    default:
      return "black";
  }
}

export function boxBellIconName(alias) {
  switch (alias) {
    case "awaiting":
      return "mdi-bell";
    case "obsolete":
      return "mdi-bell";
    case "watch":
      return "mdi-bell-outline";
    case "dont-watch":
      return "mdi-bell-off";
    default:
      return "mdi-bell";
  }
}

export function getFormattedLocalDateTimeString() {
  let nowDate = new Date();
  let year = nowDate.getFullYear();
  let month = nowDate.getMonth();
  let day = nowDate.getDay();
  let hours = nowDate.getHours();
  let minutes = nowDate.getMinutes();
  let seconds = nowDate.getSeconds();

  return `${year}-${month}-${day}_${hours}:${minutes}:${seconds}`;
}
