<template>
  <div class="row">
    <router-view />
  </div>
</template>

<script>
  import { createMetaMixin } from "quasar";

  export default {
    name: "Settings",

    mixins: [
      createMetaMixin(function () {
        return {
          title: "Настройки",
        };
      }),
    ],
  };
</script>
