<template>
  <q-dialog
    ref="dialog"
    position="top"
    transition-hide="slide-up"
    transition-show="slide-down"
    @hide="onDialogHide"
  >
    <q-card style="width: 600px; max-width: 600px">
      <div class="flex items-center justify-between q-px-md">
        <div>Справка по правилам корректировок (ПК)</div>
        <q-btn v-close-popup flat dense icon="mdi-close" size="lg" />
      </div>

      <q-card-section>
        <div class="text-h6 q-mb-sm">Порядок применения</div>
        <span>
          В случаях, когда для поля есть несколько ПК, применяются все согласно
          уровню применения в последовательности от наивысшего к низкому:
        </span>
        <ul>
          <li>
            <b>Наивысший</b> - если указан Корпус и ПД файл. Применяется для
            конкретного ПД файла конкретного корпуса;
          </li>
          <li>
            <b>Высокий</b> - если указан только Корпус. Применяется для всех ПД
            файлов конкретного корпуса;
          </li>
          <li>
            <b>Средний</b> - если указан только ПД файл. Применяется для
            конкретного ПД файла;
          </li>
          <li>
            <b>Низкий</b> - если не указан ни Корпус ни ПД файл. Применяется для
            всех ПД файлов всех корпусов.
          </li>
        </ul>
        <span>
          В случаях, когда в одном уровне есть несколько ПК, применяются все ПК
          для данного уровня в случайном порядке.
        </span>
      </q-card-section>

      <q-card-section>
        <div class="text-h6 q-mb-sm">Ручной режим</div>
        <span>
          Если для ПК проставлена галочка "только ручной режим" это значит, что
          правило не будет автоматически применяться на этапе парса ПД файла.
        </span>
      </q-card-section>

      <q-card-section>
        <div class="text-h6 q-mb-sm">Удаление ПК</div>
        <span>
          Если удалить привязанный ДомРФ корпус или ПД файл к ПК, то удалится и
          само правило. Такая логика гарантирует, что ПК не перейдет на более
          низкий уровень применения.
        </span>
      </q-card-section>

      <q-card-actions class="q-pa-md flex justify-end">
        <q-btn
          v-close-popup
          label="Закрыть"
          color="primary"
          title="Закрыть окно"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
  export default {
    name: "DeclarationCorrectionInfoDialog",

    emits: ["hide"],

    methods: {
      // following method is REQUIRED
      // (don't change its name --> "show")
      show() {
        this.$refs.dialog.show();
      },

      // following method is REQUIRED
      // (don't change its name --> "hide")
      hide() {
        this.$refs.dialog.hide();
      },

      onDialogHide() {
        this.$emit("hide");
      },
    },
  };
</script>
