<template>
  <q-dialog
    ref="dialog"
    position="top"
    transition-hide="slide-up"
    transition-show="slide-down"
    @hide="onDialogHide"
  >
    <q-card style="width: 600px; max-width: 600px">
      <div class="flex items-center justify-between q-px-md">
        <div>Справка по декларациям</div>
        <q-btn v-close-popup flat dense icon="mdi-close" size="lg" />
      </div>

      <q-card-section>
        <div class="text-h6 q-mb-sm">Подсветка ячеек</div>
        <div>
          Цветовое кодирование ячеек применяется в случаях, когда значение в
          поле требует внимания: данные ошибочные, не были найдены или по другим
          причинам.
        </div>
        <div>
          Подсветка ячеек снимается, если было применено хотя бы одно Правило
          корректировки для данного поля.
        </div>
        <div>
          Подсветка ячеек не применяется для деклараций с датой размещения
          старее 2021-02-01.
        </div>
      </q-card-section>

      <q-card-section>
        <div class="text-h6 q-mb-sm">Контекстное меню строки Декларации</div>
        <div>
          Для быстрого доступа к некоторым функциям, нажмите правую кнопку мыши
          на строке Декларации.
        </div>
        <div>
          Если при добавлении Корректировки в появившемся окне нет значения в
          "Поле", значит для данного поля Корректировку создать нельзя.
        </div>
      </q-card-section>

      <q-card-section>
        <div class="text-h6 q-mb-sm">
          Копирование текста из всплывающей подсказки
        </div>
        <div>
          Чтобы скопировать текст из большинства всплывающих подсказок, кликните
          по иконке левой кнопкой мыши.
        </div>
        <img src="@/assets/img/tooltip-copy-to-clipboard.jpg" />
      </q-card-section>

      <q-card-section>
        <div class="text-h6 q-mb-sm">
          Разница ссылок "Файл домрф" и "Файл CDN"
        </div>
        <ul>
          <li>"Файл домрф" - текущий ПД файл, размещенный на сайте домрф.</li>
          <li>
            "Файл CDN" - копия ПД файла "Файл домрф", размещенная в этом
            сервисе.
          </li>
        </ul>
        <div>
          Источником данных для парса выступает именно "Файл CDN". В некоторых
          случаях ПД файлы могут отличаться, например, если домрф изменит файл
          по ссылке до очередного планового парса корпуса/списка файлов.
        </div>
      </q-card-section>

      <q-card-section>
        <div class="text-h6 q-mb-sm">Фильтр "Только актуальные"</div>
        <div>
          Если активировать этот фильтр, то в таблицу деклараций попадут
          последние по дате размещения записи в рамках корпусов.
        </div>
      </q-card-section>

      <q-card-section>
        <div class="text-h6 q-mb-sm">Откат примененных Корректировок</div>
        <div>
          Для точечного возврата к предыдущим значениям по какому-либо полю
          Декларации можно воспользоваться функцией Отката примененных
          Корректировок. При откате примененных Корректировок Правило
          корректировки с наивысшим приоритетом удалится. С другими приоритетами
          правила не удаляются автоматически и применятся при следующем парсе.
          Откатить можно только в порядке применения. Другими словами, если для
          поля были применены несколько корректировок, то при откате самого
          старого откатятся и все новейшие по дате. Такой порядок гарантирует
          корректную цепочку значений До-После.
        </div>
      </q-card-section>

      <q-card-actions class="q-pa-md flex justify-end">
        <q-btn
          v-close-popup
          label="Закрыть"
          color="primary"
          title="Закрыть окно"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
  export default {
    name: "DeclarationInfoDialog",

    emits: ["hide"],

    methods: {
      // following method is REQUIRED
      // (don't change its name --> "show")
      show() {
        this.$refs.dialog.show();
      },

      // following method is REQUIRED
      // (don't change its name --> "hide")
      hide() {
        this.$refs.dialog.hide();
      },

      onDialogHide() {
        this.$emit("hide");
      },
    },
  };
</script>
