<template>
  <div ref="observer">
    <form ref="form">
      <div
          ref="textInputProvider"

      >
        <q-input
            ref="commentInput"
            v-model="text"
            autogrow
            bottom-slots
            dense
            hide-bottom-space
            type="textarea"
        >
          <template v-slot:append>
            <q-btn dense flat label="🙂" @click="isEmojiPickerShowed = true">
              <q-menu>
                <EmojiPicker @select="onSelectEmoji"/>
              </q-menu>
            </q-btn>
          </template>
        </q-input>

        <div class="col-24 q-py-sm q-gutter-x-md">
          <q-btn
              :disable="
              awaitResponse || !(typeof text === 'string' && text.length)
            "
              :loading="awaitResponse"
              dense
              flat
              label="Отмена"
              type="button"
              @click="
              $store.commit('SET_COMMENT_EDITING', {
                state: false,
                id: commentId,
              })
            "
          />

          <q-btn
              :disable="
              awaitResponse || !(typeof text === 'string' && text.length)
            "
              :loading="awaitResponse"
              color="positive"
              dense
              label="Сохранить"
              type="button"
              @click="submitCommentForm"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import store from "@/store";
import emitter from "@/plugins/mitt";
import EmojiPicker from "@/components/EmojiPicker";
import api from "@/api";

export default {
  name: "EditComment",

  props: {
    commentId: Number,
    commentText: String,
  },

  components: {
    EmojiPicker,
  },

  data() {
    return {
      awaitResponse: false,
      text: null,
      isEmojiPickerShowed: false,
    };
  },

  async created() {
    this.text = this.commentText;
  },

  methods: {
    submitCommentForm() {

      this.awaitResponse = true;

      let data = {
        text: this.text,
      };

      api.comment
          .update(this.commentId, data, "commented")
          .then(
              (res) => {
                emitter.emit("comment-updated");
                store.commit("SET_COMMENT_EDITING", {
                  state: false,
                  id: null,
                });
              },
              (error) => {
                if (error.response.status !== 500) {
                  this.$q.notify({
                    color: "negative",
                    message: error.response.data.message,
                  });
                }
              }
          )
          .then(() => {
            this.awaitResponse = false;
          });

    },

    onSelectEmoji(emoji) {
      this.text += `${emoji} `;

      this.isEmojiPickerShowed = false;
    },
  },
};
</script>