<template>
  <div v-if="!loading" class="col bg-white rounded-borders shadow-2">
    <div style="height: 2px">
      <q-linear-progress v-show="awaitApBoxes" indeterminate size="2px" />
    </div>

    <div class="row q-px-md q-py-sm">
      <div class="col-24 flex items-center justify-between">
        <q-select
          v-model="search.related"
          :disable="awaitApBoxes"
          :options="relatedFilterOptions"
          emit-value
          label="Привязанные корпуса"
          map-options
          option-label="label"
          option-value="value"
          style="min-width: 200px"
          @update:model-value="setApBoxes(true)"
        />

        <div
          class="flex items-center"
          :class="$q.screen.xs || $q.screen.sm ? '' : 'justify-end'"
        >
          <q-btn
            :label="$q.screen.xs || $q.screen.sm ? '' : 'Справка'"
            flat
            icon="mdi-information-outline"
            @click="showTableNotesDialog"
          />

          <q-btn
            icon="mdi-cog"
            :label="$q.screen.xs || $q.screen.sm ? '' : 'Параметры'"
          >
            <q-menu touch-position transition-hide="" transition-show="">
              <q-list separator>
                <q-item clickable>
                  <q-item-section>
                    <q-select
                      v-model="visibleColumns"
                      :options="columns"
                      borderless
                      dense
                      display-value="Показывать столбцы"
                      emit-value
                      map-options
                      multiple
                      option-value="name"
                      options-dense
                      @update:model-value="onInputVisibleColumns"
                    >
                      <template v-slot:before>
                        <q-icon class="q-mr-sm" name="mdi-eye-check" />
                      </template>

                      <template v-slot:option="scope">
                        <q-item v-bind="scope.itemProps">
                          <q-item-section>
                            <q-item-label>{{ scope.opt.label }}</q-item-label>
                          </q-item-section>
                          <q-item-section side>
                            <q-icon v-if="scope.selected" name="mdi-check" />
                          </q-item-section>
                        </q-item>
                      </template>
                    </q-select>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
      </div>
    </div>

    <div class="row bg-white">
      <div class="col-24 q-px-md">
        <div
          aria-label="Корпуса АП"
          class="l-table l-table--sticky-first-column q-pb-sm"
          role="table"
          :style="getStylesForApBoxesTable()"
        >
          <div
            class="l-table__row min-w-full sticky-top bg-white"
            role="rowgroup"
            style="z-index: 2"
          >
            <template v-for="item in columns" :key="item.name">
              <div
                v-if="visibleColumns.includes(item.name)"
                :style="getCellStyle(item.name)"
                class="l-table__cell"
                role="columnheader"
              >
                <div
                  :class="item.sortName && 'cursor-pointer'"
                  class="text-body3"
                  @click="item.sortName && sortByColumn(item.sortName)"
                >
                  {{ item.label }}
                  <q-icon
                    v-if="pagination.sortBy === item.sortName"
                    :name="
                      pagination.descending ? 'mdi-arrow-down' : 'mdi-arrow-up'
                    "
                  />
                </div>
              </div>
            </template>
          </div>

          <ApBoxTableFiltersRow
            :cellStyles="cellStyles"
            :search="search"
            :visibleColumns="visibleColumns"
            @filter-boxes="filterApBoxes"
            @set-boxes="setApBoxes"
          />

          <div
            v-for="apBox in apBoxes"
            :key="apBox.id"
            :class="checkBoxHasBlueMark(apBox) ? 'l-table__row--blue' : ''"
            class="l-table__row min-w-full wrap"
          >
            <q-menu
              auto-close
              context-menu
              touch-position
              transition-hide=""
              transition-show=""
            >
              <q-list separator>
                <q-item clickable @click="toggleExpandedRow(apBox.id)">
                  <q-item-section v-if="!expandedRows.includes(apBox.id)"
                    >Показать корпуса ДомРФ
                  </q-item-section>
                  <q-item-section v-else>Скрыть корпуса ДомРФ</q-item-section>
                </q-item>

                <q-item class="q-pa-none" clickable>
                  <a
                    :href="getBuildingLink(apBox.serial)"
                    :title="apBox.name"
                    class="l-link flex self-stretch items-center cursor-pointer text-black q-px-md"
                    target="_blank"
                  >
                    Открыть в АП
                  </a>
                </q-item>

                <q-item clickable @click="expandedRows = []">
                  <q-item-section>Скрыть все</q-item-section>
                </q-item>
              </q-list>
            </q-menu>

            <div class="l-table__row min-w-full" role="rowgroup">
              <div
                v-if="visibleColumns.includes('id')"
                :style="getCellStyle('id')"
                class="l-table__cell"
              >
                {{ apBox.id }}
              </div>

              <div
                v-if="visibleColumns.includes('serial')"
                :style="getCellStyle('serial')"
                class="l-table__cell"
              >
                {{ apBox.serial }}
              </div>

              <div
                v-if="visibleColumns.includes('region_name')"
                :style="getCellStyle('region_name')"
                class="l-table__cell"
              >
                {{ apBox.region_name }}
              </div>

              <div
                v-if="visibleColumns.includes('name')"
                :style="getCellStyle('name')"
                :title="apBox.name"
                class="l-table__cell"
              >
                {{ apBox.name }}
              </div>

              <div
                v-if="visibleColumns.includes('project')"
                :style="getCellStyle('project')"
                class="l-table__cell"
              >
                {{ apBox.project }}
              </div>

              <div
                v-if="visibleColumns.includes('address')"
                :style="getCellStyle('address')"
                :title="apBox.address"
                class="l-table__cell"
              >
                {{ shortness(apBox.address, 80) }}
              </div>

              <div
                v-if="visibleColumns.includes('coordinates')"
                :style="getCellStyle('coordinates')"
                class="l-table__cell"
              >
                {{
                  apBox.coordinates &&
                  apBox.coordinates.length &&
                  apBox.coordinates.join(" ")
                }}
              </div>

              <div
                v-if="visibleColumns.includes('developer')"
                :style="getCellStyle('developer')"
                class="l-table__cell"
              >
                {{ apBox.developer }}
              </div>

              <div
                v-if="visibleColumns.includes('builder')"
                :style="getCellStyle('builder')"
                class="l-table__cell"
              >
                {{ apBox.builder }}
              </div>

              <div
                v-if="visibleColumns.includes('floor_max')"
                :style="getCellStyle('floor_max')"
                class="l-table__cell"
              >
                {{ apBox.floor_max }}
              </div>

              <div
                v-if="visibleColumns.includes('flat_qty')"
                :style="getCellStyle('flat_qty')"
                class="l-table__cell"
              >
                {{ apBox.flat_qty }}
              </div>

              <div
                v-if="visibleColumns.includes('parking_qty')"
                :style="getCellStyle('parking_qty')"
                class="l-table__cell"
              >
                {{ apBox.parking_qty }}
              </div>

              <div
                v-if="visibleColumns.includes('date_rve')"
                :style="getCellStyle('date_rve')"
                class="l-table__cell"
              >
                {{ apBox.date_rve }}
              </div>

              <div
                v-if="visibleColumns.includes('finishing_type')"
                :style="getCellStyle('finishing_type')"
                class="l-table__cell"
              >
                {{ apBox.finishing_type }}
              </div>

              <div
                v-if="visibleColumns.includes('agreement_type')"
                :style="getCellStyle('agreement_type')"
                class="l-table__cell"
              >
                {{ apBox.agreement_type }}
              </div>

              <div
                v-if="visibleColumns.includes('living_square')"
                :style="getCellStyle('living_square')"
                class="l-table__cell"
              >
                {{ apBox.living_square }}
              </div>

              <div
                v-if="visibleColumns.includes('ceiling_height')"
                :style="getCellStyle('ceiling_height')"
                class="l-table__cell"
              >
                {{ getCeilingHeight(apBox.ceiling_height) }}
              </div>

              <div
                v-if="visibleColumns.includes('cadastrals')"
                :style="getCellStyle('cadastrals')"
                class="l-table__cell"
              >
                {{ apBox.cadastrals && apBox.cadastrals.join(", ") }}
              </div>

              <div
                v-if="visibleColumns.includes('actions')"
                :style="getCellStyle('actions')"
                class="l-table__cell"
              >
                <div class="flex no-wrap">
                  <q-btn
                    :icon="
                      expandedRows.includes(apBox.id)
                        ? 'mdi-chevron-up'
                        : 'mdi-chevron-down'
                    "
                    flat
                    @click="toggleExpandedRow(apBox.id)"
                  />

                  <q-btn
                    :href="getBuildingLink(apBox.serial)"
                    alt="Перейти на страницу корпуса"
                    flat
                    icon="mdi-open-in-new"
                    target="_blank"
                    type="a"
                  />

                  <q-btn
                    v-if="+$can(['ap-box.delete'])"
                    :disable="awaitDeleting"
                    flat
                    icon="mdi-delete-outline"
                    title="Удалить АП корпус"
                    @click="deleteApBox(apBox.id)"
                  />

                  <q-btn
                    v-if="apBox.boxes && apBox.boxes.length"
                    :disable="awaitReCompare"
                    flat
                    icon="mdi-chart-bar-stacked"
                    title="Запустить пересчет сравнения"
                    @click="reCompare(apBox.id)"
                  />
                </div>
              </div>
            </div>

            <div
              v-show="expandedRows.includes(apBox.id)"
              class="l-table__row min-w-full bg-grey-2 inset-shadow"
              role="rowgroup"
            >
              <ExpandedBoxRow :apBoxId="apBox.id" :boxes="apBox.boxes" />
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="!apBoxes || (Array.isArray(apBoxes) && apBoxes.length === 0)"
        class="q-pa-md"
      >
        Нет данных
      </div>

      <div
        v-if="apBoxes && apBoxes.length"
        class="col-24 flex justify-end items-center q-px-md q-py-sm bg-white sticky-bottom shadow-up-3"
        style="z-index: 2"
      >
        <div class="text-body3 q-mr-md">
          Всего: <span class="text-body1">{{ pagination.rowsNumber }}</span>
        </div>

        <q-select
          v-model="pagination.rowsPerPage"
          :disable="awaitApBoxes"
          :options="[15, 50]"
          dense
          borderless
          @update:model-value="onRowsPerPageInput"
        >
          <template v-slot:before>
            <q-icon name="mdi-eye-outline" size="sm" />
          </template>
        </q-select>

        <q-pagination
          v-model="pagination.page"
          :disable="awaitApBoxes"
          :input="true"
          :max="Math.ceil(pagination.rowsNumber / pagination.rowsPerPage)"
          :model-value="pagination.page"
          @update:model-value="onPaginationInput"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import lf from "@/plugins/localforage";
  import api from "@/api";
  import emitter from "@/plugins/mitt";
  import {
    normalizeQueryForRequest,
    getApBuildingLink,
    formatCeilingHeight,
  } from "@/utils/batch";
  import { shortness } from "@/plugins/filters";
  import { createMetaMixin } from "quasar";
  import ApBoxTableFiltersRow from "@/components/apBoxes/ApBoxTableFiltersRow";
  import ExpandedBoxRow from "@/components/apBoxes/ExpandedBoxRow";

  export default {
    name: "ApBoxList",

    components: {
      ApBoxTableFiltersRow,
      ExpandedBoxRow,
    },

    mixins: [
      createMetaMixin(function () {
        return {
          title: "АП корпуса",
        };
      }),
    ],

    data() {
      return {
        loading: true,
        awaitApBoxes: false,
        awaitReCompare: false,
        awaitDeleting: false,
        apBoxes: [],
        expandedRows: [],
        pagination: {
          rowsNumber: null,
          rowsPerPage: 15,
          sortBy: "serial",
          descending: true,
          page: 1,
        },
        visibleColumns: [
          "serial",
          "region_name",
          "project",
          "address",
          "builder",
          "floor_max",
          "flat_qty",
          "date_rve",
          "living_square",
          "actions",
        ],
        columns: [
          {
            name: "id",
            label: "ID",
            visible: true,
            sortName: "id",
            style: "width: 80px;",
          },
          {
            name: "serial",
            label: "Номер",
            visible: true,
            sortName: "serial",
            style: "width: 80px;",
          },
          {
            name: "region_name",
            label: "Регион",
            visible: true,
            sortName: "region_name",
            style: "width: 160px;",
          },
          {
            name: "name",
            label: "Название",
            visible: true,
            sortName: "name",
            style: "width: 160px;",
          },
          {
            name: "project",
            label: "Проект",
            visible: true,
            sortName: "project",
            style: "width: 160px;",
          },
          {
            name: "address",
            label: "Адрес",
            visible: true,
            sortName: "address",
            style: "width: 300px;",
          },
          {
            name: "coordinates",
            label: "Координаты",
            visible: true,
            style: "width: 160px;",
          },
          {
            name: "developer",
            label: "Девелопер",
            visible: true,
            sortName: "developer",
            style: "width: 160px;",
          },
          {
            name: "builder",
            label: "Застройщик",
            visible: true,
            sortName: "builder",
            style: "width: 160px;",
          },
          {
            name: "floor_max",
            label: "Этажность",
            visible: true,
            sortName: "floor_max",
            style: "width: 100px;",
          },
          {
            name: "flat_qty",
            label: "Квартир",
            visible: true,
            sortName: "flat_qty",
            style: "width: 100px;",
          },
          {
            name: "parking_qty",
            label: "Машиномест",
            visible: true,
            sortName: "parking_qty",
            style: "width: 100px;",
          },
          {
            name: "date_rve",
            label: "РВЭ",
            visible: true,
            sortName: "date_rve",
            style: "width: 140px;",
          },
          {
            name: "finishing_type",
            label: "Отделка",
            visible: true,
            sortName: "finishing_type",
            style: "width: 160px;",
          },
          {
            name: "agreement_type",
            label: "Тип договора",
            visible: true,
            sortName: "agreement_type",
            style: "width: 160px;",
          },
          {
            name: "living_square",
            label: "Жилая площадь",
            visible: true,
            sortName: "living_square",
            style: "width: 100px;",
          },
          {
            name: "ceiling_height",
            label: "Высота потолков",
            visible: true,
            style: "width: 120px;",
          },
          {
            name: "cadastrals",
            label: "Кадастр",
            visible: true,
            style: "width: 160px;",
          },
          {
            name: "actions",
            label: "Действия",
            visible: true,
            style: "width: 230px;",
          },
        ],
        search: {
          id: { c: "eq", v: null },
          serial: { c: "ctn", v: null },
          region_name: { c: "ctn", v: null },
          name: { c: "ctn", v: null },
          project: { c: "ctn", v: null },
          address: { c: "ctn", v: null },
          developer: { c: "ctn", v: null },
          builder: { c: "ctn", v: null },
          floor_max: { c: "eq", v: null },
          flat_qty: { c: "eq", v: null },
          parking_qty: { c: "eq", v: null },
          date_rve: {
            from: null,
            to: null,
          },
          finishing_type: { c: "ctn", v: null },
          agreement_type: { c: "ctn", v: null },
          living_square: { c: "eq", v: null },
          ceiling_height: null,
          related: null,
          cadastrals: null,
        },
        cellStyles: {},
      };
    },

    computed: {
      relatedFilterOptions() {
        return [
          { label: "Любые", value: null },
          { label: "Только проблемные", value: "problem" },
          { label: "Только непроблемные", value: "not-problem" },
          { label: "Есть привязанные", value: "linked" },
          { label: "Нет привязанных", value: "not-linked" },
        ];
      },
    },

    created() {
      emitter.on("boxes-linked", this.setApBoxes);
      emitter.on("box-unlinked", this.setApBoxes);
    },

    async mounted() {
      this.columns = this.columns.filter((item) => item.visible);
      await this.checkVisibleColumns();
      this.updateColumnStyles(this.columns);
      await this.loadRowsPerPageParamFromStorage();

      this.loading = false;
    },

    unmounted() {
      emitter.off("boxes-linked", this.setApBoxes);
      emitter.off("box-unlinked", this.setApBoxes);
    },

    methods: {
      filterApBoxes() {
        this.setApBoxes(true);
      },

      async setApBoxes(isFiltering = false) {
        this.awaitApBoxes = true;

        // we can't send filtering request from page greater then 1
        if (isFiltering) {
          this.pagination.page = 1;
        }

        let paginateOptions = {
          q: normalizeQueryForRequest(this.search),
          sort_by: this.pagination.sortBy,
          descending: this.pagination.descending,
          limit: this.pagination.rowsPerPage,
          page: this.pagination.page,
        };

        let res = await api.apBox.find(paginateOptions, "boxes");

        if (res.status === 200 && res.data.apboxes) {
          this.apBoxes = res.data.apboxes;
          this.pagination.rowsNumber = res.data.meta.pagination.total;
        }

        if (res.status === 204) {
          this.apBoxes = [];
        }

        this.awaitApBoxes = false;
      },

      sortByColumn(field) {
        this.pagination.sortBy = field;
        this.pagination.descending = !this.pagination.descending;

        this.setApBoxes();
      },

      onPaginationInput(page) {
        this.setApBoxes();

        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      },

      getCellStyle(columnName) {
        return this.cellStyles[columnName];
      },

      updateColumnStyles(columns) {
        let obj = {};

        columns.forEach((item) => {
          obj[item.name] = item.style;
        });

        this.cellStyles = obj;
      },

      async checkVisibleColumns() {
        let visibleColumns = await lf.getItem("ap_boxes_table_visible_columns");

        if (visibleColumns) {
          const columnNames = this.columns.map((item) => item.name);

          // remove old column names
          visibleColumns.forEach((name, index) => {
            if (!columnNames.includes(name)) {
              delete visibleColumns[index];
            }
          });

          this.visibleColumns = visibleColumns;
          await this.saveVisibleColumnsInStorage(visibleColumns);
        }
      },

      async saveVisibleColumnsInStorage(values) {
        try {
          await lf.setItem("ap_boxes_table_visible_columns", values);
        } catch (e) {
          this.$q.notify({
            color: "negative",
            message:
              "Хранилище браузера недоступно. Пожалуйста, проверьте настройки.",
            timeout: 60000,
          });
        }
      },

      onInputVisibleColumns(values) {
        this.updateColumnStyles(this.columns);
        this.saveVisibleColumnsInStorage(values);
      },

      toggleExpandedRow(id) {
        if (this.expandedRows.includes(id)) {
          let index = this.expandedRows.indexOf(id);
          this.expandedRows.splice(index, 1);
        } else {
          this.expandedRows.push(id);
        }
      },

      async loadRowsPerPageParamFromStorage() {
        const rowsPerPage = await lf.getItem("ap_boxes_table_rows_per_page");

        if (rowsPerPage && rowsPerPage !== this.pagination.rowsPerPage) {
          this.pagination.rowsPerPage = rowsPerPage;
        }
      },

      async onRowsPerPageInput(val) {
        try {
          await lf.setItem("ap_boxes_table_rows_per_page", val);
        } catch (e) {
          this.$q.notify({
            color: "negative",
            message:
              "Хранилище браузера недоступно. Пожалуйста, проверьте настройки.",
            timeout: 60000,
          });
        }

        await this.setApBoxes();
      },

      checkBoxHasBlueMark(apBox) {
        if (apBox.boxes && apBox.boxes.length) {
          let problemBoxes = apBox.boxes.filter((item) =>
            item.flags.includes("problem")
          );

          return problemBoxes && problemBoxes.length;
        }

        return false;
      },

      getBuildingLink(serial) {
        return getApBuildingLink(serial);
      },

      showTableNotesDialog() {
        this.$q.dialog({
          title: "Пояснения к таблице",
          message:
            "Синяя подсветка строки - в этом АП корпусе есть привязанные проблемные корпуса ДомРФ.\n",
        });
      },

      reCompare(apBoxId) {
        this.awaitReCompare = true;

        api.apBox
          .reCompare(apBoxId)
          .then(
            (res) => {
              this.$q.notify({
                color: "positive",
                message: res.data.message,
              });
            },
            (error) => {
              this.$q.notify({
                color: "negative",
                message: error.response.data.message,
              });
            }
          )
          .finally(() => {
            this.awaitReCompare = false;
          });
      },

      deleteApBox(id) {
        this.$q
          .dialog({
            title: "Удаление АП корпуса",
            message: "Вы уверены?",
            ok: {
              label: "Удалить",
            },
          })
          .onOk(async () => {
            this.awaitDeleting = true;

            await api.apBox
              .delete(id)
              .then(
                (res) => {
                  this.setApBoxes();
                },
                (error) => {
                  this.$q.notify({
                    color: "negative",
                    message: error.response.data.message,
                  });
                }
              )
              .then(() => {
                this.awaitDeleting = false;
              });
          });
      },

      shortness(value, length) {
        return shortness(value, length);
      },

      getStylesForApBoxesTable() {
        if (this.$q.screen.xs || this.$q.screen.sm) {
          return "";
        }

        const height =
          this.$store.state.windowInnerHeight - (this.$q.screen.md ? 245 : 210);

        return `max-height: ${height}px`;
      },

      getCeilingHeight(value) {
        return formatCeilingHeight(value);
      },
    },
  };
</script>
