<template>
  <div v-if="!loading">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
  import {
    isAccessTokenExpiresSoon,
    reloadPageIfTokenExpireSoonInterval,
    removeAuthDataFromStorage,
  } from "@/utils/batch";
  import { configureScope as SentryConfigureScope } from "@sentry/vue";
  import api from "@/api";
  import store from "@/store";
  import { createMetaMixin } from "quasar";
  import debounce from "lodash.debounce";
  import lf from "@/plugins/localforage";

  export default {
    name: "App",

    mixins: [
      createMetaMixin(() => {
        return {
          title: "Сервис Сравнения",
        };
      }),
    ],

    computed: {
      layout() {
        return (this.$route.meta.layout || "main") + "-layout";
      },
    },

    data() {
      return {
        loading: true,
      };
    },

    created() {
      window.addEventListener("storage", this.syncAuth);
    },

    async mounted() {
      this.windowInnerHeight();
      window.addEventListener("resize", debounce(this.windowInnerHeight, 50));

      reloadPageIfTokenExpireSoonInterval();

      const [resPdFields, resBoxMarks, resBoxBells, resQueuesWorkload] =
        await Promise.all([
          api.declaration.fields(),
          api.boxMark.find(),
          api.boxBell.find(),
          api.utils.queuesWorkload(),
        ]);

      store.commit("SET_PD_FIELDS", resPdFields.data);
      store.commit("SET_BOX_MARKS", resBoxMarks.data);
      store.commit("SET_BOX_BELLS", resBoxBells.data);
      store.commit("SET_QUEUES_WORKLOAD", resQueuesWorkload.data);

      setInterval(async function () {
        const resQueuesWorkload = await api.utils.queuesWorkload();
        store.commit("SET_QUEUES_WORKLOAD", resQueuesWorkload.data);
      }, 10000);

      this.loading = false;
    },

    unmounted() {
      window.removeEventListener("resize", this.windowInnerHeight);
      window.removeEventListener("storage", this.syncAuth);
    },

    methods: {
      windowInnerHeight() {
        this.$store.commit("SET_WINDOW_INNER_HEIGHT", window.innerHeight);
      },

      /**
       * Sync auth between browser's opened pages (windows) on user login/logout.
       *
       * Pros:
       * + prevent unauth access to backend resources if user logout from some page;
       * + auto login for all pages;
       * + keep one access token for all windows.
       *
       * NOTE: localStorage is universal cross-browser realisation.
       * @param event
       * @return {Promise<void>}
       */
      async syncAuth(event) {
        if (event.key === "logout" && event.newValue === "true") {
          await removeAuthDataFromStorage();

          this.$store.commit("auth/UNSET_USER");
          this.$store.commit("auth/UNSET_PERMISSIONS");

          SentryConfigureScope((scope) => {
            scope.setUser({});
          });

          await this.$router.push({ name: "Login" });
        }

        if (event.key === "login" && event.newValue === "true") {
          // no need to copy-paste code from store's login action, cause
          // we update auth in reloadPageIfTokenExpireSoonInterval() in the same way.
          window.location.reload();
        }
      },
    },
  };
</script>
