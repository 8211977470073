import auth from "@/router/middlewares/auth";
import checkPermissions from "@/router/middlewares/checkPermissions";

import ApProjects from "@/pages/apProjects/ApProjects";
import ApProjectList from "@/pages/apProjects/ApProjectList";
import ApProjectEdit from "@/pages/apProjects/ApProjectEdit";

const apProjectRoutes = [
  {
    path: "/ap-projects",
    component: ApProjects,
    beforeEnter: [auth, checkPermissions],
    children: [
      {
        path: "",
        component: ApProjectList,
        name: "ApProjectList",
        meta: {
          layout: "main",
          permissions: ["ap-project.view"],
          crumbs: "/АП проекты",
        },
      },
      {
        path: ":serial",
        name: "ApProjectEdit",
        component: ApProjectEdit,
        meta: {
          layout: "main",
          permissions: ["ap-project.update"],
          crumbs: "/АП проекты/{:}",
        },
        props: (route) => ({ serial: route.params.serial }),
      },
    ],
  },
];

export default apProjectRoutes;
