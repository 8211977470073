<template>
  <div class="row q-col-gutter-x-md q-pb-lg">
    <div class="col-24">
      <BoxHeader :box="box" />
    </div>

    <div class="col-24">
      <q-table
        :columns="columns"
        :filter="filter"
        :rows="box.logs || []"
        :rows-per-page-options="[0]"
        dense
        row-key="id"
        :pagination="pagination"
      >
        <template v-slot:top-left>
          <q-input v-model="filter" color="primary" debounce="300" dense>
            <template v-slot:append>
              <q-icon name="mdi-magnify" />
            </template>
          </q-input>
        </template>

        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td key="status" :props="props">{{ props.row.status }}</q-td>
            <q-td key="message" :props="props">{{ props.row.message }}</q-td>
            <q-td key="created_at" :props="props"
              >{{ props.row.created_at }}
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>
  </div>
</template>

<script>
  import BoxHeader from "@/components/boxes/BoxHeader";

  export default {
    name: "BoxLogs",

    components: { BoxHeader },

    props: {
      box: {
        type: Object,
        required: true,
      },
    },

    computed: {
      columns() {
        return [
          {
            name: "status",
            required: true,
            label: "Статус",
            align: "left",
            field: (row) => row.status,
            sortable: true,
          },
          {
            name: "message",
            required: true,
            label: "Сообщение",
            align: "left",
            field: (row) => row.message,
            sortable: true,
          },
          {
            name: "created_at",
            required: true,
            label: "Дата",
            align: "left",
            field: (row) => row.created_at,
            sortable: true,
          },
        ];
      },
    },

    data() {
      return {
        filter: "",
        pagination: {
          sortBy: "created_at",
          descending: true,
          rowsPerPage: 0,
        },
      };
    },
  };
</script>
