<template>
  <q-dialog
    ref="dialog"
    position="top"
    transition-hide="slide-up"
    transition-show="slide-down"
  >
    <q-card style="width: 900px; max-width: 900px">
      <div class="flex items-center justify-between q-px-md">
        <div>
          История параметра <b>{{ paramName }}</b>
        </div>
        <q-btn v-close-popup flat icon="mdi-close" size="lg" />
      </div>

      <q-card-section>
        <template v-for="(item, key) in paramHistory" :key="key">
          <div v-if="!(item.old_value == null && item.new_value == null)">
            <span>{{ item.created_at }}</span>
            :
            <span class="bg-deep-orange-2">{{ item.old_value }}</span>
            <q-icon name="mdi-arrow-right" />
            <span class="bg-green-2">{{ item.new_value }}</span>
          </div>
        </template>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
  export default {
    name: "DeclarationParameterHistoryDialog",

    props: {
      paramHistory: {
        type: Array,
        required: true,
      },
      paramName: {
        type: String,
        required: true,
      },
    },

    methods: {
      // following method is REQUIRED
      // (don't change its name --> "show")
      show() {
        this.$refs.dialog.show();
      },

      // following method is REQUIRED
      // (don't change its name --> "hide")
      hide() {
        this.$refs.dialog.hide();
      },
    },
  };
</script>
