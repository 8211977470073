<template>
  <q-dialog
    ref="dialog"
    position="top"
    transition-hide="slide-up"
    transition-show="slide-down"
  >
    <q-card style="width: 700px; max-width: 700px">
      <div class="flex items-center justify-between q-px-md">
        <div>Изменить кадастры Декларации ID: {{ declaration.id }}</div>
        <q-btn v-close-popup flat dense icon="mdi-close" size="lg" />
      </div>

      <q-card-section>
        <div class="flex q-gutter-x-md q-mb-md">
          <q-btn
            :disabled="awaitResponse"
            icon="mdi-plus"
            title="Добавить"
            label="Добавить"
            @click="addCadastral"
          />

          <q-btn
            icon="mdi-content-copy"
            title="Скопировать"
            label="Скопировать"
            @click="copyToBuffer"
          />

          <q-btn
            :disabled="awaitResponse"
            icon="mdi-content-paste"
            title="Вставить из буфера"
            label="Вставить из буфера"
            @click="pasteFromBuffer"
          />
        </div>

        <div
          v-for="(cadastral, index) in cadastrals"
          :key="index"
          class="flex no-wrap items-center"
        >
          <q-input
            v-model="cadastral.number"
            :disable="awaitResponse"
            clearable
            filled
            label="Номер"
          />

          <q-input
            v-model="cadastral.square"
            :disable="awaitResponse"
            clearable
            filled
            label="Площадь"
          />

          <q-btn
            :disabled="awaitResponse"
            icon="mdi-delete-outline"
            title="удалить"
            flat
            @click="removeCadastral(cadastral)"
          />
        </div>
      </q-card-section>

      <q-card-actions>
        <q-btn
          :disable="awaitResponse"
          :loading="awaitResponse"
          color="primary"
          label="Сохранить"
          title="Сохранить"
          class="full-width"
          @click="save"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
  import api from "@/api";
  import emitter from "@/plugins/mitt";
  import cloneDeep from "lodash.clonedeep";
  import { copyToClipboard } from "quasar";

  export default {
    name: "DeclarationEditConstructionStagesDialog",

    props: {
      declaration: {
        type: Object,
        required: true,
      },
      columnName: {
        type: String,
      },
      fieldValue: {
        required: false,
      },
    },

    created() {
      this.cadastrals = cloneDeep(this.fieldValue);
    },

    data() {
      return {
        awaitResponse: false,
        cadastrals: [],
      };
    },

    methods: {
      save() {
        this.awaitResponse = true;

        api.declaration
          .updateField(this.declaration.id, this.columnName, this.cadastrals)
          .then(
            (res) => {
              this.$q.notify({
                color: "positive",
                message: res.data.message,
              });

              emitter.emit("declaration-updated");
              this.hide();
            },
            (error) => {
              this.$q.notify({
                color: "negative",
                message: error.response.data.message,
              });

              if (error.response.status === 422) {
                Object.keys(error.response.data.errors).forEach((name, i) => {
                  this.$q.notify({
                    color: "negative",
                    message: error.response.data.errors[name][i],
                    timeout: 10000,
                  });
                });
              }
            }
          )
          .then(() => {
            this.awaitResponse = false;
          });
      },

      copyToBuffer() {
        copyToClipboard(JSON.stringify(this.cadastrals))
          .then(() => {
            this.$q.notify({
              color: "positive",
              message: "Скопировано в буфер!",
            });
          })
          .catch(() => {
            this.$q.notify({
              color: "negative",
              message: "Копирование в буфер не выполнено",
            });
          });
      },

      async pasteFromBuffer() {
        let data = await navigator.clipboard.readText();

        if (data.length === 0) {
          this.$q.notify({
            color: "negative",
            message: "Буфер пуст",
          });

          return;
        }

        try {
          this.cadastrals = JSON.parse(data);

          this.$q.notify({
            color: "positive",
            message: "Данные из буфера загружены!",
          });
        } catch (e) {
          this.$q.notify({
            color: "negative",
            message: "Некорретные данные в буфере",
          });
        }
      },

      addCadastral() {
        if (!this.cadastrals) {
          this.cadastrals = [];
        }

        this.cadastrals.push({
          number: null,
          square: null,
        });
      },

      removeCadastral(item) {
        const index = this.cadastrals.indexOf(item);
        this.cadastrals.splice(index, 1);
      },

      // following method is REQUIRED
      // (don't change its name --> "show")
      show() {
        this.$refs.dialog.show();
      },

      // following method is REQUIRED
      // (don't change its name --> "hide")
      hide() {
        this.$refs.dialog.hide();
      },
    },
  };
</script>
