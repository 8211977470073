import store from "@/store";
import lf from "@/plugins/localforage";
import { actions as authActions } from "@/store/auth/actions";

const serfRoutesDeniedForAuthUsers = ["Login"];

export default async function (to) {
  const accessToken = await lf.getItem("access_token");
  const refreshToken = await lf.getItem("refresh_token");
  const user = store.getters["auth/user"];

  if (accessToken && refreshToken) {
    if (user) {
      if (serfRoutesDeniedForAuthUsers.includes(to.name)) {
        return "/";
      }

      return true;
    } else {
      await store.dispatch(`auth/${authActions.fetchUser}`);
    }

    return { name: to.name, params: to.params, query: to.query };
  }

  if (to.name === "Login") {
    if (user) {
      return "/";
    }

    // avoid redirect loops to login page when not auth
    return true;
  }

  return { name: "Login" };
}
