<template>
  <q-layout view="hHh lpR fFf">
    <q-page-container>
      <q-page>
        <router-view />
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script>
  export default {
    name: "BlankLayout",
  };
</script>