<template>
  <div class="q-pa-md">
    <div
      v-if="boxes && boxes.length"
      aria-label="Корпуса ДомРФ"
      class="l-table q-table--bordered bg-white"
      role="table"
    >
      <div class="l-table__row min-w-full" role="rowgroup">
        <div
          v-for="column in columns"
          :key="column.name"
          :style="column.style"
          class="l-table__cell"
          role="columnheader"
        >
          <div class="text-body3">{{ column.label }}</div>
        </div>
      </div>

      <div
        v-for="box in boxes"
        :key="box.id"
        :class="box.flags.includes('problem') ? 'l-table__row--blue' : ''"
        class="l-table__row min-w-full"
        role="rowgroup"
      >
        <div :style="getColumnStyle('serial')" class="l-table__cell">
          {{ box.serial }}
        </div>

        <div :style="getColumnStyle('name')" class="l-table__cell">
          {{ box.name }}
        </div>

        <div :style="getColumnStyle('builder')" class="l-table__cell">
          {{ box.builder }}
        </div>

        <div :style="getColumnStyle('floor_max')" class="l-table__cell">
          {{ box.floor_max }}
        </div>

        <div :style="getColumnStyle('flat_qty')" class="l-table__cell">
          {{ box.flat_qty }}
        </div>

        <div :style="getColumnStyle('parking_qty')" class="l-table__cell">
          {{ box.parking_qty }}
        </div>

        <div :style="getColumnStyle('date_rve')" class="l-table__cell">
          {{ box.date_rve }}
        </div>

        <div :style="getColumnStyle('ceiling_height')" class="l-table__cell">
          {{ getCeilingHeight(box.ceiling_height) }}
        </div>

        <div :style="getColumnStyle('finishing_type')" class="l-table__cell">
          {{ box.finishing_type }}
        </div>

        <div :style="getColumnStyle('living_square')" class="l-table__cell">
          {{ box.living_square }}
        </div>

        <div :style="getColumnStyle('flags_readable')" class="l-table__cell">
          {{
            box.flags_readable &&
            box.flags_readable.length &&
            box.flags_readable.join(", ")
          }}
        </div>

        <div :style="getColumnStyle('cadastrals')" class="l-table__cell">
          {{ box.cadastrals && box.cadastrals.join(", ") }}
        </div>

        <div :style="getColumnStyle('actions')" class="l-table__cell">
          <div class="flex no-wrap">
            <q-btn
              :disable="loading"
              flat
              icon="mdi-link-variant-off"
              title="Отвязать"
              @click="unlink(box.serial)"
            />

            <q-btn
              :href="getBuildingLink(box.serial)"
              alt="Перейти на страницу корпуса"
              flat
              icon="mdi-open-in-new"
              target="_blank"
              type="a"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="q-py-md">
      <q-btn
        class="bg-white"
        icon="mdi-link-variant-plus"
        label="Привязать"
        @click="link"
      />
    </div>
  </div>
</template>

<script>
  import LinkBoxesDialog from "@/components/dialogs/LinkBoxesDialog";
  import { formatCeilingHeight, getDomRfBuildingLink } from "@/utils/batch";
  import api from "@/api";
  import emitter from "@/plugins/mitt";

  export default {
    name: "ExpandedBoxRow",

    components: {
      LinkBoxesDialog,
    },

    props: {
      apBoxId: {
        type: Number,
        required: true,
      },
      boxes: {
        type: Array,
        required: false,
      },
    },

    data() {
      return {
        loading: false,
        columns: [
          {
            name: "serial",
            label: "Номер",
            style: "width: 80px;",
          },
          {
            name: "name",
            label: "Название",
            style: "width: 160px;",
          },
          {
            name: "builder",
            label: "Застройщик",
            style: "width: 160px;",
          },
          {
            name: "floor_max",
            label: "Этажность",
            style: "width: 100px;",
          },
          {
            name: "flat_qty",
            label: "Квартир",
            style: "width: 100px;",
          },
          {
            name: "parking_qty",
            label: "Машиномест",
            style: "width: 100px;",
          },
          {
            name: "date_rve",
            label: "РВЭ",
            style: "width: 160px;",
          },
          {
            name: "ceiling_height",
            label: "Высота потолков",
            style: "width: 120px;",
          },
          {
            name: "finishing_type",
            label: "Отделка",
            style: "width: 160px;",
          },
          {
            name: "living_square",
            label: "Жилая площадь",
            style: "width: 160px;",
          },
          {
            name: "flags_readable",
            label: "Дополнительно",
            style: "width: 160px;",
          },
          {
            name: "cadastrals",
            label: "Кадастр",
            style: "width: 160px;",
          },
          {
            name: "actions",
            label: "",
            style: "width: 100px;",
          },
        ],
      };
    },

    methods: {
      getColumnStyle(col) {
        return this.columns.filter((obj) => obj.name === col)[0].style;
      },

      async unlink(boxSerial) {
        this.loading = true;

        await api.apBox
          .unlink(this.apBoxId, boxSerial)
          .then((res) => {
            this.$q.notify({
              color: "positive",
              message: res.data.message,
            });

            emitter.emit("box-unlinked");
          })
          .finally(() => {
            this.loading = false;
          });
      },

      link() {
        this.$q.dialog({
          component: LinkBoxesDialog,
          componentProps: {
            apBoxId: this.apBoxId,
          },
        });
      },

      getBuildingLink(serial) {
        return getDomRfBuildingLink(serial);
      },

      getCeilingHeight(value) {
        return formatCeilingHeight(value);
      },
    },
  };
</script>
