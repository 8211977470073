<template>
  <q-dialog
    ref="dialog"
    position="top"
    transition-hide="slide-up"
    transition-show="slide-down"
    @hide="onDialogHide"
  >
    <q-card style="width: 600px; max-width: 600px">
      <div class="flex items-center justify-between q-px-md">
        <div>
          Добавить Корректировку для Декларации ID: {{ declaration.id }}
        </div>
        <q-btn v-close-popup flat dense icon="mdi-close" size="lg" />
      </div>

      <q-card-section>
        <DeclarationCorrectionUpdateForm
          :box-selected-initial="declaration.box"
          :pd-file-selected-initial="declaration.pd_file"
          :field-name="columnName"
          :field-value="fieldValue"
          @correction-saved="onCorrectionSaved"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
  import emitter from "@/plugins/mitt";
  import DeclarationCorrectionUpdateForm from "@/components/DeclarationCorrectionUpdateForm";

  export default {
    name: "DeclarationCorrectionAddDialog",

    components: { DeclarationCorrectionUpdateForm },

    emits: ["hide"],

    props: {
      declaration: {
        type: Object,
        required: true,
      },
      columnName: {
        type: String,
      },
      fieldValue: {
        required: false,
      },
    },

    data() {
      return {
        awaitResponse: false,
      };
    },

    methods: {
      onCorrectionSaved(val) {
        if (val) {
          emitter.emit("set-declarations");
        }

        this.hide();
      },

      // following method is REQUIRED
      // (don't change its name --> "show")
      show() {
        this.$refs.dialog.show();
      },

      // following method is REQUIRED
      // (don't change its name --> "hide")
      hide() {
        this.$refs.dialog.hide();
      },

      onDialogHide() {
        this.$emit("hide");
      },
    },
  };
</script>
