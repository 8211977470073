<template>
    <div class="bg-white  rounded-borders shadow-2 col-24 q-px-md q-pt-md">
        <q-btn
                icon="mdi-history"
                label="История изменений"
                @click="showCadastralHistoryDialog(id)"
        />
        <div class="col">
            <div class="flex wrap q-gutter-y-md">
                <div class="full-width">

                    <div class="row shadow-2">
                        <div class="col q-pa-sm">
                            <q-input
                                    v-if="isEdit"
                                    v-model.trim="id"
                                    :disable="true"
                                    bottom-slots
                                    clearable
                                    dense
                                    label="ID"
                                    readonly
                            />

                            <div class="history_input_wrapper">
                                <q-input
                                        v-model.trim="cadastral"
                                        :disable="awaitResponse"
                                        :error="v$.cadastral.$error"
                                        :error-message="
              v$.cadastral.$error ? v$.cadastral.$errors[0].$message : ''
            "
                                        autofocus
                                        bottom-slots
                                        clearable
                                        dense
                                        label="Кадастр"
                                        :readonly="isEdit"
                                />
                            </div>
                            <div class="history_button_wrapper">
                                <q-btn
                                        icon="mdi-history"
                                        text-color="black"
                                        size="sm"
                                        @click="showCadastralHistoryDialog(id)"
                                />
                            </div>

                            <q-select
                                    :popup-content-style="{ width: '350px' }"
                                    v-model="region_name"
                                    :disable="awaitResponse"
                                    :error="v$.region_name.$error"
                                    :error-message="
            v$.region_name.$error ? v$.region_name.$errors[0].$message : ''
          "
                                    label="Регион"
                                    emit-value
                                    map-options
                                    new-value-mode="add-unique"
                                    use-chips
                                    use-input
                                    :options="regionsOptions"
                                    @filter="filterFn"
                            />
                        </div>

                        <div class="col q-pa-sm">
                            <div class="q-field__label" style="font-size:12px">ID корпусов АП</div>
                            <span v-for="ap in ap_id" class="inline-block">
                                <a  :href="getBuildingLink(host, ap)" target="_blank" class="building-link">
                                    {{ap}}
                                </a>
                            </span>

                            <div class="q-field__label" style="font-size:12px">ID корпусов ДОМРФ</div>
                            <span v-for="id in domrf_id" class="inline-block">
                                <a  :href="'https://xn--80az8a.xn--d1aqf.xn--p1ai/%D1%81%D0%B5%D1%80%D0%B2%D0%B8%D1%81%D1%8B/%D0%BA%D0%B0%D1%82%D0%B0%D0%BB%D0%BE%D0%B3-%D0%BD%D0%BE%D0%B2%D0%BE%D1%81%D1%82%D1%80%D0%BE%D0%B5%D0%BA/%D0%BE%D0%B1%D1%8A%D0%B5%D0%BA%D1%82/' + id"
                                    target="_blank" class="building-link">
                                    {{id}}
                                </a>
                            </span>

                            <q-input
                                    v-if="isEdit"
                                    v-model.trim="created_at"
                                    :disable="awaitResponse"
                                    :error="v$.created_at.$error"
                                    :error-message="
              v$.created_at.$error ? v$.created_at.$errors[0].$message : ''
            "
                                    bottom-slots
                                    clearable
                                    dense
                                    label="Дата создания"
                                    readonly
                            />
                            <q-input
                                    v-if="isEdit"
                                    v-model.trim="updated_at"
                                    :disable="awaitResponse"
                                    :error="v$.updated_at.$error"
                                    :error-message="
              v$.updated_at.$error ? v$.updated_at.$errors[0].$message : ''
            "
                                    bottom-slots
                                    clearable
                                    dense
                                    label="Дата обновления"
                                    readonly
                            />
                        </div>
                    </div>

                    <br>
                    <div class="row ">
                        <div class="col q-pa-sm" v-if="isEdit">
                            <q-card class="my-card bg-secondary text-white"
                                    style="min-width: 350px!important;
                                background-color: rgb(255 255 255) !important;
                                border: 5px solid #ffa570;">
                                <q-card-section>

                                    <div class="history_input_wrapper">
                                        <q-select
                                                v-model="projects"
                                                :disable="awaitResponse"
                                                :error="v$.projects.$error"
                                                :error-message="
            v$.projects.$error ? v$.projects.$errors[0].$message : ''
          "
                                                label="Проект"
                                                multiple
                                                new-value-mode="add-unique"
                                                use-chips
                                                use-input
                                                readonly
                                        />
                                    </div>
                                    <div class="history_button_wrapper">
                                        <q-btn
                                                icon="mdi-history"
                                                text-color="black"
                                                size="sm"
                                                @click="showCadastralHistoryDialog(id)"
                                        />
                                    </div>
                                    <q-select
                                            v-model="addresses"
                                            :disable="awaitResponse"
                                            :error="v$.addresses.$error"
                                            :error-message="
            v$.addresses.$error ? v$.addresses.$errors[0].$message : ''
          "
                                            label="Адреса"
                                            multiple
                                            new-value-mode="add-unique"
                                            use-chips
                                            use-input
                                            readonly
                                    />

                                    <q-select
                                            v-model="ap_objectclass_id"
                                            :disable="awaitResponse"
                                            :error="v$.ap_objectclass_id.$error"
                                            :error-message="
            v$.ap_objectclass_id.$error ? v$.ap_objectclass_id.$errors[0].$message : ''
          "
                                            label="Класс"
                                            :options="classes"
                                            emit-value
                                            map-options
                                            multiple
                                            new-value-mode="add-unique"
                                            use-chips
                                            use-input
                                            readonly
                                    />
                                    <div class="history_input_wrapper">
                                        <q-select
                                                v-model="ap_stage_id"
                                                :disable="awaitResponse"
                                                :error="v$.ap_stage_id.$error"
                                                :error-message="
            v$.ap_stage_id.$error ? v$.ap_stage_id.$errors[0].$message : ''
          "
                                                label="Стадия"
                                                :options="stages"
                                                emit-value
                                                map-options
                                                multiple
                                                new-value-mode="add-unique"
                                                use-chips
                                                use-input
                                                readonly
                                        />
                                    </div>
                                    <div class="history_button_wrapper">
                                        <q-btn
                                                icon="mdi-history"
                                                text-color="black"
                                                size="sm"
                                                @click="showCadastralHistoryDialog(id)"
                                        />
                                    </div>

                                    <div class="history_input_wrapper">
                                        <q-select
                                                v-model="ap_date_state_commission"
                                                :disable="awaitResponse"
                                                :error="v$.ap_date_state_commission.$error"
                                                :error-message="
            v$.ap_date_state_commission.$error ? v$.ap_date_state_commission.$errors[0].$message : ''
          "
                                                label="ГК"
                                                multiple
                                                new-value-mode="add-unique"
                                                use-chips
                                                use-input
                                                readonly
                                        />
                                    </div>
                                    <div class="history_button_wrapper">
                                        <q-btn
                                                icon="mdi-history"
                                                text-color="black"
                                                size="sm"
                                                @click="showCadastralHistoryDialog(id)"
                                        />
                                    </div>

                                    <div class="history_input_wrapper">
                                        <q-select
                                                v-model="ap_agreement_id"
                                                :disable="awaitResponse"
                                                :error="v$.ap_agreement_id.$error"
                                                :error-message="
            v$.ap_agreement_id.$error ? v$.ap_agreement_id.$errors[0].$message : ''
          "
                                                label="Договор"
                                                :options="agreements"
                                                emit-value
                                                map-options
                                                multiple
                                                new-value-mode="add-unique"
                                                use-chips
                                                use-input
                                                readonly
                                        />
                                    </div>
                                    <div class="history_button_wrapper">
                                        <q-btn
                                                icon="mdi-history"
                                                text-color="black"
                                                size="sm"
                                                @click="showCadastralHistoryDialog(id)"
                                        />
                                    </div>

                                </q-card-section>

                            </q-card>
                        </div>

                        <div class="col q-pa-sm" v-if="isEdit">
                            <q-card class="my-card bg-secondary text-white" style="min-width: 350px!important;
                                background-color: rgb(255 255 255) !important;
                                border: 5px solid #bae586;">
                                <q-card-section>

                                    <div class="history_input_wrapper">
                                        <q-select
                                                v-model="statement_status"
                                                :disable="awaitResponse"
                                                :error="v$.statement_status.$error"
                                                :error-message="
            v$.statement_status.$error ? v$.statement_status.$errors[0].$message : ''
          "
                                                label="Статус заказа выписок"
                                                :options="statement_statuses"
                                                emit-value
                                                map-options
                                                new-value-mode="add-unique"
                                                use-chips
                                                use-input
                                        />
                                    </div>
                                    <div class="history_button_wrapper">
                                        <q-btn
                                                icon="mdi-history"
                                                text-color="black"
                                                size="sm"
                                                @click="showCadastralHistoryDialog(id)"
                                        />
                                    </div>

                                    <div class="history_input_wrapper">
                                        <q-select
                                                v-model="rosreestr_status"
                                                :disable="awaitResponse"
                                                :error="v$.rosreestr_status.$error"
                                                :error-message="
            v$.rosreestr_status.$error ? v$.rosreestr_status.$errors[0].$message : ''
          "
                                                label="Статус Росреестра"
                                                :options="rosreestr_statuses"
                                                emit-value
                                                map-options
                                                new-value-mode="add-unique"
                                                use-chips
                                                use-input
                                        />
                                    </div>
                                    <div class="history_button_wrapper">
                                        <q-btn
                                                icon="mdi-history"
                                                text-color="black"
                                                size="sm"
                                                @click="showCadastralHistoryDialog(id)"
                                        />
                                    </div>

                                    <div class="history_input_wrapper">
                                        <q-input
                                                v-model.trim="ap_document_date"
                                                :disable="awaitResponse"
                                                :error="v$.ap_document_date.$error"
                                                :error-message="v$.ap_document_date.$error ? v$.ap_document_date.$errors[0].$message : ''"
                                                bottom-slots
                                                clearable
                                                dense
                                                label="Дата актуальности выписки"
                                                readonly
                                        />
                                    </div>
                                    <div class="history_button_wrapper">
                                        <q-btn
                                                icon="mdi-history"
                                                text-color="black"
                                                size="sm"
                                                @click="showCadastralHistoryDialog(id)"
                                        />
                                    </div>

                                    <div class="history_input_wrapper">
                                        <q-input
                                                v-model.trim="ap_registration_date"
                                                :disable="awaitResponse"
                                                :error="v$.ap_registration_date.$error"
                                                :error-message="v$.ap_registration_date.$error ? v$.ap_registration_date.$errors[0].$message : ''"
                                                bottom-slots
                                                clearable
                                                dense
                                                label="Дата последней регистрации сделки"
                                                readonly
                                        />
                                    </div>
                                    <div class="history_button_wrapper">
                                        <q-btn
                                                icon="mdi-history"
                                                text-color="black"
                                                size="sm"
                                                @click="showCadastralHistoryDialog(id)"
                                        />
                                    </div>

                                    <div class="history_input_wrapper">
                                        <q-input
                                                v-model.trim="ap_document_count"
                                                :disable="awaitResponse"
                                                :error="v$.ap_document_count.$error"
                                                :error-message="v$.ap_document_count.$error ? v$.ap_document_count.$errors[0].$message : ''"
                                                bottom-slots
                                                clearable
                                                dense
                                                label="Кол-во записей в выписке"
                                                readonly
                                        />
                                    </div>
                                    <div class="history_button_wrapper">
                                        <q-btn
                                                icon="mdi-history"
                                                text-color="black"
                                                size="sm"
                                                @click="showCadastralHistoryDialog(id)"
                                        />
                                    </div>

                                    <div class="history_input_wrapper">
                                        <q-input
                                                v-model.trim="user_id"
                                                :disable="awaitResponse"
                                                :error="v$.user_id.$error"
                                                :error-message="v$.user_id.$error ? v$.user_id.$errors[0].$message : ''"
                                                bottom-slots
                                                clearable
                                                dense
                                                readonly
                                                label="Специалист"
                                        />
                                    </div>
                                    <div class="history_button_wrapper">
                                        <q-btn
                                                icon="mdi-history"
                                                text-color="black"
                                                size="sm"
                                                @click="showCadastralHistoryDialog(id)"
                                        />
                                    </div>

                                </q-card-section>


                            </q-card>
                        </div>

                        <div class="col q-pa-sm">
                            <q-card class="my-card bg-secondary text-white" style="min-width: 350px!important;
                                background-color: rgb(255 255 255) !important;
                                border: 5px solid #e5d586;">
                                <q-card-section>
                                    <div class="history_input_wrapper">
                                        <q-input
                                                v-model.trim="note"
                                                :disable="awaitResponse"
                                                :error="v$.note.$error"
                                                :error-message="v$.note.$error ? v$.note.$errors[0].$message : ''"
                                                bottom-slots
                                                clearable
                                                dense
                                                label="Примечание"
                                        />
                                    </div>
                                    <div class="history_button_wrapper">
                                        <q-btn
                                                icon="mdi-history"
                                                text-color="black"
                                                size="sm"
                                                @click="showCadastralHistoryDialog(id)"
                                        />
                                    </div>
                                </q-card-section>

                                <q-separator dark/>

                            </q-card>

                            <q-card class="my-card bg-secondary text-white" style="min-width: 350px!important;
                                background-color: rgb(255 255 255) !important;
                                border: 5px solid #86c1e5;">
                                <q-card-section>
                                    <q-btn
                                        color="primary"
                                        icon="mdi-alpha-r"
                                        label="Запустить проверку в Росреестре"
                                        :disable="$store.state.queuesWorkload.rosreestr_state.pull_job_executing"
                                        @click="runRosreestrDataPulling(id)"
                                    ></q-btn>
                                    <hr>
                                    <q-input
                                        v-model="last_deal_date"
                                        bottom-slots
                                        clearable
                                        dense
                                        readonly
                                        label="Дата послед. Регистрации в РР"
                                    />
                                    <q-input
                                        v-model="current_month_deals_count"
                                        bottom-slots
                                        clearable
                                        dense
                                        readonly
                                        label="Кол-во записей в РР за мес"
                                    />
                                    <q-input
                                        v-model="current_month_deals"
                                        bottom-slots
                                        clearable
                                        dense
                                        readonly
                                        label="Новые сделки за месяц РР"
                                    />
                                    <q-input
                                        v-model="three_months_deals"
                                        bottom-slots
                                        clearable
                                        dense
                                        readonly
                                        label="Новые сделки за квартал РР"
                                    />
                                    <q-input
                                        v-model="deals_count_diff_with_last_time"
                                        bottom-slots
                                        clearable
                                        dense
                                        readonly
                                        label="Разница по кол-ву сделок в РР"
                                    />
                                    <q-input
                                        v-model="rosreestr_updated_at"
                                        bottom-slots
                                        clearable
                                        dense
                                        readonly
                                        label="Дата сбора данных в РР"
                                    />
                                    <q-input
                                        v-model="rosreestr_response_status"
                                        bottom-slots
                                        clearable
                                        dense
                                        readonly
                                        label="Статус ответа"
                                    />

                                </q-card-section>
                                <q-separator dark/>
                            </q-card>
                        </div>

                    </div>
                </div>
            </div>


            <q-btn
                    :disable="awaitResponse"
                    :loading="awaitResponse"
                    color="primary"
                    :label="this.addButtonLabel"
                    :title="this.addButtonLabel"
                    @click="submit"
            />
        </div>
        <br>
    </div>
</template>

<script>
import api from "@/api";
import {required} from "@/utils/i18n-validators";
import useVuelidate from "@vuelidate/core";
import {createMetaMixin} from "quasar";
import {useRoute} from "vue-router";
import {normalizeQueryForRequest} from "../../utils/batch";
import {helpers} from "@vuelidate/validators";
import CadastralHistoryDialog from "../../components/dialogs/CadastralHistoryDialog.vue";

function doValidateCadastral(value) {
    return value.toString().match(/^[, :0-9]*$/);
}

const cadastralValidate = (value) => doValidateCadastral(value)

export default {
    name: "CadastralEdit",
    mixins: [
        createMetaMixin(function () {
            return {
                title: "База Кадастров",
            };
        }),
    ],

    setup() {
        return {v$: useVuelidate()};
    },

    emits: ["hide"],

    data() {
        return {
            awaitResponse: false,
            isEdit: false,
            addButtonLabel: 'Добавить',

            classes: [
                {label: 'Эконом', value: 'Эконом'},
                {label: 'Комфорт', value: 'Комфорт'},
                {label: 'Бизнес', value: 'Бизнес'},
                {label: 'Элитный', value: 'Элитный'},
                {label: 'Премиум', value: 'Премиум'},
                {label: 'Бизнес-', value: 'Бизнес-'},
                {label: 'Бизнес+', value: 'Бизнес+'}
            ],

            stages: [
                {label: 'Строительство не начато', value: 'Строительство не начато'},
                {label: 'Введен в эксплуатацию', value: 'Введен в эксплуатацию'},
                {label: 'Начало монтажных работ', value: 'Начало монтажных работ'},
                {label: 'Монтажные и отделочные работы', value: 'Монтажные и отделочные работы'},
                {label: 'Получение РВЭ, благоустройство территории', value: 'Получение РВЭ, благоустройство территории'},
                {label: 'Работы нулевого цикла', value: 'Работы нулевого цикла'},
                {label: 'Реконструкция', value: 'Реконструкция'}
            ],

            agreements: [
                {label: 'КП', value: 'КП'},
                {label: 'Предварительный КП', value: 'Предварительный КП'},
                {label: 'Договор соинвестирования', value: 'Договор соинвестирования'},
                {label: 'ДДУ без эскроу', value: 'ДДУ без эскроу'},
                {label: 'Уступка прав от юрлица', value: 'Уступка прав от юрлица'},
                {label: 'ДДУ и уступка по ДДУ', value: 'ДДУ и уступка по ДДУ'},
                {label: 'Уступка прав по ДДУ', value: 'Уступка прав по ДДУ'},
                {label: 'ДДУ и соинвестирование', value: 'ДДУ и соинвестирование'},
                {label: 'ЖСК', value: 'ЖСК'},
                {label: 'Предварительный ДДУ', value: 'Предварительный ДДУ'},
                {label: 'Уступка прав от физлица', value: 'Уступка прав от физлица'},
                {label: 'Договор бронирования', value: 'Договор бронирования'},
                {label: 'Уступка прав по ДДУ, КП', value: 'Уступка прав по ДДУ, КП'},
                {label: 'Уступка прав по ДДУ, Предварительный КП', value: 'Уступка прав по ДДУ, Предварительный КП'},
                {label: 'ДВОУ', value: 'ДВОУ'},
                {label: 'Предварительный КП, ДДУ', value: 'Предварительный КП, ДДУ'},
                {label: 'Договор опциона', value: 'Договор опциона'},
                {label: 'Договор купли будущей недвижимой вещи', value: 'Договор купли будущей недвижимой вещи'},
                {label: 'Договор инвестирования', value: 'Договор инвестирования'},
                {label: 'ДДУ, КП', value: 'ДДУ, КП'},
                {label: 'Предварительный КП, КП', value: 'Предварительный КП, КП'},
                {label: 'ДДУ с эскроу', value: 'ДДУ с эскроу'},
                {label: 'Договор купли будущей недвижимой вещи, Предварительный КП', value: 'Договор купли будущей недвижимой вещи, Предварительный КП'},
            ],

            statement_statuses: [
                {label: 'Активный', value: 'Активный'},
                {label: 'Новый', value: 'Новый'},
                {label: 'Не активен', value: 'Не активен'},
                {label: 'Архив', value: 'Архив'},
                {label: 'Пустой', value: 'Пустой'},

            ],

            rosreestr_statuses: [
                {label: 'Сделки', value: 'Сделки'},
                {label: 'Нет сделок', value: 'Нет сделок'},
                {label: 'Архив', value: 'Архив'},
            ],

            regions: [],
            regionsOptions: [],

            id: null,
            cadastral: null,
            region_name: null,
            ap_id: null,
            domrf_id: null,
            created_at: null,
            updated_at: null,
            projects: null,
            addresses: null,
            statement_status: null,
            rosreestr_status: null,
            ap_objectclass_id: null,
            ap_stage_id: null,
            ap_date_state_commission: null,
            ap_agreement_id: null,
            ap_document_date: null,
            ap_document_count: null,
            ap_registration_date: null,
            user_id: null,
            note: null,
            host: null,
            last_deal_date: null,
            current_month_deals_count: null,
            current_month_deals: null,
            three_months_deals: null,
            deals_count_diff_with_last_time: null,
            rosreestr_updated_at: null,
            rosreestr_response_status: null,
        };
    },

    async mounted() {
        let id = useRoute().query.id;
        if (id) {
            this.isEdit = true
            this.addButtonLabel = 'Сохранить'

            const res = await api.cadastral.getOne({
                q: normalizeQueryForRequest({id: id})
            });

            if (res) {
                this.id = res.data[0].id
                this.cadastral = res.data[0].cadastral
                this.region_name = res.data[0].region_name
                this.ap_id = res.data[0].ap_id
                this.domrf_id = res.data[0].domrf_id
                this.created_at = res.data[0].created_at
                this.updated_at = res.data[0].updated_at
                this.projects = res.data[0].projects
                this.addresses = res.data[0].addresses
                this.statement_status = res.data[0].statement_status
                this.rosreestr_status = res.data[0].rosreestr_status
                this.ap_objectclass_id = res.data[0].ap_objectclass_id
                this.ap_stage_id = res.data[0].ap_stage_id
                this.ap_date_state_commission = res.data[0].ap_date_state_commission
                this.ap_agreement_id = res.data[0].ap_agreement_id
                this.ap_document_date = res.data[0].ap_document_date
                this.ap_document_count = res.data[0].ap_document_count
                this.ap_registration_date = res.data[0].ap_registration_date
                this.user_id = res.data[0].user_id
                this.note = res.data[0].note
                this.host = res.data[0].host
                this.last_deal_date = res.data[0].last_deal_date
                this.current_month_deals_count = res.data[0].current_month_deals_count
                this.current_month_deals = this.russianBoolean(res.data[0].current_month_deals)
                this.three_months_deals = this.russianBoolean(res.data[0].three_months_deals)
                this.deals_count_diff_with_last_time = res.data[0].deals_count_diff_with_last_time
                this.rosreestr_updated_at = res.data[0].rosreestr_updated_at
                this.rosreestr_response_status = res.data[0].rosreestr_response_status
            }
        }

        let regions = await api.cadastral.getRegionsList();
        if (regions) {
            this.regions = regions.data
        }
        this.regionsOptions = this.regions
    },

    methods: {
        async submit() {
            this.awaitResponse = true;

            const valid = await this.v$.$validate();

            if (!valid) {
                this.awaitResponse = false;

                return;
            }

            let payload = {
                cadastral: this.cadastral,
                region_name: this.region_name,
                ap_id: this.ap_id,
                domrf_id: this.domrf_id,
                created_at: this.created_at,
                updated_at: this.updated_at,
                projects: this.projects,
                addresses: this.addresses,
                statement_status: this.statement_status,
                rosreestr_status: this.rosreestr_status,
                user_id: this.user_id,
                note: this.note
            };

            if (this.isEdit) {
                api.cadastral
                    .update(this.id, payload)
                    .then(
                        (res) => {
                            this.$q.notify({
                                color: "positive",
                                message: res.data.message,
                            });

                        },
                        (error) => {
                            this.$q.notify({
                                color: "negative",
                                message: error.response.data.message,
                            });
                        }
                    )
                    .then(() => {
                        this.awaitResponse = false;
                    });
            } else {
                api.cadastral
                    .create(payload)
                    .then(
                        (res) => {
                            this.$q.notify({
                                color: "positive",
                                message: res.data.message,
                            });

                        },
                        (error) => {
                            this.$q.notify({
                                color: "negative",
                                message: error.response.data.message,
                            });
                        }
                    )
                    .then(() => {
                        this.awaitResponse = false;
                    });
            }
        },

        filterFn (val, update) {
            update(() => {
                const needle = val.toLowerCase()
                this.regionsOptions = this.regions.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
            })
        },

        getBuildingLink(host, id) {
            return `https://${host}/parser/building/update/${id}`;
        },

        showCadastralHistoryDialog(id) {
            this.$q.dialog({
                component: CadastralHistoryDialog,
                componentProps: {
                    cadastralId: id,
                },
            });
        },

        async runRosreestrDataPulling(cadastral) {
            let ids = [cadastral]

            this.$q
                .dialog({
                    title: `Проверка в Росреестре`,
                    message: "Вы хотите заказать "+ ids.length +" кадастровых номеров. Все правильно?",
                    ok: {
                        label: "Да",
                    },
                })
                .onOk(async () => {
                    await api.cadastral.runRosreestrDataPulling(ids).then(
                        (res) => {
                            this.$q.notify({
                                color: "positive",
                                message: "Задача поставлена в очередь",
                            });
                        },
                        (error) => {
                            this.$q.notify({
                                color: "negative",
                                message: error.response.data.message,
                            });
                        }
                    )
                });
        },

        russianBoolean(val) {
            if (val === true) {
                return 'Да'
            }
            if (val === false) {
                return 'Нет'
            }

            return null
        },

    },

    validations() {
        return {
            cadastral: {
                required,
                cadastralValidate:
                    helpers.withMessage('Разрешаются только цифры и символ двоеточние', cadastralValidate)
            },
            region_name: {required},
            ap_id: {},
            domrf_id: {},
            created_at: {},
            updated_at: {},
            projects: {},
            addresses: {},
            statement_status: {},
            rosreestr_status: {},
            ap_objectclass_id: {},
            ap_stage_id: {},
            ap_date_state_commission: {},
            ap_agreement_id: {},
            ap_document_date: {},
            ap_document_count: {},
            ap_registration_date: {},
            user_id: {},
            note: {},
        };
    },
};
</script>

<style>
.history_input_wrapper {
    width: 85%;
    display: inline-block;
}

.history_button_wrapper {
    width: 15%;
    display: inline-block;
    text-align: right;
}

.building-link {
    padding-right: 5px;
    padding-left: 5px;
    color:black;
}
</style>
