<template>
  <div v-if="!loading" class="col">
    <div class="row justify-center">
      <div class="col col-md-12">
        <div class="bg-white q-pa-md rounded-borders">
          <div class="flex column">
            <q-checkbox
              v-model="settings.autoparse_box_pds_updated"
              :disable="!+$can(['settings.pd.update'])"
              label="Запускать парс ПД при обновлении списка ПД файлов ДомРФ корпуса"
            />
            <q-checkbox
              v-model="settings.autoparse_box_created"
              :disable="!+$can(['settings.pd.update'])"
              label="Запускать парс ПД при добавлении ДомРФ корпуса"
            />
          </div>

          <q-btn
            :loading="awaitUpdating"
            :disable="awaitUpdating"
            label="Сохранить"
            color="primary"
            class="q-mt-md"
            @click="save"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import api from "@/api";
  import { createMetaMixin } from "quasar";

  export default {
    name: "PdSettings",

    mixins: [
      createMetaMixin(function () {
        return {
          title: "Настройки ПД",
        };
      }),
    ],

    async mounted() {
      await this.setSettings();
      this.loading = false;
    },

    data() {
      return {
        loading: true,
        awaitUpdating: false,
        settings: null,
      };
    },

    methods: {
      async setSettings() {
        const res = await api.settings.findPd();
        this.settings = res.data;
      },

      async save() {
        let payload = {
          autoparse_box_pds_updated: this.settings.autoparse_box_pds_updated,
          autoparse_box_created: this.settings.autoparse_box_created,
        };

        this.awaitUpdating = true;

        return api.settings
          .updatePd(payload)
          .then(
            (res) => {
              this.$q.notify({
                color: "positive",
                message: res.data.message,
              });
            },
            (error) => {
              this.$q.notify({
                color: "negative",
                message: error.response.data.message,
              });
            }
          )
          .then(() => {
            this.awaitUpdating = false;
          });
      },
    },
  };
</script>
