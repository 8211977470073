import { axiosInstance as axios } from "@/plugins/axios";

const comment = {
  create(entity, entityId, data, include = "") {
    return axios.post(`comments/${entity}/${entityId}`, data, {
          params: { include },
        });
  },

  update(id, data, include = "") {
    return include
      ? axios.patch(`comments/${id}`, data, { params: { include } })
      : axios.patch(`comments/${id}`, data);
  },

  delete(entity, entityId, commentId) {
    return axios.delete(`comments/${entity}/${entityId}/${commentId}`);
  },
};

export default comment;
