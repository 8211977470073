<template>
  <div v-if="Object.keys(user).length" class="col col-md-10 shadow-2 bg-white">
    <div class="q-gutter-md q-pa-md relative-position">
      <q-badge v-show="isOwner" color="green" floating label="Это Вы" />

      <div class="row flex items-center">
        <div class="col-9 col-md-6 text-grey-8">Имя</div>
        <div class="col-15 col-md-18">{{ user.name }}</div>
      </div>

      <div class="row flex items-center">
        <div class="col-9 col-md-6 text-grey-8">Email</div>
        <div class="col-15 col-md-18">{{ user.email }}</div>
      </div>

      <div class="row flex items-center">
        <div class="col-9 col-md-6 text-grey-8">Роль</div>
        <div class="col-15 col-md-18">{{ user.roles[0].readable_name }}</div>
      </div>

      <div class="row flex items-center">
        <div class="col-9 col-md-6 text-grey-8">Статус</div>
        <div class="col-15 col-md-18">
          {{ user.status === 3 ? "Активен" : "Заблокирован" }}
        </div>
      </div>

      <div class="row flex items-center">
        <div class="col-9 col-md-6 text-grey-8">Создан</div>
        <div class="col-15 col-md-18">{{ user.readable_created_at }}</div>
      </div>

      <div class="row flex items-center">
        <div class="col-9 col-md-6 text-grey-8">Обновлен</div>
        <div class="col-15 col-md-18">
          <span class="q-mr-sm">{{ user.readable_updated_at }}</span>
          <q-btn
            v-if="user.editor"
            :disable="
              !+$can(['user.update']).andLevelGreater(user.roles[0].level)
            "
            :label="user.editor.name"
            :to="{ name: 'UserDetails', params: { id: user.editor.id } }"
            color="primary"
            flat
          />
        </div>
      </div>

      <q-btn
        :disable="!+$can(['user.update']).andLevelGreater(user.roles[0].level)"
        :to="{ name: 'UserEdit', params: { id: user.id } }"
        color="primary"
        label="Редактировать"
      />

      <q-inner-loading :showing="loading" />
    </div>
  </div>
</template>

<script>
  import api from "@/api";
  import { createMetaMixin } from "quasar";

  export default {
    name: "UserDetails",

    props: {
      id: {
        type: Number,
        required: true,
      },
    },

    mixins: [
      createMetaMixin(function () {
        return {
          title: this.title,
        };
      }),
    ],

    data() {
      return {
        loading: false,
        title: "",
        user: {},
      };
    },

    computed: {
      isOwner() {
        return this.$store.getters["auth/userId"] === this.user.id;
      },
    },

    watch: {
      "$route.params.id"(id) {
        this.setUser(id);
      },
    },

    created() {
      this.setUser(this.id);
    },

    methods: {
      async setUser(id) {
        this.loading = true;

        const res = await api.user.findUserById(id, "roles,editor");

        if (res.status === 200) {
          this.user = res.data;
          this.title = res.data.name;
        }

        this.loading = false;
      },
    },
  };
</script>
